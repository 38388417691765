import { request } from "./base";

/* NOTE: Receive import errors when import getData/postData from base (when added to base.ts) */

export const updateDataReaload = async (data: {
  dealerCode: string;
  startMonth: string;
  endMonth: string;
  actmonth: string;
  progress: number;
  quality: number;
  mappingOption: string;
}) => {
  const response = await request("/submission/data-reload", {
    method: "POST",
    body: JSON.stringify({ data }),
  });
  if (response.status < 400) {
    return;
  }
  const { error } = await response.json();
  const err = { error: error.message, statusCode: response.status };
  throw err;
};

export const dealerCubeReloadForRange = async (data: {
  dealerCode: string;
  startMonth: string;
  endMonth: string;
}) => {
  const response = await request("/submission/cube-reload-for-month-range", {
    method: "POST",
    body: JSON.stringify({ data }),
  });
  if (response.status < 400) {
    return;
  }
  const { error } = await response.json();
  const err = { error: error.message, statusCode: response.status };
  throw err;
};

export const getDataQuality = async (dealerCode: string, actmonth: string) => {
  const response = await request(`/submission/data-quality/${dealerCode}?actmonth=${encodeURIComponent(actmonth)}`, {
    method: "GET",
  });
  if (response.status < 300) {
    return response.json();
  }
  const err = { error: "Error", statusCode: response.status };
  throw err;
};

export const dealerDataReset = async (data: {
  dealerCode: string;
  startMonth: string;
  endMonth: string;
  actmonth: string;
  progress: number;
  quality: number;
}) => {
  const response = await request("/submission/data-reset", {
    method: "POST",
    body: JSON.stringify({ data }),
  });
  if (response.status < 400) {
    return;
  }
  const { error } = await response.json();
  const err = { error: error.message, statusCode: response.status };
  throw err;
};

export const getDataReloadProgress = async (dealerCode: string, startMonth: string, endMonth: string) => {
  const response = await request(
    `/submission/data-reload/progress/${dealerCode}?startMonth=${encodeURIComponent(startMonth)}&endMonth=${encodeURIComponent(endMonth)}`,
    {
      method: "GET",
    },
  );
  if (response.status < 300) {
    return response.json();
  }
  const err = { error: "Error", statusCode: response.status };
  throw err;
};

export const getDealerEndMonth = async (dealerCode: string, startMonth: string): Promise<string> => {
  const response = await request(`/submission/dealer/endmonth/${dealerCode}/${startMonth}`, {
    method: "GET",
  });

  if (response.status !== 200) {
    let json;
    try {
      json = await response.json();
    } catch (error) {
      throw new Error(response.statusText);
    }
    throw json.error ? json.error : new Error(json.message);
  }

  const { data } = await response.json();
  return data;
};

export const getChildDealers = async (dealerCode: string): Promise<string[]> => {
  const response = await request(`/submission/dealer/childDealers/${dealerCode}`, {
    method: "GET",
  });

  if (response.status !== 200) {
    let json;
    try {
      json = await response.json();
    } catch (error) {
      throw new Error(response.statusText);
    }
    throw json.error ? json.error : new Error(json.message);
  }

  const { data } = await response.json();
  return data;
};