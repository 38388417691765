import React, { createContext, useState, useReducer, useMemo } from "react";
import { Loading } from "./base";
import { Attachment, getAttachments, createAttachments, deleteAttachment } from "../lib/api/attachments";

const defaultAttachments = [];

export type AttachmentsContextState = {
  attachments: Attachment[];
  loading: Loading;
  hydrate();
  createAttachments(attachment: Partial<Attachment>);
  deleteAttachment(id);
  getAttachments(): Attachment[];
};


export const AttachmentsContext = createContext<AttachmentsContextState>({
  attachments: defaultAttachments,
  loading: { loading: false, loaded: false, error: null },
  hydrate: () => {},
  createAttachments: () => {},  
  deleteAttachment: () => {},
  getAttachments: () => [],
});


export const AttachmentsProvider = ({ children }) => {
  const [attachments, setAttachments] = useState<Attachment[]>(defaultAttachments);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const hydrate = async () => {
    try {
      setLoading({
        loading: true,
        loaded: false,
        error: null,
      });
      const attachments = await getAttachments();
      setAttachments(attachments);
      setLoading({
        loading: false,
        loaded: true,
        error: null,
      });
    } catch (e) {
      setLoading({
        loading: false,
        loaded: false,
        error: e,
      });
    }
  }

  const value = useMemo(() => ({
    attachments,
    loading,
    hydrate,
    getAttachments() {
      if (loading.loaded && !loading.loading) {
        return attachments;
      }
      if (!loading.loading && !loading.error) {
        hydrate();
      }
      return [];
    },
    createAttachments: async (attachment: Attachment) => {
      try {
        setLoading({
          loading: true,
          loaded: false,
          error: null,
        });
        await createAttachments(attachment);
        setLoading({
          loading: false,
          loaded: true,
          error: null,
        });
        hydrate();
      } catch (e) {
        setLoading({
          loading: false,
          loaded: false,
          error: e,
        });
      }
    },
    deleteAttachment: async (id: number) => {
      try {
        setLoading({
          loading: true,
          loaded: false,
          error: null,
        });
        await deleteAttachment(id);
        setLoading({
          loading: false,
          loaded: true,
          error: null,
        });
        hydrate();
      } catch (e) {
        setLoading({
          loading: false,
          loaded: false,
          error: e,
        });
      }
    },
    setAttachments,
  }), [attachments, loading]);

  return (
    <AttachmentsContext.Provider value={value}>
      {children}
    </AttachmentsContext.Provider>
  )
}
