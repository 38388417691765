export const apiHeaders = (content: { length: number } = { length: 0 }, options: {} = {}) =>
  new Headers({
    Accept: "application/json",
    ...(content && content.length ? { "Content-Length": `${content.length}` } : {}),
    "Content-Type": "application/json",
    "X-csrf-validation": "-",
    ...options,
  });

const config = {
  url: process.env.REACT_APP_BASE_URL || "/api",
};

export const request = (path: string, options: RequestInit = {}) =>
  fetch(`${config.url}/${path}`, {
    headers: apiHeaders((options.body as any) || ""),
    credentials: "include",
    ...options,
  });

export const login = async (fields: { email: string; password: string; market: string }) => {
  const response = await request("/login", {
    method: "POST",
    body: JSON.stringify(fields),
  });
  return response.json();
};

export const resetPassword = async (username: string, market: string) => {
  const response = await request("/reset-password", {
    method: "POST",
    body: JSON.stringify({ username, market, locale: "default" }),
  });
  return response.json();
};

export const updatePassword = async (passwordToken: string, password: string, market: string) => {
  const response = await request("/update-password", {
    method: "POST",
    body: JSON.stringify({ passwordToken, password, market }),
  });
  return response.json();
};

export type All<T> = () => Promise<T[]>;

export const all = <T>(endpoint: string): All<T> => async () => {
  const result = await request(endpoint);
  const { data } = await result.json();
  return data;
};

export type Single<T> = (id: string) => Promise<T>;

export const single = <T>(endpoint: string): Single<T> => async (id: string) => {
  const response = await request(`${endpoint}/${id}`);
  const { data } = await response.json();
  return data;
};

export type Create<T> = (item: Partial<T>) => Promise<T>;

export const create = <T>(endpoint: string): Create<T> => async (item: any) => {
  const content = JSON.stringify({ data: item });
  const response = await request(endpoint, {
    method: "POST",
    headers: apiHeaders(content),
    body: content,
  });
  const { data } = await response.json();
  return data;
};

const wait = (ms: number) =>
  new Promise<void>(resolve => {
    setTimeout(() => {
      resolve();
    }, ms);
  });

export type Update<T> = (id: string, item: Partial<T>) => Promise<void>;

export const update = <T>(endpoint: string): Update<T> => async (id: string, item: T) => {
  const content = JSON.stringify({ data: item });
  await wait(1000);
  const response = await request(`${endpoint}/${id}`, {
    method: "PUT",
    headers: apiHeaders(content),
    body: content,
  });
  const { data } = await response.json();
  return data;
};

export type Remove<T> = (id: string) => Promise<void>;
export const remove = <T>(endpoint: string): Remove<T> => async (id: string) => {
  await request(`${endpoint}/${id}`, {
    method: "DELETE",
  });
};

export interface Client<T> {
  all: All<T>;
  single: Single<T>;
  update: Update<T>;
  create: Create<T>;
  remove: Remove<T>;
}

export const client = <T>(endpoint: string): Client<T> => ({
  all: all<T>(endpoint),
  single: single<T>(endpoint),
  update: update<T>(endpoint),
  create: create<T>(endpoint),
  remove: remove<T>(endpoint),
});

const defaultTransformCallback = async function<T>(response: Response): Promise<T> {
  const { data } = await response.json();
  return data;
};

export const rawJsonTransformCallback = async function<T>(response: Response): Promise<T> {
  return await response.json();
};

export const voidTransformCallback = async function(response: Response): Promise<void> {
  return new Promise((res, rej) => res());
};

const defaultErrorCallback = async function(response: Response): Promise<Error> {
  let json;
  try {
    json = await response.json();
  } catch (error) {
    throw new Error(response.statusText);
  }
  return json.error ? json.error : new Error(json.message);
};

const restCall = async function<T>(
  request: () => Promise<Response>,
  onError: string | ((response) => Promise<Error>) = defaultErrorCallback,
  transformCallback: (response: Response) => Promise<T> = defaultTransformCallback,
): Promise<T> {
  const response = await request();

  if (response.status !== 200) {
    if (typeof onError === "string") {
      throw new Error(onError);
    } else {
      const err = await onError(response);
      throw err;
    }
  }

  return await transformCallback(response);
};

export const getData = async function<T>(
  url: string,
  onError: string | ((response) => Promise<Error>) = defaultErrorCallback,
  transformCallback: (response: Response) => Promise<T> = defaultTransformCallback,
): Promise<T> {
  return restCall(async () => request(url, { method: "GET" }), onError, transformCallback);
};

/**
 * Posts data to the server for processing and expects a json response.
 *
 * @param url
 * @param data A data object to submit for processing, or a FormData object for binary data.
 * @param onError If an error is returned from the server, then the onError function is run. If onError is a string, then a new Error is thrown with the given string as its message.
 * @param transformCallback
 */
export const postData = async function<T, R>(
  url: string,
  data: T,
  onError: string | ((response) => Promise<Error>) = defaultErrorCallback,
  transformCallback: (response: Response) => Promise<R> = defaultTransformCallback,
): Promise<R> {
  return restCall(
    async () =>
      request(url, {
        ...{
          method: "POST",
          body: data instanceof FormData ? data : JSON.stringify(data || {}),
        },
        ...(data instanceof FormData
          ? {
              headers: new Headers({
                Accept: "application/json",
                "X-csrf-validation": "-",
              }),
            }
          : {}),
      }),
    onError,
    transformCallback,
  );
};
