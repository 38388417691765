import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import React from "react";
import { Route } from "react-router-dom";
import { MenuItem } from "./MenuItem";
import { Tabber } from "./Tabber";
import { Dealer } from "../lib/api/charts";
import CircularProgress from "@material-ui/core/CircularProgress";
import { locations } from "../layout/Layout";
import { TabAccessContext } from "../contexts/TabAccessContext";
import { translation } from "../translations/Translations";
import { useIntl } from "react-intl";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "flex",
      },
      width: "100%",
      flexDirection: "row",
      marginTop: "0.5em !important",
      flexWrap: "wrap",
      alignItems: "center",
      flexGrow: 1,
      backgroundColor: "#191919",
      color: "#fff",
      border: "none",
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    },
    tab: {
      padding: theme.spacing.unit * 2,
      borderTopRightRadius: "3px",
      borderTopLeftRadius: "3px",
    },
    selected: {
      backgroundColor: "#fff",
      color: "#191919",
      border: "none",
      boxShadow: "none",
    },
  });

export type DashboardTabsProps = {
  className: any;
  dealer: Dealer;
  oemSpecificOption?: boolean;
} & WithStyles<typeof styles>;

export const fixedOemLocations = [
  { to: "/oem/fixed/home", label: "Home", id: "OEM Dashboard / Fixed / Home", count: 0, children: [] },
  { to: "/oem/fixed/fordacademy", label: "Ford Academy", id: "OEM Dashboard / Fixed / Ford Academy", count: 0, children: [] },
  { to: "/oem/fixed/integrated-report", label: "Integrated Report", id: "OEM Dashboard / Fixed / Integrated Report", count: 0, children: [] },
  { to: "/oem/fixed/risk", label: "Risk Dashboard", id: "OEM Dashboard / Fixed / Risk", count: 0, children: [] },
  {
    to: "/oem/fixed/foa-performance-snapshot",
    label: "FoA Performance Snapshot",
    id: "OEM Dashboard / Fixed / FoA Performance Snapshot",
    count: 0,
    children: [],
  },
  { to: "/oem/fixed/foa-nv-dept", label: "FoA NV Dept", id: "OEM Dashboard / Fixed / FoA NV Dept", count: 0, children: [] },
  { to: "/oem/fixed/foa-aftersales-dept", label: "FoA After Sales Dept", id: "OEM Dashboard / Fixed / FoA After Sales Dept", count: 0, children: [] },
];

export const dynamicOemLocations = [
  { to: "/oem/dynamic/home", label: "Home", id: "OEM Dashboard / Dynamic / Home", count: 0, children: [] },
  { to: "/oem/dynamic/fordacademy", label: "Ford Academy", id: "OEM Dashboard / Dynamic / Ford Academy", count: 0, children: [] },
  { to: "/oem/dynamic/integrated-report", label: "Integrated Report", id: "OEM Dashboard / Dynamic / Integrated Report", count: 0, children: [] },
  { to: "/oem/dynamic/risk", label: "Risk Dashboard", id: "OEM Dashboard / Dynamic / Risk", count: 0, children: [] },
  {
    to: "/oem/dynamic/foa-performance-snapshot",
    label: "FoA Performance Snapshot",
    id: "OEM Dashboard / Dynamic / FoA Performance Snapshot",
    count: 0,
    children: [],
  },
  { to: "/oem/dynamic/foa-nv-dept", label: "FoA NV Dept", id: "OEM Dashboard / Dynamic / FoA NV Dept", count: 0, children: [] },
  { to: "/oem/dynamic/foa-aftersales-dept", label: "FoA After Sales Dept", id: "OEM Dashboard / Dynamic / FoA After Sales Dept", count: 0, children: [] },
];

const defaultRoute = (availableLocations: string[], location: any) => {
  const childLocations = /fixed/.test(location.to) ? fixedOemLocations : dynamicOemLocations;

  const firstTab = childLocations.filter(tabLocation =>
    availableLocations.find(item => {
      return item == tabLocation.id;
    }),
  );

  if (firstTab.length > 0) {
    return firstTab[0].to;
  }
  return "/";
};

const firstTab = (availableLocations: string[], location: any) => defaultRoute(availableLocations, location);

const DashboardTabsUnstlyed: React.FunctionComponent<DashboardTabsProps> = ({ classes, dealer, oemSpecificOption = false, children, className }) => {
  const { tabs: accessTabs, loading } = React.useContext(TabAccessContext);

  if (loading.loading) {
    <CircularProgress size={15} />;
  }

  const availLocations = Object.values(accessTabs).map((tab: any) => tab.name);

  const intl = useIntl();

  return (
    <>
      <Tabber className={classnames(classes.root, className)}>
        <Route
          path="/dashboard/:department?"
          render={({ match }) =>
            locations.dashboard.children.map(location => {
              if (/Home/.test(location.id) || availLocations.includes(location.id)) {
                return (
                  <MenuItem to={location.to} key={location.label} active={match.url == location.to} count={location.count}>
                    {translation[location.label] ? intl.formatMessage({ id: translation[location.label] }) : location.label}
                  </MenuItem>
                );
              }
              return null;
            })
          }
        />
        <Route
          path="/oem/fixed/:dashboard?"
          render={({ match }) =>
            locations.OEMDashboard.children.map(location => {
              if (availLocations.includes(location.id)) {
                return (
                  <MenuItem to={firstTab(availLocations, location)} key={location.label} active={/fixed/.test(location.to)} count={location.count}>
                    {translation[location.label] ? intl.formatMessage({ id: translation[location.label] }) : location.label}
                  </MenuItem>
                );
              }
              return null;
            })
          }
        />
        <Route
          path="/oem/dynamic/:dashboard?"
          render={({ match }) =>
            locations.OEMDashboard.children.map(location => {
              if (availLocations.includes(location.id)) {
                return (
                  <MenuItem to={firstTab(availLocations, location)} key={location.label} active={/dynamic/.test(location.to)} count={location.count}>
                    {translation[location.label] ? intl.formatMessage({ id: translation[location.label] }) : location.label}
                  </MenuItem>
                );
              }
              return null;
            })
          }
        />
      </Tabber>
      <Tabber className={classnames(classes.root, className)}>
        <Route
          path="/oem/fixed/:dashboard?"
          render={({ match }) =>
            fixedOemLocations.map(location => {
              if (availLocations.includes(location.id)) {
                return (
                  <MenuItem to={location.to} key={location.label} active={match.url == location.to} count={location.count}>
                    {translation[location.label] ? intl.formatMessage({ id: translation[location.label] }) : location.label}
                  </MenuItem>
                );
              }
              return null;
            })
          }
        />
        <Route
          path="/oem/dynamic/:dashboard?"
          render={({ match }) =>
            dynamicOemLocations.map(location => {
              if (availLocations.includes(location.id)) {
                return (
                  <MenuItem to={location.to} key={location.label} active={match.url == location.to} count={location.count}>
                    {translation[location.label] ? intl.formatMessage({ id: translation[location.label] }) : location.label}
                  </MenuItem>
                );
              }
              return null;
            })
          }
        />
      </Tabber>
    </>
  );
};

export const DashboardTabs = withStyles(styles)(DashboardTabsUnstlyed);
