import React, { createContext, useState, useMemo, useContext } from "react";
import { IntlProvider } from "react-intl";
import messages_en from "../translations/en.json";
import messages_vn from "../translations/vn.json";
import messages_th from "../translations/th.json";
import messages_jp from "../translations/jp.json";
import messages_tw from "../translations/tw.json";
import messages_cn from "../translations/cn.json";
import { getDefaultLocale, updateLanguageConfig, whoami } from "../lib/api/user";
import { useLoading, Loading } from "../hooks/useLoading";

const browserLang = {
  en: "en",
  "zh-CN": "cn",
  "zh-TW": "tw",
  th: "th",
  vi: "vn",
  ja: "jp",
};

const defaultLanguage = localStorage.getItem("esos-upload-language") || browserLang[navigator.language] || "en";

type LanguageTypes = {
  label: string;
  value: string;
};

export const messages = {
  en: messages_en,
  vn: messages_vn,
  th: messages_th,
  jp: messages_jp,
  tw: messages_tw,
  cn: messages_cn,
};

export type LanguageContextState = {
  language: string;
  updateUserLanguage(selectedLanguage: string): void;
  updateLanguageConfig(selectedLanguage: string): Promise<void>;
  languageOptions: LanguageTypes[];
  hydrate();
  loading: Loading;
};

export const LanguageContext = createContext<LanguageContextState>({
  language: defaultLanguage,
  updateUserLanguage: () => {},
  updateLanguageConfig: () => Promise.resolve(),
  languageOptions: [],
  hydrate: () => {},
  loading: { loading: false, loaded: false, error: null },
});

const fullLanguageSet = [
  { value: "vn", label: "Tiếng Việt" },
  { value: "th", label: "ไทย" },
  { value: "jp", label: "日本語" },
  { value: "tw", label: "繁體中文" },
  { value: "cn", label: "简体中文" },
];

const fullLanguageCodeSet = [
  { value: "vn", label: "[VN] Tiếng Việt" },
  { value: "th", label: "[TH] ไทย" },
  { value: "jp", label: "[JP] 日本語" },
  { value: "tw", label: "[TW] 繁體中文" },
  { value: "cn", label: "[CN] 简体中文" },
];

export const javaLanguageOptions = {
  cn: "zh_CN",
  tw: "zh_TW",
  th: "th_TH",
  vn: "vi_VN",
  jp: "ja_JP",
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(defaultLanguage || "en");
  const [loading, setLoading, withLoading] = useLoading<void>();
  //NB: 日本語 was taken from Google Translate. Needs to be replaced if not correct.

  const [languageOptions, setLanguageOptions] = useState<LanguageTypes[]>([{ value: "en", label: "English" }, ...fullLanguageSet]);

  const updateUserLanguage = (selectedLanguage: string) => {
    setLanguage(selectedLanguage);
    localStorage.setItem("esos-upload-language", selectedLanguage);
  };

  const hydrate = async () => {
    return withLoading(async () => {
      const defaultLocale = await getDefaultLocale();
      // Added extra whoami call so the list is populated without having to wait for UserContext. May need revision. Results in 2 whoami calls compared to 8 config calls.
      const user = await whoami();
      // This should make testing languages easier.
      const viewCode = user.email.toLowerCase().includes("@vulcanlabs.com");
      setLanguage(language == "en" ? defaultLocale.language : language);
      setLanguageOptions([
        viewCode ? { value: "en", label: "[EN] English" } : { value: "en", label: "English" },
        ...(viewCode ? fullLanguageCodeSet : fullLanguageSet).filter(language => {
          return user.languageAccess.map(languages => languages.locale).includes(language.value);
        }),
      ]);
    });
  };

  const value = useMemo(
    () => ({
      language,
      languageOptions,
      updateUserLanguage,
      updateLanguageConfig: async (selectedLanguage: string) => {
        const languageConfig = languageOptions.find(lang => lang.value === selectedLanguage).value;
        await updateLanguageConfig({ value: languageConfig });
        updateUserLanguage(selectedLanguage);
      },
      setLanguage,
      hydrate,
      loading,
    }),
    [language, languageOptions],
  );

  return (
    <LanguageContext.Provider value={value}>
      <IntlProvider locale={language} messages={messages[language]} defaultLocale={"en"}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};
