import React, { createContext, useState, useReducer, useMemo } from "react";
import { Loading } from "./base";
import { Country, countries as getCountries } from "../lib/api/countries";

const defaultCountry = [];

export type CountryContextState = {
  countries: Country[];
  loading: Loading;
  hydrate();
};


export const CountryContext = createContext<CountryContextState>({
  countries: defaultCountry,
  loading: { loading: false, loaded: false, error: null },
  hydrate: () => {},
});


export const CountryProvider = ({ children }) => {
  const [countries, setCountries] = useState<Country[]>(defaultCountry);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });

  const value = useMemo(() => ({
    countries,
    loading,
    hydrate: async () => {
      try {
        setLoading({
          loading: true,
          loaded: false,
          error: null,
        });
        const returnedcountries = await getCountries();
        setCountries(returnedcountries);
        setLoading({
          loading: false,
          loaded: true,
          error: null,
        });
      } catch (e) {
        setLoading({
          loading: false,
          loaded: false,
          error: e,
        });
      }
    },
  }), [countries, loading]);

  return (
    <CountryContext.Provider value={value}>
      {children}
    </CountryContext.Provider>
  )
}
