import { Theme } from "@material-ui/core/styles";
export const drawerWidth = 240;

export const toolbar = (theme: Theme) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: "0 8px",
  ...theme.mixins.toolbar
});
