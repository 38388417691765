import { request } from "./base";

export const getAdditionalStats = (dealers: string[]) =>
  request(`/custom/additional-stats`, {
    method: "POST",
    body: JSON.stringify({ dealers: dealers }),
  }).then(res => res.json());

export const getCustomDashDepartments = () =>
  request(`/custom/departments`, {
    method: "GET",
  }).then(res => res.json());
