import React, { createContext, useState, useMemo, useContext, useCallback } from "react";
import { Loading } from "../base";
import { getDealerModels as apiGetDealerModels } from "../../lib/api/dealer-models";
import { CustomDashboardModelTemplate, CustomDashboardRetailUnitsTemplate } from "../../charts/custom-dashboard-reports/NewVehicle";
import { CustomDashboardType } from "../../charts/CustomDashReports";

export type DealerModelsContextState = {
  dealerModels: string[];
  carlineAnalysisMeasures: any;
  loading: Loading;
  getDealerModels(dealers: string[]);
};

export const DealerModelsContext = createContext<DealerModelsContextState>({
  dealerModels: [],
  carlineAnalysisMeasures: {},
  loading: { loading: false, loaded: false, error: null },
  getDealerModels: () => {},
});

export const DealerModelsProvider = ({ children }) => {
  const [dealerModels, setDealerModels] = useState<string[]>([]);
  const [carlineAnalysisMeasures, setCarlineAnalysisMeasures] = useState({});
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });

  const getDealerModels = useCallback(
    async (dealers: string[]) => {
      try {
        setLoading({
          loading: true,
          loaded: false,
          error: null,
        });
        const result = await apiGetDealerModels(dealers);
        const models = result.data.map(r => r.model);
        setDealerModels(models);
        const customLabels = models.map((model, i) => {
          return CustomDashboardModelTemplate(model, 501 + i);
        });

        const retailUnitsLabels = models.map((model, i) => {
          return CustomDashboardRetailUnitsTemplate(model, 601 + i);
        });

        var a: { [index: string]: CustomDashboardType } = {};

        customLabels.map(label => (a[label.name] = label));
        retailUnitsLabels.map(label => (a[label.name] = label));

        setCarlineAnalysisMeasures(a);
        setLoading({
          loading: false,
          loaded: true,
          error: null,
        });
      } catch (e) {
        setLoading({
          loading: false,
          loaded: false,
          error: e,
        });
      }
    },
    [setLoading, setDealerModels, setCarlineAnalysisMeasures],
  );
  const value = useMemo(
    () => ({
      dealerModels,
      carlineAnalysisMeasures,
      loading,
      getDealerModels,
    }),
    [dealerModels, carlineAnalysisMeasures, loading, getDealerModels],
  );

  return <DealerModelsContext.Provider value={value}>{children}</DealerModelsContext.Provider>;
};
