import React, { createContext, useState, useMemo, FC, useContext } from "react";
import { Loading } from "./base";
import {
  flashReportsConfig,
  flashReportDealerAnalysis,
  flashReportNetworkAnalysis,
  OemComparativeDealer,
  getFlashReportLanguages,
  FlashReportLanguages,
} from "../lib/api/flash-reports-config";
import { DealerContext } from "./DealerContext";

const defaultFlashReportsConfig = [{ name: "", oemcode: "", dealercode: "", language: "", inventorydays: 0 }];

export type FlashReportsConfigContextState = {
  flashReports: OemComparativeDealer[];
  dealerAnalysis: Partial<OemComparativeDealer>[];
  networkAnalysis: Partial<OemComparativeDealer>[];
  loading: Loading;
  languagesList: FlashReportLanguages[];
  languagesLoading: Loading;
  hydrate();
  getFlashReportLanguages(dealercode: string, datasetTitle: string): void;
};

export const FlashReportsConfigContext = createContext<FlashReportsConfigContextState>({
  flashReports: defaultFlashReportsConfig,
  dealerAnalysis: defaultFlashReportsConfig,
  networkAnalysis: defaultFlashReportsConfig,
  languagesList: [],
  loading: { loading: false, loaded: false, error: null },
  languagesLoading: { loading: false, loaded: false, error: null },
  hydrate: () => {},
  getFlashReportLanguages: () => Promise.resolve(),
});

export const FlashReportsConfigProvider: FC = ({ children }) => {
  const [flashReports, setFlashReports] = useState<OemComparativeDealer[]>(defaultFlashReportsConfig);
  const [dealerAnalysis, setDealerAnalysis] = useState<Partial<OemComparativeDealer>[]>(defaultFlashReportsConfig);
  const [networkAnalysis, setNetworkAnalysis] = useState<Partial<OemComparativeDealer>[]>(defaultFlashReportsConfig);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const [languagesLoading, setLanguagesLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const [flashReportLanguages, setFlashReportLanguages] = useState<FlashReportLanguages[]>([]);

  const languagesList: FlashReportLanguages[] = useMemo(() => {
    if (!flashReportLanguages) {
      return [];
    }
    return flashReportLanguages;
  }, [flashReportLanguages]);

  const value = useMemo(
    () => ({
      flashReports,
      dealerAnalysis,
      networkAnalysis,
      loading,
      languagesLoading,
      languagesList,
      hydrate: async () => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          const flashReports = await flashReportsConfig();
          setFlashReports(flashReports);
          const dealerAnalysis = await flashReportDealerAnalysis();
          setDealerAnalysis(dealerAnalysis);
          const networkAnalysis = await flashReportNetworkAnalysis();
          setNetworkAnalysis(networkAnalysis);
          setLoading({
            loading: false,
            loaded: true,
            error: null,
          });
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      getFlashReportLanguages: async (dealercode: string, datasetTitle: string) => {
        try {
          setLanguagesLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          const languages = await getFlashReportLanguages(dealercode, datasetTitle);
          setFlashReportLanguages(languages);
          setLanguagesLoading({
            loading: false,
            loaded: true,
            error: null,
          });
        } catch (e) {
          setLanguagesLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
    }),
    [flashReports, dealerAnalysis, networkAnalysis, loading, languagesLoading, flashReportLanguages],
  );
  return <FlashReportsConfigContext.Provider value={value}>{children}</FlashReportsConfigContext.Provider>;
};
