import { CustomDashboardType, getMeasure } from "../CustomDashReports";

export const BalanceSheet: { [index: string]: CustomDashboardType } = {
  "BS > BS KPIs > Current Ratio": {
    name: "BS > BS KPIs > Current Ratio",
    department: "Balance Sheet",
    category: "BS KPIs",
    label: "Current Ratio",
    tableNumber: 18,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Current Ratio | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "BS > BS KPIs > Working Capital in Days of Turnover": {
    name: "BS > BS KPIs > Working Capital in Days of Turnover",
    department: "Balance Sheet",
    category: "BS KPIs",
    label: "Working Capital in Days of Turnover",
    tableNumber: 19,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Working Capital Days | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "BS > BS KPIs > Working Capital / Unit": {
    name: "BS > BS KPIs > Working Capital / Unit",
    department: "Balance Sheet",
    category: "BS KPIs",
    label: "Working Capital / Unit",
    tableNumber: 19,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Working Capital per Unit | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > BS KPIs > Working Capital Amount": {
    name: "BS > BS KPIs > Working Capital Amount",
    department: "Balance Sheet",
    category: "BS KPIs",
    label: "Working Capital Amount",
    tableNumber: 20,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Working Capital | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > BS KPIs > CCC = DIO + DSO - DPO": {
    name: "BS > BS KPIs > CCC = DIO + DSO - DPO",
    department: "Balance Sheet",
    category: "BS KPIs",
    label: "CCC = DIO + DSO - DPO",
    tableNumber: 21,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Cash Conversion Cycle | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > BS KPIs > DIO (Total Inventory Days)": {
    name: "BS > BS KPIs > DIO (Total Inventory Days)",
    department: "Balance Sheet",
    category: "BS KPIs",
    label: "DIO (Total Inventory Days)",
    tableNumber: 23,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory days | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > BS KPIs > DSO (Total Debtor Days)": {
    name: "BS > BS KPIs > DSO (Total Debtor Days)",
    department: "Balance Sheet",
    category: "BS KPIs",
    label: "DSO (Total Debtor Days)",
    tableNumber: 22,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Debtors days | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > BS KPIs > DPO (Total Payable Days)": {
    name: "BS > BS KPIs > DPO (Total Payable Days)",
    department: "Balance Sheet",
    category: "BS KPIs",
    label: "DPO (Total Payable Days)",
    tableNumber: 21,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Payables days | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > BS KPIs > NV Inventory Days": {
    name: "BS > BS KPIs > NV Inventory Days",
    department: "Balance Sheet",
    category: "BS KPIs",
    label: "NV Inventory Days",
    tableNumber: 23,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory days | New | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > BS KPIs > UV Inventory Days": {
    name: "BS > BS KPIs > UV Inventory Days",
    department: "Balance Sheet",
    category: "BS KPIs",
    label: "UV Inventory Days",
    tableNumber: 23,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory days | Used | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > BS KPIs > Parts Inventory Days": {
    name: "BS > BS KPIs > Parts Inventory Days",
    department: "Balance Sheet",
    category: "BS KPIs",
    label: "Parts Inventory Days",
    tableNumber: 23,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory days | Parts | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > BS KPIs > DEBT to EQUITY": {
    name: "BS > BS KPIs > DEBT to EQUITY",
    department: "Balance Sheet",
    category: "BS KPIs",
    label: "DEBT to EQUITY",
    tableNumber: 24,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Debt to Equity | All Department | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "BS > BS KPIs > DEBT to ASSET": {
    name: "BS > BS KPIs > DEBT to ASSET",
    department: "Balance Sheet",
    category: "BS KPIs",
    label: "DEBT to ASSET",
    tableNumber: 24,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Debt to Asset Ratio | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "BS > BS KPIs > ROCE": {
    name: "BS > BS KPIs > ROCE",
    department: "Balance Sheet",
    category: "BS KPIs",
    label: "ROCE",
    tableNumber: 25,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Return On Capital Employed (ROCE) | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > CURRENT RATIO > Current Ratio": {
    name: "BS > CURRENT RATIO > Current Ratio",
    department: "Balance Sheet",
    category: "CURRENT RATIO",
    label: "Current Ratio",
    tableNumber: 18,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Current Ratio | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "BS > CURRENT RATIO > Average Total Current Assets": {
    name: "BS > CURRENT RATIO > Average Total Current Assets",
    department: "Balance Sheet",
    category: "CURRENT RATIO",
    label: "Average Total Current Assets",
    tableNumber: 18,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Current Assets | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > CURRENT RATIO > Average Total Current Liabilities": {
    name: "BS > CURRENT RATIO > Average Total Current Liabilities",
    department: "Balance Sheet",
    category: "CURRENT RATIO",
    label: "Average Total Current Liabilities",
    tableNumber: 18,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Current Liabilities | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > CURRENT RATIO > Cash and Cash equivalents": {
    name: "BS > CURRENT RATIO > Cash and Cash equivalents",
    department: "Balance Sheet",
    category: "CURRENT RATIO",
    label: "Cash and Cash equivalents",
    tableNumber: 18,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Cash & Cash Equivalent | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > CURRENT RATIO > Receivables": {
    name: "BS > CURRENT RATIO > Receivables",
    department: "Balance Sheet",
    category: "CURRENT RATIO",
    label: "Receivables",
    tableNumber: 18,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Trade and Other Receivables | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > CURRENT RATIO > Inventory": {
    name: "BS > CURRENT RATIO > Inventory",
    department: "Balance Sheet",
    category: "CURRENT RATIO",
    label: "Inventory",
    tableNumber: 18,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Net Inventories | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > CURRENT RATIO > Other Current Assets": {
    name: "BS > CURRENT RATIO > Other Current Assets",
    department: "Balance Sheet",
    category: "CURRENT RATIO",
    label: "Other Current Assets",
    tableNumber: 18,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Other Current assets | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > CURRENT RATIO > Creditors & Other Current Liabilities": {
    name: "BS > CURRENT RATIO > Creditors & Other Current Liabilities",
    department: "Balance Sheet",
    category: "CURRENT RATIO",
    label: "Creditors & Other Current Liabilities",
    tableNumber: 18,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Trade and Other Payables | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > CURRENT RATIO > Deferred Tax": {
    name: "BS > CURRENT RATIO > Deferred Tax",
    department: "Balance Sheet",
    category: "CURRENT RATIO",
    label: "Deferred Tax",
    tableNumber: 18,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Deferred Tax | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > CURRENT RATIO > Deposits": {
    name: "BS > CURRENT RATIO > Deposits",
    department: "Balance Sheet",
    category: "CURRENT RATIO",
    label: "Deposits",
    tableNumber: 18,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Deposits | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > CURRENT RATIO > Overdraft": {
    name: "BS > CURRENT RATIO > Overdraft",
    department: "Balance Sheet",
    category: "CURRENT RATIO",
    label: "Overdraft",
    tableNumber: 18,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Overdraft | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > CURRENT RATIO > Provisions": {
    name: "BS > CURRENT RATIO > Provisions",
    department: "Balance Sheet",
    category: "CURRENT RATIO",
    label: "Provisions",
    tableNumber: 18,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Provisions | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > CURRENT RATIO > S/T Loan Inter Company": {
    name: "BS > CURRENT RATIO > S/T Loan Inter Company",
    department: "Balance Sheet",
    category: "CURRENT RATIO",
    label: "S/T Loan Inter Company",
    tableNumber: 18,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > STL > Inter Company | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > CURRENT RATIO > S/T Loan Other": {
    name: "BS > CURRENT RATIO > S/T Loan Other",
    department: "Balance Sheet",
    category: "CURRENT RATIO",
    label: "S/T Loan Other",
    tableNumber: 18,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Short Term Loans excl. Inter Company | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL DAYS > Working Capital in Days of Turnover": {
    name: "BS > WORKING CAPITAL DAYS > Working Capital in Days of Turnover",
    department: "Balance Sheet",
    category: "WORKING CAPITAL DAYS",
    label: "Working Capital in Days of Turnover",
    tableNumber: 19,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Working Capital Days | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > WORKING CAPITAL DAYS > Average Total Current Assets": {
    name: "BS > WORKING CAPITAL DAYS > Average Total Current Assets",
    department: "Balance Sheet",
    category: "WORKING CAPITAL DAYS",
    label: "Average Total Current Assets",
    tableNumber: 19,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Current Assets | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL DAYS > Average Total Current Liabilities": {
    name: "BS > WORKING CAPITAL DAYS > Average Total Current Liabilities",
    department: "Balance Sheet",
    category: "WORKING CAPITAL DAYS",
    label: "Average Total Current Liabilities",
    tableNumber: 19,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Current Liabilities | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL DAYS > Working Capital / Unit": {
    name: "BS > WORKING CAPITAL DAYS > Working Capital / Unit",
    department: "Balance Sheet",
    category: "WORKING CAPITAL DAYS",
    label: "Working Capital / Unit",
    tableNumber: 19,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Working Capital per Unit | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > WORKING CAPITAL DAYS > Current Ratio": {
    name: "BS > WORKING CAPITAL DAYS > Current Ratio",
    department: "Balance Sheet",
    category: "WORKING CAPITAL DAYS",
    label: "Current Ratio",
    tableNumber: 19,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Current Ratio | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "BS > WORKING CAPITAL DAYS > Cash and Cash equivalents": {
    name: "BS > WORKING CAPITAL DAYS > Cash and Cash equivalents",
    department: "Balance Sheet",
    category: "WORKING CAPITAL DAYS",
    label: "Cash and Cash equivalents",
    tableNumber: 19,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Cash & Cash Equivalent | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL DAYS > Receivables": {
    name: "BS > WORKING CAPITAL DAYS > Receivables",
    department: "Balance Sheet",
    category: "WORKING CAPITAL DAYS",
    label: "Receivables",
    tableNumber: 19,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Trade and Other Receivables | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL DAYS > Inventory": {
    name: "BS > WORKING CAPITAL DAYS > Inventory",
    department: "Balance Sheet",
    category: "WORKING CAPITAL DAYS",
    label: "Inventory",
    tableNumber: 19,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Net Inventories | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL DAYS > Other Current Assets": {
    name: "BS > WORKING CAPITAL DAYS > Other Current Assets",
    department: "Balance Sheet",
    category: "WORKING CAPITAL DAYS",
    label: "Other Current Assets",
    tableNumber: 19,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Other Current assets | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL DAYS > Creditors & Other Current Liabilities": {
    name: "BS > WORKING CAPITAL DAYS > Creditors & Other Current Liabilities",
    department: "Balance Sheet",
    category: "WORKING CAPITAL DAYS",
    label: "Creditors & Other Current Liabilities",
    tableNumber: 19,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Trade and Other Payables | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL DAYS > Deferred Tax": {
    name: "BS > WORKING CAPITAL DAYS > Deferred Tax",
    department: "Balance Sheet",
    category: "WORKING CAPITAL DAYS",
    label: "Deferred Tax",
    tableNumber: 19,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Deferred Tax | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL DAYS > Deposits": {
    name: "BS > WORKING CAPITAL DAYS > Deposits",
    department: "Balance Sheet",
    category: "WORKING CAPITAL DAYS",
    label: "Deposits",
    tableNumber: 19,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Deposits | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL DAYS > Overdraft": {
    name: "BS > WORKING CAPITAL DAYS > Overdraft",
    department: "Balance Sheet",
    category: "WORKING CAPITAL DAYS",
    label: "Overdraft",
    tableNumber: 19,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Overdraft | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL DAYS > Provisions": {
    name: "BS > WORKING CAPITAL DAYS > Provisions",
    department: "Balance Sheet",
    category: "WORKING CAPITAL DAYS",
    label: "Provisions",
    tableNumber: 19,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Provisions | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL DAYS > S/T Loan Inter Company": {
    name: "BS > WORKING CAPITAL DAYS > S/T Loan Inter Company",
    department: "Balance Sheet",
    category: "WORKING CAPITAL DAYS",
    label: "S/T Loan Inter Company",
    tableNumber: 19,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > STL > Inter Company | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL DAYS > S/T Loan Other": {
    name: "BS > WORKING CAPITAL DAYS > S/T Loan Other",
    department: "Balance Sheet",
    category: "WORKING CAPITAL DAYS",
    label: "S/T Loan Other",
    tableNumber: 19,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Short Term Loans excl. Inter Company | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL > Working Capital Amount": {
    name: "BS > WORKING CAPITAL > Working Capital Amount",
    department: "Balance Sheet",
    category: "WORKING CAPITAL",
    label: "Working Capital Amount",
    tableNumber: 20,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Working Capital | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL > Average Total Current Assets": {
    name: "BS > WORKING CAPITAL > Average Total Current Assets",
    department: "Balance Sheet",
    category: "WORKING CAPITAL",
    label: "Average Total Current Assets",
    tableNumber: 20,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Current Assets | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL > Average Total Current Liabilities": {
    name: "BS > WORKING CAPITAL > Average Total Current Liabilities",
    department: "Balance Sheet",
    category: "WORKING CAPITAL",
    label: "Average Total Current Liabilities",
    tableNumber: 20,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Current Liabilities | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL > Working Capital in Days of Turnover": {
    name: "BS > WORKING CAPITAL > Working Capital in Days of Turnover",
    department: "Balance Sheet",
    category: "WORKING CAPITAL",
    label: "Working Capital in Days of Turnover",
    tableNumber: 20,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Working Capital Days | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > WORKING CAPITAL > Working Capital / Unit": {
    name: "BS > WORKING CAPITAL > Working Capital / Unit",
    department: "Balance Sheet",
    category: "WORKING CAPITAL",
    label: "Working Capital / Unit",
    tableNumber: 20,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Working Capital per Unit | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > WORKING CAPITAL > Current Ratio": {
    name: "BS > WORKING CAPITAL > Current Ratio",
    department: "Balance Sheet",
    category: "WORKING CAPITAL",
    label: "Current Ratio",
    tableNumber: 20,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Current Ratio | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "BS > WORKING CAPITAL > Cash and Cash equivalents": {
    name: "BS > WORKING CAPITAL > Cash and Cash equivalents",
    department: "Balance Sheet",
    category: "WORKING CAPITAL",
    label: "Cash and Cash equivalents",
    tableNumber: 20,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Cash & Cash Equivalent | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL > Receivables": {
    name: "BS > WORKING CAPITAL > Receivables",
    department: "Balance Sheet",
    category: "WORKING CAPITAL",
    label: "Receivables",
    tableNumber: 20,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Trade and Other Receivables | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL > Inventory": {
    name: "BS > WORKING CAPITAL > Inventory",
    department: "Balance Sheet",
    category: "WORKING CAPITAL",
    label: "Inventory",
    tableNumber: 20,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Net Inventories | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL > Other Current Assets": {
    name: "BS > WORKING CAPITAL > Other Current Assets",
    department: "Balance Sheet",
    category: "WORKING CAPITAL",
    label: "Other Current Assets",
    tableNumber: 20,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Other Current assets | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL > Creditors & Other Current Liabilities": {
    name: "BS > WORKING CAPITAL > Creditors & Other Current Liabilities",
    department: "Balance Sheet",
    category: "WORKING CAPITAL",
    label: "Creditors & Other Current Liabilities",
    tableNumber: 20,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Trade and Other Payables | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL > Deferred Tax": {
    name: "BS > WORKING CAPITAL > Deferred Tax",
    department: "Balance Sheet",
    category: "WORKING CAPITAL",
    label: "Deferred Tax",
    tableNumber: 20,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Deferred Tax | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL > Deposits": {
    name: "BS > WORKING CAPITAL > Deposits",
    department: "Balance Sheet",
    category: "WORKING CAPITAL",
    label: "Deposits",
    tableNumber: 20,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Deposits | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL > Overdraft": {
    name: "BS > WORKING CAPITAL > Overdraft",
    department: "Balance Sheet",
    category: "WORKING CAPITAL",
    label: "Overdraft",
    tableNumber: 20,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Overdraft | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL > Provisions": {
    name: "BS > WORKING CAPITAL > Provisions",
    department: "Balance Sheet",
    category: "WORKING CAPITAL",
    label: "Provisions",
    tableNumber: 20,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Provisions | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL > S/T Loan Inter Company": {
    name: "BS > WORKING CAPITAL > S/T Loan Inter Company",
    department: "Balance Sheet",
    category: "WORKING CAPITAL",
    label: "S/T Loan Inter Company",
    tableNumber: 20,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > STL > Inter Company | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > WORKING CAPITAL > S/T Loan Other": {
    name: "BS > WORKING CAPITAL > S/T Loan Other",
    department: "Balance Sheet",
    category: "WORKING CAPITAL",
    label: "S/T Loan Other",
    tableNumber: 20,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Short Term Loans excl. Inter Company | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > CCC (Cash Conversion Cycle) > CCC = DIO + DSO - DPO": {
    name: "BS > CCC (Cash Conversion Cycle) > CCC = DIO + DSO - DPO",
    department: "Balance Sheet",
    category: "CCC (Cash Conversion Cycle)",
    label: "CCC = DIO + DSO - DPO",
    tableNumber: 21,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Cash Conversion Cycle | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > CCC (Cash Conversion Cycle) > DIO (Total Inventory Days)": {
    name: "BS > CCC (Cash Conversion Cycle) > DIO (Total Inventory Days)",
    department: "Balance Sheet",
    category: "CCC (Cash Conversion Cycle)",
    label: "DIO (Total Inventory Days)",
    tableNumber: 21,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory days | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > CCC (Cash Conversion Cycle) > DSO (Total Debtor Days)": {
    name: "BS > CCC (Cash Conversion Cycle) > DSO (Total Debtor Days)",
    department: "Balance Sheet",
    category: "CCC (Cash Conversion Cycle)",
    label: "DSO (Total Debtor Days)",
    tableNumber: 21,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Debtors days | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > CCC (Cash Conversion Cycle) > DPO (Total Payable Days)": {
    name: "BS > CCC (Cash Conversion Cycle) > DPO (Total Payable Days)",
    department: "Balance Sheet",
    category: "CCC (Cash Conversion Cycle)",
    label: "DPO (Total Payable Days)",
    tableNumber: 21,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Payables days | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > CCC (Cash Conversion Cycle) > NV Inventory Days": {
    name: "BS > CCC (Cash Conversion Cycle) > NV Inventory Days",
    department: "Balance Sheet",
    category: "CCC (Cash Conversion Cycle)",
    label: "NV Inventory Days",
    tableNumber: 21,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory days | New | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > CCC (Cash Conversion Cycle) > UV Inventory Days": {
    name: "BS > CCC (Cash Conversion Cycle) > UV Inventory Days",
    department: "Balance Sheet",
    category: "CCC (Cash Conversion Cycle)",
    label: "UV Inventory Days",
    tableNumber: 21,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory days | Used | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > CCC (Cash Conversion Cycle) > Parts Inventory Days": {
    name: "BS > CCC (Cash Conversion Cycle) > Parts Inventory Days",
    department: "Balance Sheet",
    category: "CCC (Cash Conversion Cycle)",
    label: "Parts Inventory Days",
    tableNumber: 21,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory days | Parts | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > CCC (Cash Conversion Cycle) > NV Inventory value mix (% Total Inv.)": {
    name: "BS > CCC (Cash Conversion Cycle) > NV Inventory value mix (% Total Inv.)",
    department: "Balance Sheet",
    category: "CCC (Cash Conversion Cycle)",
    label: "NV Inventory value mix (% Total Inv.)",
    tableNumber: 21,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Dept. Inventory % Total Inventory | New | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > CCC (Cash Conversion Cycle) > UV Inventory value mix (% Total Inv.)": {
    name: "BS > CCC (Cash Conversion Cycle) > UV Inventory value mix (% Total Inv.)",
    department: "Balance Sheet",
    category: "CCC (Cash Conversion Cycle)",
    label: "UV Inventory value mix (% Total Inv.)",
    tableNumber: 21,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Dept. Inventory % Total Inventory | Used | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > CCC (Cash Conversion Cycle) > Parts Inventory value mix (% Total Inv.)": {
    name: "BS > CCC (Cash Conversion Cycle) > Parts Inventory value mix (% Total Inv.)",
    department: "Balance Sheet",
    category: "CCC (Cash Conversion Cycle)",
    label: "Parts Inventory value mix (% Total Inv.)",
    tableNumber: 21,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Dept. Inventory % Total Inventory | Parts | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > DEBTOR DAYS > Total Debtor Days (DSO)": {
    name: "BS > DEBTOR DAYS > Total Debtor Days (DSO)",
    department: "Balance Sheet",
    category: "DEBTOR DAYS",
    label: "Total Debtor Days (DSO)",
    tableNumber: 22,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Debtors days | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > DEBTOR DAYS > Total Receivable (average)": {
    name: "BS > DEBTOR DAYS > Total Receivable (average)",
    department: "Balance Sheet",
    category: "DEBTOR DAYS",
    label: "Total Receivable (average)",
    tableNumber: 22,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Trade and Other Receivables | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > DEBTOR DAYS > Total Dealership Sales": {
    name: "BS > DEBTOR DAYS > Total Dealership Sales",
    department: "Balance Sheet",
    category: "DEBTOR DAYS",
    label: "Total Dealership Sales",
    tableNumber: 22,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "BS > DEBTOR DAYS > Receivables Non Vehicle": {
    name: "BS > DEBTOR DAYS > Receivables Non Vehicle",
    department: "Balance Sheet",
    category: "DEBTOR DAYS",
    label: "Receivables Non Vehicle",
    tableNumber: 22,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Receivables Non-Vehicles Total | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > DEBTOR DAYS > Receivables Vehicle": {
    name: "BS > DEBTOR DAYS > Receivables Vehicle",
    department: "Balance Sheet",
    category: "DEBTOR DAYS",
    label: "Receivables Vehicle",
    tableNumber: 22,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Receivables Vehicles | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > DEBTOR DAYS > Receivables Other": {
    name: "BS > DEBTOR DAYS > Receivables Other",
    department: "Balance Sheet",
    category: "DEBTOR DAYS",
    label: "Receivables Other",
    tableNumber: 22,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Receivables Other | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > TOTAL INVENTORY DAYS > Total Inventory Days (DIO)": {
    name: "BS > TOTAL INVENTORY DAYS > Total Inventory Days (DIO)",
    department: "Balance Sheet",
    category: "TOTAL INVENTORY DAYS",
    label: "Total Inventory Days (DIO)",
    tableNumber: 23,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory days | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > TOTAL INVENTORY DAYS > Total Inventory (average)": {
    name: "BS > TOTAL INVENTORY DAYS > Total Inventory (average)",
    department: "Balance Sheet",
    category: "TOTAL INVENTORY DAYS",
    label: "Total Inventory (average)",
    tableNumber: 23,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Net Inventories | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "BS > TOTAL INVENTORY DAYS > Total Cost of Goods Sold (COGS)": {
    name: "BS > TOTAL INVENTORY DAYS > Total Cost of Goods Sold (COGS)",
    department: "Balance Sheet",
    category: "TOTAL INVENTORY DAYS",
    label: "Total Cost of Goods Sold (COGS)",
    tableNumber: 23,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > COS > Cost of Sales (Inv days) | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "BS > TOTAL INVENTORY DAYS > NV Inventory Days": {
    name: "BS > TOTAL INVENTORY DAYS > NV Inventory Days",
    department: "Balance Sheet",
    category: "TOTAL INVENTORY DAYS",
    label: "NV Inventory Days",
    tableNumber: 23,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory days | New | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > TOTAL INVENTORY DAYS > UV Inventory Days": {
    name: "BS > TOTAL INVENTORY DAYS > UV Inventory Days",
    department: "Balance Sheet",
    category: "TOTAL INVENTORY DAYS",
    label: "UV Inventory Days",
    tableNumber: 23,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory days | Used | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > TOTAL INVENTORY DAYS > Parts Inventory Days": {
    name: "BS > TOTAL INVENTORY DAYS > Parts Inventory Days",
    department: "Balance Sheet",
    category: "TOTAL INVENTORY DAYS",
    label: "Parts Inventory Days",
    tableNumber: 23,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory days | Parts | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > TOTAL INVENTORY DAYS > NV Inventory value mix (% Total Inv.)": {
    name: "BS > TOTAL INVENTORY DAYS > NV Inventory value mix (% Total Inv.)",
    department: "Balance Sheet",
    category: "TOTAL INVENTORY DAYS",
    label: "NV Inventory value mix (% Total Inv.)",
    tableNumber: 23,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Dept. Inventory % Total Inventory | New | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > TOTAL INVENTORY DAYS > UV Inventory value mix (% Total Inv.)": {
    name: "BS > TOTAL INVENTORY DAYS > UV Inventory value mix (% Total Inv.)",
    department: "Balance Sheet",
    category: "TOTAL INVENTORY DAYS",
    label: "UV Inventory value mix (% Total Inv.)",
    tableNumber: 23,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Dept. Inventory % Total Inventory | Used | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > TOTAL INVENTORY DAYS > Parts Inventory value mix (% Total Inv.)": {
    name: "BS > TOTAL INVENTORY DAYS > Parts Inventory value mix (% Total Inv.)",
    department: "Balance Sheet",
    category: "TOTAL INVENTORY DAYS",
    label: "Parts Inventory value mix (% Total Inv.)",
    tableNumber: 23,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Dept. Inventory % Total Inventory | Parts | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "BS > DEBT to EQUITY > DEBT to EQUITY": {
    name: "BS > DEBT to EQUITY > DEBT to EQUITY",
    department: "Balance Sheet",
    category: "DEBT to EQUITY",
    label: "DEBT to EQUITY",
    tableNumber: 24,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Debt to Equity | All Department | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "BS > DEBT to EQUITY > Total Liabilities": {
    name: "BS > DEBT to EQUITY > Total Liabilities",
    department: "Balance Sheet",
    category: "DEBT to EQUITY",
    label: "Total Liabilities",
    tableNumber: 24,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "BS > Liabilities | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > DEBT to EQUITY > Total Shareholders Fund": {
    name: "BS > DEBT to EQUITY > Total Shareholders Fund",
    department: "Balance Sheet",
    category: "DEBT to EQUITY",
    label: "Total Shareholders Fund",
    tableNumber: 24,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CR > Capital and Reserves | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > DEBT to EQUITY > DEBT to ASSET": {
    name: "BS > DEBT to EQUITY > DEBT to ASSET",
    department: "Balance Sheet",
    category: "DEBT to EQUITY",
    label: "DEBT to ASSET",
    tableNumber: 24,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Debt to Asset Ratio | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "BS > DEBT to EQUITY > Total Assets excl. Goodwill & Parent Child Balance": {
    name: "BS > DEBT to EQUITY > Total Assets excl. Goodwill & Parent Child Balance",
    department: "Balance Sheet",
    category: "DEBT to EQUITY",
    label: "Total Assets excl. Goodwill & Parent Child Balance",
    tableNumber: 24,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "BS > Total Assets excl. Goodwill & Parent Child Balance | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > DEBT to EQUITY > Current Liabilities": {
    name: "BS > DEBT to EQUITY > Current Liabilities",
    department: "Balance Sheet",
    category: "DEBT to EQUITY",
    label: "Current Liabilities",
    tableNumber: 24,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Current Liabilities | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > DEBT to EQUITY > Non-Current Liabilities": {
    name: "BS > DEBT to EQUITY > Non-Current Liabilities",
    department: "Balance Sheet",
    category: "DEBT to EQUITY",
    label: "Non-Current Liabilities",
    tableNumber: 24,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "NCL > Non-Current Liabilities | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > DEBT to EQUITY > Directors Accounts": {
    name: "BS > DEBT to EQUITY > Directors Accounts",
    department: "Balance Sheet",
    category: "DEBT to EQUITY",
    label: "Directors Accounts",
    tableNumber: 24,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CR > Directors Accounts | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > DEBT to EQUITY > Issued Share Capital": {
    name: "BS > DEBT to EQUITY > Issued Share Capital",
    department: "Balance Sheet",
    category: "DEBT to EQUITY",
    label: "Issued Share Capital",
    tableNumber: 24,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CR > Share Capital | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > DEBT to EQUITY > Distributable Reserves": {
    name: "BS > DEBT to EQUITY > Distributable Reserves",
    department: "Balance Sheet",
    category: "DEBT to EQUITY",
    label: "Distributable Reserves",
    tableNumber: 24,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CR > Distributable | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > DEBT to EQUITY > Non Distributable Reserves": {
    name: "BS > DEBT to EQUITY > Non Distributable Reserves",
    department: "Balance Sheet",
    category: "DEBT to EQUITY",
    label: "Non Distributable Reserves",
    tableNumber: 24,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CR > Non Distributable | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > DEBT to EQUITY > Retained Earnings": {
    name: "BS > DEBT to EQUITY > Retained Earnings",
    department: "Balance Sheet",
    category: "DEBT to EQUITY",
    label: "Retained Earnings",
    tableNumber: 24,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CR > Retained Earnings | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > DEBT to EQUITY > Revaluation of Fixed Assets": {
    name: "BS > DEBT to EQUITY > Revaluation of Fixed Assets",
    department: "Balance Sheet",
    category: "DEBT to EQUITY",
    label: "Revaluation of Fixed Assets",
    tableNumber: 24,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CR > Revaluation of Fixed Assets | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > DEBT to EQUITY > Share Premium": {
    name: "BS > DEBT to EQUITY > Share Premium",
    department: "Balance Sheet",
    category: "DEBT to EQUITY",
    label: "Share Premium",
    tableNumber: 24,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CR > Share Premium | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > DEBT to EQUITY > Current Assets": {
    name: "BS > DEBT to EQUITY > Current Assets",
    department: "Balance Sheet",
    category: "DEBT to EQUITY",
    label: "Current Assets",
    tableNumber: 24,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Current Assets | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > DEBT to EQUITY > Land & Buildings": {
    name: "BS > DEBT to EQUITY > Land & Buildings",
    department: "Balance Sheet",
    category: "DEBT to EQUITY",
    label: "Land & Buildings",
    tableNumber: 24,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "NCA > Land & Buildings | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > DEBT to EQUITY > Equipment and Other Fixed Assets": {
    name: "BS > DEBT to EQUITY > Equipment and Other Fixed Assets",
    department: "Balance Sheet",
    category: "DEBT to EQUITY",
    label: "Equipment and Other Fixed Assets",
    tableNumber: 24,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "NCA > Fix Assets | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > DEBT to EQUITY > Investments excl. Parent Child Balance": {
    name: "BS > DEBT to EQUITY > Investments excl. Parent Child Balance",
    department: "Balance Sheet",
    category: "DEBT to EQUITY",
    label: "Investments excl. Parent Child Balance",
    tableNumber: 24,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "NCA > Investments excl. Parent Child Balance | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Current Assets": {
    name: "BS > BS > Current Assets",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Current Assets",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Current Assets | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Cash and Cash equivalents": {
    name: "BS > BS > Cash and Cash equivalents",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Cash and Cash equivalents",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Cash & Cash Equivalent | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Receivables": {
    name: "BS > BS > Receivables",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Receivables",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Trade and Other Receivables | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Receivables Non Vehicle": {
    name: "BS > BS > Receivables Non Vehicle",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Receivables Non Vehicle",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Receivables Non-Vehicles Total | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Receivables Vehicle": {
    name: "BS > BS > Receivables Vehicle",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Receivables Vehicle",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Receivables Vehicles | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Receivables Other": {
    name: "BS > BS > Receivables Other",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Receivables Other",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Receivables Other | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Inventory": {
    name: "BS > BS > Inventory",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Inventory",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Net Inventories | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > NV Inventory": {
    name: "BS > BS > NV Inventory",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "NV Inventory",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Net Inventories | New | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > UV Inventory": {
    name: "BS > BS > UV Inventory",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "UV Inventory",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Net Inventories | Used | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Parts Inventory": {
    name: "BS > BS > Parts Inventory",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Parts Inventory",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Net Inventories | Parts | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Other Inventory": {
    name: "BS > BS > Other Inventory",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Other Inventory",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Net Inventories Other | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Other Current Assets": {
    name: "BS > BS > Other Current Assets",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Other Current Assets",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Other Current assets | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Non Current Assets": {
    name: "BS > BS > Non Current Assets",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Non Current Assets",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "NCA > Non-Current Assets | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Land & Buildings": {
    name: "BS > BS > Land & Buildings",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Land & Buildings",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "NCA > Land & Buildings | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Equipment and Other Fixed Assets": {
    name: "BS > BS > Equipment and Other Fixed Assets",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Equipment and Other Fixed Assets",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "NCA > Fix Assets | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Goodwill": {
    name: "BS > BS > Goodwill",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Goodwill",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "NCA > Goodwill | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Investments": {
    name: "BS > BS > Investments",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Investments",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "NCA > Investments | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Total Assets": {
    name: "BS > BS > Total Assets",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Total Assets",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "BS > Assets | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Directors Accounts": {
    name: "BS > BS > Directors Accounts",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Directors Accounts",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CR > Directors Accounts | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Issued Share Capital": {
    name: "BS > BS > Issued Share Capital",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Issued Share Capital",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CR > Share Capital | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Distributable Reserves": {
    name: "BS > BS > Distributable Reserves",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Distributable Reserves",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CR > Distributable | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Non Distributable Reserves": {
    name: "BS > BS > Non Distributable Reserves",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Non Distributable Reserves",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CR > Non Distributable | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Retained Earnings": {
    name: "BS > BS > Retained Earnings",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Retained Earnings",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CR > Retained Earnings | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Revaluation of Fixed Assets": {
    name: "BS > BS > Revaluation of Fixed Assets",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Revaluation of Fixed Assets",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CR > Revaluation of Fixed Assets | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Share Premium": {
    name: "BS > BS > Share Premium",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Share Premium",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CR > Share Premium | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Total Shareholder Funds": {
    name: "BS > BS > Total Shareholder Funds",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Total Shareholder Funds",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CR > Capital and Reserves | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Current Liabilities": {
    name: "BS > BS > Current Liabilities",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Current Liabilities",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Current Liabilities | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Creditors & Other Current Liabilities": {
    name: "BS > BS > Creditors & Other Current Liabilities",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Creditors & Other Current Liabilities",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Trade and Other Payables | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Deferred Tax": {
    name: "BS > BS > Deferred Tax",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Deferred Tax",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Deferred Tax | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Deposits": {
    name: "BS > BS > Deposits",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Deposits",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Deposits | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Overdraft": {
    name: "BS > BS > Overdraft",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Overdraft",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Overdraft | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Provisions": {
    name: "BS > BS > Provisions",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Provisions",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Provisions | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > S/T Loan Inter Company": {
    name: "BS > BS > S/T Loan Inter Company",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "S/T Loan Inter Company",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > STL > Inter Company | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > S/T Loan Other": {
    name: "BS > BS > S/T Loan Other",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "S/T Loan Other",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CL > Short Term Loans excl. Inter Company | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Non-Current Liabilities": {
    name: "BS > BS > Non-Current Liabilities",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Non-Current Liabilities",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "NCL > Non-Current Liabilities | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > L/T Loan Shareholders": {
    name: "BS > BS > L/T Loan Shareholders",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "L/T Loan Shareholders",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "NCL > Long-term Loans > Shareholders | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > L/T Loan Inter Company": {
    name: "BS > BS > L/T Loan Inter Company",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "L/T Loan Inter Company",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "NCL > Long-term Loans > Inter company | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > L/T Loan Mortgage": {
    name: "BS > BS > L/T Loan Mortgage",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "L/T Loan Mortgage",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "NCL > Long-term Loans > Mortgage | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > L/T Loan Other": {
    name: "BS > BS > L/T Loan Other",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "L/T Loan Other",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "NCL > Long-term Loans > Other | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > TOTAL LIABILITIES": {
    name: "BS > BS > TOTAL LIABILITIES",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "TOTAL LIABILITIES",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "BS > Liabilities | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "BS > BS > Total Equity & Liabilities": {
    name: "BS > BS > Total Equity & Liabilities",
    department: "Balance Sheet",
    category: "Balance Sheet",
    label: "Total Equity & Liabilities",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "BS > Equity and Liabilities | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
};
