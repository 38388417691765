import React, { createContext, useState, useMemo, FC, useContext } from "react";
import { Dealer } from "../lib/api/charts";
import { childDealers } from "../lib/api/dealers";
import { Loading } from "./base";
import { BenchmarkContext } from "./BenchmarkContext";
import { DateContext } from "./FilterDateContext";
import { formatFullDate, formatDisplayDate } from "../charts/DashboardOuter";
import { Filters, FilterSet, DealerCode, DealerName, defaultDealer } from "./DealerContext";

export type OemDealerContextState = {
  loading: Loading;
  oemSelected: Dealer;
  oemFilters: Filters;
  oemFilterSet: FilterSet;
  oemDealerCode: DealerCode;
  oemDealerName: DealerName;
  setOemDealerCode(dealerCode: DealerCode): void;
  setOemDealerName(dealerName: DealerName): void;
  setOemDealer(dealer: Dealer): void;
  setOemFilters(obj: Filters): void;
  setOemFilterSet(obj: FilterSet): void;
};

export const OemDealerContext = createContext<OemDealerContextState>({
  loading: { loading: false, loaded: false, error: null },
  oemSelected: defaultDealer,
  oemFilters: { oem: [], region: [], dealerGroup: [], dealerSet: [], country: [], excludeNewDealers: false },
  oemFilterSet: {
    dealerCode: "",
    dealerName: "",
    filters: { oem: [], region: [], dealerGroup: [], dealerSet: [], country: [], excludeNewDealers: false },
  },
  oemDealerCode: { value: "" },
  oemDealerName: { value: "" },
  setOemDealerCode: () => {},
  setOemDealerName: () => {},
  setOemDealer: () => {},
  setOemFilters: () => {},
  setOemFilterSet: () => {},
});

export const OemDealerProviderInner: FC = ({ children }) => {
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const { date, updateDate } = useContext(DateContext);
  const { setOemBenchmark: setBenchmark, oemSelected: selected } = useContext(BenchmarkContext);
  const [oemDealer, xsetOemDealer] = useState<Dealer>(defaultDealer);
  const setOemDealer = (dealer?: Dealer) => {
    xsetOemDealer(dealer || defaultDealer);
  };
  const [oemDealerCode, setOemDealerCodeValue] = React.useState({ value: "" });
  const [oemDealerName, setOemDealerNameValue] = React.useState({ value: "" });
  const [oemFilters, setOemFiltersValue] = React.useState({
    oem: [],
    region: [],
    dealerGroup: [],
    dealerSet: [],
    country: [],
    excludeNewDealers: false,
  });
  const [oemFilterSet, setOemFilterSetValue] = React.useState({
    dealerCode: "",
    dealerName: "",
    filters: {
      oem: [],
      region: [],
      dealerGroup: [],
      dealerSet: [],
      country: [],
      excludeNewDealers: false,
    },
  });
  const value = useMemo(
    () => ({
      oemSelected: {
        ...oemDealer,
        minMonth: new Date(oemDealer.minMonth as any),
        maxMonth: new Date(oemDealer.maxMonth as any),
      },
      oemDealerCode,
      oemDealerName,
      oemFilters,
      oemFilterSet,
      loading,
      setOemDealer: async dealer => {
        if (!loading.loading) {
          try {
            setLoading({
              loading: true,
              loaded: false,
              error: null,
            });
            if (dealer.benchmarks.length <= 0) {
              await setBenchmark(["", ""]);
            } else {
              if (selected && selected.filter(bm => bm.length > 0).length > 0 && !dealer.defaultBMLimits) {
                await setBenchmark(selected as [string, string]);
              } else if (dealer.defaultBMLimits) {
                await setBenchmark([`${dealer.benchmarks[0]} - UL`, `${dealer.benchmarks[0]} - LL`] as [string, string]);
              } else {
                await setBenchmark([dealer.benchmarks[0], `${dealer.benchmarks[0]} - Median`]);
              }
            }
            if (!!dealer.dealer && dealer.maxMonth) {
              const d = new Date(Date.parse(dealer.maxMonth.toString()));
              updateDate({
                value: formatFullDate(d),
                label: formatDisplayDate(d),
              });
            }
            const cDealers = await childDealers(dealer.code, new Date(dealer.maxMonth ? dealer.maxMonth.toString() : date.value), []);
            const dealerWithKids = { ...dealer, childDealers: cDealers.dealers };
            setOemDealer(dealerWithKids);
            setLoading({
              loading: false,
              loaded: true,
              error: null,
            });
          } catch (e) {
            setLoading({
              loading: false,
              loaded: false,
              error: e,
            });
          }
          return;
        } else {
          if (dealer.benchmarks.length <= 0) {
            await setBenchmark(["", ""]);
          } else {
            await setBenchmark([dealer.benchmarks[0], `${dealer.benchmarks[0]} - Median`]);
          }
          if (dealer.maxMonth) {
            const d = new Date(Date.parse(dealer.maxMonth.toString()));
            updateDate({
              value: formatFullDate(d),
              label: formatDisplayDate(d),
            });
          }
          const cDealers = await childDealers(dealer.code, new Date(dealer.maxMonth ? dealer.maxMonth.toString() : date.value), []);
          const dealerWithKids = { ...dealer, childDealers: cDealers.dealers };
          setOemDealer(dealerWithKids);
        }
      },
      setOemDealerCode: obj => {
        setOemDealerCodeValue(obj);
      },
      setOemDealerName: obj => {
        setOemDealerNameValue(obj);
      },
      setOemFilters: obj => {
        setOemFiltersValue(obj);
      },
      setOemFilterSet: obj => {
        setOemFilterSetValue(obj);
      },
      setOemDealerCodeValue,
      setOemDealerNameValue,
      setOemFiltersValue,
      setOemFilterSetValue,
    }),
    [
      date,
      updateDate,
      oemDealer,
      oemDealerCode,
      oemDealerName,
      oemFilters,
      oemFilterSet,
      xsetOemDealer,
      setOemDealerCodeValue,
      setOemDealerNameValue,
      setOemFiltersValue,
      setOemFilterSetValue,
      setOemDealer,
    ],
  );

  return <OemDealerContext.Provider value={value}>{children}</OemDealerContext.Provider>;
};

export const OemDealerProvider = OemDealerProviderInner;
