import CircularProgress from '@material-ui/core/CircularProgress';
import crimson from "@material-ui/core/colors/red";
import Paper from '@material-ui/core/Paper';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import React, { FC } from 'react';
import { LoadingComponentProps } from 'react-loadable';
import { FormattedMessage } from "react-intl";

const styles = (theme: Theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing.unit * 5,
    border: "none",
    flex: "1"
  },
  loader: {
    color: crimson[600]
  }
});

export type LoadingProps = {

} & WithStyles<typeof styles>;

const LoadingUnstlyed: React.FunctionComponent<LoadingProps> = ({ classes, children }) => (
  <Paper className={classnames(classes.root)}>
    <CircularProgress size={40} className={classes.loader} />
    <Typography variant="caption">
      <FormattedMessage id="fetching.loading" />...
    </Typography>
  </Paper>
)

export const Loading = withStyles(styles)(LoadingUnstlyed);


export const LoadableLoading: FC<LoadingComponentProps> = ({ pastDelay }) => {
  if (pastDelay) {
    return <Loading />
  }
  return null;
};
