import { request, apiHeaders } from "./base";

export type Person = {
  id?: number;
  name: string;
  surname: string;
  email: string;
  number?: string;
  description?: string;
  image?: any;
}

export type PersonCountry = {
  countryId: number;
  personId: number;
}

export type PersonOem = {
  personId: number;
  oemCode: string;
}

export const getPeople = (): Promise<Person[]> => request("/people").then((res) => res.json()).then(({ data }) => data);

export const getPerson = (id: number): Promise<Person[]> => request(`/person/${id}`).then((res) => res.json()).then(({ data }) => data);

export const createPerson = async (file: Partial<Person>) => {
  const formData = new FormData();

  Object.keys(file).forEach((key) => {
    formData.append(key, file[key]);
  });

  return request("/people", {
    method: "POST",
    body: formData,
    headers: new Headers({
      Accept: "application/json",
      "X-csrf-validation": "-",
    })
  }).then((res) => res.json());
}

export const deletePerson = (id: number) => request(`/people/${id}`, {
  method: "DELETE",
}).then((res) => res.json());

export const getPersonCountries = (): Promise<PersonCountry[]> => request("/person-countries").then((res) => res.json()).then(({ data }) => data);

export const createPersonCountries = (country: Partial<PersonCountry>) => request("/person-countries", {
  method: "POST",
  body: JSON.stringify(country)
}).then((res) => res.json())
  .then(({ data }) => data);

export const getPersonOems = (): Promise<PersonOem[]> => request("/person-oems").then((res) => res.json()).then(({ data }) => data);

export const createPersonOems = (oem: Partial<PersonOem>) => request("/person-oems", {
  method: "POST",
  body: JSON.stringify(oem)
}).then((res) => res.json())
  .then(({ data }) => data);

export const updatePerson = async (file: Partial<Person>) => {
  const formData = new FormData();

  Object.keys(file).forEach((key) => {
    formData.append(key, file[key]);
  });

  return request("/person", {
    method: "POST",
    body: formData,
    headers: new Headers({
      Accept: "application/json",
      "X-csrf-validation": "-",
    })
  }).then((res) => res.json());
}

export const updatePersonOems = (data: any) => request("/person-oems", {
  method: "PUT",
  body: JSON.stringify(data)
}).then((res) => res.json());

export const updatePersonCountries = (data: any) => request("/person-countries", {
  method: "PUT",
  body: JSON.stringify(data)
}).then((res) => res.json());