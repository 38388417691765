import React, { createContext, useState, useReducer, useMemo } from "react";
import { Loading } from "./base";
import { PersonOem, createPersonOems, getPersonOems, updatePersonOems } from "../lib/api/people";

const defaultPerson = [];

export type PersonOemContextState = {
  personOems: PersonOem[];
  loading: Loading;
  hydrate(): void;
  createPersonOems(personOems: Partial<PersonOem>): void;
  updatePersonOems(personOems: Partial<PersonOem>): void;
  getPersonOems(): PersonOem[];
};


export const PersonOemContext = createContext<PersonOemContextState>({
  personOems: defaultPerson,
  loading: { loading: false, loaded: false, error: null },
  hydrate: () => {},
  createPersonOems: () => {},
  updatePersonOems: () => {},
  getPersonOems: () => [],
});


export const PersonOemProvider = ({ children }) => {
  const [personOems, setPersonOems] = useState<PersonOem[]>(defaultPerson);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const hydrate = async () => {
    try {
      setLoading({
        loading: true,
        loaded: false,
        error: null,
      });
      const person = await getPersonOems();
      setPersonOems(person);
      setLoading({
        loading: false,
        loaded: true,
        error: null,
      });
    } catch (e) {
      setLoading({
        loading: false,
        loaded: false,
        error: e,
      });
    }
  }

  const value = useMemo(() => ({
    personOems,
    loading,
    hydrate,
    getPersonOems() {
      if (loading.loaded && !loading.loading) {
        return personOems;
      }
      if (!loading.loading && !loading.error) {
        hydrate();
      }
      return [];
    },
    createPersonOems: async (personOems: PersonOem) => {
      try {
        setLoading({
          loading: true,
          loaded: false,
          error: null,
        });
        await createPersonOems(personOems);
        setLoading({
          loading: false,
          loaded: true,
          error: null,
        });
        hydrate();
      } catch (e) {
        setLoading({
          loading: false,
          loaded: false,
          error: e,
        });
      }
    },
    updatePersonOems: async (personOems: PersonOem) => {
      try {
        setLoading({
          loading: true,
          loaded: false,
          error: null,
        });
        await updatePersonOems(personOems);
        setLoading({
          loading: false,
          loaded: true,
          error: null,
        });
        hydrate();
      } catch (e) {
        setLoading({
          loading: false,
          loaded: false,
          error: e,
        });
      }
    },
    setPersonOems,
  }), [personOems, loading]);

  return (
    <PersonOemContext.Provider value={value}>
      {children}
    </PersonOemContext.Provider>
  )
}
