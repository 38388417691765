import React, { createContext, useState, useMemo } from "react";
import { useIntl } from "react-intl";

const defaultBrand = {};

export type FilterBrandContextState = {
  brand: any;
  updateBrand(newBrand: any);
};

export const FilterBrandContext = createContext<FilterBrandContextState>({
  brand: defaultBrand,
  updateBrand: () => { },
});

export const FilterBrandContextProvider = ({ children }) => {
  const intl = useIntl();
  const [brand, setBrand] = useState({ value: "", label: intl.formatMessage({ id: "all.brand.label" }) });

  const value = useMemo(() => ({
    brand,
    updateBrand: async (newBrand: any) => {
      try {
        setBrand(newBrand);
      }
      catch (e) {
        return e;
      }
    },
    setBrand,
  }), [brand]);

  return (
    <FilterBrandContext.Provider value={value}>
      {children}
    </FilterBrandContext.Provider>
  )
}