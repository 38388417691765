import React, { createContext, useState, useReducer, useMemo } from "react";
import { Loading } from "./base";
import { Brands, getBrands } from "../lib/api/brands";

const defaultBrand = {};

export type BrandContextState = {
  brands: Brands;
  loading: Loading;
  hydrate();
};

export const BrandContext = createContext<BrandContextState>({
  brands: defaultBrand,
  loading: { loading: false, loaded: false, error: null },
  hydrate: () => {},
});

export const BrandProvider = ({ children }) => {
  const [brands, setBrand] = useState<Brands>(defaultBrand);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const value = useMemo(
    () => ({
      brands,
      loading,
      hydrate: async () => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          const brands = await getBrands();
          setBrand(brands);
          setLoading({
            loading: false,
            loaded: true,
            error: null,
          });
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      setBrand,
    }),
    [brands, loading],
  );
  return <BrandContext.Provider value={value}>{children}</BrandContext.Provider>;
};
