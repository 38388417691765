import React, { createContext, useState, useMemo } from "react";
import { Loading } from "./../base";
import { getCustomDashDepartments } from "./../../lib/api/custom";

type Departments = {
  name:
    | "Additional Stats"
    | "Aftercare"
    | "After Sales"
    | "Balance Sheet"
    | "Body Shop"
    | "Carline Analysis"
    | "Driveway"
    | "F&I"
    | "NV F&I"
    | "OEM Support Analysis"
    | "Other Dept."
    | "Overall Dealership"
    | "Parts"
    | "Service"
    | "UV F&I";
};

const defaultDepartments: Departments[] = [];

export type CustomDashboardDepartmentsContextState = {
  departments: Departments[];
  loading: Loading;
  hydrate();
};

export const CustomDashboardDepartmentsContext = createContext<CustomDashboardDepartmentsContextState>({
  departments: defaultDepartments,
  loading: { loading: false, loaded: false, error: null },
  hydrate: () => {},
});

export const CustomDashboardDepartmentsProvider = ({ children }) => {
  const [departments, setDepartments] = useState<Departments[]>(defaultDepartments);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const value = useMemo(
    () => ({
      departments,
      loading,
      hydrate: async () => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          const data = await getCustomDashDepartments();
          setDepartments(data.data);
          setLoading({
            loading: false,
            loaded: true,
            error: null,
          });
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      setDepartments,
    }),
    [departments, loading],
  );
  return <CustomDashboardDepartmentsContext.Provider value={value}>{children}</CustomDashboardDepartmentsContext.Provider>;
};
