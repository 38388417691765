import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import React, { useContext } from "react";
import { Query } from "../../../lib/api/charts";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { CustomMetricRowContext } from "../../../contexts/Custom/MetricRowContext";
import { green } from "@material-ui/core/colors";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MeasuresContext } from "../../../contexts/MeasuresContext";
import { MetricGuidance } from "../../../components/MetricGuidance";
import { LanguageContext } from "../../../contexts/LanguageContext";
import { translation } from "../../../translations/Translations";
import { FormattedMessage } from "react-intl";

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
      flex: 1,
      borderRadius: "3px",
    },
    heading: {
      color: "#2f2f2f",
      fontWeight: "bold",
      marginBottom: "32px",
      fontSize: "10px",
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
    },
    table: {
      border: "1px solid #e0e0e0",
      backgroundColor: "#FFF",
      "& th": {
        border: "solid #e0e0e0",
        borderWidth: "0px 0px 1px 0px",
      },
      "& td": {
        border: "solid #e0e0e0",
        borderWidth: "0px 0px 1px 0px",
      },
    },
    tableHeader: {
      padding: `${theme.spacing.unit}px`,
      fontSize: "10px",
      textAlign: "right",
      color: "#2f2f2f",
      "&:nth-child(even)": {
        backgroundColor: "#f3f3f3",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
    },
    tableCell: {
      padding: `${theme.spacing.unit}px`,
      fontSize: "10px",
      textAlign: "right",
      "&:nth-child(even)": {
        backgroundColor: "#f3f3f3",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
    },
    tableCellSubHeader: {
      textAlign: "left",
      minWidth: "100px",
    },
    tableCellSubHeaderFoa: {
      textAlign: "left",
      minWidth: "100px",
      fontWeight: "bold",
    },
    tableCellSubFoa: {
      textAlign: "left",
      minWidth: "100px",
      paddingLeft: `${theme.spacing.unit * 2}px`,
    },
    rule: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      textAlign: "left",
    },
    ruleBold: {
      fontWeight: "bold",
    },
    good: {
      background: "#92D050 !important",
      color: "white",
      textAlign: "center",
    },
    bad: {
      background: "#FF0000 !important",
      color: "white",
      textAlign: "center",
    },
    note: {
      color: "#191919",
      marginLeft: "25px",
    },
    tableContentIndent: {
      border: "solid #e0e0e0",
      borderWidth: "0px 0px 1px 0px",
      padding: `${theme.spacing.unit}px`,
      textAlign: "right",
      fontSize: "10px",
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
    },
    red: {
      color: "red",
    },
    orange: {
      color: "orange",
    },
    yellow: {
      color: "orange",
    },
    tableCellNull: {
      background: "#ededed",
      "&:nth-child(even)": {
        backgroundColor: "#e8e8e8",
      },
    },
    tableHeading: {
      padding: `${theme.spacing.unit}px`,
      textAlign: "left",
      color: "#2f2f2f",
      fontSize: "10px",
      "&:nth-child(even)": {
        backgroundColor: "#f3f3f3",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
    },
    exportButton: {
      border: "none",
      outline: "none",
      color: "rgba(0, 0, 0, 0.87)",
      padding: "8px 7px",
      fontSize: "0.6875rem",
      minWidth: "64px",
      boxSizing: "border-box",
      lineHeight: 1.75,
      fontWeight: 500,
      fontFamily: "Montserrat,-apple-systrem,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,Helvetica,Arial,sans-serif",
      borderRadius: "4px",
      textTransform: "uppercase",
      cursor: "pointer",
      backgroundColor: "transparent",
      transition:
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    icon: {
      marginLeft: "6px",
      fontSize: "12px",
      color: green[500],
    },
    pointer: {
      cursor: "pointer",
    },
    tableGuidance: {
      padding: "8px !important",
      maxWidth: "34px",
      width: "34px",
      "&:nth-child(even)": {
        backgroundColor: "#f3f3f3",
      },
    },
    tableSelector: {
      padding: "8px !important",
      maxWidth: "40px",
      width: "40px",
      "&:nth-child(even)": {
        backgroundColor: "#f3f3f3",
      },
    },
    actions: {
      flexDirection: "row",
      alignItems: "center",
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "flex",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
      [theme.breakpoints.up("lg")]: {
        display: "flex",
      },
      [theme.breakpoints.up("xl")]: {
        display: "flex",
      },
    },
  });

export type CustomMetricRowProps = {
  query: Query;
  title?: string;
  metric: any;
  allowInsights: boolean;
  renderIcon?(): React.ReactNode;
  custom?: any;
  variant: string;
} & React.HtmlHTMLAttributes<HTMLDivElement> &
  WithStyles<typeof styles>;

const CustomMetricRowUnstyled: React.FunctionComponent<CustomMetricRowProps> = ({ classes, metric: report, query, renderIcon, custom, variant }) => {
  const { getCustomMetricRow } = useContext(CustomMetricRowContext);
  const { metric, loading } = getCustomMetricRow(report, { ...query, department: custom.department }, custom);
  const { getMeasures } = React.useContext(MeasuresContext);

  const { language } = React.useContext(LanguageContext);

  const getGuidanceStr = (metric: string) => {
    const currentMeasure = getMeasures().find(dbmeasure => dbmeasure.title == metric) || { description: "" };
    if (language != "en" && !!currentMeasure[`${language}_description`]) {
      return currentMeasure[`${language}_description`];
    }
    return currentMeasure.description;
  };

  const formatData = data =>
    data == "0%" || data == "0.00%" || data == "0.0%" || data == 0 || (data || "").includes("NaN") || (data || "").includes("Infinity") ? "-" : data || "N/A";

  if (!loading.loaded || loading.loading) {
    return (
      <TableRow>
        <TableCell className={classes.tableGuidance}>
          <CircularProgress size={10} />
        </TableCell>
        <TableCell className={classes.tableSelector} />
        <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)}>Loading...</TableCell>
        <TableCell className={classes.tableCell} />
        <TableCell className={classes.tableCell} />
        <TableCell className={classes.tableCell} />
        <TableCell className={classes.tableCell} />
        <TableCell className={classes.tableCell} />
        <TableCell className={classes.tableCell} />
        <TableCell className={classes.tableCell} />
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell className={classes.tableGuidance}>
        {getGuidanceStr(metric.measure) && <MetricGuidance guidanceHTML={getGuidanceStr(metric.measure)} name={metric.label} />}
      </TableCell>
      <TableCell className={classes.tableSelector}>
        <div className={classes.actions}>{renderIcon && renderIcon()}</div>
      </TableCell>
      <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)} style={{ whiteSpace: "nowrap" }}>
        {translation[metric.label] ? <FormattedMessage id={translation[metric.label]} /> : metric.label}
      </TableCell>
      {metric.data[variant].map((d, i) => {
        if (i < 2) {
          if (!!d[1]) {
            return <TableCell className={classes.tableCell}>{d[0] || "-"}</TableCell>;
          } else {
            return <TableCell className={classes.tableCell}>-</TableCell>;
          }
        }
        if (metric.columns[variant][i] == "YTD" || metric.columns[variant][i] == "YTD-1") {
          return <TableCell className={classnames(classes.tableCell, classes.pointer)}>{formatData(d[0])}</TableCell>;
        }
        if (metric.columns[variant][i] == "Var") {
          return (
            <TableCell
              className={classnames(classes.tableCell, {
                [classes.red]: metric.columns[variant][i] == "Var" ? (metric.classification == "income" ? d[1] < 0 : d[1] > 0) : false,
              })}
            >
              {d[0]}
            </TableCell>
          );
        }
        return <TableCell className={classes.tableCell}>{formatData(d[0])}</TableCell>;
      })}
    </TableRow>
  );
};

export const CustomMetricRow = withStyles(styles)(CustomMetricRowUnstyled);
