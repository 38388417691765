import { CustomDashboardType, getMeasure } from "../CustomDashReports";

export const fordSAScorecard: { [index: string]: CustomDashboardType } = {
  "Ford SA Scorecard > Academy > Total Dealer Staff Competence Headcount": {
    name: "Ford SA Scorecard > Academy > Total Dealer Staff Competence Headcount",
    department: "Ford SA Scorecard",
    category: "Academy",
    label: "Total Dealer Staff Competence Headcount",
    tableNumber: 500,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Staff Competence mix | Admin | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Academy > Total Dealer Staff Competence mix % (Uncertified)": {
    name: "Ford SA Scorecard > Academy > Total Dealer Staff Competence mix % (Uncertified)",
    department: "Ford SA Scorecard",
    category: "Academy",
    label: "Total Dealer Staff Competence mix % (Uncertified)",
    tableNumber: 500,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Competence mix % Uncertified | Admin | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Academy > Total Dealer Staff Competence mix % (Induction)": {
    name: "Ford SA Scorecard > Academy > Total Dealer Staff Competence mix % (Induction)",
    department: "Ford SA Scorecard",
    category: "Academy",
    label: "Total Dealer Staff Competence mix % (Induction)",
    tableNumber: 500,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Competence mix % Induction | Admin | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Academy > Total Dealer Staff Competence mix % (Silver)": {
    name: "Ford SA Scorecard > Academy > Total Dealer Staff Competence mix % (Silver)",
    department: "Ford SA Scorecard",
    category: "Academy",
    label: "Total Dealer Staff Competence mix % (Silver)",
    tableNumber: 500,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Competence mix % Silver | Admin | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Academy > Total Dealer Staff Competence mix % (Gold)": {
    name: "Ford SA Scorecard > Academy > Total Dealer Staff Competence mix % (Gold)",
    department: "Ford SA Scorecard",
    category: "Academy",
    label: "Total Dealer Staff Competence mix % (Gold)",
    tableNumber: 500,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Competence mix % Gold | Admin | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Academy > Total Dealer Staff Competence mix % (Platinum)": {
    name: "Ford SA Scorecard > Academy > Total Dealer Staff Competence mix % (Platinum)",
    department: "Ford SA Scorecard",
    category: "Academy",
    label: "Total Dealer Staff Competence mix % (Platinum)",
    tableNumber: 500,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Competence mix % Platinum | Admin | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Academy > Percentage of PBR Sessions Completed - Sales Executive": {
    name: "Ford SA Scorecard > Academy > Percentage of PBR Sessions Completed - Sales Executive",
    department: "Ford SA Scorecard",
    category: "Academy",
    label: "Percentage of PBR Sessions Completed - Sales Executive",
    tableNumber: 500,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > PBR Sessions Completed % - Sales Executive | New | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "Ford SA Scorecard > Academy > Percentage of PBR Sessions Completed - Service Advisor": {
    name: "Ford SA Scorecard > Academy > Percentage of PBR Sessions Completed - Service Advisor",
    department: "Ford SA Scorecard",
    category: "Academy",
    label: "Percentage of PBR Sessions Completed - Service Advisor",
    tableNumber: 500,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > PBR Sessions Completed % - Service Advisor | Service | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "Ford SA Scorecard > Business Management > Retained Cash": {
    name: "Ford SA Scorecard > Business Management > Retained Cash",
    department: "Ford SA Scorecard",
    category: "Business Management",
    label: "Retained Cash",
    tableNumber: 501,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Bank Accounts | All Department | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "Ford SA Scorecard > Business Management > ROA": {
    name: "Ford SA Scorecard > Business Management > ROA",
    department: "Ford SA Scorecard",
    category: "Business Management",
    label: "ROA",
    tableNumber: 501,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Return on Total Assets (ROA) | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Ford SA Scorecard > Business Management > EBIT": {
    name: "Ford SA Scorecard > Business Management > EBIT",
    department: "Ford SA Scorecard",
    category: "Business Management",
    label: "EBIT",
    tableNumber: 501,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "E > Earnings Before Interest (EBIT) | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Ford SA Scorecard > Business Management > Working Capital Days": {
    name: "Ford SA Scorecard > Business Management > Working Capital Days",
    department: "Ford SA Scorecard",
    category: "Business Management",
    label: "Working Capital Days",
    tableNumber: 501,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Working Capital Days | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > Average WIP Days CYTD": {
    name: "Ford SA Scorecard > FSCD/Service > Average WIP Days CYTD",
    department: "Ford SA Scorecard",
    category: "FSCD/Service",
    label: "Average WIP Days CYTD",
    tableNumber: 502,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > OEM Data - Average WIP Days CYTD | Service | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > Service Retention (%)": {
    name: "Ford SA Scorecard > FSCD/Service > Service Retention (%)",
    department: "Ford SA Scorecard",
    category: "FSCD/Service",
    label: "Service Retention (%)",
    tableNumber: 502,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Market Share > Service Market > YTD Service Retention | Service | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > Ranger Accessory Sales PNUR against national average": {
    name: "Ford SA Scorecard > FSCD/Service > Ranger Accessory Sales PNUR against national average",
    department: "Ford SA Scorecard",
    category: "FSCD/Service",
    label: "Ranger Accessory Sales PNUR against national average",
    tableNumber: 502,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Accessory Sales PNUR % Network Achievement | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > Market Share %": {
    name: "Ford SA Scorecard > Ford Credit > Market Share %",
    department: "Ford SA Scorecard",
    category: "Ford Credit",
    label: "Market Share %",
    tableNumber: 503,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Captive Finance Penetration % | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > Ford Options Penetration % of new contracts": {
    name: "Ford SA Scorecard > Ford Credit > Ford Options Penetration % of new contracts",
    department: "Ford SA Scorecard",
    category: "Ford Credit",
    label: "Ford Options Penetration % of new contracts",
    tableNumber: 503,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Captive Finance Option % Total Contracts | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > New Approval Rate %": {
    name: "Ford SA Scorecard > Ford Credit > New Approval Rate %",
    department: "Ford SA Scorecard",
    category: "Ford Credit",
    label: "New Approval Rate %",
    tableNumber: 503,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Finance Approval Rate % | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > New Take-Up Ratio %": {
    name: "Ford SA Scorecard > Ford Credit > New Take-Up Ratio %",
    department: "Ford SA Scorecard",
    category: "Ford Credit",
    label: "New Take-Up Ratio %",
    tableNumber: 503,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Captive Finance % Total Approved | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > Commercial (Nr of Juristic contracts) only New": {
    name: "Ford SA Scorecard > Ford Credit > Commercial (Nr of Juristic contracts) only New",
    department: "Ford SA Scorecard",
    category: "Ford Credit",
    label: "Commercial (Nr of Juristic contracts) only New",
    tableNumber: 503,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Captive Finance Commercial Contracts | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "Ford SA Scorecard > Ford Credit > Ford Lease (New)": {
    name: "Ford SA Scorecard > Ford Credit > Ford Lease (New)",
    department: "Ford SA Scorecard",
    category: "Ford Credit",
    label: "Ford Lease (New)",
    tableNumber: 503,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Captive Finance Contracts | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: true,
  },
  "Ford SA Scorecard > Ford Credit > Retention %": {
    name: "Ford SA Scorecard > Ford Credit > Retention %",
    department: "Ford SA Scorecard",
    category: "Ford Credit",
    label: "Retention %",
    tableNumber: 503,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Captive Finance Customers Retention % | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > Wholesale Rebate %": {
    name: "Ford SA Scorecard > Ford Credit > Wholesale Rebate %",
    department: "Ford SA Scorecard",
    category: "Ford Credit",
    label: "Wholesale Rebate %",
    tableNumber: 503,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Wholesale Rebate % | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > Wholesale Audits (% violations)": {
    name: "Ford SA Scorecard > Ford Credit > Wholesale Audits (% violations)",
    department: "Ford SA Scorecard",
    category: "Ford Credit",
    label: "Wholesale Audits (% violations)",
    tableNumber: 503,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sold out of trust % | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > Current ratio": {
    name: "Ford SA Scorecard > Ford Credit > Current ratio",
    department: "Ford SA Scorecard",
    category: "Ford Credit",
    label: "Current ratio",
    tableNumber: 503,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Current Ratio | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > Profit before tax": {
    name: "Ford SA Scorecard > Ford Credit > Profit before tax",
    department: "Ford SA Scorecard",
    category: "Ford Credit",
    label: "Profit before tax",
    tableNumber: 503,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT (Profit Before Tax) | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Ford SA Scorecard > Ford Protect > Extended Business Penetration": {
    name: "Ford SA Scorecard > Ford Protect > Extended Business Penetration",
    department: "Ford SA Scorecard",
    category: "Ford Protect",
    label: "Extended Business Penetration",
    tableNumber: 504,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Optional Service Plan Penetration | New | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Protect > RTR Service and Maintenance Penetration": {
    name: "Ford SA Scorecard > Ford Protect > RTR Service and Maintenance Penetration",
    department: "Ford SA Scorecard",
    category: "Ford Protect",
    label: "RTR Service and Maintenance Penetration",
    tableNumber: 504,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > RTR Serv. & Maint. Penetration % | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Protect > Extended Warranty Penetration": {
    name: "Ford SA Scorecard > Ford Protect > Extended Warranty Penetration",
    department: "Ford SA Scorecard",
    category: "Ford Protect",
    label: "Extended Warranty Penetration",
    tableNumber: 504,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Extended Warranty Penetration % | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > OneCX > Dealer Satisfaction - Sales": {
    name: "Ford SA Scorecard > OneCX > Dealer Satisfaction - Sales",
    department: "Ford SA Scorecard",
    category: "OneCX",
    label: "Dealer Satisfaction - Sales",
    tableNumber: 505,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Dealer Satisfaction Sales (unit) | New | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > OneCX > Dealer Satisfaction - Service": {
    name: "Ford SA Scorecard > OneCX > Dealer Satisfaction - Service",
    department: "Ford SA Scorecard",
    category: "OneCX",
    label: "Dealer Satisfaction - Service",
    tableNumber: 505,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Dealer Satisfaction Service (unit) | Service | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > OneCX > Sales Experience Index": {
    name: "Ford SA Scorecard > OneCX > Sales Experience Index",
    department: "Ford SA Scorecard",
    category: "OneCX",
    label: "Sales Experience Index",
    tableNumber: 505,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales CEI (unit) | New | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > OneCX > Service Experience Index": {
    name: "Ford SA Scorecard > OneCX > Service Experience Index",
    department: "Ford SA Scorecard",
    category: "OneCX",
    label: "Service Experience Index",
    tableNumber: 505,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service CEI (unit) | Service | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > OneCX > FRFT": {
    name: "Ford SA Scorecard > OneCX > FRFT",
    department: "Ford SA Scorecard",
    category: "OneCX",
    label: "FRFT",
    tableNumber: 505,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > FORD CVP Service - FRFT% | Service | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > OneCX > Sales Dealer Recommend Score (NPS)": {
    name: "Ford SA Scorecard > OneCX > Sales Dealer Recommend Score (NPS)",
    department: "Ford SA Scorecard",
    category: "OneCX",
    label: "Sales Dealer Recommend Score (NPS)",
    tableNumber: 505,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Dealer Recommend Score | New | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > OneCX > Sales Brand Recommend Score (NPS)": {
    name: "Ford SA Scorecard > OneCX > Sales Brand Recommend Score (NPS)",
    department: "Ford SA Scorecard",
    category: "OneCX",
    label: "Sales Brand Recommend Score (NPS)",
    tableNumber: 505,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Brand Recommend Score | New | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > OneCX > Service Dealer Recommend Score (NPS)": {
    name: "Ford SA Scorecard > OneCX > Service Dealer Recommend Score (NPS)",
    department: "Ford SA Scorecard",
    category: "OneCX",
    label: "Service Dealer Recommend Score (NPS)",
    tableNumber: 505,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Dealer Recommend Score | Service | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > OneCX > Service Brand Recommend Score (NPS)": {
    name: "Ford SA Scorecard > OneCX > Service Brand Recommend Score (NPS)",
    department: "Ford SA Scorecard",
    category: "OneCX",
    label: "Service Brand Recommend Score (NPS)",
    tableNumber: 505,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Brand Recommend Score | Service | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Gross Profit": {
    name: "Ford SA Scorecard > Sales > Gross Profit",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Gross Profit",
    tableNumber: 506,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Ford SA Scorecard > Sales > Return on Sales %": {
    name: "Ford SA Scorecard > Sales > Return on Sales %",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Return on Sales %",
    tableNumber: 506,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT % of Sales | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > New Vehicle Sales - Act vs Obj": {
    name: "Ford SA Scorecard > Sales > New Vehicle Sales - Act vs Obj",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "New Vehicle Sales - Act vs Obj",
    tableNumber: 506,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Target % Achieved - New Total Units | New | Ford | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Used Vehicle Sales": {
    name: "Ford SA Scorecard > Sales > Used Vehicle Sales",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Used Vehicle Sales",
    tableNumber: 506,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | Used | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Ford SA Scorecard > Sales > Used to New Vehicle Ratio": {
    name: "Ford SA Scorecard > Sales > Used to New Vehicle Ratio",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Used to New Vehicle Ratio",
    tableNumber: 506,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ratio Used : New | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Sales conversion rates": {
    name: "Ford SA Scorecard > Sales > Sales conversion rates",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Sales conversion rates",
    tableNumber: 506,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > New Vehicle Conversion Rate | New | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Sales by Salesperson": {
    name: "Ford SA Scorecard > Sales > Sales by Salesperson",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Sales by Salesperson",
    tableNumber: 506,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Units per Productive | New | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Market Share": {
    name: "Ford SA Scorecard > Sales > Market Share",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Market Share",
    tableNumber: 506,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Market Share > New Market > YTD Market Share | New | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Dealer Share of Brand Sales": {
    name: "Ford SA Scorecard > Sales > Dealer Share of Brand Sales",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Dealer Share of Brand Sales",
    tableNumber: 506,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Dealer Share of Brand Sales | New | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Dealership Performance - ROS": {
    name: "Ford SA Scorecard > Sales > Dealership Performance - ROS",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Dealer Share of Brand Sales",
    tableNumber: 507,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT % of Sales | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > ROS": {
    name: "Ford SA Scorecard > Sales > Working Capital Days",
    tableNumber: 507,
    category: "Sales",
    label: "ROS",
    measure: "PBT > PBT % of Sales | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    canAverage: false,
    department: "Ford SA Scorecard",
  },
  "Ford SA Scorecard > Sales > Sales": {
    name: "Ford SA Scorecard > Sales > Sales",
    tableNumber: 507,
    category: "Sales",
    label: "Sales",
    measure: "TNS > Net Sales | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    canAverage: false,
    department: "Ford SA Scorecard",
  },
  "Ford SA Scorecard > Sales > GP": {
    name: "Ford SA Scorecard > Sales > GP",
    tableNumber: 507,
    category: "Sales",
    label: "GP",
    measure: "G > Gross Profit | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    canAverage: false,
    department: "Ford SA Scorecard",
  },
  "Ford SA Scorecard > Sales > Expenses": {
    name: "Ford SA Scorecard > Sales > Expenses",
    tableNumber: 507,
    category: "Sales",
    label: "Expenses",
    measure: "TE > Total Expenses | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    canAverage: false,
    department: "Ford SA Scorecard",
  },
  "Ford SA Scorecard > Sales > PBT": {
    name: "Ford SA Scorecard > Sales > PBT",
    tableNumber: 507,
    category: "Sales",
    label: "PBT",
    measure: "PBT > PBIT (Profit Before Interest & Tax) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    canAverage: false,
    department: "Ford SA Scorecard",
  },
  "Ford SA Scorecard > Sales > ROOA": {
    name: "Ford SA Scorecard > Sales > ROOA",
    tableNumber: 507,
    category: "Sales",
    label: "ROOA",
    measure: "STAT > Return on Operational Assets (ROOA) | All Department | All Brand | All Model | | AVG",
    getMeasure: (label, measures) => getMeasure(label, measures),
    canAverage: false,
    department: "Ford SA Scorecard",
  },
  "Ford SA Scorecard > Sales > MIX": {
    name: "Ford SA Scorecard > Sales > MIX",
    tableNumber: 507,
    category: "Sales",
    label: "MIX",
    measure: "G > Gross Profit % of Sales (MIX) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    canAverage: false,
    department: "Ford SA Scorecard",
  },
  "Ford SA Scorecard > Sales > Retained Cash": {
    name: "Ford SA Scorecard > Sales > Retained Cash",
    tableNumber: 507,
    category: "Sales",
    label: "Retained",
    measure: "PBT > PBT % of TGP: RETAINED | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    canAverage: false,
    department: "Ford SA Scorecard",
  },
  "Ford SA Scorecard > Sales > Activity": {
    name: "Ford SA Scorecard > Sales > Activity",
    tableNumber: 507,
    category: "Sales",
    label: "Activity",
    measure: "STAT > Asset Activity | All Department | All Brand | All Model | | AVG",
    getMeasure: (label, measures) => getMeasure(label, measures),
    canAverage: false,
    department: "Ford SA Scorecard",
  },

  "Ford SA Scorecard > Sales > EBIT": {
    name: "Ford SA Scorecard > Sales > EBIT",
    tableNumber: 507,
    category: "Sales",
    label: "EBIT",
    measure: "PBT > PBIT (Profit Before Interest & Tax) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    canAverage: false,
    department: "Ford SA Scorecard",
  },
  "Ford SA Scorecard > Sales > Cash Retained": {
    name: "Ford SA Scorecard > Sales > Cash Retained",
    tableNumber: 507,
    category: "Sales",
    label: "Cash Retained",
    measure: "CA > Bank Accounts | All Department | All Brand | All Model | | CLOSE",
    getMeasure: (label, measures) => getMeasure(label, measures),
    canAverage: false,
    department: "Ford SA Scorecard",
  },

  "Ford SA Scorecard > Sales > New Vehicle Sales": {
    name: "Ford SA Scorecard > Sales > New Vehicle Sales",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "New Vehicle Sales",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Target % Achieved - New Total Units | New | Ford | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Dealer Share Brand Sales": {
    name: "Ford SA Scorecard > Sales > Dealer Share of Brand Sales",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Dealer Share of Brand Sales",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Dealer Share of Brand Sales | New | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Dealer Share of Overall Market": {
    name: "Ford SA Scorecard > Sales > Dealer Share of Overall Market",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Dealer Share of Overall Market",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Market Share > New Market > YTD Market Share | New | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Sales Conversion Rates": {
    name: "Ford SA Scorecard > Sales > Sales Conversion Rates",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Sales Conversion Rates",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > New Vehicle Conversion Rate | New | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Sales By Salesperson": {
    name: "Ford SA Scorecard > Sales > Sales By Salesperson",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Sales By Salesperson",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Units per Productive | New | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > NV Units sold": {
    name: "Ford SA Scorecard > Sales > NV Units sold",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "NV Units sold",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > In Dealer Units | New | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Fleet Retail Ratio": {
    name: "Ford SA Scorecard > Sales > Fleet Retail Ratio",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Fleet Retail Ratio",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ratio Fleet : Retail | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > NV Sales": {
    name: "Ford SA Scorecard > Sales > NV Sales",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "NV Sales",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | New | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > NV GP%": {
    name: "Ford SA Scorecard > Sales > NV GP%",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "NV GP%",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | New | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > NV ROS": {
    name: "Ford SA Scorecard > Sales > NV ROS",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "NV ROS",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT Profit Before Tax % of Sales (NewF&IAftercare) | New | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Used To New Vehicle Ratio": {
    name: "Ford SA Scorecard > Sales > Used To New Vehicle Ratio",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Used To New Vehicle Ratio",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ratio Used : New | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > UV Units Sold": {
    name: "Ford SA Scorecard > Sales > UV Units Sold",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "UV Units Sold",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > In Dealer Units | Used | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > UV Sales": {
    name: "Ford SA Scorecard > Sales > UV Sales",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "UV Sales",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | Used | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > UV GP%": {
    name: "Ford SA Scorecard > Sales > UV GP%",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "UV GP%",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | Used | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > UV ROS": {
    name: "Ford SA Scorecard > Sales > UV ROS",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "UV ROS",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT Profit Before Tax % of Sales (UsedF&IAftercare) | Used | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > NV Inventory Days": {
    name: "Ford SA Scorecard > Sales > NV Inventory Days",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "NV Inventory Days",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory days | New | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > NV Inventory Value (ending)": {
    name: "Ford SA Scorecard > Sales > NV Inventory Value (ending)",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "NV Inventory Value (ending)",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory Value | New | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > NV Inventory Value 0-30 days": {
    name: "Ford SA Scorecard > Sales > NV Inventory Value 0-30 days",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "NV Inventory Value 0-30 days",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory Value > 0 - 30 days | New | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > NV Floor Plan Interest / Unit": {
    name: "Ford SA Scorecard > Sales > NV Floor Plan Interest / Unit",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "NV Floor Plan Interest / Unit",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "I > Floorplan Interest pu | NewAftercareF&ISplit | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > UV Inventory Days": {
    name: "Ford SA Scorecard > Sales > UV Inventory Days",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "UV Inventory Days",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory days | Used | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > UV Inventory Value (ending)": {
    name: "Ford SA Scorecard > Sales > UV Inventory Value (ending)",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "UV Inventory Value (ending)",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory Value | Used | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > UV Inventory Value 0-30 days": {
    name: "Ford SA Scorecard > Sales > UV Inventory Value 0-30 days",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "UV Inventory Value 0-30 days",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory Value > 0 - 30 days | Used | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > UV Floor Plan Interest / Unit": {
    name: "Ford SA Scorecard > Sales > UV Floor Plan Interest / Unit",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "UV Floor Plan Interest / Unit",
    tableNumber: 508,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "I > Floorplan Interest pu | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
    canAverage: false,
  },

  "Ford SA Scorecard > FSCD/Service > Average WIP Days": {
    name: "Ford SA Scorecard > FSCD/Service > Average WIP Days",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Average WIP Days",
    tableNumber: 509,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Market Share > Service Market > YTD Service Retention | Service | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > Service Sales": {
    name: "Ford SA Scorecard > FSCD/Service > Service Sales",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Service Sales",
    tableNumber: 509,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | ServiceQuickService | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > Service GP%": {
    name: "Ford SA Scorecard > FSCD/Service > Service GP%",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Service GP%",
    tableNumber: 509,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | ServiceQuickService | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > Ave. WIP days (VORs)": {
    name: "Ford SA Scorecard > FSCD/Service > Ave. WIP days (VORs)",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Ave. WIP days (VORs)",
    tableNumber: 509,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > OEM Data - Average WIP Days CYTD | Service | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > Parts Sales": {
    name: "Ford SA Scorecard > FSCD/Service > Parts Sales",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Parts Sales",
    tableNumber: 509,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | Parts | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > Ranger Accessory Sales PNUR Against National Average": {
    name: "Ford SA Scorecard > FSCD/Service > Ranger Accessory Sales PNUR Against National Average",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Ranger Accessory Sales PNUR Against National Average",
    tableNumber: 509,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Accessory Sales PNUR % Network Achievement | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > Parts GP%": {
    name: "Ford SA Scorecard > FSCD/Service > Parts GP%",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Parts GP%",
    tableNumber: 509,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | Parts | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > Unit Serviced": {
    name: "Ford SA Scorecard > FSCD/Service > Unit Serviced",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Unit Serviced",
    tableNumber: 509,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Units Serviced | ServiceQuickService | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > Sales / Units Serviced": {
    name: "Ford SA Scorecard > FSCD/Service > Sales / Units Serviced",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Sales / Units Serviced",
    tableNumber: 509,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales / Unit Serviced | ServiceQuickService | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > Retail RO": {
    name: "Ford SA Scorecard > FSCD/Service > Retail RO",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Retail RO",
    tableNumber: 509,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Retail RO | Service | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > Retail Labour Sales / Retail  RO": {
    name: "Ford SA Scorecard > FSCD/Service > Retail Labour Sales / Retail  RO",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Retail Labour Sales / Retail  RO",
    tableNumber: 509,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Retail Labour sales p RO | ServiceQuickService | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > Parts Wholesale Sales": {
    name: "Ford SA Scorecard > FSCD/Service > Parts Wholesale Sales",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Parts Wholesale Sales",
    tableNumber: 509,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Wholesale net sales | Parts | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > Parts sales per RO": {
    name: "Ford SA Scorecard > FSCD/Service > Parts sales per RO",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Parts sales per RO",
    tableNumber: 509,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Parts workshop sales per RO (Incl. Quick Service) | ServiceQuickService | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > Parts sales per U serviced": {
    name: "Ford SA Scorecard > FSCD/Service > Parts sales per U serviced",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Parts sales per U serviced",
    tableNumber: 509,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Parts Workshop Sales (Inc. Quick Service) / Unit Serviced | ServiceQuickService | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > FOA": {
    name: "Ford SA Scorecard > FSCD/Service > FOA",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "FOA",
    tableNumber: 509,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Fixed Operation Absorption | Aftersales | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > Overhead Exp": {
    name: "Ford SA Scorecard > FSCD/Service > Overhead Exp",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Overhead Exp",
    tableNumber: 509,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TE > Overhead Expenses FOA | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > Parts GP": {
    name: "Ford SA Scorecard > FSCD/Service > Parts GP",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Parts GP",
    tableNumber: 509,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | Parts | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > Service GP": {
    name: "Ford SA Scorecard > FSCD/Service > Service GP",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Service GP",
    tableNumber: 509,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | ServiceQuickService | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > After Sales GP": {
    name: "Ford SA Scorecard > FSCD/Service > After Sales GP",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "After Sales GP",
    tableNumber: 509,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | Aftersales | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > Parts GP %": {
    name: "Ford SA Scorecard > FSCD/Service > Parts GP %",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Parts GP %",
    tableNumber: 509,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | Parts | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > Service GP %": {
    name: "Ford SA Scorecard > FSCD/Service > Service GP %",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Service GP %",
    tableNumber: 509,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | ServiceQuickService | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > FSCD/Service > After Sales GP %": {
    name: "Ford SA Scorecard > FSCD/Service > After Sales GP %",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "After Sales GP %",
    tableNumber: 509,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | Aftersales | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > Market Share Performance": {
    name: "Ford SA Scorecard > Ford Credit > Market Share Performance",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Market Share %",
    tableNumber: 510,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Captive Finance Penetration % | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > Ford Lease Contract (New)": {
    name: "Ford SA Scorecard > Ford Credit > Ford Lease Contract (New)",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Ford Lease Contract (New)",
    tableNumber: 510,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Captive Finance Contracts | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > Commercial (Nr of Juristic contracts) New": {
    name: "Ford SA Scorecard > Ford Credit > Commercial (Nr of Juristic contracts) New",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Commercial (Nr of Juristic contracts) New",
    tableNumber: 510,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Captive Finance Commercial Contracts | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > Ford Options Penetration % Of New Contracts": {
    name: "Ford SA Scorecard > Ford Credit > Ford Options Penetration % Of New Contracts",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Ford Options Penetration % Of New Contracts",
    tableNumber: 510,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Captive Finance Option % Total Contracts | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > New Approval rate %": {
    name: "Ford SA Scorecard > Ford Credit > New Approval rate %",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "New Approval rate %",
    tableNumber: 510,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Finance Approval Rate % | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > New Take Up Ratio %": {
    name: "Ford SA Scorecard > Ford Credit > New Take Up Ratio %",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "New Take-Up Ratio %",
    tableNumber: 510,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Captive Finance % Total Approved | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > retention %": {
    name: "Ford SA Scorecard > Ford Credit > retention %",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Retention %",
    tableNumber: 510,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Captive Finance Customers Retention % | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > Wholesale rebate %": {
    name: "Ford SA Scorecard > Ford Credit > Wholesale rebate %",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Wholesale Rebate %",
    tableNumber: 510,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Wholesale Rebate % | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > Wholesale audits (% violations)": {
    name: "Ford SA Scorecard > Ford Credit > Wholesale audits (% violations)",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Wholesale Audits (% violations)",
    tableNumber: 510,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sold out of trust % | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > Current Ratio": {
    name: "Ford SA Scorecard > Ford Credit > Current Ratio",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Current Ratio",
    tableNumber: 510,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Current Ratio | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > Profit Before Tax": {
    name: "Ford SA Scorecard > Ford Credit > Profit Before Tax",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Profit Before Tax",
    tableNumber: 510,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT (Profit Before Tax) | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > Extended Business Penetration": {
    name: "Ford SA Scorecard > Ford Credit > Extended Business Penetration",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Extended Business Penetration",
    tableNumber: 510,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Optional Service Plan Penetration | New | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > RTR Service and Maintenance Penetration": {
    name: "Ford SA Scorecard > Ford Credit > RTR Service and Maintenance Penetration",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "RTR Service and Maintenance Penetration",
    tableNumber: 510,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > RTR Serv. & Maint. Penetration % | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Ford Credit > Extended Warranty Penetration": {
    name: "Ford SA Scorecard > Ford Credit > Extended Warranty Penetration",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Extended Warranty Penetration",
    tableNumber: 510,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Extended Warranty Penetration % | F&I Dept | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford Academy > Service > Net Promoter Score (NPS)": {
    name: "Ford Academy > Service > Net Promoter Score (NPS)",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "FRFT",
    tableNumber: 511,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Dealer Recommend Score | New | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford Academy > Service > Dealer Net Promoter Score (NPS) Service": {
    name: "Ford Academy > Service > Dealer Net Promoter Score (NPS) Service",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Dealer Net Promoter Score (NPS) Service",
    tableNumber: 511,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Dealer Recommend Score | Service | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford Academy > Service > Brand Net Promoter Score (NPS) Sales": {
    name: "Ford Academy > Service > Brand Net Promoter Score (NPS) Sales",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Brand Net Promoter Score (NPS) Sales",
    tableNumber: 511,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Brand Recommend Score | New | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford Academy > Service > Brand Net Promoter Score (NPS) Service": {
    name: "Ford Academy > Service > Brand Net Promoter Score (NPS) Service",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Brand Net Promoter Score (NPS) Service",
    tableNumber: 511,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Brand Recommend Score | Service | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford Academy > Service > Dealer Satisfaction Sales": {
    name: "Ford Academy > Service > Dealer Satisfaction Sales",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Dealer Satisfaction Sales",
    tableNumber: 511,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Dealer Satisfaction Sales (unit) | New | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford Academy > Service > Dealer Satisfaction Service": {
    name: "Ford Academy > Service > Dealer Satisfaction Service",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Dealer Satisfaction Service",
    tableNumber: 511,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Dealer Satisfaction Service (unit) | Service | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford Academy > Service > Sales Experience Index": {
    name: "Ford Academy > Service > Sales Experience Index",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Sales Experience Index",
    tableNumber: 511,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales CEI (unit) | New | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford Academy > Service > Service Experience Index": {
    name: "Ford Academy > Service > Service Experience Index",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Service Experience Index",
    tableNumber: 511,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service CEI (unit) | Service | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford Academy > Service > Service Total Dealer Staff - Uncertified": {
    name: "Ford Academy > Service > Total Dealer Staff - Uncertified",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Total Dealer Staff - Uncertified",
    tableNumber: 512,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Staff Competence mix - Uncertified | Admin | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford Academy > Service > Service Total Dealer Staff - Induction": {
    name: "Ford Academy > Service > Total Dealer Staff - Induction",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Total Dealer Staff - Induction",
    tableNumber: 512,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Staff Competence mix - Induction | Admin | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford Academy > Service > Total Dealer Staff - Silver": {
    name: "Ford Academy > Service > Total Dealer Staff - Silver",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Total Dealer Staff - Silver",
    tableNumber: 512,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Staff Competence mix - Silver | Admin | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford Academy > Service > Total Dealer Staff - Gold": {
    name: "Ford Academy > Service > Total Dealer Staff - Gold",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Total Dealer Staff - Gold",
    tableNumber: 512,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Staff Competence mix - Gold | Admin | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford Academy > Service > Total Dealer Staff - Platinum": {
    name: "Ford Academy > Service > Total Dealer Staff - Platinum",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Total Dealer Staff - Platinum",
    tableNumber: 512,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Staff Competence mix - Platinum | Admin | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford Academy > Service > Total Dealer Staff Competence Headcount": {
    name: "Ford Academy > Service > Total Dealer Staff - Uncertified",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Total Dealer Staff Competence Headcount",
    tableNumber: 512,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Staff Competence mix | Admin | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford Academy > Service > Total Dealer Staff Competence mix % (Uncertified)": {
    name: "Ford Academy > Service > Total Dealer Staff Competence mix % (Uncertified)",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Total Dealer Staff Competence mix % (Uncertified)",
    tableNumber: 512,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Competence mix % Uncertified | Admin | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford Academy > Service > Total Dealer Staff Competence mix % (Induction)": {
    name: "Ford Academy > Service > Total Dealer Staff Competence mix % (Induction)",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Total Dealer Staff Competence mix % (Induction)",
    tableNumber: 512,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Competence mix % Induction | Admin | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford Academy > Service > Total Dealer Staff Competence mix % (Silver)": {
    name: "Ford Academy > Service > Total Dealer Staff Competence mix % (Silver)",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Total Dealer Staff Competence mix % (Silver)",
    tableNumber: 512,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Competence mix % Silver | Admin | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford Academy > Service > Total Dealer Staff Competence mix % (Gold)": {
    name: "Ford Academy > Service > Total Dealer Staff Competence mix % (Gold)",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Total Dealer Staff Competence mix % (Gold)",
    tableNumber: 512,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Competence mix % Gold | Admin | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford Academy > Service > Total Dealer Staff Competence mix % (Platinum)": {
    name: "Ford Academy > Service > Total Dealer Staff Competence mix % (Platinum)",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Total Dealer Staff Competence mix % (Platinum)",
    tableNumber: 512,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Competence mix % Platinum | Admin | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford Academy > Service > Percentage of PBR Sessions Completed - Sales Executive": {
    name: "Ford Academy > Service > Percentage of PBR Sessions Completed - Sales Executive",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Percentage of PBR Sessions Completed - Sales Executive",
    tableNumber: 512,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > PBR Sessions Completed % - Sales Executive | New | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford Academy > Service > Percentage of PBR Sessions Completed - Service Advisor": {
    name: "Ford Academy > Service > Percentage of PBR Sessions Completed - Service Advisor",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Percentage of PBR Sessions Completed - Service Advisor",
    tableNumber: 512,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > PBR Sessions Completed % - Service Advisor | Service | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Total Dealer Staff - Uncertified": {
    name: "Ford SA Scorecard > Sales > Total Dealer Staff - Uncertified",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Total Dealer Staff - Uncertified",
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Staff Competence mix - Uncertified | Admin | All Brand | All Model | | CLOSE",
    tableNumber: 512,
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Total Dealer Staff - Induction": {
    name: "Ford SA Scorecard > Sales > Total Dealer Staff - Induction",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Total Dealer Staff - Induction",
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Staff Competence mix - Induction | Admin | All Brand | All Model | | CLOSE",
    tableNumber: 512,
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Total Dealer Staff - Silver": {
    name: "Ford SA Scorecard > Sales > Total Dealer Staff - Silver",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Total Dealer Staff - Silver",
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Staff Competence mix - Silver | Admin | All Brand | All Model | | CLOSE",
    tableNumber: 512,
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Total Dealer Staff - Gold": {
    name: "Ford SA Scorecard > Sales > Total Dealer Staff - Gold",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Total Dealer Staff - Gold",
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Staff Competence mix - Gold | Admin | All Brand | All Model | | CLOSE",
    tableNumber: 512,
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Total Dealer Staff - Platinum": {
    name: "Ford SA Scorecard > Sales > Total Dealer Staff - Platinum",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Total Dealer Staff - Platinum",
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Staff Competence mix - Platinum | Admin | All Brand | All Model | | CLOSE",
    tableNumber: 512,
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Total Dealer Staff Competence Headcount": {
    name: "Ford SA Scorecard > Sales > Total Dealer Staff Competence Headcount",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Total Dealer Staff Competence Headcount",
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Staff Competence mix | Admin | All Brand | All Model | | CLOSE",
    tableNumber: 512,
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Total Dealer Staff Competence mix % (Uncertified)": {
    name: "Ford SA Scorecard > Sales > Total Dealer Staff Competence mix % (Uncertified)",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Total Dealer Staff Competence mix % (Uncertified)",
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Competence mix % Uncertified | Admin | All Brand | All Model | | CLOSE",
    tableNumber: 512,
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Total Dealer Staff Competence mix % (Induction)": {
    name: "Ford SA Scorecard > Sales > Total Dealer Staff Competence mix % (Induction)",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Total Dealer Staff Competence mix % (Induction)",
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Competence mix % Induction | Admin | All Brand | All Model | | CLOSE",
    tableNumber: 512,
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Total Dealer Staff Competence mix % (Silver)": {
    name: "Ford SA Scorecard > Sales > Total Dealer Staff Competence mix % (Silver)",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Total Dealer Staff Competence mix % (Silver)",
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Competence mix % Silver | Admin | All Brand | All Model | | CLOSE",
    tableNumber: 512,
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Total Dealer Staff Competence mix % (Gold)": {
    name: "Ford SA Scorecard > Sales > Total Dealer Staff Competence mix % (Gold)",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Total Dealer Staff Competence mix % (Gold)",
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Competence mix % Gold | Admin | All Brand | All Model | | CLOSE",
    tableNumber: 512,
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Total Dealer Staff Competence mix % (Platinum)": {
    name: "Ford SA Scorecard > Sales > Total Dealer Staff Competence mix % (Platinum)",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Total Dealer Staff Competence mix % (Platinum)",
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Total Dealer Competence mix % Platinum | Admin | All Brand | All Model | | CLOSE",
    tableNumber: 512,
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Percentage of PBR Sessions Completed - Sales Executive": {
    name: "Ford SA Scorecard > Sales > Percentage of PBR Sessions Completed - Sales Executive",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Percentage of PBR Sessions Completed - Sales Executive",
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > PBR Sessions Completed % - Sales Executive | New | All Brand | All Model | | CLOSE",
    tableNumber: 512,
    canAverage: false,
  },
  "Ford SA Scorecard > Sales > Percentage of PBR Sessions Completed - Service Advisor": {
    name: "Ford SA Scorecard > Sales > Percentage of PBR Sessions Completed - Service Advisor",
    department: "Ford SA Scorecard",
    category: "Sales",
    label: "Percentage of PBR Sessions Completed - Service Advisor",
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > PBR Sessions Completed % - Service Advisor | Service | All Brand | All Model | | CLOSE",
    tableNumber: 512,
    canAverage: false,
  },
};
