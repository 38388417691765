import React, { createContext, useState, useReducer, useMemo } from "react";
import { MetricTable, MetricReport, Query, stringifyQuery, customMetricTableFor } from "../../lib/api/charts";
import { Loading } from "../base";
import { CustomDashboardMeasures, CustomType } from "../../charts/CustomDashReports";

type MetricTableSet = { metric: MetricTable; loading: Loading; custom?: any };

export type CustomMetricTableContextState = {
  metric: {
    [index: string]: MetricTableSet;
  };
  getCustomMetricTable(report: MetricReport, query: Query, custom?: any): Partial<MetricTableSet>;
};

export const CustomMetricTableContext = createContext<CustomMetricTableContextState>({
  metric: {},
  getCustomMetricTable: () => ({}),
});

type QueryAction = {
  type: "PATCH_METRIC";
  id: string;
  data: Partial<MetricTableSet>;
};

const defaultCustom: string = "Overall Dealership > REVENUE > Total Dealership Sales";

const defaultMetricTable: MetricTableSet = {
  metric: {
    name: "",
    classification: [],
    columns: [],
    rows: [],
    data: [],
    style: [],
    aggregationOverride: [],
    measures: [],
    divideMeasure: [],
  },
  loading: { loaded: false, loading: true, error: null },
  custom: CustomDashboardMeasures[defaultCustom].getMeasure(defaultCustom, CustomDashboardMeasures),
};

export const CustomMetricTableProvider = ({ children }) => {
  const memo = (report: MetricReport, query: Query, custom?: any) => `${report}-${stringifyQuery(query)}-${JSON.stringify(custom)}`;

  const [metric, dispatchMetricTable] = useReducer((state: { [index: string]: MetricTableSet }, action: QueryAction) => {
    switch (action.type) {
      case "PATCH_METRIC":
        return {
          ...state,
          [action.id]: {
            ...state[action.id],
            ...action.data,
          },
        };
      default:
        return state;
    }
  }, {});

  const value = useMemo(
    () => ({
      metric,
      getCustomMetricTable: (report: MetricReport, query: Query, custom?: CustomType) => {
        const id = memo(report, query, custom);
        const set = metric[id];

        if (set) {
          return set;
        }

        dispatchMetricTable({
          type: "PATCH_METRIC",
          id,
          data: defaultMetricTable,
        });

        customMetricTableFor(query, report, custom)
          .then(metric => {
            dispatchMetricTable({
              type: "PATCH_METRIC",
              id,
              data: {
                metric: metric,
                custom: custom,
                loading: {
                  loaded: true,
                  loading: false,
                  error: null,
                },
              },
            });
          })
          .catch(e => {
            dispatchMetricTable({
              type: "PATCH_METRIC",
              id,
              data: {
                loading: {
                  loaded: false,
                  loading: false,
                  error: e,
                },
              },
            });
          });

        return defaultMetricTable;
      },
    }),
    [metric],
  );

  return <CustomMetricTableContext.Provider value={value}>{children}</CustomMetricTableContext.Provider>;
};
