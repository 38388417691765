import Paper from "@material-ui/core/Paper";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import React from "react";
import { Route } from "react-router-dom";
import { MenuItem } from "../../charts/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import { locations } from "../../layout";
import { TabAccessContext } from "../../contexts/TabAccessContext";
import { translation } from "../../translations/Translations";
import { useIntl } from "react-intl";
import { CustomDashboardContext } from "../../contexts/Custom/DashboardContext";
import { PermissionsContext } from "../../contexts/PermissionsContext";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "none",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      flexGrow: 1,
      backgroundColor: "#191919",
      color: "#fff",
      border: "none",
      paddingRight: theme.spacing.unit * 2,
      width: "1268px", // Custom to line up
      margin: "auto",
    },
    mobileMenu: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  });

export type CustomDashboardTabsProps = {
  className: any;
} & WithStyles<typeof styles>;

const CustomDashboardTabsUnstyled: React.FunctionComponent<CustomDashboardTabsProps> = ({ classes, children, className }) => {
  const { tabs: accessTabs, loading } = React.useContext(TabAccessContext);
  const { customTab } = React.useContext(CustomDashboardContext);

  if (loading.loading) {
    <CircularProgress size={15} />;
  }

  const availLocations = Object.values(accessTabs).map((tab: any) => tab.name);

  const intl = useIntl();

  const { commandPermissions } = React.useContext(PermissionsContext);

  const canViewOemDashboard = React.useMemo(
    () => (commandPermissions.find(permission => permission.commandName === "customOemDash") || { canExecute: false }).canExecute,
    [commandPermissions],
  );

  return (
    <Paper square className={classnames(classes.root, classes.mobileMenu, className)}>
      <Route
        path="/custom/:type?"
        render={({ match }) =>
          locations.customDashboard.children.map(location => {
            if (availLocations.includes(location.id) || (location.id == "Distribution Dashboard / Custom" && canViewOemDashboard)) {
              return (
                <MenuItem
                  to={`${location.to}/${customTab ? customTab : ""}`}
                  key={location.label}
                  active={match.url == `${location.to}/`}
                  count={location.count}
                  variant="dashboard"
                >
                  {translation[location.label] ? intl.formatMessage({ id: translation[location.label] }) : location.label}
                </MenuItem>
              );
            }
            return null;
          })
        }
      />
    </Paper>
  );
};

export const CustomDashboardTabs = withStyles(styles)(CustomDashboardTabsUnstyled);
