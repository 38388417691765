import React, { createContext, useState, useReducer, useMemo } from "react";
import { Loading } from "./base";
import { TaskAttachment, createTaskAttachments, deleteTaskAttachment, getTaskAttachments } from "../lib/api/todo-list";

const defaultAttachments = [];

export type TodoAttachmentsContextState = {
  attachments: TaskAttachment[];
  loading: Loading;
  hydrate();
  createTaskAttachments(attachment: Partial<TaskAttachment>);
  deleteTaskAttachment(id: number);
  getTaskAttachments(): TaskAttachment[];
};


export const TodoAttachmentsContext = createContext<TodoAttachmentsContextState>({
  attachments: defaultAttachments,
  loading: { loading: false, loaded: false, error: null },
  hydrate: () => { },
  createTaskAttachments: () => { },
  deleteTaskAttachment: () => { },
  getTaskAttachments: () => [],
});


export const TodoAttachmentsProvider = ({ children }) => {
  const [attachments, setAttachments] = useState<TaskAttachment[]>(defaultAttachments);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const hydrate = async () => {
    try {
      setLoading({
        loading: true,
        loaded: false,
        error: null,
      });
      const attachments = await getTaskAttachments();
      setAttachments(attachments);
      setLoading({
        loading: false,
        loaded: true,
        error: null,
      });
    } catch (e) {
      setLoading({
        loading: false,
        loaded: false,
        error: e,
      });
    }
  }

  const value = useMemo(() => ({
    attachments,
    loading,
    hydrate,
    getTaskAttachments() {
      if (loading.loaded && !loading.loading) {
        return attachments;
      }
      if (!loading.loading && !loading.error) {
        hydrate();
      }
      return [];
    },
    createTaskAttachments: async (attachment: TaskAttachment) => {
      try {
        setLoading({
          loading: true,
          loaded: false,
          error: null,
        });
        await createTaskAttachments(attachment);
        setLoading({
          loading: false,
          loaded: true,
          error: null,
        });
        hydrate();
      } catch (e) {
        setLoading({
          loading: false,
          loaded: false,
          error: e,
        });
      }
    },
    deleteTaskAttachment: async (id: number) => {
      try {
        setLoading({
          loading: true,
          loaded: false,
          error: null,
        });
        await deleteTaskAttachment(id);
        setLoading({
          loading: false,
          loaded: true,
          error: null,
        });
        hydrate();
      } catch (e) {
        setLoading({
          loading: false,
          loaded: false,
          error: e,
        });
      }
    },
    setAttachments,
  }), [attachments, loading]);

  return (
    <TodoAttachmentsContext.Provider value={value}>
      {children}
    </TodoAttachmentsContext.Provider>
  )
}
