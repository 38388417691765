import { CustomDashboardType, getMeasure } from "../CustomDashReports";

export const OverallDealership: { [index: string]: CustomDashboardType } = {
  "Overall Dealership > REVENUE > Total Dealership Sales": {
    name: "Overall Dealership > REVENUE > Total Dealership Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "Total Dealership Sales",
    tableNumber: 1,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > REVENUE > Total Dealership Breakeven NV Units": {
    name: "Overall Dealership > REVENUE > Total Dealership Breakeven NV Units",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "Total Dealership Breakeven NV Units",
    tableNumber: 2,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Total Dealership Breakeven Units | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > REVENUE > Total Dealership Breakeven Days": {
    name: "Overall Dealership > REVENUE > Total Dealership Breakeven Days",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "Total Dealership Breakeven Days",
    tableNumber: 2,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Breakeven Days | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > REVENUE > NV Units": {
    name: "Overall Dealership > REVENUE > NV Units",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "NV Units",
    tableNumber: 27,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > In Dealer Units | New | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > REVENUE > NV Target ahcievment (Factory)": {
    name: "Overall Dealership > REVENUE > NV Target ahcievment (Factory)",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "NV Target Achievement (Factory)",
    tableNumber: 27,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Target % Achieved - New Total Units | New | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > REVENUE > NV Retail Units": {
    name: "Overall Dealership > REVENUE > NV Retail Units",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "NV Retail Units",
    tableNumber: 27,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > In Dealer Units | New Retail | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > REVENUE > NV Fleet Units": {
    name: "Overall Dealership > REVENUE > NV Fleet Units",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "NV Fleet Units",
    tableNumber: 27,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > In Dealer Units | New Fleet | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > REVENUE > UV Units": {
    name: "Overall Dealership > REVENUE > UV Units",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "UV Units",
    tableNumber: 41,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > In Dealer Units | Used | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > REVENUE > UV Retail & Demo Units": {
    name: "Overall Dealership > REVENUE > UV Retail & Demo Units",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "UV Retail & Demo Units",
    tableNumber: 41,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Units Retail & Demo | Used | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > REVENUE > UV Retail Units": {
    name: "Overall Dealership > REVENUE > UV Retail Units",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "UV Retail Units",
    tableNumber: 41,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Units Retail | Used | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > REVENUE > Demo Vehicle Units": {
    name: "Overall Dealership > REVENUE > Demo Vehicle Units",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "Demo Vehicle Units",
    tableNumber: 41,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Units Demo | Used | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > REVENUE > UV Trade Units": {
    name: "Overall Dealership > REVENUE > UV Trade Units",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "UV Trade Units",
    tableNumber: 41,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Units Trade | Used | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > REVENUE > NV Sales": {
    name: "Overall Dealership > REVENUE > NV Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "NV Sales",
    tableNumber: 27,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | New | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > REVENUE > UV Sales": {
    name: "Overall Dealership > REVENUE > UV Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "UV Sales",
    tableNumber: 41,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | Used | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > REVENUE > F&I Sales": {
    name: "Overall Dealership > REVENUE > F&I Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "F&I Sales",
    tableNumber: 55,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | F&I Dept | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > REVENUE > Aftercare Sales": {
    name: "Overall Dealership > REVENUE > Aftercare Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "Aftercare Sales ",
    tableNumber: 142,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | Aftercare | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > REVENUE > NV Sales incl. F&I & Aftercare": {
    name: "Overall Dealership > REVENUE > NV Sales incl. F&I & Aftercare",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "NV Sales incl. F&I & Aftercare",
    tableNumber: 27,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > New Net Sales New Aftercare New F&I | New | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > REVENUE > UV Sales incl. F&I & Aftercare": {
    name: "Overall Dealership > REVENUE > UV Sales incl. F&I & Aftercare",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "UV Sales incl. F&I & Aftercare",
    tableNumber: 41,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Used Net Sales Used Aftercare Used F&I | Used | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > REVENUE > Fixed Ops Sales": {
    name: "Overall Dealership > REVENUE > Fixed Ops Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "Fixed Ops Sales",
    tableNumber: 67,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | AfterSales | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > REVENUE > Parts Sales": {
    name: "Overall Dealership > REVENUE > Parts Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "Parts Sales",
    tableNumber: 80,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | Parts | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > REVENUE > Service Sales": {
    name: "Overall Dealership > REVENUE > Service Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "Service Sales",
    tableNumber: 97,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | Service | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > REVENUE > Quick Service Sales": {
    name: "Overall Dealership > REVENUE > Quick Service Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "Quick Service Sales",
    tableNumber: 97,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | Quick Service | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > REVENUE > Service & Quick Service Sales": {
    name: "Overall Dealership > REVENUE > Service & Quick Service Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "Service & Quick Service Sales",
    tableNumber: 97,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | ServiceQuickService | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > REVENUE > Body Shop Sales": {
    name: "Overall Dealership > REVENUE > Body Shop Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "Body Shop Sales",
    tableNumber: 113,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | Body Shop | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > REVENUE > Others Sales": {
    name: "Overall Dealership > REVENUE > Others Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "Others Sales",
    tableNumber: 154,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | OtherAP | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > REVENUE > New F&I Aftercare Sales % Total Sales": {
    name: "Overall Dealership > REVENUE > New F&I Aftercare Sales % Total Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "New F&I Aftercare Sales % Total Sales",
    tableNumber: 166,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > New Vehicle (incl F&I & Aftercare) Mix of Sales | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > REVENUE > Used F&I Aftercare Sales % Total Sales": {
    name: "Overall Dealership > REVENUE > Used F&I Aftercare Sales % Total Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "Used F&I Aftercare Sales % Total Sales",
    tableNumber: 166,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Used Vehicle (incl F&I & Aftercare) Mix of Sales | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > REVENUE > New Vehicle Sales % Total Sales": {
    name: "Overall Dealership > REVENUE > New Vehicle Sales % Total Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "New Vehicle Sales % Total Sales",
    tableNumber: 166,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > New Vehicle Mix of Sales | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > REVENUE > Used Vehicle Sales % Total Sales": {
    name: "Overall Dealership > REVENUE > Used Vehicle Sales % Total Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "Used Vehicle Sales % Total Sales",
    tableNumber: 166,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Used Vehicle Mix of Sales | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > REVENUE > F&I Sales % Total Sales": {
    name: "Overall Dealership > REVENUE > F&I Sales % Total Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "F&I Sales % Total Sales",
    tableNumber: 166,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > F&I Mix of Sales | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > REVENUE > Aftercare Sales % Total Sales": {
    name: "Overall Dealership > REVENUE > Aftercare Sales % Total Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "Aftercare Sales % Total Sales",
    tableNumber: 166,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Aftercare Mix of Sales | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > REVENUE > After-Sales Sales % Total Sales": {
    name: "Overall Dealership > REVENUE > After-Sales Sales % Total Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "After-Sales Sales % Total Sales",
    tableNumber: 166,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Aftersales Mix of Sales | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > REVENUE > Parts Sales % Total Sales": {
    name: "Overall Dealership > REVENUE > Parts Sales % Total Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "Parts Sales % Total Sales",
    tableNumber: 166,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Parts Mix of Sales | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > REVENUE > Service Sales % Total Sales": {
    name: "Overall Dealership > REVENUE > Service Sales % Total Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "Service Sales % Total Sales",
    tableNumber: 166,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Service QuickService Mix of Sales | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > REVENUE > Body Shop Sales % Total Sales": {
    name: "Overall Dealership > REVENUE > Body Shop Sales % Total Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "Body Shop Sales % Total Sales",
    tableNumber: 166,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Body Shop Mix of Sales | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > REVENUE > Other Sales % Total Sales": {
    name: "Overall Dealership > REVENUE > Other Sales % Total Sales",
    department: "Overall Dealership",
    category: "REVENUE",
    label: "Other Sales % Total Sales",
    tableNumber: 166,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Other Mix of Sales | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > GROSS PROFIT": {
    name: "Overall Dealership > GROSS PROFIT > GROSS PROFIT",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "GROSS PROFIT",
    tableNumber: 3,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Total Dealership Sales": {
    name: "Overall Dealership > GROSS PROFIT > Total Dealership Sales",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Total Dealership Sales",
    tableNumber: 1,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > GROSS PROFIT > Total Dealership GP": {
    name: "Overall Dealership > GROSS PROFIT > Total Dealership GP",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Total Dealership GP",
    tableNumber: 3,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > GROSS PROFIT > Total Dealership GP% excl. Manufacturer Incentives": {
    name: "Overall Dealership > GROSS PROFIT > Total Dealership GP% excl. Manufacturer Incentives",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Total Dealership GP% excl. Manufacturer Incentives",
    tableNumber: 3,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit (excl Incentive) % of Net Sales | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > NV MIX (NV Only)": {
    name: "Overall Dealership > GROSS PROFIT > NV MIX (NV Only)",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "NV MIX (NV Only)",
    tableNumber: 29,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | New | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > UV MIX (UV Only)": {
    name: "Overall Dealership > GROSS PROFIT > UV MIX (UV Only)",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "UV MIX (UV Only)",
    tableNumber: 43,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | Used | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > F&I MIX (NV & UV)": {
    name: "Overall Dealership > GROSS PROFIT > F&I MIX (NV & UV)",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "F&I MIX (NV & UV)",
    tableNumber: 56,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | F&I Dept | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Aftercare MIX (NV & UV)": {
    name: "Overall Dealership > GROSS PROFIT > Aftercare MIX (NV & UV)",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Aftercare MIX (NV & UV)",
    tableNumber: 143,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | Aftercare | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > NV MIX incl. F&I & Aftercare": {
    name: "Overall Dealership > GROSS PROFIT > NV MIX incl. F&I & Aftercare",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "NV MIX incl. F&I & Aftercare",
    tableNumber: 29,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > New Gross Profit % of Sales (incl F&I & Aftercare) | NewF&IAftercare | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > UV MIX incl. F&I & Aftercare": {
    name: "Overall Dealership > GROSS PROFIT > UV MIX incl. F&I & Aftercare",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "UV MIX incl. F&I & Aftercare",
    tableNumber: 43,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Used Gross Profit % of Sales (incl F&I & Aftercare) | UsedF&IAftercare | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Parts MIX": {
    name: "Overall Dealership > GROSS PROFIT > Parts MIX",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Parts MIX",
    tableNumber: 86,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | Parts | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Service MIX": {
    name: "Overall Dealership > GROSS PROFIT > Service MIX",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Service MIX",
    tableNumber: 101,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | Service | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Quick Service MIX": {
    name: "Overall Dealership > GROSS PROFIT > Quick Service MIX",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Quick Service MIX",
    tableNumber: 101,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | Quick Service | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Service & Quick Service MIX": {
    name: "Overall Dealership > GROSS PROFIT > Service & Quick Service MIX",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Service & Quick Service MIX",
    tableNumber: 101,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | ServiceQuickService | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Body Shop MIX": {
    name: "Overall Dealership > GROSS PROFIT > Body Shop MIX",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Body Shop MIX",
    tableNumber: 117,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | Body Shop | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Driveway MIX": {
    name: "Overall Dealership > GROSS PROFIT > Driveway MIX",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Driveway MIX",
    tableNumber: 131,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | Driveway | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Other Departments MIX": {
    name: "Overall Dealership > GROSS PROFIT > Other Departments MIX",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Other Departments MIX",
    tableNumber: 155,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | OtherAP | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > NV GP mix (NV Only)": {
    name: "Overall Dealership > GROSS PROFIT > NV GP mix (NV Only)",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "NV GP mix (NV Only)",
    tableNumber: 29,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > New Vehicle Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > UV GP mix (UV Only)": {
    name: "Overall Dealership > GROSS PROFIT > UV GP mix (UV Only)",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "UV GP mix (UV Only)",
    tableNumber: 43,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Used Vehicle Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > F&I GP mix (NV & UV)": {
    name: "Overall Dealership > GROSS PROFIT > F&I GP mix (NV & UV)",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "F&I GP mix (NV & UV)",
    tableNumber: 56,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > F&I Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Aftercare GP mix (NV & UV)": {
    name: "Overall Dealership > GROSS PROFIT > Aftercare GP mix (NV & UV)",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Aftercare GP mix (NV & UV)",
    tableNumber: 143,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Aftercare Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > NV GP mix incl. F&I & Aftercare": {
    name: "Overall Dealership > GROSS PROFIT > NV GP mix incl. F&I & Aftercare",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "NV GP mix incl. F&I & Aftercare",
    tableNumber: 29,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > New Gross Profit (incl F&I & Aftercare) Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > UV GP mix incl. F&I & Aftercare": {
    name: "Overall Dealership > GROSS PROFIT > UV GP mix incl. F&I & Aftercare",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "UV GP mix incl. F&I & Aftercare",
    tableNumber: 43,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Used Gross Profit (incl F&I & Aftercare) Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Parts GP mix": {
    name: "Overall Dealership > GROSS PROFIT > Parts GP mix",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Parts GP mix",
    tableNumber: 86,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Parts Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Service GP mix": {
    name: "Overall Dealership > GROSS PROFIT > Service GP mix",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Service GP mix",
    tableNumber: 101,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Service Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Quick Service GP mix": {
    name: "Overall Dealership > GROSS PROFIT > Quick Service GP mix",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Quick Service GP mix",
    tableNumber: 101,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > QuickService Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Service & Quick Service GP mix": {
    name: "Overall Dealership > GROSS PROFIT > Service & Quick Service GP mix",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Service & Quick Service GP mix",
    tableNumber: 101,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Service QuickService Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Body Shop GP mix": {
    name: "Overall Dealership > GROSS PROFIT > Body Shop GP mix",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Body Shop GP mix",
    tableNumber: 117,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Body Shop Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Driveway GP mix": {
    name: "Overall Dealership > GROSS PROFIT > Driveway GP mix",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Driveway GP mix",
    tableNumber: 131,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Driveway Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Other Departments GP mix": {
    name: "Overall Dealership > GROSS PROFIT > Other Departments GP mix",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Other Departments GP mix",
    tableNumber: 155,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Other Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > New F&I Aftercare GP % Total GP": {
    name: "Overall Dealership > GROSS PROFIT > New F&I Aftercare GP % Total GP",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "New F&I Aftercare GP % Total GP",
    tableNumber: 167,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > New Gross Profit (incl F&I & Aftercare) Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Used F&I Aftercare GP % Total GP": {
    name: "Overall Dealership > GROSS PROFIT > Used F&I Aftercare GP % Total GP",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Used F&I Aftercare GP % Total GP",
    tableNumber: 167,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Used Gross Profit (incl F&I & Aftercare) Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > NV GP % Total GP": {
    name: "Overall Dealership > GROSS PROFIT > NV GP % Total GP",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "NV GP % Total GP",
    tableNumber: 167,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > New Vehicle Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > UV GP % Total GP": {
    name: "Overall Dealership > GROSS PROFIT > UV GP % Total GP",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "UV GP % Total GP",
    tableNumber: 167,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Used Vehicle Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > F&I GP % Total GP": {
    name: "Overall Dealership > GROSS PROFIT > F&I GP % Total GP",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "F&I GP % Total GP",
    tableNumber: 167,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > F&I Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Aftercare GP % Total GP": {
    name: "Overall Dealership > GROSS PROFIT > Aftercare GP % Total GP",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Aftercare GP % Total GP",
    tableNumber: 167,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Aftercare Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > After-Sales GP % Total GP": {
    name: "Overall Dealership > GROSS PROFIT > After-Sales GP % Total GP",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "After-Sales GP % Total GP",
    tableNumber: 167,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Aftersales Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Parts GP % Total GP": {
    name: "Overall Dealership > GROSS PROFIT > Parts GP % Total GP",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Parts GP % Total GP",
    tableNumber: 167,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Parts Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Service GP % Total GP": {
    name: "Overall Dealership > GROSS PROFIT > Service GP % Total GP",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Service GP % Total GP",
    tableNumber: 167,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Service QuickService Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Body Shop GP % Total GP": {
    name: "Overall Dealership > GROSS PROFIT > Body Shop GP % Total GP",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Body Shop GP % Total GP",
    tableNumber: 167,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Body Shop Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > GROSS PROFIT > Other GP % Total GP": {
    name: "Overall Dealership > GROSS PROFIT > Other GP % Total GP",
    department: "Overall Dealership",
    category: "GROSS PROFIT",
    label: "Other GP % Total GP",
    tableNumber: 167,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Other Mix of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > EXPENSES > RETAINED": {
    name: "Overall Dealership > EXPENSES > RETAINED",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "RETAINED",
    tableNumber: 4,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT % of TGP: RETAINED | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > EXPENSES > Total Dealership GP": {
    name: "Overall Dealership > EXPENSES > Total Dealership GP",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Total Dealership GP",
    tableNumber: 3,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > EXPENSES > Total Dealership Expenses": {
    name: "Overall Dealership > EXPENSES > Total Dealership Expenses",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Total Dealership Expenses",
    tableNumber: 4,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TE > Total Expenses | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > EXPENSES > Total Dealership PBT": {
    name: "Overall Dealership > EXPENSES > Total Dealership PBT",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Total Dealership PBT",
    tableNumber: 14,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT (Profit Before Tax) | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > EXPENSES > Total Expenses % TGP": {
    name: "Overall Dealership > EXPENSES > Total Expenses % TGP",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Total Expenses % TGP",
    tableNumber: 4,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TE > Total Expenses % of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > EXPENSES > Variable Expenses % TGP": {
    name: "Overall Dealership > EXPENSES > Variable Expenses % TGP",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Variable Expenses % TGP",
    tableNumber: 5,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "VE > Variable Expenses % of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > EXPENSES > Personnel Expenses % TGP": {
    name: "Overall Dealership > EXPENSES > Personnel Expenses % TGP",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Personnel Expenses % TGP",
    tableNumber: 6,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses % of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > EXPENSES > Other Semi Var. Expenses % TGP": {
    name: "Overall Dealership > EXPENSES > Other Semi Var. Expenses % TGP",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Other Semi Var. Expenses % TGP",
    tableNumber: 7,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "SVE > Other Semi Variable Expenses % of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > EXPENSES > Fixed Expenses % TGP": {
    name: "Overall Dealership > EXPENSES > Fixed Expenses % TGP",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Fixed Expenses % TGP",
    tableNumber: 8,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "FE > Fixed Expenses % of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > EXPENSES > Interest Expenses % TGP": {
    name: "Overall Dealership > EXPENSES > Interest Expenses % TGP",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Interest Expenses % TGP",
    tableNumber: 9,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "I > Interest % of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > EXPENSES > Admin Fees Payable % TGP": {
    name: "Overall Dealership > EXPENSES > Admin Fees Payable % TGP",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Admin Fees Payable  % TGP",
    tableNumber: 10,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Adm > Admin Fee Payable % of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > EXPENSES > Deduction / (Addition) % TGP": {
    name: "Overall Dealership > EXPENSES > Deduction / (Addition) % TGP",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Deduction / (Addition) % TGP",
    tableNumber: 11,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "D > Additions & Deductions % of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > EXPENSES > Variable Expenses% Total Expenses": {
    name: "Overall Dealership > EXPENSES > Variable Expenses% Total Expenses",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Variable Expenses% Total Expenses",
    tableNumber: 5,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "VE > Variable Expenses % of Total Expenses | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > EXPENSES > Personnel Expenses% Total Expenses": {
    name: "Overall Dealership > EXPENSES > Personnel Expenses% Total Expenses",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Personnel Expenses% Total Expenses",
    tableNumber: 6,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses % of Total Expenses | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > EXPENSES > Other Semi Var.Exp.% Total Expenses": {
    name: "Overall Dealership > EXPENSES > Other Semi Var.Exp.% Total Expenses",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Other Semi Var.Exp.% Total Expenses",
    tableNumber: 7,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "SVE > Other Semi Variable Expenses % of Total Expenses | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > EXPENSES > Fixed Expenses% Total Expenses": {
    name: "Overall Dealership > EXPENSES > Fixed Expenses% Total Expenses",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Fixed Expenses% Total Expenses",
    tableNumber: 8,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "FE > Fixed Expenses % of Total Expenses | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > EXPENSES > Interest Expenses% Total Expenses": {
    name: "Overall Dealership > EXPENSES > Interest Expenses% Total Expenses",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Interest Expenses% Total Expenses",
    tableNumber: 9,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "I > Interest % of Total Expenses | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > EXPENSES > Admin Fees% Total Expenses": {
    name: "Overall Dealership > EXPENSES > Admin Fees% Total Expenses",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Admin Fees% Total Expenses",
    tableNumber: 10,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Adm > Admin Fee Payable % of Total Expenses | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > EXPENSES > Ded. & Add. to income% Total Expenses": {
    name: "Overall Dealership > EXPENSES > Ded. & Add. to income% Total Expenses",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Ded. & Add. to income% Total Expenses",
    tableNumber: 11,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "D > Additions & Deductions % of Total Expenses | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > EXPENSES > Variable Expenses": {
    name: "Overall Dealership > EXPENSES > Variable Expenses",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Variable Expenses",
    tableNumber: 168,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "VE > Variable Expenses | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > EXPENSES > Personnel Expenses": {
    name: "Overall Dealership > EXPENSES > Personnel Expenses",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Personnel Expenses",
    tableNumber: 168,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > EXPENSES > Other Semi Var.Exp.": {
    name: "Overall Dealership > EXPENSES > Other Semi Var.Exp.",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Other Semi Var.Exp.",
    tableNumber: 168,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "SVE > Other Semi Variable Expenses | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > EXPENSES > Fixed Expenses": {
    name: "Overall Dealership > EXPENSES > Fixed Expenses",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Fixed Expenses",
    tableNumber: 168,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "FE > Fixed Expenses | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > EXPENSES > Interest Expenses": {
    name: "Overall Dealership > EXPENSES > Interest Expenses",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Interest Expenses",
    tableNumber: 168,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "I > Interest | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > EXPENSES > Admin Fees": {
    name: "Overall Dealership > EXPENSES > Admin Fees",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Admin Fees",
    tableNumber: 168,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Adm > Admin Fee Payable | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > EXPENSES > Deductions from Income": {
    name: "Overall Dealership > EXPENSES > Deductions from Income",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Deductions from Income",
    tableNumber: 168,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "D > Deductions | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > EXPENSES > Additions to Income": {
    name: "Overall Dealership > EXPENSES > Additions to Income",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Additions to Income",
    tableNumber: 168,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "A > Additions | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > EXPENSES > Facility cost": {
    name: "Overall Dealership > EXPENSES > Facility cost",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Facility cost",
    tableNumber: 168,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "FE > Rent & Depreciation | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > EXPENSES > Facility cost % Sales": {
    name: "Overall Dealership > EXPENSES > Facility cost % Sales",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Facility cost % Sales",
    tableNumber: 168,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "FE > Rent & Depreciation % Sales | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > EXPENSES > Facility cost % GP": {
    name: "Overall Dealership > EXPENSES > Facility cost % GP",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Facility cost % GP",
    tableNumber: 168,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "FE > Rent & Depreciation % TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > EXPENSES > Rent cost": {
    name: "Overall Dealership > EXPENSES > Rent cost",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Rent cost",
    tableNumber: 168,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "FE > Rent | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > EXPENSES > Rent cost % Sales": {
    name: "Overall Dealership > EXPENSES > Rent cost % Sales",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Rent cost % Sales",
    tableNumber: 168,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "FE > Rent % of TO | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > EXPENSES > Rent cost % GP": {
    name: "Overall Dealership > EXPENSES > Rent cost % GP",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Rent cost % GP",
    tableNumber: 168,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "FE > Rent % of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > EXPENSES > Depreciation cost": {
    name: "Overall Dealership > EXPENSES > Depreciation cost",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Depreciation cost",
    tableNumber: 168,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "FE > Depreciation | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > EXPENSES > Depreciation cost % Sales": {
    name: "Overall Dealership > EXPENSES > Depreciation cost % Sales",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Depreciation cost % Sales",
    tableNumber: 168,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Placeholder Measure",
    canAverage: false,
  },
  "Overall Dealership > EXPENSES > Depreciation cost % GP": {
    name: "Overall Dealership > EXPENSES > Depreciation cost % GP",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Depreciation cost % GP",
    tableNumber: 168,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "FE > Depreciation % of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },

  "Overall Dealership >	EXPENSES > Total Cost Recovery": {
    name: "Overall Dealership >	EXPENSES > Total Cost Recovery",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Total Cost Recovery",
    tableNumber: 170,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Cost Recovery | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership >	EXPENSES > New F&I Aftercare Cost Recovery": {
    name: "Overall Dealership >	EXPENSES > New F&I Aftercare Cost Recovery",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "New F&I Aftercare Cost Recovery",
    tableNumber: 171,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Cost Recovery | NewAftercareF&ISplit | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership >	EXPENSES > Used F&I Aftercare Cost Recovery": {
    name: "Overall Dealership >	EXPENSES > Used F&I Aftercare Cost Recovery",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Used F&I Aftercare Cost Recovery",
    tableNumber: 172,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Cost Recovery | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership >	EXPENSES > New Cost Recovery": {
    name: "Overall Dealership >	EXPENSES > New Cost Recovery",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "New Cost Recovery",
    tableNumber: 171,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Cost Recovery | New | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership >	EXPENSES > Used Cost Recovery": {
    name: "Overall Dealership >	EXPENSES > Used Cost Recovery",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Used Cost Recovery",
    tableNumber: 172,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Cost Recovery | Used | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership >	EXPENSES > F&I Cost Recovery": {
    name: "Overall Dealership >	EXPENSES > F&I Cost Recovery",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "F&I Cost Recovery",
    tableNumber: 173,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Cost Recovery | F&I Dept | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership >	EXPENSES > Aftercare Cost Recovery": {
    name: "Overall Dealership >	EXPENSES > Aftercare Cost Recovery",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Aftercare Cost Recovery",
    tableNumber: 174,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Cost Recovery | Aftercare | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership >	EXPENSES > After Sales Cost Recovery": {
    name: "Overall Dealership >	EXPENSES > After Sales Cost Recovery",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "After Sales Cost Recovery",
    tableNumber: 175,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Cost Recovery | Aftersales | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership >	EXPENSES > Parts Cost Recovery": {
    name: "Overall Dealership >	EXPENSES > Parts Cost Recovery",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Parts Cost Recovery",
    tableNumber: 176,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Cost Recovery | Parts | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership >	EXPENSES > Service Cost Recovery": {
    name: "Overall Dealership >	EXPENSES > Service Cost Recovery",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Service Cost Recovery",
    tableNumber: 177,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Cost Recovery | ServiceQuickService | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership >	EXPENSES > Body Shop Cost Recovery": {
    name: "Overall Dealership >	EXPENSES > Body Shop Cost Recovery",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Body Shop Cost Recovery",
    tableNumber: 178,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Cost Recovery | Body Shop | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership >	EXPENSES > Other Department Cost Recovery": {
    name: "Overall Dealership >	EXPENSES > Other Department Cost Recovery",
    department: "Overall Dealership",
    category: "EXPENSES",
    label: "Other Department Cost Recovery",
    tableNumber: 179,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Cost Recovery | OtherAP | All Brand | All Model | | SUM",
    canAverage: false,
  },

  "Overall Dealership > OPERATIONAL ASSETS > ACTIVITY": {
    name: "Overall Dealership > OPERATIONAL ASSETS > ACTIVITY",
    department: "Overall Dealership",
    category: "OPERATIONAL ASSETS",
    label: "ACTIVITY",
    tableNumber: 12,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Asset Activity | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > OPERATIONAL ASSETS > Annualised Sales": {
    name: "Overall Dealership > OPERATIONAL ASSETS > Annualised Sales",
    department: "Overall Dealership",
    category: "OPERATIONAL ASSETS",
    label: "Annualised Sales",
    tableNumber: 1,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Projected Annualised Sales | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > OPERATIONAL ASSETS > Average Operational Assets": {
    name: "Overall Dealership > OPERATIONAL ASSETS > Average Operational Assets",
    department: "Overall Dealership",
    category: "OPERATIONAL ASSETS",
    label: "Average Operational Assets",
    tableNumber: 12,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Average Operational Assets | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Overall Dealership > OPERATIONAL ASSETS > AVG Inventories": {
    name: "Overall Dealership > OPERATIONAL ASSETS > AVG Inventories",
    department: "Overall Dealership",
    category: "OPERATIONAL ASSETS",
    label: "AVG Inventories",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Net Inventories | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Overall Dealership > OPERATIONAL ASSETS > AVG Receivables": {
    name: "Overall Dealership > OPERATIONAL ASSETS > AVG Receivables",
    department: "Overall Dealership",
    category: "OPERATIONAL ASSETS",
    label: "AVG Receivables",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Trade and Other Receivables | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Overall Dealership > OPERATIONAL ASSETS > AVG Cash on Hand": {
    name: "Overall Dealership > OPERATIONAL ASSETS > AVG Cash on Hand",
    department: "Overall Dealership",
    category: "OPERATIONAL ASSETS",
    label: "AVG Cash on Hand",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Cash & Cash Equivalent | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Overall Dealership > OPERATIONAL ASSETS > AVG Other Current Assets": {
    name: "Overall Dealership > OPERATIONAL ASSETS > AVG Other Current Assets",
    department: "Overall Dealership",
    category: "OPERATIONAL ASSETS",
    label: "AVG Other Current Assets",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "CA > Other Current assets | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Overall Dealership > OPERATIONAL ASSETS > AVG Equipment and Other Fixed Assets": {
    name: "Overall Dealership > OPERATIONAL ASSETS > AVG Equipment and Other Fixed Assets",
    department: "Overall Dealership",
    category: "OPERATIONAL ASSETS",
    label: "AVG Equipment and Other Fixed Assets",
    tableNumber: 26,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "NCA > Fix Assets | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Overall Dealership > OPERATIONAL ASSETS > Debtors Days": {
    name: "Overall Dealership > OPERATIONAL ASSETS > Debtors Days",
    department: "Overall Dealership",
    category: "OPERATIONAL ASSETS",
    label: "Debtors Days",
    tableNumber: 22,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Debtors days | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > OPERATIONAL ASSETS > NV Stock Days": {
    name: "Overall Dealership > OPERATIONAL ASSETS > NV Stock Days",
    department: "Overall Dealership",
    category: "OPERATIONAL ASSETS",
    label: "NV Stock Days",
    tableNumber: 36,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory days | New | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > OPERATIONAL ASSETS > UV Stock Days": {
    name: "Overall Dealership > OPERATIONAL ASSETS > UV Stock Days",
    department: "Overall Dealership",
    category: "OPERATIONAL ASSETS",
    label: "UV Stock Days",
    tableNumber: 50,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory days | Used | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > OPERATIONAL ASSETS > Retail Stock Days": {
    name: "Overall Dealership > OPERATIONAL ASSETS > Retail Stock Days",
    department: "Overall Dealership",
    category: "OPERATIONAL ASSETS",
    label: "Retail Stock Days",
    tableNumber: 50,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory days (Excl Demo) | Used | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > OPERATIONAL ASSETS > Demo Stock Days": {
    name: "Overall Dealership > OPERATIONAL ASSETS > Demo Stock Days",
    department: "Overall Dealership",
    category: "OPERATIONAL ASSETS",
    label: "Demo Stock Days",
    tableNumber: 50,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory days Demo Inventory | Used | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > OPERATIONAL ASSETS > Parts Stock Days": {
    name: "Overall Dealership > OPERATIONAL ASSETS > Parts Stock Days",
    department: "Overall Dealership",
    category: "OPERATIONAL ASSETS",
    label: "Parts Stock Days",
    tableNumber: 93,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Inventory days | Parts | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > OPERATIONAL ASSETS > Service WIP Days": {
    name: "Overall Dealership > OPERATIONAL ASSETS > Service WIP Days",
    department: "Overall Dealership",
    category: "OPERATIONAL ASSETS",
    label: "Service WIP Days",
    tableNumber: 109,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > WIP days | ServiceQuickService | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > MRA MODEL > ROOA": {
    name: "Overall Dealership > MRA MODEL > ROOA",
    department: "Overall Dealership",
    category: "MRA MODEL",
    label: "ROOA",
    tableNumber: 13,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Return on Operational Assets (ROOA) | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > MRA MODEL > MIX": {
    name: "Overall Dealership > MRA MODEL > MIX",
    department: "Overall Dealership",
    category: "MRA MODEL",
    label: "MIX",
    tableNumber: 3,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit % of Sales (MIX) | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > MRA MODEL > RETAINED": {
    name: "Overall Dealership > MRA MODEL > RETAINED",
    department: "Overall Dealership",
    category: "MRA MODEL",
    label: "RETAINED",
    tableNumber: 4,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT % of TGP: RETAINED | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > MRA MODEL > ACTIVITY": {
    name: "Overall Dealership > MRA MODEL > ACTIVITY",
    department: "Overall Dealership",
    category: "MRA MODEL",
    label: "ACTIVITY",
    tableNumber: 12,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Asset Activity | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > MRA MODEL > Gross profit": {
    name: "Overall Dealership > MRA MODEL > Gross profit",
    department: "Overall Dealership",
    category: "MRA MODEL",
    label: "Gross profit",
    tableNumber: 3,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > MRA MODEL > Revenue": {
    name: "Overall Dealership > MRA MODEL > Revenue",
    department: "Overall Dealership",
    category: "MRA MODEL",
    label: "Revenue",
    tableNumber: 1,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > MRA MODEL > Expenses": {
    name: "Overall Dealership > MRA MODEL > Expenses",
    department: "Overall Dealership",
    category: "MRA MODEL",
    label: "Expenses",
    tableNumber: 4,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TE > Total Expenses | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > MRA MODEL > Annualised Sales": {
    name: "Overall Dealership > MRA MODEL > Annualised Sales",
    department: "Overall Dealership",
    category: "MRA MODEL",
    label: "Annualised Sales",
    tableNumber: 12,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Projected Annualised Sales | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > MRA MODEL > Average Operational Assets": {
    name: "Overall Dealership > MRA MODEL > Average Operational Assets",
    department: "Overall Dealership",
    category: "MRA MODEL",
    label: "Average Operational Assets",
    tableNumber: 12,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Average Operational Assets | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Overall Dealership > MRA MODEL > ROS": {
    name: "Overall Dealership > MRA MODEL > ROS",
    department: "Overall Dealership",
    category: "MRA MODEL",
    label: "ROS",
    tableNumber: 14,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT % of Sales | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > ROS (Income Statement) > ROS": {
    name: "Overall Dealership > ROS (Income Statement) > ROS",
    department: "Overall Dealership",
    category: "ROS (Income Statement)",
    label: "ROS",
    tableNumber: 14,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT % of Sales | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > ROS (Income Statement) > Total Dealership Sales": {
    name: "Overall Dealership > ROS (Income Statement) > Total Dealership Sales",
    department: "Overall Dealership",
    category: "ROS (Income Statement)",
    label: "Total Dealership Sales",
    tableNumber: 1,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TNS > Net Sales | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > ROS (Income Statement) > Total Dealership PBT": {
    name: "Overall Dealership > ROS (Income Statement) > Total Dealership PBT",
    department: "Overall Dealership",
    category: "ROS (Income Statement)",
    label: "Total Dealership PBT",
    tableNumber: 14,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT (Profit Before Tax) | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > ROS (Income Statement) > Operational Income % Sales": {
    name: "Overall Dealership > ROS (Income Statement) > Operational Income % Sales",
    department: "Overall Dealership",
    category: "ROS (Income Statement)",
    label: "Operational Income % Sales",
    tableNumber: 14,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "OPI > Operational Income % of Sales | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > ROS (Income Statement) > EBIT% (PBIT%)": {
    name: "Overall Dealership > ROS (Income Statement) > EBIT% (PBIT%)",
    department: "Overall Dealership",
    category: "ROS (Income Statement)",
    label: "EBIT% (PBIT%)",
    tableNumber: 14,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBIT % of Sales | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > ROS (Income Statement) > Variable Selling Gross % Gorss Profit": {
    name: "Overall Dealership > ROS (Income Statement) > Variable Selling Gross % Gorss Profit",
    department: "Overall Dealership",
    category: "ROS (Income Statement)",
    label: "Variable Selling Gross % Gorss Profit",
    tableNumber: 5,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Variable Selling Gross % of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > ROS (Income Statement) > Selling Gross % Gross Profit": {
    name: "Overall Dealership > ROS (Income Statement) > Selling Gross % Gross Profit",
    department: "Overall Dealership",
    category: "ROS (Income Statement)",
    label: "Selling Gross % Gross Profit",
    tableNumber: 14,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Selling Gross % of TGP | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > ROS (Income Statement) > Selling Gross % Net Sales": {
    name: "Overall Dealership > ROS (Income Statement) > Selling Gross % Net Sales",
    department: "Overall Dealership",
    category: "ROS (Income Statement)",
    label: "Selling Gross % Net Sales",
    tableNumber: 14,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Selling Gross % of TNS | All Department | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > ROS (Income Statement) > Total Dealership GP": {
    name: "Overall Dealership > ROS (Income Statement) > Total Dealership GP",
    department: "Overall Dealership",
    category: "ROS (Income Statement)",
    label: "Total Dealership GP",
    tableNumber: 14,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > ROS (Income Statement) > Total Expenses": {
    name: "Overall Dealership > ROS (Income Statement) > Total Expenses",
    department: "Overall Dealership",
    category: "ROS (Income Statement)",
    label: "Total Expenses",
    tableNumber: 4,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TE > Total Expenses | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > ROS (Income Statement) > Total Variable Expenses": {
    name: "Overall Dealership > ROS (Income Statement) > Total Variable Expenses",
    department: "Overall Dealership",
    category: "ROS (Income Statement)",
    label: "Total Variable Expenses",
    tableNumber: 5,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "VE > Variable Expenses | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > ROS (Income Statement) > Total Personnel Expenses": {
    name: "Overall Dealership > ROS (Income Statement) > Total Personnel Expenses",
    department: "Overall Dealership",
    category: "ROS (Income Statement)",
    label: "Total Personnel Expenses",
    tableNumber: 6,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > ROS (Income Statement) > Total Other Semi Variable Expenses": {
    name: "Overall Dealership > ROS (Income Statement) > Total Other Semi Variable Expenses",
    department: "Overall Dealership",
    category: "ROS (Income Statement)",
    label: "Total Other Semi Variable Expenses",
    tableNumber: 7,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "SVE > Other Semi Variable Expenses | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > ROS (Income Statement) > Total Fixed Expenses": {
    name: "Overall Dealership > ROS (Income Statement) > Total Fixed Expenses",
    department: "Overall Dealership",
    category: "ROS (Income Statement)",
    label: "Total Fixed Expenses",
    tableNumber: 8,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "FE > Fixed Expenses | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > ROS (Income Statement) > Total Interest Expenses": {
    name: "Overall Dealership > ROS (Income Statement) > Total Interest Expenses",
    department: "Overall Dealership",
    category: "ROS (Income Statement)",
    label: "Total Interest Expenses",
    tableNumber: 9,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "I > Interest | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > ROS (Income Statement) > Total Admin Fees Payable": {
    name: "Overall Dealership > ROS (Income Statement) > Total Admin Fees Payable",
    department: "Overall Dealership",
    category: "ROS (Income Statement)",
    label: "Total Admin Fees Payable ",
    tableNumber: 10,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Adm > Admin Fee Payable | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > ROS (Income Statement) > Total Deduction / (Addition)": {
    name: "Overall Dealership > ROS (Income Statement) > Total Deduction / (Addition)",
    department: "Overall Dealership",
    category: "ROS (Income Statement)",
    label: "Total Deduction / (Addition)",
    tableNumber: 11,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "A > Additions & Deductions | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > ROS (Income Statement) > Total Operational Income": {
    name: "Overall Dealership > ROS (Income Statement) > Total Operational Income",
    department: "Overall Dealership",
    category: "ROS (Income Statement)",
    label: "Total Operational Income",
    tableNumber: 14,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "OPI > Operational Income | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > ROS (Income Statement) > Total EBIT": {
    name: "Overall Dealership > ROS (Income Statement) > Total EBIT",
    department: "Overall Dealership",
    category: "ROS (Income Statement)",
    label: "Total EBIT",
    tableNumber: 14,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBIT (Profit Before Interest & Tax) | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > ROS (Income Statement) > Total PBT": {
    name: "Overall Dealership > ROS (Income Statement) > Total PBT",
    department: "Overall Dealership",
    category: "ROS (Income Statement)",
    label: "Total PBT",
    tableNumber: 14,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT (Profit Before Tax) | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > PRODUCTIVITY > Total Gross Profit / Productive / Month": {
    name: "Overall Dealership > PRODUCTIVITY > Total Gross Profit / Productive / Month",
    department: "Overall Dealership",
    category: "PRODUCTIVITY",
    label: "Total Gross Profit / Productive / Month",
    tableNumber: 15,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Monthly TGP per Productive | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > PRODUCTIVITY > Total PBT / Employee / Month": {
    name: "Overall Dealership > PRODUCTIVITY > Total PBT / Employee / Month",
    department: "Overall Dealership",
    category: "PRODUCTIVITY",
    label: "Total PBT / Employee / Month",
    tableNumber: 15,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Monthly PBT per Employee | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > PRODUCTIVITY > Total Dealership GP": {
    name: "Overall Dealership > PRODUCTIVITY > Total Dealership GP",
    department: "Overall Dealership",
    category: "PRODUCTIVITY",
    label: "Total Dealership GP",
    tableNumber: 3,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > PRODUCTIVITY > Total Dealership PBT": {
    name: "Overall Dealership > PRODUCTIVITY > Total Dealership PBT",
    department: "Overall Dealership",
    category: "PRODUCTIVITY",
    label: "Total Dealership PBT",
    tableNumber: 14,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PBT > PBT (Profit Before Tax) | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > PRODUCTIVITY > Total Staff Productives": {
    name: "Overall Dealership > PRODUCTIVITY > Total Staff Productives",
    department: "Overall Dealership",
    category: "PRODUCTIVITY",
    label: "Total Staff Productives",
    tableNumber: 16,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Staff > Total Productives | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Overall Dealership > PRODUCTIVITY > GP / Productive / Month NV only": {
    name: "Overall Dealership > PRODUCTIVITY > GP / Productive / Month NV only",
    department: "Overall Dealership",
    category: "PRODUCTIVITY",
    label: "GP / Productive / Month NV only",
    tableNumber: 39,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Monthly TGP per Productive | New | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > PRODUCTIVITY > GP / Productive / Month UV only": {
    name: "Overall Dealership > PRODUCTIVITY > GP / Productive / Month UV only",
    department: "Overall Dealership",
    category: "PRODUCTIVITY",
    label: "GP / Productive / Month UV only",
    tableNumber: 53,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Monthly TGP per Productive | Used | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > PRODUCTIVITY > GP / Productive / Month F&I": {
    name: "Overall Dealership > PRODUCTIVITY > GP / Productive / Month F&I",
    department: "Overall Dealership",
    category: "PRODUCTIVITY",
    label: "GP / Productive / Month F&I",
    tableNumber: 66,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Monthly TGP per Productive | F&I Dept | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > PRODUCTIVITY > GP / Productive / Month Aftercare": {
    name: "Overall Dealership > PRODUCTIVITY > GP / Productive / Month Aftercare",
    department: "Overall Dealership",
    category: "PRODUCTIVITY",
    label: "GP / Productive / Month Aftercare",
    tableNumber: 153,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Monthly TGP per Productive | Aftercare | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > PRODUCTIVITY > GP / Productive / Month NV Incl. F&I & Aftercare": {
    name: "Overall Dealership > PRODUCTIVITY > GP / Productive / Month NV Incl. F&I & Aftercare",
    department: "Overall Dealership",
    category: "PRODUCTIVITY",
    label: "GP / Productive / Month NV Incl. F&I & Aftercare",
    tableNumber: 39,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Monthly TGP per Productive | NewAftercareF&ISplit | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > PRODUCTIVITY > GP / Productive / Month UV Incl. F&I & Aftercare": {
    name: "Overall Dealership > PRODUCTIVITY > GP / Productive / Month UV Incl. F&I & Aftercare",
    department: "Overall Dealership",
    category: "PRODUCTIVITY",
    label: "GP / Productive / Month UV Incl. F&I & Aftercare",
    tableNumber: 53,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Monthly TGP per Productive | UsedAftercareF&ISplit | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > PRODUCTIVITY > GP / Productive / Month Parts": {
    name: "Overall Dealership > PRODUCTIVITY > GP / Productive / Month Parts",
    department: "Overall Dealership",
    category: "PRODUCTIVITY",
    label: "GP / Productive / Month Parts",
    tableNumber: 96,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Monthly TGP per Productive | Parts | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > PRODUCTIVITY > GP / Productive / Month Service": {
    name: "Overall Dealership > PRODUCTIVITY > GP / Productive / Month Service",
    department: "Overall Dealership",
    category: "PRODUCTIVITY",
    label: "GP / Productive / Month Service",
    tableNumber: 112,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Monthly TGP per Productive | Service | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > PRODUCTIVITY > GP / Productive / Month Quick Service": {
    name: "Overall Dealership > PRODUCTIVITY > GP / Productive / Month Quick Service",
    department: "Overall Dealership",
    category: "PRODUCTIVITY",
    label: "GP / Productive / Month Quick Service",
    tableNumber: 112,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Monthly TGP per Productive | Quick Service | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > PRODUCTIVITY > GP / Productive / Month Serv. & Quick Service": {
    name: "Overall Dealership > PRODUCTIVITY > GP / Productive / Month Serv. & Quick Service",
    department: "Overall Dealership",
    category: "PRODUCTIVITY",
    label: "GP / Productive / Month Serv. & Quick Service",
    tableNumber: 112,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Monthly TGP per Productive | ServiceQuickService | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > PRODUCTIVITY > GP / Productive / Month Body Shop": {
    name: "Overall Dealership > PRODUCTIVITY > GP / Productive / Month Body Shop",
    department: "Overall Dealership",
    category: "PRODUCTIVITY",
    label: "GP / Productive / Month Body Shop",
    tableNumber: 128,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Monthly TGP per Productive | Body Shop | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > PRODUCTIVITY > GP / Productive / Month Driveway": {
    name: "Overall Dealership > PRODUCTIVITY > GP / Productive / Month Driveway",
    department: "Overall Dealership",
    category: "PRODUCTIVITY",
    label: "GP / Productive / Month Driveway",
    tableNumber: 141,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Monthly TGP per Productive | Driveway | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > PRODUCTIVITY > GP / Productive / Month Others": {
    name: "Overall Dealership > PRODUCTIVITY > GP / Productive / Month Others",
    department: "Overall Dealership",
    category: "PRODUCTIVITY",
    label: "GP / Productive / Month Others",
    tableNumber: 165,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ave Monthly TGP per Productive | OtherAP | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > PRODUCTIVITY > Total Staff": {
    name: "Overall Dealership > PRODUCTIVITY > Total Staff",
    department: "Overall Dealership",
    category: "PRODUCTIVITY",
    label: "Total Staff",
    tableNumber: 16,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Staff > Total Employees | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Overall Dealership > PRODUCTIVITY > Total Staff Non Productives": {
    name: "Overall Dealership > PRODUCTIVITY > Total Staff Non Productives",
    department: "Overall Dealership",
    category: "PRODUCTIVITY",
    label: "Total Staff Non Productives",
    tableNumber: 16,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Staff > Non-Productives | All Department | All Brand | All Model | | AVG",
    canAverage: true,
  },
  "Overall Dealership > PRODUCTIVITY > Ratio Productive : Non Productive": {
    name: "Overall Dealership > PRODUCTIVITY > Ratio Productive : Non Productive",
    department: "Overall Dealership",
    category: "PRODUCTIVITY",
    label: "Ratio Productive : Non Productive",
    tableNumber: 16,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Ratio Productive to Non-Productive staff | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Overall Dealership > FOA > FOA": {
    name: "Overall Dealership > FOA > FOA",
    department: "Overall Dealership",
    category: "FOA",
    label: "FOA",
    tableNumber: 17,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STAT > Fixed Operation Absorption | Aftersales | All Brand | All Model | | SUM",
    canAverage: false,
  },
  "Overall Dealership > FOA > After Sales GP": {
    name: "Overall Dealership > FOA > After Sales GP",
    department: "Overall Dealership",
    category: "FOA",
    label: "After Sales GP",
    tableNumber: 68,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | Aftersales | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > FOA > Overhead Expenses": {
    name: "Overall Dealership > FOA > Overhead Expenses",
    department: "Overall Dealership",
    category: "FOA",
    label: "Overhead Expenses",
    tableNumber: 17,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "TE > Overhead Expenses FOA | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > FOA > Parts GP": {
    name: "Overall Dealership > FOA > Parts GP",
    department: "Overall Dealership",
    category: "FOA",
    label: "Parts GP",
    tableNumber: 86,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | Parts | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > FOA > Service GP": {
    name: "Overall Dealership > FOA > Service GP",
    department: "Overall Dealership",
    category: "FOA",
    label: "Service GP",
    tableNumber: 101,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | ServiceQuickService | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > FOA > Body Shop GP": {
    name: "Overall Dealership > FOA > Body Shop GP",
    department: "Overall Dealership",
    category: "FOA",
    label: "Body Shop GP",
    tableNumber: 117,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "G > Gross Profit | Body Shop | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > FOA > Variable Expenses (Non Vehicle)": {
    name: "Overall Dealership > FOA > Variable Expenses (Non Vehicle)",
    department: "Overall Dealership",
    category: "FOA",
    label: "Variable Expenses (Non Vehicle)",
    tableNumber: 17,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "VE > Variable Expenses (Non Vehicle) | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > FOA > Personnel Expenses": {
    name: "Overall Dealership > FOA > Personnel Expenses",
    department: "Overall Dealership",
    category: "FOA",
    label: "Personnel Expenses",
    tableNumber: 6,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "PE > Personnel Expenses | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > FOA > Other Semi Variable Expenses": {
    name: "Overall Dealership > FOA > Other Semi Variable Expenses",
    department: "Overall Dealership",
    category: "FOA",
    label: "Other Semi Variable Expenses",
    tableNumber: 7,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "SVE > Other Semi Variable Expenses | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > FOA > Fixed Expenses": {
    name: "Overall Dealership > FOA > Fixed Expenses",
    department: "Overall Dealership",
    category: "FOA",
    label: "Fixed Expenses",
    tableNumber: 8,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "FE > Fixed Expenses | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > FOA > Interest Expenses": {
    name: "Overall Dealership > FOA > Interest Expenses",
    department: "Overall Dealership",
    category: "FOA",
    label: "Interest Expenses",
    tableNumber: 9,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "I > Interest | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
  "Overall Dealership > FOA > Admin Fees": {
    name: "Overall Dealership > FOA > Admin Fees",
    department: "Overall Dealership",
    category: "FOA",
    label: "Admin Fees",
    tableNumber: 10,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Adm > Admin Fee Payable | All Department | All Brand | All Model | | SUM",
    canAverage: true,
  },
};

export const OverallDealershipOEMSupportsAnalysis: { [index: string]: CustomDashboardType } = {
  "Overall Dealership > OEM Supports Analysis > Total Support from OEM": {
    name: "Overall Dealership > OEM Supports Analysis > Total Support from OEM",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "Total Support from OEM",
    measure: "G > OEM Incentives & Expenses Subsidy | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: true,
  },
  "Overall Dealership > OEM Supports Analysis > OEM Incentives": {
    name: "Overall Dealership > OEM Supports Analysis > OEM Incentives",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "OEM Incentives",
    measure: "G > OEM Incentives | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: true,
  },
  "Overall Dealership > OEM Supports Analysis > OEM Direct Incentives": {
    name: "Overall Dealership > OEM Supports Analysis > OEM Direct Incentives",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "OEM Direct Incentives",
    measure: "G > 1st > Direct Incentives | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: true,
  },
  "Overall Dealership > OEM Supports Analysis > OEM Indirect Incentives": {
    name: "Overall Dealership > OEM Supports Analysis > OEM Indirect Incentives",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "OEM Indirect Incentives",
    measure: "OI > Incentives | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: true,
  },
  "Overall Dealership > OEM Supports Analysis > OEM Expenses Subsidy": {
    name: "Overall Dealership > OEM Supports Analysis > OEM Expenses Subsidy",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "OEM Expenses Subsidy",
    measure: "TE > OEM Expenses Subsidy | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: true,
  },
  "Overall Dealership > OEM Supports Analysis > OEM Direct Advertising Subsidy": {
    name: "Overall Dealership > OEM Supports Analysis > OEM Direct Advertising Subsidy",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "OEM Direct Advertising Subsidy",
    measure: "VE > Advertising Subsidy | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: true,
  },
  "Overall Dealership > OEM Supports Analysis > OEM Indirect Advertising Subsidy": {
    name: "Overall Dealership > OEM Supports Analysis > OEM Indirect Advertising Subsidy",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "OEM Indirect Advertising Subsidy",
    measure: "SVE > Indirect Advertising Subsidy | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: true,
  },
  "Overall Dealership > OEM Supports Analysis > OEM Floor Plan Interest Subsidy": {
    name: "Overall Dealership > OEM Supports Analysis > OEM Floor Plan Interest Subsidy",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "OEM Floor Plan Interest Subsidy",
    measure: "I > Floorplan Interest Subsidy | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: true,
  },
  "Overall Dealership > OEM Supports Analysis > OEM Incentives % TGP": {
    name: "Overall Dealership > OEM Supports Analysis > OEM Incentives % TGP",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "OEM Incentives % TGP",
    measure: "G > OEM Incentives % TGP | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: false,
  },
  "Overall Dealership > OEM Supports Analysis > OEM Incentives % Sales": {
    name: "Overall Dealership > OEM Supports Analysis > OEM Incentives % Sales",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "OEM Incentives % Sales",
    measure: "G > OEM Incentives % Net Sales | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: false,
  },
  "Overall Dealership > OEM Supports Analysis > OEM Expenses Subsidy % Sales": {
    name: "Overall Dealership > OEM Supports Analysis > OEM Expenses Subsidy % Sales",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "OEM Expenses Subsidy % Sales",
    measure: "TE > OEM Expenses Subsidy % Net Sales | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: false,
  },
  "Overall Dealership > OEM Supports Analysis > OEM All Supports % Sales": {
    name: "Overall Dealership > OEM Supports Analysis > OEM All Supports % Sales",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "OEM All Supports % Sales",
    measure: "G > OEM Incentives & Expenses Subsidy % Net Sales | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: false,
  },
  "Overall Dealership > OEM Supports Analysis > MIX": {
    name: "Overall Dealership > OEM Supports Analysis > MIX",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "MIX",
    measure: "G > Gross Profit % of Sales (MIX) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: false,
  },
  "Overall Dealership > OEM Supports Analysis > MIX (excl Incentive)": {
    name: "Overall Dealership > OEM Supports Analysis > MIX (excl Incentive)",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "MIX (excl Incentive)",
    measure: "G > Gross Profit (excl Incentive) % of Net Sales | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: false,
  },
  "Overall Dealership > OEM Supports Analysis > RETAINED": {
    name: "Overall Dealership > OEM Supports Analysis > RETAINED",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "RETAINED",
    measure: "PBT > PBT % of TGP: RETAINED | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: false,
  },
  "Overall Dealership > OEM Supports Analysis > RETAINED (excl All Supports from OEM)": {
    name: "Overall Dealership > OEM Supports Analysis > RETAINED (excl All Supports from OEM)",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "RETAINED (excl All Supports from OEM)",
    measure: "PBT > PBT % of TGP: RETAINED (excl OEM Incentives & Expenses Subsidy) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: false,
  },
  "Overall Dealership > OEM Supports Analysis > RETAINED (excl Incentives)": {
    name: "Overall Dealership > OEM Supports Analysis > RETAINED (excl Incentives)",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "RETAINED (excl Incentives)",
    measure: "PBT > PBT % of TGP: RETAINED (excl Incentive) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: false,
  },
  "Overall Dealership > OEM Supports Analysis > RETAINED (excl OEM Expenses Subsidy)": {
    name: "Overall Dealership > OEM Supports Analysis > RETAINED (excl OEM Expenses Subsidy)",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "RETAINED (excl OEM Expenses Subsidy)",
    measure: "PBT > PBT % of TGP: RETAINED (excl OEM Expenses Subsidy) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: false,
  },
  "Overall Dealership > OEM Supports Analysis > Expenses % TGP": {
    name: "Overall Dealership > OEM Supports Analysis > Expenses % TGP",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "Expenses % TGP",
    measure: "TE > Total Expenses % of TGP | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: false,
  },
  "Overall Dealership > OEM Supports Analysis > Expenses % TGP (excl All Supports from OEM)": {
    name: "Overall Dealership > OEM Supports Analysis > Expenses % TGP (excl All Supports from OEM)",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "Expenses % TGP (excl All Supports from OEM)",
    measure: "TE > Total Expenses % of TGP (excl OEM Incentives & Expenses Subsidy) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: false,
  },
  "Overall Dealership > OEM Supports Analysis > Expenses % TGP (excl Incentives)": {
    name: "Overall Dealership > OEM Supports Analysis > Expenses % TGP (excl Incentives)",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "Expenses % TGP (excl Incentives)",
    measure: "TE > Total Expenses % of TGP (excl Incentive) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: false,
  },
  "Overall Dealership > OEM Supports Analysis > Expenses % TGP (excl OEM Expenses Subsidy)": {
    name: "Overall Dealership > OEM Supports Analysis > Expenses % TGP (excl OEM Expenses Subsidy)",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "Expenses % TGP (excl OEM Expenses Subsidy)",
    measure: "TE > Total Expenses % of TGP (excl OEM Expenses Subsidy) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: false,
  },
  "Overall Dealership > OEM Supports Analysis > Operational Income % Net Sales": {
    name: "Overall Dealership > OEM Supports Analysis > Operational Income % Net Sales",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "Operational Income % Net Sales",
    measure: "OPI > Operational Income % of Sales | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: false,
  },
  "Overall Dealership > OEM Supports Analysis > Operational Income % Net Sales (excl All Supports from OEM)": {
    name: "Overall Dealership > OEM Supports Analysis > Operational Income % Net Sales (excl All Supports from OEM)",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "Operational Income % Net Sales (excl All Supports from OEM)",
    measure: "OPI > Operational Income % of Sales (excl OEM Incentives & Expenses Subsidy) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: false,
  },
  "Overall Dealership > OEM Supports Analysis > Operational Income % Net Sales (excl Incentives)": {
    name: "Overall Dealership > OEM Supports Analysis > Operational Income % Net Sales (excl Incentives)",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "Operational Income % Net Sales (excl Incentives)",
    measure: "OPI > Operational Income % of Sales (excl Incentive) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: false,
  },
  "Overall Dealership > OEM Supports Analysis > Operational Income % Net Sales (excl OEM Expenses Subsidy)": {
    name: "Overall Dealership > OEM Supports Analysis > Operational Income % Net Sales (excl OEM Expenses Subsidy)",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "Operational Income % Net Sales (excl OEM Expenses Subsidy)",
    measure: "OPI > Operational Income % of Sales (excl OEM Expenses Subsidy) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: false,
  },
  "Overall Dealership > OEM Supports Analysis > ROS": {
    name: "Overall Dealership > OEM Supports Analysis > ROS",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "ROS",
    measure: "PBT > PBT % of Sales | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: false,
  },
  "Overall Dealership > OEM Supports Analysis > ROS (excl All Supports from OEM)": {
    name: "Overall Dealership > OEM Supports Analysis > ROS (excl All Supports from OEM)",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "ROS (excl All Supports from OEM)",
    measure: "PBT > PBT (excl OEM Incentives & Expenses Subsidy) % of Sales | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: false,
  },
  "Overall Dealership > OEM Supports Analysis > ROS (excl Incentives)": {
    name: "Overall Dealership > OEM Supports Analysis > ROS (excl Incentives)",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "ROS (excl Incentives)",
    measure: "PBT > PBT (excl OEM Incentive) % of Sales | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: false,
  },
  "Overall Dealership > OEM Supports Analysis > ROS (excl OEM Expenses Subsidy)": {
    name: "Overall Dealership > OEM Supports Analysis > ROS (excl OEM Expenses Subsidy)",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "ROS (excl OEM Expenses Subsidy)",
    measure: "PBT > PBT (excl OEM Expenses Subsidy) % of Sales | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: false,
  },
  "Overall Dealership > OEM Supports Analysis > Total Sales": {
    name: "Overall Dealership > OEM Supports Analysis > Total Sales",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "Total Sales",
    measure: "TNS > Net Sales | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: true,
  },
  "Overall Dealership > OEM Supports Analysis > Total GP": {
    name: "Overall Dealership > OEM Supports Analysis > Total GP",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "Total GP",
    measure: "G > Gross Profit | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: true,
  },
  "Overall Dealership > OEM Supports Analysis > Total GP (excl Incentives)": {
    name: "Overall Dealership > OEM Supports Analysis > Total GP (excl Incentives)",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "Total GP (excl Incentives)",
    measure: "G > Gross Profit (excl Incentive) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: true,
  },
  "Overall Dealership > OEM Supports Analysis > Total Expenses": {
    name: "Overall Dealership > OEM Supports Analysis > Total Expenses",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "Total Expenses",
    measure: "TE > Total Expenses | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: true,
  },
  "Overall Dealership > OEM Supports Analysis > Total Expenses (excl OEM Expenses Subsidy)": {
    name: "Overall Dealership > OEM Supports Analysis > Total Expenses (excl OEM Expenses Subsidy)",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "Total Expenses (excl OEM Expenses Subsidy)",
    measure: "TE > Total Expenses (excl Expenses Subsidy) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: true,
  },
  "Overall Dealership > OEM Supports Analysis > Operational Income": {
    name: "Overall Dealership > OEM Supports Analysis > Operational Income",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "Operational Income",
    measure: "OPI > Operational Income | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: true,
  },
  "Overall Dealership > OEM Supports Analysis > Operational Income (excl All Supports from OEM)": {
    name: "Overall Dealership > OEM Supports Analysis > Operational Income (excl All Supports from OEM)",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "Operational Income (excl All Supports from OEM)",
    measure: "OPI > Operational Income (excl OEM Incentives & Expenses Subsidy) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: true,
  },
  "Overall Dealership > OEM Supports Analysis > Operational Income (excl Incentives)": {
    name: "Overall Dealership > OEM Supports Analysis > Operational Income (excl Incentives)",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "Operational Income (excl Incentives)",
    measure: "OPI > Operational Income (excl Incentive) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: true,
  },
  "Overall Dealership > OEM Supports Analysis > Operational Income (excl OEM Expenses Subsidy)": {
    name: "Overall Dealership > OEM Supports Analysis > Operational Income (excl OEM Expenses Subsidy)",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "Operational Income (excl OEM Expenses Subsidy)",
    measure: "OPI > Operational Income (excl OEM Expenses Subsidy) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: true,
  },
  "Overall Dealership > OEM Supports Analysis > PBT": {
    name: "Overall Dealership > OEM Supports Analysis > PBT",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "PBT",
    measure: "PBT > PBT (Profit Before Tax) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: true,
  },
  "Overall Dealership > OEM Supports Analysis > PBT (excl All Supports from OEM)": {
    name: "Overall Dealership > OEM Supports Analysis > PBT (excl All Supports from OEM)",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "PBT (excl All Supports from OEM)",
    measure: "PBT > PBT (excl OEM Incentives & Expenses Subsidy) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: true,
  },
  "Overall Dealership > OEM Supports Analysis > PBT (excl Incentives)": {
    name: "Overall Dealership > OEM Supports Analysis > PBT (excl Incentives)",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "PBT (excl Incentives)",
    measure: "PBT > PBT (excl OEM Incentive) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: true,
  },
  "Overall Dealership > OEM Supports Analysis > PBT (excl OEM Expenses Subsidy)": {
    name: "Overall Dealership > OEM Supports Analysis > PBT (excl OEM Expenses Subsidy)",
    department: "Overall Dealership",
    category: "OEM Supports Analysis",
    label: "PBT (excl OEM Expenses Subsidy)",
    measure: "PBT > PBT (excl OEM Expenses Subsidy) | All Department | All Brand | All Model | | SUM",
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: 526,
    canAverage: true,
  },
};
