export enum DropdownDescription {
  DealerSelect = "Select Dealer",
  BrandSelect = "Select Brand",
  MonthSelect = "Select Month",
  ForecastSelect = "Select Forecast",
  PeriodSelect = "Select Period",
  FinancialMonthSelect = "Select Financial Month Start",
  BenchmarkSelect = "Select Benchmark",
  ViewSelect = "Select Dashboard View",
  CurrencySelect = "Select Currency",
  CurrencyPeriodSelect = "Select Currency Period"
}
