import IconButton from "@material-ui/core/IconButton";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import classNames from "classnames";
import React from "react";

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      maxHeight: "300px",
    },
    paper: {
      marginRight: theme.spacing.unit * 2,
    },
    iconButton: {
      padding: "5px",
    },
  });

export type ChartMenuProps = {
  expand?: boolean;
  showLeft?: boolean;
} & WithStyles<typeof styles>;

const ChartMenuUnstyled: React.FunctionComponent<ChartMenuProps> = ({ classes, children, expand = true, showLeft = false }) => {
  const [open, setOpen] = React.useState(false);
  const anchorEl = React.useRef(null);

  function handleToggle() {
    setOpen(!open);
  }

  function handleClose(event) {
    if (anchorEl.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }

  return (
    <div className={classNames(classes.root)}>
      {expand && (
        <IconButton
          buttonRef={anchorEl}
          aria-label="More"
          aria-owns={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={classNames(classes.iconButton)}
        >
          <MoreVertIcon />
        </IconButton>
      )}
      <Popper open={open} 
        modifiers={showLeft ? {
          name: 'flip', 
          enabled: false, 
          options: { 
            altBoundary: true, 
            rootBoundary: 'document', 
            padding: 8
          }} 
          : undefined
        }
        placement={showLeft ? "top-end" : "bottom-end"} 
        anchorEl={anchorEl.current} 
        transition 
        disablePortal 
        style={{ zIndex: 1000 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>{children}</MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export const ChartMenu = withStyles(styles)(ChartMenuUnstyled);
