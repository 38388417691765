import React, { createContext, useState, useMemo, FC, useContext } from "react";
import { Dealer } from "../lib/api/charts";
import { Loading } from "./base";
import { BenchmarkContext } from "./BenchmarkContext";
import { DateContext } from "./FilterDateContext";
import { formatFullDate, formatDisplayDate } from "../charts/DashboardOuter";
import { Filters, FilterSet, DealerCode, DealerName, defaultDealer } from "./DealerContext";

export type DealerAnalysisDealerContextState = {
  loading: Loading;
  dealerAnalysisSelected: Dealer;
  dealerAnalysisFilters: Filters;
  dealerAnalysisFilterSet: FilterSet;
  dealerAnalysisDealerCode: DealerCode;
  dealerAnalysisDealerName: DealerName;
  setDealerAnalysisDealerCode(dealerCode: DealerCode): void;
  setDealerAnalysisDealerName(dealerName: DealerName): void;
  setDealerAnalysisDealer(dealer: Dealer): void;
  setDealerAnalysisFilters(obj: Filters): void;
  setDealerAnalysisFilterSet(obj: FilterSet): void;
};

export const DealerAnalysisDealerContext = createContext<DealerAnalysisDealerContextState>({
  loading: { loading: false, loaded: false, error: null },
  dealerAnalysisSelected: defaultDealer,
  dealerAnalysisFilters: { oem: [], region: [], dealerGroup: [], dealerSet: [], country: [], excludeNewDealers: false },
  dealerAnalysisFilterSet: {
    dealerCode: "",
    dealerName: "",
    filters: { oem: [], region: [], dealerGroup: [], dealerSet: [], country: [], excludeNewDealers: false },
  },
  dealerAnalysisDealerCode: { value: "" },
  dealerAnalysisDealerName: { value: "" },
  setDealerAnalysisDealerCode: () => {},
  setDealerAnalysisDealerName: () => {},
  setDealerAnalysisDealer: () => {},
  setDealerAnalysisFilters: () => {},
  setDealerAnalysisFilterSet: () => {},
});

export const DealerAnalysisDealerProviderInner: FC = ({ children }) => {
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const { updateDate } = useContext(DateContext);
  const { setDealerAnalysisBenchmark: setBenchmark, dealerAnalysisSelected: selected } = useContext(BenchmarkContext);

  const [dealerAnalysisDealer, xsetDealerAnalysisDealer] = useState<Dealer>(defaultDealer);
  const setDealerAnalysisDealer = (dealer?: Dealer) => {
    xsetDealerAnalysisDealer(dealer || defaultDealer);
  };
  const [dealerAnalysisDealerCode, setDealerAnalysisDealerCodeValue] = React.useState({ value: "" });
  const [dealerAnalysisDealerName, setDealerAnalysisDealerNameValue] = React.useState({ value: "" });
  const [dealerAnalysisFilters, setDealerAnalysisFiltersValue] = React.useState({
    oem: [],
    region: [],
    dealerGroup: [],
    dealerSet: [],
    country: [],
    excludeNewDealers: false,
  });
  const [dealerAnalysisFilterSet, setDealerAnalysisFilterSetValue] = React.useState({
    dealerCode: "",
    dealerName: "",
    filters: {
      oem: [],
      region: [],
      dealerGroup: [],
      dealerSet: [],
      country: [],
      excludeNewDealers: false,
    },
  });

  const value = useMemo(
    () => ({
      dealerAnalysisSelected: {
        ...dealerAnalysisDealer,
        minMonth: new Date(dealerAnalysisDealer.minMonth as any),
        maxMonth: new Date(dealerAnalysisDealer.maxMonth as any),
      },
      dealerAnalysisDealerCode,
      dealerAnalysisDealerName,
      dealerAnalysisFilters,
      dealerAnalysisFilterSet,
      loading,
      setDealerAnalysisDealer: async dealer => {
        if (!loading.loading) {
          try {
            setLoading({
              loading: true,
              loaded: false,
              error: null,
            });
            if (dealer.benchmarks.length <= 0) {
              await setBenchmark(["", ""]);
            } else {
              if (selected && selected.filter(bm => bm.length > 0).length > 0) {
                await setBenchmark(selected as [string, string]);
              } else {
                await setBenchmark([dealer.benchmarks[0], `${dealer.benchmarks[0]} - Median`]);
              }
            }
            setDealerAnalysisDealer(dealer);
            if (dealer.maxMonth) {
              const d = new Date(Date.parse(dealer.maxMonth.toString()));
              updateDate({
                value: formatFullDate(d),
                label: formatDisplayDate(d),
              });
            }
            setLoading({
              loading: false,
              loaded: true,
              error: null,
            });
          } catch (e) {
            setLoading({
              loading: false,
              loaded: false,
              error: e,
            });
          }
          return;
        } else {
          if (dealer.benchmarks.length <= 0) {
            await setBenchmark(["", ""]);
          } else {
            await setBenchmark([dealer.benchmarks[0], `${dealer.benchmarks[0]} - Median`]);
          }
          setDealerAnalysisDealer(dealer);
          if (dealer.maxMonth) {
            const d = new Date(Date.parse(dealer.maxMonth.toString()));
            updateDate({
              value: formatFullDate(d),
              label: formatDisplayDate(d),
            });
          }
        }
      },
      setDealerAnalysisDealerCode: obj => {
        setDealerAnalysisDealerCodeValue(obj);
      },
      setDealerAnalysisDealerName: obj => {
        setDealerAnalysisDealerNameValue(obj);
      },
      setDealerAnalysisFilters: obj => {
        setDealerAnalysisFiltersValue(obj);
      },
      setDealerAnalysisFilterSet: obj => {
        setDealerAnalysisFilterSetValue(obj);
      },
      setDealerAnalysisDealerCodeValue,
      setDealerAnalysisDealerNameValue,
      setDealerAnalysisFiltersValue,
      setDealerAnalysisFilterSetValue,
    }),
    [
      dealerAnalysisDealer,
      dealerAnalysisDealerCode,
      dealerAnalysisDealerName,
      dealerAnalysisFilters,
      dealerAnalysisFilterSet,
      xsetDealerAnalysisDealer,
      setDealerAnalysisDealerCodeValue,
      setDealerAnalysisDealerNameValue,
      setDealerAnalysisFiltersValue,
      setDealerAnalysisFilterSetValue,
      setDealerAnalysisDealer,
    ],
  );

  return <DealerAnalysisDealerContext.Provider value={value}>{children}</DealerAnalysisDealerContext.Provider>;
};

export const DealerAnalysisDealerProvider = DealerAnalysisDealerProviderInner;
