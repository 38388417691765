import React, { createContext, useState, useReducer, useMemo } from "react";
import { Loading } from "./base";
import {
  Permission,
  permissions as permissionData,
  NewsPermission,
  newsPermissionsData,
  CommandPermissions,
  getCommandPermissions,
  getAnalysisExcelDownloadPermission,
  getAllowDashboardAvgPermission,
} from "../lib/api/permissions";

const defaultPermissions = [];

export type PermissionsContextState = {
  permissions: Permission[];
  newsPermissions: NewsPermission[];
  analysisXls: boolean;
  allowDashboardAvg: boolean;
  loading: Loading;
  newsLoading: Loading;
  commandPermissions: CommandPermissions[];
  hydrate();
  getPermissions();
  getNewsPermissions();
};

export const PermissionsContext = createContext<PermissionsContextState>({
  permissions: defaultPermissions,
  newsPermissions: defaultPermissions,
  analysisXls: false,
  allowDashboardAvg: false,
  loading: { loading: false, loaded: false, error: null },
  newsLoading: { loading: false, loaded: false, error: null },
  commandPermissions: [],
  hydrate: () => {},
  getPermissions: () => [],
  getNewsPermissions: () => [],
});

export const PermissionsProvider = ({ children }) => {
  const [permissions, setPermisssions] = useState<Permission[]>(defaultPermissions);
  const [newsPermissions, setNewsPermisssions] = useState<NewsPermission[]>(defaultPermissions);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const [newsLoading, setNewsLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const [commandPermissions, setCommandPermissions] = useState<CommandPermissions[]>([]);
  const [analysisXls, setAnalysisXls] = useState<boolean>(false);
  const [allowDashboardAvg, setAllowDashboardAvg] = useState<boolean>(false);

  const hydrate = async () => {
    try {
      setLoading({
        loading: true,
        loaded: false,
        error: null,
      });
      const permissions = await permissionData();
      const newsPermissions = await newsPermissionsData();
      const commandPermissions = await getCommandPermissions();
      const analysisXlsPermission = await getAnalysisExcelDownloadPermission();
      const allowDashboardAvgPermission = await getAllowDashboardAvgPermission();
      setNewsPermisssions(newsPermissions);
      setPermisssions(permissions);
      setCommandPermissions(commandPermissions);
      setAnalysisXls(analysisXlsPermission);
      setAllowDashboardAvg(allowDashboardAvgPermission);
      setLoading({
        loading: false,
        loaded: true,
        error: null,
      });
    } catch (e) {
      setLoading({
        loading: false,
        loaded: false,
        error: e,
      });
    }
  };
  const hydrateNewsPermissions = async () => {
    try {
      setNewsLoading({
        loading: true,
        loaded: false,
        error: null,
      });
      const newsPermissions = await newsPermissionsData();
      setNewsPermisssions(newsPermissions);
      setNewsLoading({
        loading: false,
        loaded: true,
        error: null,
      });
    } catch (e) {
      setNewsLoading({
        loading: false,
        loaded: false,
        error: e,
      });
    }
  };
  const value = useMemo(
    () => ({
      permissions,
      newsPermissions,
      loading,
      newsLoading,
      commandPermissions,
      analysisXls,
      allowDashboardAvg,
      hydrate,
      hydrateNewsPermissions,
      getPermissions() {
        if (loading.loaded && !loading.loading) {
          return permissions;
        }
        if (!loading.loading && !loading.error) {
          hydrate();
        }
        return [];
      },
      getNewsPermissions() {
        if (newsLoading.loaded && !newsLoading.loading) {
          return newsPermissions;
        }
        if (!newsLoading.loading && !newsLoading.error) {
          hydrateNewsPermissions();
        }
        return [];
      },
      setPermisssions,
      setNewsPermisssions,
    }),
    [permissions, newsPermissions, loading, newsLoading, commandPermissions],
  );
  return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
};
