import { request } from './base';

export type Country = {
  name: string;
  code: string;
  id: number;
}

export const countries = (): Promise<Country[]> =>
  request('/countries')
    .then(res => res.json())
    .then(({ data }) => data.countries);
