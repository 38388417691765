import React, { createContext, useState, FC, useMemo, useContext } from "react";
import { getAnalysisMeta } from "../lib/api/analysis";
import { DealerContext } from "./DealerContext";

type Loading = {
  loading: boolean;
  loaded: boolean;
  error?: Error;
};

type AnalysisPeriod = "CYTD" | "FYTD";

const defaultPeriod: AnalysisPeriod = "CYTD";
const defaultPeriodOptions: AnalysisPeriod[] = ["CYTD", "FYTD"];

type DatasetMeta = {
  multipleBenchmarks: boolean;
  hasDynamic: boolean;
  excludeConsolidated: boolean;
  consolidatedDealersOnly: boolean;
  includeConsolidated: boolean;
  hasBenchmarks: boolean;
  benchmarks: string[];
  showPeriod: boolean;
};

const defaultDatasetMeta: DatasetMeta = {
  multipleBenchmarks: false,
  hasDynamic: false,
  excludeConsolidated: false,
  consolidatedDealersOnly: false,
  includeConsolidated: false,
  hasBenchmarks: false,
  benchmarks: [],
  showPeriod: false,
};

export type AnalysisMetaContextState = {
  selected: AnalysisPeriod;
  periodOptions: AnalysisPeriod[];
  loading: Loading;
  brandOptions: { value: string; label: string }[];
  datasetMeta: DatasetMeta;
  setPeriod: (period: AnalysisPeriod) => void;
  getDatasetMeta: (datasetTitle: string) => Promise<void>;
};

export const AnalysisMetaContext = createContext<AnalysisMetaContextState>({
  selected: defaultPeriod,
  periodOptions: defaultPeriodOptions,
  loading: { loading: false, loaded: false, error: undefined },
  brandOptions: [],
  datasetMeta: defaultDatasetMeta,
  setPeriod: () => {},
  getDatasetMeta: () => Promise.resolve(),
});

export const AnalysisMetaProvider: FC = ({ children }) => {
  const dealer = useContext(DealerContext);
  const [selected, setPeriod] = useState<AnalysisPeriod>(defaultPeriod);
  const [periodOptions, setPeriodOptions] = useState<AnalysisPeriod[]>(defaultPeriodOptions);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: undefined });
  const [brandOptions, setBrandOptions] = useState([]);
  const [datasetMeta, setDatasetMeta] = useState<DatasetMeta>(defaultDatasetMeta);

  const getDatasetMeta = async (datasetTitle: string) => {
    try {
      setLoading({
        loading: true,
        loaded: false,
        error: null,
      });
      const {
        has_dynamic,
        multiple_benchmarks,
        brands,
        exclude_consolidated,
        consolidated_dealers_only,
        periods,
        include_consolidated,
        has_benchmarks,
        benchmarks,
        show_period,
      } = await getAnalysisMeta(dealer.selected.dealer, datasetTitle);

      setBrandOptions(brands);
      setDatasetMeta({
        multipleBenchmarks: multiple_benchmarks,
        hasDynamic: has_dynamic,
        excludeConsolidated: exclude_consolidated,
        consolidatedDealersOnly: consolidated_dealers_only,
        includeConsolidated: include_consolidated,
        hasBenchmarks: has_benchmarks,
        benchmarks: benchmarks.sort(),
        showPeriod: show_period,
      });
      setPeriodOptions(periods);
      setLoading({
        loading: false,
        loaded: true,
        error: null,
      });
    } catch (e) {
      setLoading({
        loading: false,
        loaded: false,
        error: e,
      });
    }
  };

  const value = useMemo(
    () => ({
      selected,
      loading,
      periodOptions,
      brandOptions,
      datasetMeta,
      setPeriod,
      setLoading,
      getDatasetMeta,
    }),
    [selected, loading, setPeriod, periodOptions, getDatasetMeta, brandOptions, datasetMeta],
  );

  return <AnalysisMetaContext.Provider value={value}>{children}</AnalysisMetaContext.Provider>;
};
