import { CustomDashboardType, getMeasure } from "../CustomDashReports";

export const OtherDept: { [index: string]: CustomDashboardType } = {
  "Other Dept. > SALES > OTHER DEPT. Total Sales": {
  name: "Other Dept. > SALES > OTHER DEPT. Total Sales",
  department: "Other Dept.",
  category: "SALES",
  label: "OTHER DEPT. Total Sales",
  tableNumber: 154,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | OtherAP | All Brand | All Model | | SUM",
  canAverage: true
},
"Other Dept. > MIX > OTHER DEPT. MIX": {
  name: "Other Dept. > MIX > OTHER DEPT. MIX",
  department: "Other Dept.",
  category: "GROSS PROFIT",
  label: "OTHER DEPT. MIX",
  tableNumber: 155,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit % of Sales (MIX) | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > MIX > OTHER DEPT. Total Sales": {
  name: "Other Dept. > MIX > OTHER DEPT. Total Sales",
  department: "Other Dept.",
  category: "GROSS PROFIT",
  label: "OTHER DEPT. Total Sales",
  tableNumber: 154,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | OtherAP | All Brand | All Model | | SUM",
  canAverage: true
},
"Other Dept. > MIX > OTHER DEPT. Total GP": {
  name: "Other Dept. > MIX > OTHER DEPT. Total GP",
  department: "Other Dept.",
  category: "GROSS PROFIT",
  label: "OTHER DEPT. Total GP",
  tableNumber: 155,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | OtherAP | All Brand | All Model | | SUM",
  canAverage: true
},
"Other Dept. > RETAINED > OTHER DEPT. RETAINED": {
  name: "Other Dept. > RETAINED > OTHER DEPT. RETAINED",
  department: "Other Dept.",
  category: "EXPENSES",
  label: "OTHER DEPT. RETAINED",
  tableNumber: 156,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT % of TGP: RETAINED | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > RETAINED > OTHER DEPT. Total GP": {
  name: "Other Dept. > RETAINED > OTHER DEPT. Total GP",
  department: "Other Dept.",
  category: "EXPENSES",
  label: "OTHER DEPT. Total GP",
  tableNumber: 155,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | OtherAP | All Brand | All Model | | SUM",
  canAverage: true
},
"Other Dept. > RETAINED > OTHER DEPT. Total Expenses": {
  name: "Other Dept. > RETAINED > OTHER DEPT. Total Expenses",
  department: "Other Dept.",
  category: "EXPENSES",
  label: "OTHER DEPT. Total Expenses",
  tableNumber: 156,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses | OtherAP | All Brand | All Model | | SUM",
  canAverage: true
},
"Other Dept. > RETAINED > OTHER DEPT. Total PBT": {
  name: "Other Dept. > RETAINED > OTHER DEPT. Total PBT",
  department: "Other Dept.",
  category: "EXPENSES",
  label: "OTHER DEPT. Total PBT",
  tableNumber: 164,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT (Profit Before Tax) | OtherAP | All Brand | All Model | | SUM",
  canAverage: true
},
"Other Dept. > RETAINED > Other Dept. Total Expenses % GP": {
  name: "Other Dept. > RETAINED > Other Dept. Total Expenses % GP",
  department: "Other Dept.",
  category: "EXPENSES",
  label: "Other Dept. Total Expenses % GP",
  tableNumber: 156,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses % of TGP | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > RETAINED > Other Dept. Variable Expenses  % GP": {
  name: "Other Dept. > RETAINED > Other Dept. Variable Expenses  % GP",
  department: "Other Dept.",
  category: "EXPENSES",
  label: "Other Dept. Variable Expenses  % GP",
  tableNumber: 157,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses % of TGP | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > RETAINED > Other Dept. Personnel  Expenses % GP": {
  name: "Other Dept. > RETAINED > Other Dept. Personnel  Expenses % GP",
  department: "Other Dept.",
  category: "EXPENSES",
  label: "Other Dept. Personnel  Expenses % GP",
  tableNumber: 158,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PE > Personnel Expenses % of TGP | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > RETAINED > Other Dept. Other Semi Variable Expenses % GP": {
  name: "Other Dept. > RETAINED > Other Dept. Other Semi Variable Expenses % GP",
  department: "Other Dept.",
  category: "EXPENSES",
  label: "Other Dept. Other Semi Variable Expenses % GP",
  tableNumber: 159,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "SVE > Other Semi Variable Expenses % of TGP | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > RETAINED > Other Dept. Fixed Expenses % GP": {
  name: "Other Dept. > RETAINED > Other Dept. Fixed Expenses % GP",
  department: "Other Dept.",
  category: "EXPENSES",
  label: "Other Dept. Fixed Expenses % GP",
  tableNumber: 160,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "FE > Fixed Expenses % of TGP | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > RETAINED > Other Dept. Interest Expenses % GP": {
  name: "Other Dept. > RETAINED > Other Dept. Interest Expenses % GP",
  department: "Other Dept.",
  category: "EXPENSES",
  label: "Other Dept. Interest Expenses % GP",
  tableNumber: 161,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Interest % of TGP | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > RETAINED > Other Dept. Allocation of Admin  Expenses % GP": {
  name: "Other Dept. > RETAINED > Other Dept. Allocation of Admin  Expenses % GP",
  department: "Other Dept.",
  category: "EXPENSES",
  label: "Other Dept. Allocation of Admin  Expenses % GP",
  tableNumber: 156,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Adm > Admin Exp. Allocation % of TGP | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > RETAINED > Other Dept. Variable Expenses% Total Expenses": {
  name: "Other Dept. > RETAINED > Other Dept. Variable Expenses% Total Expenses",
  department: "Other Dept.",
  category: "EXPENSES",
  label: "Other Dept. Variable Expenses% Total Expenses",
  tableNumber: 157,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses % of Total Expenses | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > RETAINED > Other Dept. Personnel Expenses% Total Expenses": {
  name: "Other Dept. > RETAINED > Other Dept. Personnel Expenses% Total Expenses",
  department: "Other Dept.",
  category: "EXPENSES",
  label: "Other Dept. Personnel Expenses% Total Expenses",
  tableNumber: 158,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PE > Personnel Expenses % of Total Expenses | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > RETAINED > Other Dept. Other Semi Var.Exp.% Total Expenses": {
  name: "Other Dept. > RETAINED > Other Dept. Other Semi Var.Exp.% Total Expenses",
  department: "Other Dept.",
  category: "EXPENSES",
  label: "Other Dept. Other Semi Var.Exp.% Total Expenses",
  tableNumber: 159,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "SVE > Other Semi Variable Expenses % of Total Expenses | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > RETAINED > Other Dept. Fixed Expenses% Total Expenses": {
  name: "Other Dept. > RETAINED > Other Dept. Fixed Expenses% Total Expenses",
  department: "Other Dept.",
  category: "EXPENSES",
  label: "Other Dept. Fixed Expenses% Total Expenses",
  tableNumber: 160,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "FE > Fixed Expenses % of Total Expenses | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > RETAINED > Other Dept. Interest Expenses % Total Expenses": {
  name: "Other Dept. > RETAINED > Other Dept. Interest Expenses % Total Expenses",
  department: "Other Dept.",
  category: "EXPENSES",
  label: "Other Dept. Interest Expenses % Total Expenses",
  tableNumber: 161,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Interest % of Total Expenses | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > RETAINED > Other Dept. Allocation of Admin Expenses % Total Expenses": {
  name: "Other Dept. > RETAINED > Other Dept. Allocation of Admin Expenses % Total Expenses",
  department: "Other Dept.",
  category: "EXPENSES",
  label: "Other Dept. Allocation of Admin Expenses % Total Expenses",
  tableNumber: 156,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Adm > Admin Exp. Allocation % of Total Expenses | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > RETAINED > Other Department Cost Recovery": {
  name: "Other Dept. > RETAINED > Other Department Cost Recovery",
  department: "Other Dept.",
  category: "EXPENSES",
  label: "Other Department Cost Recovery",
  tableNumber: 179,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Cost Recovery | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > ACTIVITY > OTHER DEPT. ACTIVITY": {
  name: "Other Dept. > ACTIVITY > OTHER DEPT. ACTIVITY",
  department: "Other Dept.",
  category: "OPERATIONAL ASSETS",
  label: "OTHER DEPT. ACTIVITY",
  tableNumber: 162,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Asset Activity | OtherAP | All Brand | All Model | | AVG",
  canAverage: false
},
"Other Dept. > ACTIVITY > OTHER DEPT. Annualised Sales": {
  name: "Other Dept. > ACTIVITY > OTHER DEPT. Annualised Sales",
  department: "Other Dept.",
  category: "OPERATIONAL ASSETS",
  label: "OTHER DEPT. Annualised Sales",
  tableNumber: 154,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Projected Annualised Sales | OtherAP | All Brand | All Model | | SUM",
  canAverage: true
},
"Other Dept. > ACTIVITY > OTHER DEPT. Average Operational Assets": {
  name: "Other Dept. > ACTIVITY > OTHER DEPT. Average Operational Assets",
  department: "Other Dept.",
  category: "OPERATIONAL ASSETS",
  label: "OTHER DEPT. Average Operational Assets",
  tableNumber: 162,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Average Operational Assets | OtherAP | All Brand | All Model | | AVG",
  canAverage: true
},
"Other Dept. > ROOA > OTHER DEPT. ROOA": {
  name: "Other Dept. > ROOA > OTHER DEPT. ROOA",
  department: "Other Dept.",
  category: "MRA MODEL",
  label: "OTHER DEPT. ROOA",
  tableNumber: 163,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Return on Operational Assets (ROOA) | OtherAP | All Brand | All Model | | AVG",
  canAverage: false
},
"Other Dept. > ROOA > OTHER DEPT. MIX": {
  name: "Other Dept. > ROOA > OTHER DEPT. MIX",
  department: "Other Dept.",
  category: "MRA MODEL",
  label: "OTHER DEPT. MIX",
  tableNumber: 155,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit % of Sales (MIX) | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > ROOA > OTHER DEPT. RETAINED": {
  name: "Other Dept. > ROOA > OTHER DEPT. RETAINED",
  department: "Other Dept.",
  category: "MRA MODEL",
  label: "OTHER DEPT. RETAINED",
  tableNumber: 156,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT % of TGP: RETAINED | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > ROOA > OTHER DEPT. ACTIVITY": {
  name: "Other Dept. > ROOA > OTHER DEPT. ACTIVITY",
  department: "Other Dept.",
  category: "MRA MODEL",
  label: "OTHER DEPT. ACTIVITY",
  tableNumber: 162,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Asset Activity | OtherAP | All Brand | All Model | | AVG",
  canAverage: false
},
"Other Dept. > ROS (Income Statement) > Other Dept. ROS": {
  name: "Other Dept. > ROS (Income Statement) > Other Dept. ROS",
  department: "Other Dept.",
  category: "ROS (Income Statement)",
  label: "Other Dept. ROS",
  tableNumber: 164,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT % of Sales | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > ROS (Income Statement) > Other Dept. Sales": {
  name: "Other Dept. > ROS (Income Statement) > Other Dept. Sales",
  department: "Other Dept.",
  category: "ROS (Income Statement)",
  label: "Other Dept. Sales",
  tableNumber: 154,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | OtherAP | All Brand | All Model | | SUM",
  canAverage: true
},
"Other Dept. > ROS (Income Statement) > Other Dept. PBT": {
  name: "Other Dept. > ROS (Income Statement) > Other Dept. PBT",
  department: "Other Dept.",
  category: "ROS (Income Statement)",
  label: "Other Dept. PBT",
  tableNumber: 164,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT (Profit Before Tax) | OtherAP | All Brand | All Model | | SUM",
  canAverage: true
},
"Other Dept. > ROS (Income Statement) > Other Dept. Dept. Profit % Net Sales": {
  name: "Other Dept. > ROS (Income Statement) > Other Dept. Dept. Profit % Net Sales",
  department: "Other Dept.",
  category: "ROS (Income Statement)",
  label: "Other Dept. Dept. Profit % Net Sales",
  tableNumber: 164,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "E > Earnings After Interest % of TNS | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > ROS (Income Statement) > Other Dept. EBIT% (PBIT%)": {
  name: "Other Dept. > ROS (Income Statement) > Other Dept. EBIT% (PBIT%)",
  department: "Other Dept.",
  category: "ROS (Income Statement)",
  label: "Other Dept. EBIT% (PBIT%)",
  tableNumber: 164,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBIT % of Sales | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > ROS (Income Statement) > Other Dept. Variable Selling Gross % Gorss Profit": {
  name: "Other Dept. > ROS (Income Statement) > Other Dept. Variable Selling Gross % Gorss Profit",
  department: "Other Dept.",
  category: "ROS (Income Statement)",
  label: "Other Dept. Variable Selling Gross % Gorss Profit",
  tableNumber: 157,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Variable Selling Gross % of TGP | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > ROS (Income Statement) > Other Dept. Selling Gross % Gross Profit": {
  name: "Other Dept. > ROS (Income Statement) > Other Dept. Selling Gross % Gross Profit",
  department: "Other Dept.",
  category: "ROS (Income Statement)",
  label: "Other Dept. Selling Gross % Gross Profit",
  tableNumber: 164,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Selling Gross % of TGP | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > ROS (Income Statement) > Other Dept. Selling Gross % Net Sales": {
  name: "Other Dept. > ROS (Income Statement) > Other Dept. Selling Gross % Net Sales",
  department: "Other Dept.",
  category: "ROS (Income Statement)",
  label: "Other Dept. Selling Gross % Net Sales",
  tableNumber: 164,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Selling Gross % of TNS | OtherAP | All Brand | All Model | | SUM",
  canAverage: false
},
"Other Dept. > ROS (Income Statement) > Other Dept. Gross Profit": {
  name: "Other Dept. > ROS (Income Statement) > Other Dept. Gross Profit",
  department: "Other Dept.",
  category: "ROS (Income Statement)",
  label: "Other Dept. Gross Profit",
  tableNumber: 155,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | OtherAP | All Brand | All Model | | SUM",
  canAverage: true
},
"Other Dept. > ROS (Income Statement) > Other Dept. Expenses": {
  name: "Other Dept. > ROS (Income Statement) > Other Dept. Expenses",
  department: "Other Dept.",
  category: "ROS (Income Statement)",
  label: "Other Dept. Expenses",
  tableNumber: 156,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses | OtherAP | All Brand | All Model | | SUM",
  canAverage: true
},
"Other Dept. > ROS (Income Statement) > Other Dept. Variable Expenses": {
  name: "Other Dept. > ROS (Income Statement) > Other Dept. Variable Expenses",
  department: "Other Dept.",
  category: "ROS (Income Statement)",
  label: "Other Dept. Variable Expenses",
  tableNumber: 157,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses | OtherAP | All Brand | All Model | | SUM",
  canAverage: true
},
"Other Dept. > ROS (Income Statement) > Other Dept. Personnel Expenses": {
  name: "Other Dept. > ROS (Income Statement) > Other Dept. Personnel Expenses",
  department: "Other Dept.",
  category: "ROS (Income Statement)",
  label: "Other Dept. Personnel Expenses",
  tableNumber: 158,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PE > Personnel Expenses | OtherAP | All Brand | All Model | | SUM",
  canAverage: true
},
"Other Dept. > ROS (Income Statement) > Other Dept. Other Semi Variable Expenses": {
  name: "Other Dept. > ROS (Income Statement) > Other Dept. Other Semi Variable Expenses",
  department: "Other Dept.",
  category: "ROS (Income Statement)",
  label: "Other Dept. Other Semi Variable Expenses",
  tableNumber: 159,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "SVE > Other Semi Variable Expenses | OtherAP | All Brand | All Model | | SUM",
  canAverage: true
},
"Other Dept. > ROS (Income Statement) > Other Dept. Fixed Expenses": {
  name: "Other Dept. > ROS (Income Statement) > Other Dept. Fixed Expenses",
  department: "Other Dept.",
  category: "ROS (Income Statement)",
  label: "Other Dept. Fixed Expenses",
  tableNumber: 160,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "FE > Fixed Expenses | OtherAP | All Brand | All Model | | SUM",
  canAverage: true
},
"Other Dept. > ROS (Income Statement) > Other Dept. Interest Expenses": {
  name: "Other Dept. > ROS (Income Statement) > Other Dept. Interest Expenses",
  department: "Other Dept.",
  category: "ROS (Income Statement)",
  label: "Other Dept. Interest Expenses",
  tableNumber: 161,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Interest | OtherAP | All Brand | All Model | | SUM",
  canAverage: true
},
"Other Dept. > ROS (Income Statement) > Other Dept. Departmental Profit": {
  name: "Other Dept. > ROS (Income Statement) > Other Dept. Departmental Profit",
  department: "Other Dept.",
  category: "ROS (Income Statement)",
  label: "Other Dept. Departmental Profit",
  tableNumber: 164,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "E > Earnings After Interest (EAIT) | OtherAP | All Brand | All Model | | SUM",
  canAverage: true
},
"Other Dept. > ROS (Income Statement) > Other Dept. Allocation of Admin Expenses": {
  name: "Other Dept. > ROS (Income Statement) > Other Dept. Allocation of Admin Expenses",
  department: "Other Dept.",
  category: "ROS (Income Statement)",
  label: "Other Dept. Allocation of Admin Expenses",
  tableNumber: 156,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Adm > Admin Exp. Allocation | OtherAP | All Brand | All Model | | SUM",
  canAverage: true
},
"Other Dept. > ROS (Income Statement) > Other Dept. EBIT": {
  name: "Other Dept. > ROS (Income Statement) > Other Dept. EBIT",
  department: "Other Dept.",
  category: "ROS (Income Statement)",
  label: "Other Dept. EBIT",
  tableNumber: 164,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBIT (Profit Before Interest & Tax) | OtherAP | All Brand | All Model | | SUM",
  canAverage: true
},
"Other Dept. > PRODUCTIVITY > Other Dept. GP / Productive / Month": {
  name: "Other Dept. > PRODUCTIVITY > Other Dept. GP / Productive / Month",
  department: "Other Dept.",
  category: "PRODUCTIVITY",
  label: "Other Dept. GP / Productive / Month",
  tableNumber: 165,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ave Monthly TGP per Productive | OtherAP | All Brand | All Model | | AVG",
  canAverage: false
},
"Other Dept. > PRODUCTIVITY > Other Dept. Sales / Productive / Month": {
  name: "Other Dept. > PRODUCTIVITY > Other Dept. Sales / Productive / Month",
  department: "Other Dept.",
  category: "PRODUCTIVITY",
  label: "Other Dept. Sales / Productive / Month",
  tableNumber: 165,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > OtherAP net sales per Productive | OtherAP | All Brand | All Model | | AVG",
  canAverage: false
},
"Other Dept. > PRODUCTIVITY > Other Dept. GP / Employee / Month": {
  name: "Other Dept. > PRODUCTIVITY > Other Dept. GP / Employee / Month",
  department: "Other Dept.",
  category: "PRODUCTIVITY",
  label: "Other Dept. GP / Employee / Month",
  tableNumber: 165,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ave Monthly TGP per Employee | OtherAP | All Brand | All Model | | AVG",
  canAverage: false
},
"Other Dept. > PRODUCTIVITY > Other Dept. Total Staff": {
  name: "Other Dept. > PRODUCTIVITY > Other Dept. Total Staff",
  department: "Other Dept.",
  category: "PRODUCTIVITY",
  label: "Other Dept. Total Staff",
  tableNumber: 165,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Total Employees | OtherAP | All Brand | All Model | | AVG",
  canAverage: true
},
"Other Dept. > PRODUCTIVITY > Other Dept. Productive Staff": {
  name: "Other Dept. > PRODUCTIVITY > Other Dept. Productive Staff",
  department: "Other Dept.",
  category: "PRODUCTIVITY",
  label: "Other Dept. Productive Staff",
  tableNumber: 165,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Productives | OtherAP | All Brand | All Model | | AVG",
  canAverage: true
},
"Other Dept. > PRODUCTIVITY > Other Dept. Non-Productive Staff": {
  name: "Other Dept. > PRODUCTIVITY > Other Dept. Non-Productive Staff",
  department: "Other Dept.",
  category: "PRODUCTIVITY",
  label: "Other Dept. Non-Productive Staff",
  tableNumber: 165,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Non-Productives | OtherAP | All Brand | All Model | | AVG",
  canAverage: true
},
"Other Dept. > PRODUCTIVITY > Other Dept. Ratio Productive : Non Productive": {
  name: "Other Dept. > PRODUCTIVITY > Other Dept. Ratio Productive : Non Productive",
  department: "Other Dept.",
  category: "PRODUCTIVITY",
  label: "Other Dept. Ratio Productive : Non Productive",
  tableNumber: 165,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ratio Productive to Non-Productive staff | OtherAP | All Brand | All Model | | AVG",
  canAverage: false
},
};
