import Paper from "@material-ui/core/Paper";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import React, { useContext } from "react";
import { CurrencyContext } from "../contexts/CurrencyContext";
import Typography from "@material-ui/core/Typography";
import { Close } from "@material-ui/icons";


export const RatesTable = ({ onClose }) => {
  const currency = useContext(CurrencyContext);

  const dealerRows = !currency.rates ? [] : currency.rates.dealerRates.map(cur => {
    const { dealer, base, target, rate } = cur;

    return createData(dealer, base, target, rate);
  });

  const benchmarkRows = !currency.rates ? [] : currency.rates.benchmarkRates.map(cur => {
    const { benchmark, base, target, rate } = cur;

    return createData(benchmark, base, target, rate);
  });



  return (
    <Paper style={{ borderRadius: "5px", padding: "10px"}}>
      <div style={{ padding: "4px 24px 8px 24px", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <Typography
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Conversion Chart
        </Typography>
        <Close onClick={onClose} style={{ cursor: "pointer", height: "20px", width: "20px" }}/>
      </div>
      <Table style={{ minWidth: "650" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontSize: "12px", width: "50%" }}>Dealer</TableCell>
            <TableCell style={{ fontSize: "12px" }} align="right">Base</TableCell>
            <TableCell style={{ fontSize: "12px" }} align="right">Target</TableCell>
            <TableCell style={{ fontSize: "12px" }} align="right">Rate</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dealerRows.map((row, i) => (
            <TableRow
              key={row.name}
            >
              <TableCell style={{ fontSize: "12px", width: "50%" }} component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell style={{ fontSize: "12px" }} align="right">{row.base}</TableCell>
              <TableCell style={{ fontSize: "12px" }} align="right">{row.target}</TableCell>
              <TableCell style={{ fontSize: "12px", fontWeight: "bold" }} align="right">{row.rate}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Table style={{ minWidth: "650", marginTop: "25px" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontSize: "12px", width: "50%" }}>Benchmark</TableCell>
            <TableCell style={{ fontSize: "12px" }} align="right">Base</TableCell>
            <TableCell style={{ fontSize: "12px" }} align="right">Target</TableCell>
            <TableCell style={{ fontSize: "12px" }} align="right">Rate</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {benchmarkRows.length === 0 ?
            <TableRow
            >
              <TableCell style={{ fontSize: "12px", width: "50%" }} component="th" scope="row">
                -
              </TableCell>
              <TableCell style={{ fontSize: "12px" }} align="right">-</TableCell>
              <TableCell style={{ fontSize: "12px" }} align="right">-</TableCell>
              <TableCell style={{ fontSize: "12px", fontWeight: "bold" }} align="right">-</TableCell>
            </TableRow>
            : benchmarkRows.map((row, i) => (
              <TableRow
                key={row.name}
              >
                <TableCell style={{ fontSize: "12px", width: "50%" }} component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell style={{ fontSize: "12px" }} align="right">{row.base}</TableCell>
                <TableCell style={{ fontSize: "12px" }} align="right">{row.target}</TableCell>
                <TableCell style={{ fontSize: "12px", fontWeight: "bold" }} align="right">{row.rate}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Paper>

  )
}

function createData(
  name: string,
  base: string,
  target: string,
  rate: string,
) {
  return { name, base, target, rate };
}
