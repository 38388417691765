import { CustomDashboardType, getMeasure } from "../CustomDashReports";

export const Parts: { [index: string]: CustomDashboardType } = {
  "PARTS > SALES > Parts Total Sales": {
  name: "PARTS > SALES > Parts Total Sales",
  department: "PARTS",
  category: "SALES",
  label: "Parts Total Sales",
  tableNumber: 80,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > SALES > Parts Workshop Sales / Service RO": {
  name: "PARTS > SALES > Parts Workshop Sales / Service RO",
  department: "PARTS",
  category: "SALES",
  label: "Parts Workshop Sales / Service RO",
  tableNumber: 81,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Parts workshop sales per RO (Incl. Quick Service) | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > SALES > Parts Body Shop Sales / Body Shop RO": {
  name: "PARTS > SALES > Parts Body Shop Sales / Body Shop RO",
  department: "PARTS",
  category: "SALES",
  label: "Parts Body Shop Sales / Body Shop RO",
  tableNumber: 82,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Parts workshop sales per RO (BS) | Body Shop | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > SALES > Parts Workshop Sales % Labour Sales": {
  name: "PARTS > SALES > Parts Workshop Sales % Labour Sales",
  department: "PARTS",
  category: "SALES",
  label: "Parts Workshop Sales % Labour Sales",
  tableNumber: 84,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Parts workshop sales to labour sales as a % (incl. BS & Quick Service) | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > SALES > Breakeven Days": {
  name: "PARTS > SALES > Breakeven Days",
  department: "PARTS",
  category: "SALES",
  label: "Parts Breakeven Days",
  tableNumber: 85,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Dept. Breakeven Days | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > SALES > Breakeven Sales": {
  name: "PARTS > SALES > Breakeven Sales",
  department: "PARTS",
  category: "SALES",
  label: "Parts Breakeven Sales",
  tableNumber: 85,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Parts Breakeven Sales | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > SALES > Parts Service Workshop Sales": {
  name: "PARTS > SALES > Parts Service Workshop Sales",
  department: "PARTS",
  category: "SALES",
  label: "Parts Service Workshop Sales",
  tableNumber: 81,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Parts Workshop net sales (Inc. Quick Service) | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > SALES > Parts Service Workshop Retail Sales": {
  name: "PARTS > SALES > Parts Service Workshop Retail Sales",
  department: "PARTS",
  category: "SALES",
  label: "Parts Service Workshop Retail Sales",
  tableNumber: 81,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Workshop net sales | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > SALES > Parts Service Workshop Warranty Sales": {
  name: "PARTS > SALES > Parts Service Workshop Warranty Sales",
  department: "PARTS",
  category: "SALES",
  label: "Parts Service Workshop Warranty Sales",
  tableNumber: 81,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Warranty net sales | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > SALES > Parts Service Workshop Internal Sales": {
  name: "PARTS > SALES > Parts Service Workshop Internal Sales",
  department: "PARTS",
  category: "SALES",
  label: "Parts Service Workshop Internal Sales",
  tableNumber: 81,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Internal net sales | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > SALES > Parts Quick Service Workshop Sales": {
  name: "PARTS > SALES > Parts Quick Service Workshop Sales",
  department: "PARTS",
  category: "SALES",
  label: "Parts Quick Service Workshop Sales",
  tableNumber: 81,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Parts Quick service workshop sales | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > SALES > Parts Body Shop Sales": {
  name: "PARTS > SALES > Parts Body Shop Sales",
  department: "PARTS",
  category: "SALES",
  label: "Parts Body Shop Sales",
  tableNumber: 82,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Parts Body Shop Net Sales | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > SALES > Parts Body Shop Retail Sales": {
  name: "PARTS > SALES > Parts Body Shop Retail Sales",
  department: "PARTS",
  category: "SALES",
  label: "Parts Body Shop Retail Sales",
  tableNumber: 82,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Panel net sales | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > SALES > Parts Body Shop Warranty Sales": {
  name: "PARTS > SALES > Parts Body Shop Warranty Sales",
  department: "PARTS",
  category: "SALES",
  label: "Parts Body Shop Warranty Sales",
  tableNumber: 82,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Parts Body Shop Warranty Net Sales | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > SALES > Parts Body Shop Internal Sales": {
  name: "PARTS > SALES > Parts Body Shop Internal Sales",
  department: "PARTS",
  category: "SALES",
  label: "Parts Body Shop Internal Sales",
  tableNumber: 82,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Parts Body Shop Internal Net Sales | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > SALES > Parts Accessories Sales": {
  name: "PARTS > SALES > Parts Accessories Sales",
  department: "PARTS",
  category: "SALES",
  label: "Parts Accessories Sales",
  tableNumber: 83,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Accessories Net sales | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > SALES > Parts Intercompany Sales": {
  name: "PARTS > SALES > Parts Intercompany Sales",
  department: "PARTS",
  category: "SALES",
  label: "Parts Intercompany Sales",
  tableNumber: 83,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Intercompany net sales | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > SALES > Parts Oil & Lubricant Sales": {
  name: "PARTS > SALES > Parts Oil & Lubricant Sales",
  department: "PARTS",
  category: "SALES",
  label: "Parts Oil & Lubricant Sales",
  tableNumber: 83,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Oil Lubricants net sale | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > SALES > Parts Counter Retail Sales": {
  name: "PARTS > SALES > Parts Counter Retail Sales",
  department: "PARTS",
  category: "SALES",
  label: "Parts Counter Retail Sales",
  tableNumber: 83,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Counter retail net sales | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > SALES > Parts Wholesale Sales": {
  name: "PARTS > SALES > Parts Wholesale Sales",
  department: "PARTS",
  category: "SALES",
  label: "Parts Wholesale Sales",
  tableNumber: 83,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Wholesale net sales | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > SALES > Parts Other Sales": {
  name: "PARTS > SALES > Parts Other Sales",
  department: "PARTS",
  category: "SALES",
  label: "Parts Other Sales",
  tableNumber: 83,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Aftersales Other Net Sales | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > MIX > PARTS MIX": {
  name: "PARTS > MIX > PARTS MIX",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "PARTS MIX",
  tableNumber: 86,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit % of Sales (MIX) | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > MIX > PARTS Total Sales": {
  name: "PARTS > MIX > PARTS Total Sales",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "PARTS Total Sales",
  tableNumber: 80,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > MIX > PARTS Total GP": {
  name: "PARTS > MIX > PARTS Total GP",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "PARTS Total GP",
  tableNumber: 86,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > MIX > Parts First GP": {
  name: "PARTS > MIX > Parts First GP",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "Parts First GP",
  tableNumber: 86,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > 1st Gross Profit | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > MIX > Parts Adj. (Pricing & Buying Penalties & Obs.)": {
  name: "PARTS > MIX > Parts Adj. (Pricing & Buying Penalties & Obs.)",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "Parts Adj. (Pricing & Buying Penalties & Obs.)",
  tableNumber: 86,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "OI > ADJ > Adjustments | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > MIX > Parts OEM Incentives": {
  name: "PARTS > MIX > Parts OEM Incentives",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "Parts OEM Incentives",
  tableNumber: 86,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "OI > Incentives | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > MIX > Parts 1st GP %": {
  name: "PARTS > MIX > Parts 1st GP %",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "Parts 1st GP %",
  tableNumber: 86,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > 1st Gross Profit % of Net Sales | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > MIX > Parts Service Workshop GP %": {
  name: "PARTS > MIX > Parts Service Workshop GP %",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "Parts Service Workshop GP %",
  tableNumber: 81,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Parts Workshop GP % of Parts Workshop Net Sales (Incl. Quick Service) | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > MIX > Parts Service Workshop Retail GP %": {
  name: "PARTS > MIX > Parts Service Workshop Retail GP %",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "Parts Service Workshop Retail GP %",
  tableNumber: 81,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Workshop GP % of Net Workshop sales | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > MIX > Parts Service Workshop Warranty GP %": {
  name: "PARTS > MIX > Parts Service Workshop Warranty GP %",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "Parts Service Workshop Warranty GP %",
  tableNumber: 81,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Warranty GP % of Net Warranty sales | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > MIX > Parts Service Workshop Internal GP %": {
  name: "PARTS > MIX > Parts Service Workshop Internal GP %",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "Parts Service Workshop Internal GP %",
  tableNumber: 81,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Internal GP % of Net Internal sales | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > MIX > Parts Quick Service GP %": {
  name: "PARTS > MIX > Parts Quick Service GP %",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "Parts Quick Service GP %",
  tableNumber: 81,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Parts Quick service workshop GP % of Parts Quick Service Net Sales | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > MIX > Parts Body Shop GP %": {
  name: "PARTS > MIX > Parts Body Shop GP %",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "Parts Body Shop GP %",
  tableNumber: 82,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Parts Body Shop GP % of Parts Body Shop Net Sales | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > MIX > Parts Body Shop Retail GP %": {
  name: "PARTS > MIX > Parts Body Shop Retail GP %",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "Parts Body Shop Retail GP %",
  tableNumber: 82,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Panel GP % of Net Panel sales | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > MIX > Parts Body Shop Warranty GP %": {
  name: "PARTS > MIX > Parts Body Shop Warranty GP %",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "Parts Body Shop Warranty GP %",
  tableNumber: 82,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Parts Body Shop Warranty GP % of BS Warranty Net Sales | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > MIX > Parts Body Shop Internal GP %": {
  name: "PARTS > MIX > Parts Body Shop Internal GP %",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "Parts Body Shop Internal GP %",
  tableNumber: 82,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Parts Body Shop Internal GP % of BS Internal Net Sales | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > MIX > Parts Accessories GP %": {
  name: "PARTS > MIX > Parts Accessories GP %",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "Parts Accessories GP %",
  tableNumber: 83,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Accessories GP % of Net Acc sales | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > MIX > Parts Intercompany GP %": {
  name: "PARTS > MIX > Parts Intercompany GP %",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "Parts Intercompany GP %",
  tableNumber: 83,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Intercompany GP % of Net Intercompany sales | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > MIX > Parts Oil & Lubricant GP %": {
  name: "PARTS > MIX > Parts Oil & Lubricant GP %",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "Parts Oil & Lubricant GP %",
  tableNumber: 83,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Oil Lubricants GP % of Net Oil Lubricants sales | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > MIX > Parts Counter Retail GP %": {
  name: "PARTS > MIX > Parts Counter Retail GP %",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "Parts Counter Retail GP %",
  tableNumber: 83,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Counter retail GP % of Net Counter retail sales | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > MIX > Parts Wholesale GP %": {
  name: "PARTS > MIX > Parts Wholesale GP %",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "Parts Wholesale GP %",
  tableNumber: 83,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Wholesale GP % of Net Wholesale sales | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > MIX > Parts Other GP %": {
  name: "PARTS > MIX > Parts Other GP %",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "Parts Other GP %",
  tableNumber: 83,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Aftersales Other GP % of Sales | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > MIX > Parts Adjustments % Sales": {
  name: "PARTS > MIX > Parts Adjustments % Sales",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "Parts Adjustments % Sales",
  tableNumber: 86,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "OI > ADJ > Adjustments % of TNS | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > MIX > Parts OEM Incentives % Sales": {
  name: "PARTS > MIX > Parts OEM Incentives % Sales",
  department: "PARTS",
  category: "GROSS PROFIT",
  label: "Parts OEM Incentives % Sales",
  tableNumber: 86,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "OI > Incentives % of TNS | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > RETAINED > PARTS RETAINED": {
  name: "PARTS > RETAINED > PARTS RETAINED",
  department: "PARTS",
  category: "EXPENSES",
  label: "PARTS RETAINED",
  tableNumber: 87,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT % of TGP: RETAINED | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > RETAINED > PARTS Total GP": {
  name: "PARTS > RETAINED > PARTS Total GP",
  department: "PARTS",
  category: "EXPENSES",
  label: "PARTS Total GP",
  tableNumber: 86,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > RETAINED > PARTS Total Expenses": {
  name: "PARTS > RETAINED > PARTS Total Expenses",
  department: "PARTS",
  category: "EXPENSES",
  label: "PARTS Total Expenses",
  tableNumber: 87,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > RETAINED > PARTS Total PBT": {
  name: "PARTS > RETAINED > PARTS Total PBT",
  department: "PARTS",
  category: "EXPENSES",
  label: "PARTS Total PBT",
  tableNumber: 95,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT (Profit Before Tax) | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > RETAINED > Total Expenses % GP": {
  name: "PARTS > RETAINED > Total Expenses % GP",
  department: "PARTS",
  category: "EXPENSES",
  label: "Parts Total Expenses % GP",
  tableNumber: 87,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses % of TGP | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > RETAINED > Variable Expenses % GP": {
  name: "PARTS > RETAINED > Variable Expenses % GP",
  department: "PARTS",
  category: "EXPENSES",
  label: "Parts Variable Expenses  % GP",
  tableNumber: 88,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses % of TGP | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > RETAINED > Personnel Expenses % GP": {
  name: "PARTS > RETAINED > Personnel Expenses % GP",
  department: "PARTS",
  category: "EXPENSES",
  label: "Parts Personnel  Expenses % GP",
  tableNumber: 89,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PE > Personnel Expenses % of TGP | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > RETAINED > Other Semi Variable Expenses % GP": {
  name: "PARTS > RETAINED > Other Semi Variable Expenses % GP",
  department: "PARTS",
  category: "EXPENSES",
  label: "Parts Other Semi Variable Expenses % GP",
  tableNumber: 90,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "SVE > Other Semi Variable Expenses % of TGP | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > RETAINED > Fixed Expenses % GP": {
  name: "PARTS > RETAINED > Fixed Expenses % GP",
  department: "PARTS",
  category: "EXPENSES",
  label: "Parts Fixed Expenses % GP",
  tableNumber: 91,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "FE > Fixed Expenses % of TGP | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > RETAINED > Interest Expenses % GP": {
  name: "PARTS > RETAINED > Interest Expenses % GP",
  department: "PARTS",
  category: "EXPENSES",
  label: "Parts Interest Expenses % GP",
  tableNumber: 92,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Interest % of TGP | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > RETAINED > Allocation of Admin Expenses % GP": {
  name: "PARTS > RETAINED > Allocation of Admin Expenses % GP",
  department: "PARTS",
  category: "EXPENSES",
  label: "Parts Allocation of Admin  Expenses % GP",
  tableNumber: 87,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Adm > Admin Exp. Allocation % of TGP | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > RETAINED > Variable Expenses% Total Expenses": {
  name: "PARTS > RETAINED > Variable Expenses% Total Expenses",
  department: "PARTS",
  category: "EXPENSES",
  label: "Parts Variable Expenses% Total Expenses",
  tableNumber: 88,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses % of Total Expenses | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > RETAINED > Personnel Expenses% Total Expenses": {
  name: "PARTS > RETAINED > Personnel Expenses% Total Expenses",
  department: "PARTS",
  category: "EXPENSES",
  label: "Parts Personnel Expenses% Total Expenses",
  tableNumber: 89,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PE > Personnel Expenses % of Total Expenses | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > RETAINED > Other Semi Var.Exp.% Total Expenses": {
  name: "PARTS > RETAINED > Other Semi Var.Exp.% Total Expenses",
  department: "PARTS",
  category: "EXPENSES",
  label: "Parts Other Semi Var.Exp.% Total Expenses",
  tableNumber: 90,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "SVE > Other Semi Variable Expenses % of Total Expenses | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > RETAINED > Fixed Expenses% Total Expenses": {
  name: "PARTS > RETAINED > Fixed Expenses% Total Expenses",
  department: "PARTS",
  category: "EXPENSES",
  label: "Parts Fixed Expenses% Total Expenses",
  tableNumber: 91,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "FE > Fixed Expenses % of Total Expenses | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > RETAINED > Interest Expenses % Total Expenses": {
  name: "PARTS > RETAINED > Interest Expenses % Total Expenses",
  department: "PARTS",
  category: "EXPENSES",
  label: "Parts Interest Expenses % Total Expenses",
  tableNumber: 92,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Interest % of Total Expenses | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > RETAINED > Allocation of Admin Expenses % Total Expenses": {
  name: "PARTS > RETAINED > Allocation of Admin Expenses % Total Expenses",
  department: "PARTS",
  category: "EXPENSES",
  label: "Parts Allocation of Admin Expenses % Total Expenses",
  tableNumber: 87,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Adm > Admin Exp. Allocation % of Total Expenses | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > RETAINED > Parts Cost Recovery": {
  name: "PARTS > RETAINED > Parts Cost Recovery",
  department: "PARTS",
  category: "EXPENSES",
  label: "Parts Cost Recovery",
  tableNumber: 176,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Cost Recovery | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > ACTIVITY > PARTS ACTIVITY": {
  name: "PARTS > ACTIVITY > PARTS ACTIVITY",
  department: "PARTS",
  category: "OPERATIONAL ASSETS",
  label: "PARTS ACTIVITY",
  tableNumber: 93,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Asset Activity | Parts | All Brand | All Model | | AVG",
  canAverage: false
},
"PARTS > ACTIVITY > PARTS Annualised Sales": {
  name: "PARTS > ACTIVITY > PARTS Annualised Sales",
  department: "PARTS",
  category: "OPERATIONAL ASSETS",
  label: "PARTS Annualised Sales",
  tableNumber: 80,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Projected Annualised Sales | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > ACTIVITY > PARTS Average Operational Assets": {
  name: "PARTS > ACTIVITY > PARTS Average Operational Assets",
  department: "PARTS",
  category: "OPERATIONAL ASSETS",
  label: "PARTS Average Operational Assets",
  tableNumber: 93,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Average Operational Assets | Parts | All Brand | All Model | | AVG",
  canAverage: true
},
"PARTS > ACTIVITY > PARTS ROSI": {
  name: "PARTS > ACTIVITY > PARTS ROSI",
  department: "PARTS",
  category: "OPERATIONAL ASSETS",
  label: "PARTS ROSI",
  tableNumber: 93,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Return on Stock Investment (ROSI) | Parts | All Brand | All Model | | AVG",
  canAverage: false
},
"PARTS > ACTIVITY > Parts Inventory Days": {
  name: "PARTS > ACTIVITY > Parts Inventory Days",
  department: "PARTS",
  category: "OPERATIONAL ASSETS",
  label: "Parts Inventory Days",
  tableNumber: 93,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Inventory days | Parts | All Brand | All Model | | AVG",
  canAverage: false
},
"PARTS > ACTIVITY > Parts Inventory Value (ending)": {
  name: "PARTS > ACTIVITY > Parts Inventory Value (ending)",
  department: "PARTS",
  category: "OPERATIONAL ASSETS",
  label: "Parts Inventory Value (ending)",
  tableNumber: 93,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Net Inventories | Parts | All Brand | All Model | | CLOSE",
  canAverage: true
},
"PARTS > ACTIVITY > Parts Inventory Value (average)": {
  name: "PARTS > ACTIVITY > Parts Inventory Value (average)",
  department: "PARTS",
  category: "OPERATIONAL ASSETS",
  label: "Parts Inventory Value (average)",
  tableNumber: 93,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Net Inventories | Parts | All Brand | All Model | | AVG",
  canAverage: true
},
"PARTS > ACTIVITY > Parts Inventory Age 0-90 Days mix": {
  name: "PARTS > ACTIVITY > Parts Inventory Age 0-90 Days mix",
  department: "PARTS",
  category: "OPERATIONAL ASSETS",
  label: "Parts Inventory Age 0-90 Days mix",
  tableNumber: 93,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Age Analysis > 0-90 days MIX | Parts | All Brand | All Model | | CLOSE",
  canAverage: false
},
"PARTS > ACTIVITY > Parts Inventory Age 91-180 Days mix": {
  name: "PARTS > ACTIVITY > Parts Inventory Age 91-180 Days mix",
  department: "PARTS",
  category: "OPERATIONAL ASSETS",
  label: "Parts Inventory Age 91-180 Days mix",
  tableNumber: 93,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Age Analysis > 91-180 days MIX | Parts | All Brand | All Model | | CLOSE",
  canAverage: false
},
"PARTS > ACTIVITY > Parts Inventory Age 181-365 Days mix": {
  name: "PARTS > ACTIVITY > Parts Inventory Age 181-365 Days mix",
  department: "PARTS",
  category: "OPERATIONAL ASSETS",
  label: "Parts Inventory Age 181-365 Days mix",
  tableNumber: 93,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Age Analysis > 181-365 days MIX | Parts | All Brand | All Model | | CLOSE",
  canAverage: false
},
"PARTS > ACTIVITY > Parts Inventory Age 365+ Days mix": {
  name: "PARTS > ACTIVITY > Parts Inventory Age 365+ Days mix",
  department: "PARTS",
  category: "OPERATIONAL ASSETS",
  label: "Parts Inventory Age 365+ Days mix",
  tableNumber: 93,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Age Analysis > 365+ days MIX | Parts | All Brand | All Model | | CLOSE",
  canAverage: false
},
"PARTS > ROOA > ROOA": {
  name: "PARTS > ROOA > ROOA",
  department: "PARTS",
  category: "MRA MODEL",
  label: "PARTS ROOA",
  tableNumber: 94,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Return on Operational Assets (ROOA) | Parts | All Brand | All Model | | AVG",
  canAverage: false
},
"PARTS > ROOA > MIX": {
  name: "PARTS > ROOA > MIX",
  department: "PARTS",
  category: "MRA MODEL",
  label: "PARTS MIX",
  tableNumber: 86,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit % of Sales (MIX) | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > ROOA > RETAINED": {
  name: "PARTS > ROOA > RETAINED",
  department: "PARTS",
  category: "MRA MODEL",
  label: "PARTS RETAINED",
  tableNumber: 87,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT % of TGP: RETAINED | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > ROOA > ACTIVITY": {
  name: "PARTS > ROOA > ACTIVITY",
  department: "PARTS",
  category: "MRA MODEL",
  label: "PARTS ACTIVITY",
  tableNumber: 93,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Asset Activity | Parts | All Brand | All Model | | AVG",
  canAverage: false
},
"PARTS > ROS (Income Statement) > ROS": {
  name: "PARTS > ROS (Income Statement) > ROS",
  department: "PARTS",
  category: "ROS (Income Statement)",
  label: "Parts ROS",
  tableNumber: 95,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT % of Sales | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > ROS (Income Statement) > Sales": {
  name: "PARTS > ROS (Income Statement) > Sales",
  department: "PARTS",
  category: "ROS (Income Statement)",
  label: "Parts Sales",
  tableNumber: 80,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > ROS (Income Statement) > PBT": {
  name: "PARTS > ROS (Income Statement) > PBT",
  department: "PARTS",
  category: "ROS (Income Statement)",
  label: "Parts PBT",
  tableNumber: 95,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT (Profit Before Tax) | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > ROS (Income Statement) > Dept. Profit % Net Sales": {
  name: "PARTS > ROS (Income Statement) > Dept. Profit % Net Sales",
  department: "PARTS",
  category: "ROS (Income Statement)",
  label: "Parts Dept. Profit % Net Sales",
  tableNumber: 95,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "E > Earnings After Interest % of TNS | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > ROS (Income Statement) > EBIT% (PBIT%)": {
  name: "PARTS > ROS (Income Statement) > EBIT% (PBIT%)",
  department: "PARTS",
  category: "ROS (Income Statement)",
  label: "Parts EBIT% (PBIT%)",
  tableNumber: 95,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBIT % of Sales | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > ROS (Income Statement) > Variable Selling Gross % Gorss Profit": {
  name: "PARTS > ROS (Income Statement) > Variable Selling Gross % Gorss Profit",
  department: "PARTS",
  category: "ROS (Income Statement)",
  label: "Parts Variable Selling Gross % Gorss Profit",
  tableNumber: 88,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Variable Selling Gross % of TGP | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > ROS (Income Statement) > Selling Gross % Gross Profit": {
  name: "PARTS > ROS (Income Statement) > Selling Gross % Gross Profit",
  department: "PARTS",
  category: "ROS (Income Statement)",
  label: "Parts Selling Gross % Gross Profit",
  tableNumber: 95,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Selling Gross % of TGP | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > ROS (Income Statement) > Selling Gross % Net Sales": {
  name: "PARTS > ROS (Income Statement) > Selling Gross % Net Sales",
  department: "PARTS",
  category: "ROS (Income Statement)",
  label: "Parts Selling Gross % Net Sales",
  tableNumber: 95,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Selling Gross % of TNS | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > ROS (Income Statement) > Gross Profit": {
  name: "PARTS > ROS (Income Statement) > Gross Profit",
  department: "PARTS",
  category: "ROS (Income Statement)",
  label: "Parts Gross Profit",
  tableNumber: 86,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > ROS (Income Statement) > Expenses": {
  name: "PARTS > ROS (Income Statement) > Expenses",
  department: "PARTS",
  category: "ROS (Income Statement)",
  label: "Parts Expenses",
  tableNumber: 87,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > ROS (Income Statement) > Variable Expenses": {
  name: "PARTS > ROS (Income Statement) > Variable Expenses",
  department: "PARTS",
  category: "ROS (Income Statement)",
  label: "Parts Variable Expenses",
  tableNumber: 88,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > ROS (Income Statement) > Personnel Expenses": {
  name: "PARTS > ROS (Income Statement) > Personnel Expenses",
  department: "PARTS",
  category: "ROS (Income Statement)",
  label: "Parts Personnel Expenses",
  tableNumber: 89,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PE > Personnel Expenses | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > ROS (Income Statement) > Other Semi Variable Expenses": {
  name: "PARTS > ROS (Income Statement) > Other Semi Variable Expenses",
  department: "PARTS",
  category: "ROS (Income Statement)",
  label: "Parts Other Semi Variable Expenses",
  tableNumber: 90,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "SVE > Other Semi Variable Expenses | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > ROS (Income Statement) > Fixed Expenses": {
  name: "PARTS > ROS (Income Statement) > Fixed Expenses",
  department: "PARTS",
  category: "ROS (Income Statement)",
  label: "Parts Fixed Expenses",
  tableNumber: 91,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "FE > Fixed Expenses | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > ROS (Income Statement) > Interest Expenses": {
  name: "PARTS > ROS (Income Statement) > Interest Expenses",
  department: "PARTS",
  category: "ROS (Income Statement)",
  label: "Parts Interest Expenses",
  tableNumber: 92,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Interest | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > ROS (Income Statement) > Departmental Profit": {
  name: "PARTS > ROS (Income Statement) > Departmental Profit",
  department: "PARTS",
  category: "ROS (Income Statement)",
  label: "Parts Departmental Profit",
  tableNumber: 95,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "E > Earnings After Interest (EAIT) | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > ROS (Income Statement) > Allocation of Admin Expenses": {
  name: "PARTS > ROS (Income Statement) > Allocation of Admin Expenses",
  department: "PARTS",
  category: "ROS (Income Statement)",
  label: "Parts Allocation of Admin Expenses",
  tableNumber: 87,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Adm > Admin Exp. Allocation | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > ROS (Income Statement) > EBIT": {
  name: "PARTS > ROS (Income Statement) > EBIT",
  department: "PARTS",
  category: "ROS (Income Statement)",
  label: "Parts EBIT",
  tableNumber: 95,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBIT (Profit Before Interest & Tax) | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"PARTS > PRODUCTIVITY > Total Parts GP / Productive / Month": {
  name: "PARTS > PRODUCTIVITY > Total Parts GP / Productive / Month",
  department: "PARTS",
  category: "PRODUCTIVITY",
  label: "Total Parts GP / Productive / Month",
  tableNumber: 96,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ave Monthly TGP per Productive | Parts | All Brand | All Model | | AVG",
  canAverage: false
},
"PARTS > PRODUCTIVITY > Parts Workshop Sales / Productive / Month": {
  name: "PARTS > PRODUCTIVITY > Parts Workshop Sales / Productive / Month",
  department: "PARTS",
  category: "PRODUCTIVITY",
  label: "Parts Workshop Sales / Productive / Month",
  tableNumber: 96,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Parts Workshop & Bodyshop sales per productive (Incl. Quick Service) | Parts | All Brand | All Model | | AVG",
  canAverage: false
},
"PARTS > PRODUCTIVITY > Total Parts Sales / Productive / Month": {
  name: "PARTS > PRODUCTIVITY > Total Parts Sales / Productive / Month",
  department: "PARTS",
  category: "PRODUCTIVITY",
  label: "Total Parts Sales / Productive / Month",
  tableNumber: 96,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Parts net sales per Productive | Parts | All Brand | All Model | | AVG",
  canAverage: false
},
"PARTS > PRODUCTIVITY > Total Parts GP / Employee / Month": {
  name: "PARTS > PRODUCTIVITY > Total Parts GP / Employee / Month",
  department: "PARTS",
  category: "PRODUCTIVITY",
  label: "Total Parts GP / Employee / Month",
  tableNumber: 96,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ave Monthly TGP per Employee | Parts | All Brand | All Model | | AVG",
  canAverage: false
},
"PARTS > PRODUCTIVITY > Parts Workshop Sales / Service RO": {
  name: "PARTS > PRODUCTIVITY > Parts Workshop Sales / Service RO",
  department: "PARTS",
  category: "PRODUCTIVITY",
  label: "Parts Workshop Sales / Service RO",
  tableNumber: 81,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Parts workshop sales per RO (Incl. Quick Service) | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > PRODUCTIVITY > Parts Body Shop Sales / Body Shop RO": {
  name: "PARTS > PRODUCTIVITY > Parts Body Shop Sales / Body Shop RO",
  department: "PARTS",
  category: "PRODUCTIVITY",
  label: "Parts Body Shop Sales / Body Shop RO",
  tableNumber: 82,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Parts workshop sales per RO (BS) | Body Shop | All Brand | All Model | | SUM",
  canAverage: false
},
"PARTS > PRODUCTIVITY > Total Staff": {
  name: "PARTS > PRODUCTIVITY > Total Staff",
  department: "PARTS",
  category: "PRODUCTIVITY",
  label: "Parts Total Staff",
  tableNumber: 96,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Total Employees | Parts | All Brand | All Model | | AVG",
  canAverage: true
},
"PARTS > PRODUCTIVITY > Productive Staff": {
  name: "PARTS > PRODUCTIVITY > Productive Staff",
  department: "PARTS",
  category: "PRODUCTIVITY",
  label: "Parts Productive Staff",
  tableNumber: 96,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Productives | Parts | All Brand | All Model | | AVG",
  canAverage: true
},
"PARTS > PRODUCTIVITY > Non-Productive Staff": {
  name: "PARTS > PRODUCTIVITY > Non-Productive Staff",
  department: "PARTS",
  category: "PRODUCTIVITY",
  label: "Parts Non-Productive Staff",
  tableNumber: 96,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Non-Productives | Parts | All Brand | All Model | | AVG",
  canAverage: true
},
"PARTS > PRODUCTIVITY > Ratio Productive : Non Productive": {
  name: "PARTS > PRODUCTIVITY > Ratio Productive : Non Productive",
  department: "PARTS",
  category: "PRODUCTIVITY",
  label: "Parts Ratio Productive : Non Productive",
  tableNumber: 96,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ratio Productive to Non-Productive staff | Parts | All Brand | All Model | | AVG",
  canAverage: false
},
};

export const PartsOEMSupportsAnalysis: { [index: string]: CustomDashboardType } = {
  "PARTS > OEM Supports Analysis > Parts Support from OEM": {
  name: "PARTS > OEM Supports Analysis > Parts Support from OEM",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts Support from OEM",
  measure: "G > OEM Incentives & Expenses Subsidy | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: true
},
"PARTS > OEM Supports Analysis > Parts OEM Incentives": {
  name: "PARTS > OEM Supports Analysis > Parts OEM Incentives",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts OEM Incentives",
  measure: "G > OEM Incentives | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: true
},
"PARTS > OEM Supports Analysis > Parts OEM Expenses Subsidy": {
  name: "PARTS > OEM Supports Analysis > Parts OEM Expenses Subsidy",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts OEM Expenses Subsidy",
  measure: "TE > OEM Expenses Subsidy | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: true
},
"PARTS > OEM Supports Analysis > Parts OEM Direct Advertising Subsidy": {
  name: "PARTS > OEM Supports Analysis > Parts OEM Direct Advertising Subsidy",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts OEM Direct Advertising Subsidy",
  measure: "VE > Advertising Subsidy | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: true
},
"PARTS > OEM Supports Analysis > Parts OEM Indirect Advertising Subsidy": {
  name: "PARTS > OEM Supports Analysis > Parts OEM Indirect Advertising Subsidy",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts OEM Indirect Advertising Subsidy",
  measure: "SVE > Indirect Advertising Subsidy | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: true
},
"PARTS > OEM Supports Analysis > Parts OEM Incentives % TGP": {
  name: "PARTS > OEM Supports Analysis > Parts OEM Incentives % TGP",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts OEM Incentives % TGP",
  measure: "G > OEM Incentives % TGP UsedF&IAftercare | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: false
},
"PARTS > OEM Supports Analysis > Parts OEM Incentives % Sales": {
  name: "PARTS > OEM Supports Analysis > Parts OEM Incentives % Sales",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts OEM Incentives % Sales",
  measure: "G > OEM Incentives % Net Sales | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: false
},
"PARTS > OEM Supports Analysis > Parts OEM Expenses Subsidy % Sales": {
  name: "PARTS > OEM Supports Analysis > Parts OEM Expenses Subsidy % Sales",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts OEM Expenses Subsidy % Sales",
  measure: "TE > OEM Expenses Subsidy % Net Sales | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: false
},
"PARTS > OEM Supports Analysis > Parts OEM All Supports % Sales": {
  name: "PARTS > OEM Supports Analysis > Parts OEM All Supports % Sales",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts OEM All Supports % Sales",
  measure: "G > OEM Incentives & Expenses Subsidy % Net Sales | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: false
},
"PARTS > OEM Supports Analysis > Parts MIX": {
  name: "PARTS > OEM Supports Analysis > Parts MIX",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts MIX",
  measure: "G > Gross Profit % of Sales (MIX) | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: false
},
"PARTS > OEM Supports Analysis > Parts MIX (excl Incentive)": {
  name: "PARTS > OEM Supports Analysis > Parts MIX (excl Incentive)",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts MIX (excl Incentive)",
  measure: "G > Gross Profit (excl Incentive) % of Net Sales | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: false
},
"PARTS > OEM Supports Analysis > Parts RETAINED": {
  name: "PARTS > OEM Supports Analysis > Parts RETAINED",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts RETAINED",
  measure: "PBT > PBT % of TGP: RETAINED | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: false
},
"PARTS > OEM Supports Analysis > Parts RETAINED (excl All Supports from OEM)": {
  name: "PARTS > OEM Supports Analysis > Parts RETAINED (excl All Supports from OEM)",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts RETAINED (excl All Supports from OEM)",
  measure: "PBT > PBT % of TGP: RETAINED (excl OEM Incentives & Expenses Subsidy) | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: false
},
"PARTS > OEM Supports Analysis > Parts RETAINED (excl Incentives)": {
  name: "PARTS > OEM Supports Analysis > Parts RETAINED (excl Incentives)",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts RETAINED (excl Incentives)",
  measure: "PBT > PBT % of TGP: RETAINED (excl Incentive) | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: false
},
"PARTS > OEM Supports Analysis > Parts RETAINED (excl OEM Expenses Subsidy)": {
  name: "PARTS > OEM Supports Analysis > Parts RETAINED (excl OEM Expenses Subsidy)",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts RETAINED (excl OEM Expenses Subsidy)",
  measure: "PBT > PBT % of TGP: RETAINED (excl OEM Expenses Subsidy) | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: false
},
"PARTS > OEM Supports Analysis > Parts Expenses % TGP": {
  name: "PARTS > OEM Supports Analysis > Parts Expenses % TGP",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts Expenses % TGP",
  measure: "TE > Total Expenses % of TGP | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: false
},
"PARTS > OEM Supports Analysis > Parts Expenses % TGP (excl All Supports from OEM)": {
  name: "PARTS > OEM Supports Analysis > Parts Expenses % TGP (excl All Supports from OEM)",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts Expenses % TGP (excl All Supports from OEM)",
  measure: "TE > Total Expenses % of TGP (excl OEM Incentives & Expenses Subsidy) | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: false
},
"PARTS > OEM Supports Analysis > Parts Expenses % TGP (excl Incentives)": {
  name: "PARTS > OEM Supports Analysis > Parts Expenses % TGP (excl Incentives)",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts Expenses % TGP (excl Incentives)",
  measure: "TE > Total Expenses % of TGP (excl Incentive) | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: false
},
"PARTS > OEM Supports Analysis > Parts Expenses % TGP (excl OEM Expenses Subsidy)": {
  name: "PARTS > OEM Supports Analysis > Parts Expenses % TGP (excl OEM Expenses Subsidy)",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts Expenses % TGP (excl OEM Expenses Subsidy)",
  measure: "TE > Total Expenses % of TGP (excl OEM Expenses Subsidy) | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: false
},
"PARTS > OEM Supports Analysis > Parts ROS": {
  name: "PARTS > OEM Supports Analysis > Parts ROS",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts ROS",
  measure: "PBT > PBT % of Sales | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: false
},
"PARTS > OEM Supports Analysis > Parts ROS (excl All Supports from OEM)": {
  name: "PARTS > OEM Supports Analysis > Parts ROS (excl All Supports from OEM)",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts ROS (excl All Supports from OEM)",
  measure: "PBT > PBT (excl OEM Incentives & Expenses Subsidy) % of Sales | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: false
},
"PARTS > OEM Supports Analysis > Parts ROS (excl Incentives)": {
  name: "PARTS > OEM Supports Analysis > Parts ROS (excl Incentives)",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts ROS (excl Incentives)",
  measure: "PBT > PBT (excl OEM Incentive) % of Sales | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: false
},
"PARTS > OEM Supports Analysis > Parts ROS (excl OEM Expenses Subsidy)": {
  name: "PARTS > OEM Supports Analysis > Parts ROS (excl OEM Expenses Subsidy)",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts ROS (excl OEM Expenses Subsidy)",
  measure: "PBT > PBT (excl OEM Expenses Subsidy) % of Sales | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: false
},
"PARTS > OEM Supports Analysis > Parts Sales": {
  name: "PARTS > OEM Supports Analysis > Parts Sales",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts Sales",
  measure: "TNS > Net Sales | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: true
},
"PARTS > OEM Supports Analysis > Parts GP": {
  name: "PARTS > OEM Supports Analysis > Parts GP",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts GP",
  measure: "G > Gross Profit | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: true
},
"PARTS > OEM Supports Analysis > Parts GP (excl Incentives)": {
  name: "PARTS > OEM Supports Analysis > Parts GP (excl Incentives)",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts GP (excl Incentives)",
  measure: "G > Gross Profit (excl Incentive) | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: true
},
"PARTS > OEM Supports Analysis > Parts Expenses": {
  name: "PARTS > OEM Supports Analysis > Parts Expenses",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts Expenses",
  measure: "TE > Total Expenses | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: true
},
"PARTS > OEM Supports Analysis > Parts Expenses (excl OEM Expenses Subsidy)": {
  name: "PARTS > OEM Supports Analysis > Parts Expenses (excl OEM Expenses Subsidy)",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts Expenses (excl OEM Expenses Subsidy)",
  measure: "TE > Total Expenses (excl Expenses Subsidy) | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: true
},
"PARTS > OEM Supports Analysis > Parts PBT": {
  name: "PARTS > OEM Supports Analysis > Parts PBT",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts PBT",
  measure: "PBT > PBT (Profit Before Tax) | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: true
},
"PARTS > OEM Supports Analysis > Parts PBT (excl All Supports from OEM)": {
  name: "PARTS > OEM Supports Analysis > Parts PBT (excl All Supports from OEM)",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts PBT (excl All Supports from OEM)",
  measure: "PBT > PBT (excl OEM Incentives & Expenses Subsidy) | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: true
},
"PARTS > OEM Supports Analysis > Parts PBT (excl Incentives)": {
  name: "PARTS > OEM Supports Analysis > Parts PBT (excl Incentives)",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts PBT (excl Incentives)",
  measure: "PBT > PBT (excl OEM Incentive) | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: true
},
"PARTS > OEM Supports Analysis > Parts PBT (excl OEM Expenses Subsidy)": {
  name: "PARTS > OEM Supports Analysis > Parts PBT (excl OEM Expenses Subsidy)",
  department: "PARTS",
  category: "OEM Supports Analysis",
  label: "Parts PBT (excl OEM Expenses Subsidy)",
  measure: "PBT > PBT (excl OEM Expenses Subsidy) | Parts | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 531,
  canAverage: true
},
};
