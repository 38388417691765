import React, { createContext, useState, useMemo, FC, useContext } from "react";
import { Dealer } from "../lib/api/charts";
import { Loading } from "./base";
import { BenchmarkContext } from "./BenchmarkContext";
import { DateContext } from "./FilterDateContext";
import { formatFullDate, formatDisplayDate } from "../charts/DashboardOuter";
import { Filters, FilterSet, DealerCode, DealerName, defaultDealer } from "./DealerContext";

export type CustomDealerContextState = {
  loading: Loading;
  customSelected: Dealer;
  customFilters: Filters;
  customFilterSet: FilterSet;
  customDealerCode: DealerCode;
  customDealerName: DealerName;
  setCustomDealerCode(dealerCode: DealerCode): void;
  setCustomDealerName(dealerName: DealerName): void;
  setCustomDealer(dealer: Dealer): void;
  setCustomFilters(obj: Filters): void;
  setCustomFilterSet(obj: FilterSet): void;
};

export const CustomDealerContext = createContext<CustomDealerContextState>({
  loading: { loading: false, loaded: false, error: null },
  customSelected: defaultDealer,
  customFilters: { oem: [], region: [], dealerGroup: [], dealerSet: [], country: [], excludeNewDealers: false },
  customFilterSet: {
    dealerCode: "",
    dealerName: "",
    filters: { oem: [], region: [], dealerGroup: [], dealerSet: [], country: [], excludeNewDealers: false },
  },
  customDealerCode: { value: "" },
  customDealerName: { value: "" },
  setCustomDealerCode: () => {},
  setCustomDealerName: () => {},
  setCustomDealer: () => {},
  setCustomFilters: () => {},
  setCustomFilterSet: () => {},
});

export const CustomDealerProviderInner: FC = ({ children }) => {
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const { updateDate } = useContext(DateContext);
  const { setCustomBenchmark: setBenchmark, customSelected: selected } = useContext(BenchmarkContext);

  const [customDealer, xsetCustomDealer] = useState<Dealer>(defaultDealer);
  const setCustomDealer = (dealer?: Dealer) => {
    xsetCustomDealer(dealer || defaultDealer);
  };
  const [customDealerCode, setCustomDealerCodeValue] = React.useState({ value: "" });
  const [customDealerName, setCustomDealerNameValue] = React.useState({ value: "" });
  const [customFilters, setCustomFiltersValue] = React.useState({
    oem: [],
    region: [],
    dealerGroup: [],
    dealerSet: [],
    country: [],
    excludeNewDealers: false,
  });
  const [customFilterSet, setCustomFilterSetValue] = React.useState({
    dealerCode: "",
    dealerName: "",
    filters: {
      oem: [],
      region: [],
      dealerGroup: [],
      dealerSet: [],
      country: [],
      excludeNewDealers: false,
    },
  });

  const value = useMemo(
    () => ({
      customSelected: {
        ...customDealer,
        minMonth: new Date(customDealer.minMonth as any),
        maxMonth: new Date(customDealer.maxMonth as any),
      },
      customDealerCode,
      customDealerName,
      customFilters,
      customFilterSet,
      loading,
      setCustomDealer: async dealer => {
        if (!loading.loading) {
          try {
            setLoading({
              loading: true,
              loaded: false,
              error: null,
            });
            if (dealer.benchmarks.length <= 0) {
              await setBenchmark(["", ""]);
            } else {
              if (selected && selected.filter(bm => bm.length > 0).length > 0 && !dealer.defaultBMLimits) {
                await setBenchmark(selected as [string, string]);
              } else if (dealer.defaultBMLimits) {
                await setBenchmark([`${dealer.benchmarks[0]} - UL`, `${dealer.benchmarks[0]} - LL`] as [string, string]);
              } else {
                await setBenchmark([dealer.benchmarks[0], `${dealer.benchmarks[0]} - Median`]);
              }
            }
            setCustomDealer(dealer);
            if (dealer.maxMonth) {
              const d = new Date(Date.parse(dealer.maxMonth.toString()));
              updateDate({
                value: formatFullDate(d),
                label: formatDisplayDate(d),
              });
            }
            setLoading({
              loading: false,
              loaded: true,
              error: null,
            });
          } catch (e) {
            setLoading({
              loading: false,
              loaded: false,
              error: e,
            });
          }
          return;
        } else {
          if (dealer.benchmarks.length <= 0) {
            await setBenchmark(["", ""]);
          } else {
            await setBenchmark([dealer.benchmarks[0], `${dealer.benchmarks[0]} - Median`]);
          }
          setCustomDealer(dealer);
          if (dealer.maxMonth) {
            const d = new Date(Date.parse(dealer.maxMonth.toString()));
            updateDate({
              value: formatFullDate(d),
              label: formatDisplayDate(d),
            });
          }
        }
      },
      setCustomDealerCode: obj => {
        setCustomDealerCodeValue(obj);
      },
      setCustomDealerName: obj => {
        setCustomDealerNameValue(obj);
      },
      setCustomFilters: obj => {
        setCustomFiltersValue(obj);
      },
      setCustomFilterSet: obj => {
        setCustomFilterSetValue(obj);
      },
      setCustomDealerCodeValue,
      setCustomDealerNameValue,
      setCustomFiltersValue,
      setCustomFilterSetValue,
    }),
    [
      customDealer,
      customDealerCode,
      customDealerName,
      customFilters,
      customFilterSet,
      xsetCustomDealer,
      setCustomDealerCodeValue,
      setCustomDealerNameValue,
      setCustomFiltersValue,
      setCustomFilterSetValue,
      setCustomDealer,
    ],
  );

  return <CustomDealerContext.Provider value={value}>{children}</CustomDealerContext.Provider>;
};

export const CustomDealerProvider = CustomDealerProviderInner;
