import React, { createContext, useState, useReducer, useMemo } from "react";
import { Loading } from "../base";
import { dashboards as customDashboards, updateDashboard, createDashboard, deleteDashboard, Dashboard } from "../../lib/api/custom-dashboards";

const defaultCustomDashboard = [];

export type CustomDashboardContextState = {
  dashboards: Dashboard[];
  loading: Loading;
  customTab: string;
  hydrate();
  createDashboard(dashboard: Dashboard);
  updateDashboard(id: string, dashboard: Dashboard);
  deleteDashboard(id: string);
  getDashboard(): Dashboard[];
  setCustomTab(tab: string): void;
};

export const CustomDashboardContext = createContext<CustomDashboardContextState>({
  dashboards: defaultCustomDashboard,
  loading: { loading: false, loaded: false, error: null },
  customTab: "",
  hydrate: () => {},
  createDashboard: () => {},
  updateDashboard: () => {},
  deleteDashboard: () => {},
  getDashboard: () => [],
  setCustomTab: () => {},
});

export const CustomDashboardProvider = ({ children }) => {
  const [dashboards, setDashboard] = useState<Dashboard[]>(defaultCustomDashboard);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const [customTab, setCustomTab] = useState<string>("");
  const hydrate = async () => {
    try {
      setLoading({
        loading: true,
        loaded: false,
        error: null,
      });
      const dashboards = await customDashboards();
      setDashboard(dashboards);
      setLoading({
        loading: false,
        loaded: true,
        error: null,
      });
    } catch (e) {
      setLoading({
        loading: false,
        loaded: false,
        error: e,
      });
    }
  };

  const value = useMemo(
    () => ({
      dashboards,
      loading,
      customTab,
      hydrate,
      getDashboard() {
        if (loading.loaded && !loading.loading) {
          return dashboards;
        }
        if (!loading.loading && !loading.error) {
          hydrate();
        }
        return [];
      },
      createDashboard: async (dashboard: Dashboard) => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          await createDashboard(dashboard);
          setLoading({
            loading: false,
            loaded: true,
            error: null,
          });
          hydrate();
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      updateDashboard: async (id: string, dashboard: Dashboard) => {
        console.log(id, dashboard);
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          await updateDashboard(id, dashboard);
          setLoading({
            loading: false,
            loaded: true,
            error: null,
          });
          hydrate();
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      deleteDashboard: async id => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          deleteDashboard(id);
          setLoading({
            loading: false,
            loaded: true,
            error: null,
          });
          hydrate();
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      setDashboard,
      setCustomTab,
    }),
    [dashboards, loading, customTab, setCustomTab],
  );

  return <CustomDashboardContext.Provider value={value}>{children}</CustomDashboardContext.Provider>;
};
