import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { createStyles, Theme, withStyles, WithStyles, withTheme } from "@material-ui/core/styles";
import { red, green } from "@material-ui/core/colors";
import classnames from "classnames";
import { add } from "date-fns";
import { Loading } from "../components/Loading";
import { MetricGuidance } from "../components/MetricGuidance";
import { BreakdownContext } from "../contexts/BreakdownContext";
import { CurrencyContext } from "../contexts/CurrencyContext";
import { CustomDealerContext } from "../contexts/CustomDealerContext";
import { LanguageContext } from "../contexts/LanguageContext";
import { MeasuresContext } from "../contexts/MeasuresContext";
import { MetricReport, Query } from "../lib/api/charts";
import { TableRows } from "./CustomTable";
import { rooaHeaders } from "../tables/ROOATable";

const monthDict = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
      flex: 1,
      borderRadius: "3px",
      overflow: "scroll",
    },
    heading: {
      color: "#2f2f2f",
      fontWeight: "bold",
      marginBottom: "32px",
      fontSize: "10px",
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
    },
    table: {
      border: "1px solid #e0e0e0",
      backgroundColor: "#FFF",
      "& th": {
        border: "solid #e0e0e0",
        borderWidth: "0px 0px 1px 0px",
      },
      "& td": {
        border: "solid #e0e0e0",
        borderWidth: "0px 0px 1px 0px",
      },
    },
    stickyHeader: { position: "sticky", top: "0px", zIndex: 9 },
    stickyHeader2: { position: "sticky", top: "33px", zIndex: 9 },
    tableHeaderTop: { zIndex: 99 },
    tableHeader: {
      whiteSpace: "nowrap",
      padding: `${theme.spacing.unit}px`,
      fontSize: "10px",
      textAlign: "right",
      color: "#2f2f2f",
      "&:nth-child(even)": {
        backgroundColor: "#f3f3f3",
      },
      "&:nth-child(odd)": {
        backgroundColor: "#FFF",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
    },
    healthTableHeader: {
      padding: `${theme.spacing.unit}px`,
      fontSize: "10px",
      textAlign: "right",
      color: "#2f2f2f",
      fontWeight: "bold",
      "&:nth-child(even)": {
        backgroundColor: "#f3f3f3",
      },
      "&:nth-child(odd)": {
        backgroundColor: "#FFF",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
    },
    tableCell: {
      padding: `${theme.spacing.unit}px`,
      fontSize: "10px",
      whiteSpace: "nowrap",
      textAlign: "right",
      "&:nth-child(even)": {
        backgroundColor: "#f3f3f3",
      },
      "&:nth-child(odd)": {
        backgroundColor: "#FFF",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
    },
    tableCellSubHeaderFoa: {
      textAlign: "left",
      minWidth: "100px",
      fontWeight: "bold",
    },
    tableCellSubFoa: {
      textAlign: "left",
      minWidth: "100px",
      paddingLeft: `${theme.spacing.unit * 2}px`,
    },
    rule: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      textAlign: "left",
    },
    ruleBold: {
      fontWeight: "bold",
    },
    good: {
      background: "#92D050 !important",
      color: "white",
      textAlign: "center",
    },
    bad: {
      background: "#FF0000 !important",
      color: "white",
      textAlign: "center",
    },
    note: {
      color: "#191919",
      marginLeft: "25px",
      textAlign: "left",
    },
    tableContentIndent: {
      border: "solid #e0e0e0",
      borderWidth: "0px 0px 1px 0px",
      padding: `${theme.spacing.unit}px`,
      textAlign: "right",
      fontSize: "10px",
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
    },
    orange: {
      color: "orange",
    },
    yellow: {
      color: "orange",
    },
    tableCellNull: {
      background: "#ededed",
      "&:nth-child(even)": {
        backgroundColor: "#e8e8e8",
      },
    },
    tableHeadingHeight: {
      height: "5em",
      // width: "2em",
      whiteSpace: "normal",
      fontWeight: "bold",
    },
    tableHeading: {
      padding: `${theme.spacing.unit}px`,
      textAlign: "left",
      color: "#2f2f2f",
      fontSize: "10px",
      "&:nth-child(even)": {
        backgroundColor: "#f3f3f3",
      },
      "&:nth-child(odd)": {
        backgroundColor: "#FFF",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      fontWeight: "bold",
      width: "400px",
      minWidth: "400px",
      maxWidth: "400px",
      whiteSpace: "nowrap",
      position: "sticky",
      left: "34px",
      backgroundColor: "#f3f3f3",
    },
    green: {
      color: green[500],
    },
    red: {
      color: red[500],
    },
    pointer: {
      cursor: "pointer",
    },
    tableGuidance: {
      padding: "8px !important",
      maxWidth: "34px",
      width: "34px",
      minWidth: "34px",
      "&:nth-child(even)": {
        backgroundColor: "#f3f3f3",
      },
      "&:nth-child(odd)": {
        backgroundColor: "#FFF",
      },
      position: "sticky",
      left: "0px",
    },
    tableCellSubHeader: {
      textAlign: "left",
      width: "400px",
      minWidth: "400px",
      maxWidth: "400px",
      whiteSpace: "nowrap",
      position: "sticky",
      left: "34px",
      backgroundColor: "#f3f3f3",
    },
    healthTableCellSubHeader: {
      textAlign: "left",
      minWidth: "100px",
      backgroundColor: "#f3f3f3",
    },
    tableCellSubHeaderBM: {
      textAlign: "left",
      width: "125px",
      minWidth: "125px",
      maxWidth: "125px",
      whiteSpace: "nowrap",
      position: "sticky",
      left: "434px",
      backgroundColor: "#FFF",
    },
    tableCellSubHeaderMD: {
      textAlign: "left",
      width: "125px",
      minWidth: "125px",
      maxWidth: "125px",
      whiteSpace: "nowrap",
      position: "sticky",
      left: "559px",
      backgroundColor: "#f3f3f3",
    },
    tableWrapper: {
      // maxWidth: margin.width,
      maxHeight: "50em",
      overflowX: "scroll",
      "&::-webkit-scrollbar": {
        width: "10px",
      },
      "&::-webkit-scrollbar-track": {
        background: "white",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#cecece",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#b3b3b3",
      },
    },
    tableCellTargetA: {
      textAlign: "left",
      width: "80px",
      minWidth: "80px",
      maxWidth: "80px",
      whiteSpace: "nowrap",
      position: "sticky",
      left: "684px",
      backgroundColor: "#FFF",
    },
    healthSummaryHeader: {
      textAlign: "center",
      whiteSpace: "nowrap",
      padding: `${theme.spacing.unit}px`,
      fontSize: "12px",
      color: "#2f2f2f",
      fontWeight: "bold",
      "&:nth-child(even)": {
        backgroundColor: "#f3f3f3",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: theme.typography.fontSize + 2,
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.typography.fontSize + 2,
      },
    },
    hidden: {
      display: "none",
    },
    stickyRow: {
      position: "sticky",
      left: 0,
      top: "23px",
      zIndex: 3,
    },
  });

export type SpecialCustomTableProps = {
  query: Query;
  custom?: any;
  heading?: string;
} & WithStyles<typeof styles>;

const SpecialCustomTableUnstyled: React.FunctionComponent<SpecialCustomTableProps> = ({ classes, query, custom, heading }) => {
  const { getMeasures } = React.useContext(MeasuresContext);
  const { language } = React.useContext(LanguageContext);
  const targetCurrency = React.useContext(CurrencyContext);
  const { getBreakdown } = React.useContext(BreakdownContext);
  const dealer = React.useContext(CustomDealerContext);

  const getGuidanceStr = (metric: string) => {
    const currentMeasure = getMeasures().find(dbmeasure => dbmeasure.title == metric) || { description: "" };
    if (language != "en" && !!currentMeasure[`${language}_description`]) {
      return currentMeasure[`${language}_description`];
    }
    return currentMeasure.description;
  };
  const { breakdown, loading } = getBreakdown("custom", { ...query }, custom);

  if (loading.loading) {
    return <Loading />;
  }

  const ruleMeasures = ["Gross profit", "Revenue", "Expenses", "Annualised Sales", "Average Operational Assets", "ROS"];

  const rooa = {
    gp: breakdown["Gross profit"],
    revenue: breakdown["Revenue"],
    expenses: breakdown["Expenses"],
    projectedSales: breakdown["Annualised Sales"],
    assets: breakdown["Average Operational Assets"],
    pbt: breakdown["ROS"],
  };

  const measureRows: string[] = Object.keys(breakdown).filter((row: string) => !ruleMeasures.find(rule => rule == row));

  const month = (monthStr: string) => {
    return monthDict[parseInt(monthStr)];
  };

  const currentDate = new Date(query.date);
  const prevDate = add(currentDate, { years: -1 });

  const rooaSubHeaders = [
    `${month(currentDate.getMonth().toString())} ${currentDate
      .getFullYear()
      .toString()
      .slice(-2)}`,
    `${month(prevDate.getMonth().toString())} ${prevDate
      .getFullYear()
      .toString()
      .slice(-2)}`,
    "",
  ];
  const measures = Object.keys(breakdown).map(b => breakdown[b].actualTitle);
  const tableTitle = breakdown[Object.keys(breakdown)[0]].tableTitle;

  return (
    <div className={classes.root}>
      {heading}
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableGuidance} />
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)}>
              {tableTitle || "ROOA Drivers"} ({targetCurrency.targetCurrency ? targetCurrency.targetCurrency : dealer.customSelected.currency})
            </TableCell>
            {rooaHeaders(query).map((header: string, indx: number) => (
              <TableCell variant={"body"} key={header} className={classes.tableHeader}>
                {header}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableGuidance} />
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)} />
            {rooaSubHeaders.map((header: string, indx: number) => (
              <TableCell variant={"body"} key={header} className={classes.tableHeader}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {measureRows.map((measure: string) => {
            const guidanceHTML = getGuidanceStr(breakdown[measure].actualTitle);
            return measure == TableRows.Header ? (
              <TableRow>
                <TableCell className={classes.tableGuidance} />
                <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)}>
                  <span
                    style={
                      breakdown[measure].style
                        ? {
                            fontWeight: breakdown[measure].style.bold ? "bold" : "normal",
                            paddingLeft: 10 * (breakdown[measure].style.depth || 0),
                          }
                        : {}
                    }
                  >
                    {breakdown[measure].title}
                  </span>
                </TableCell>
                <TableCell className={classes.tableCell} />
                <TableCell className={classes.tableCell} />
                <TableCell className={classes.tableCell} />
              </TableRow>
            ) : measure == TableRows.Space ? (
              <TableRow>
                <TableCell className={classes.tableGuidance} />
                <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader, classes.ruleBold)} />
                <TableCell className={classes.tableCell} />
                <TableCell className={classes.tableCell} />
                <TableCell className={classes.tableCell} />
              </TableRow>
            ) : (
              <TableRow>
                <TableCell className={classes.tableGuidance}>
                  {guidanceHTML && <MetricGuidance guidanceHTML={guidanceHTML} name={breakdown[measure].title} />}
                </TableCell>
                <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)}>
                  <span
                    style={
                      breakdown[measure].style
                        ? {
                            fontWeight: breakdown[measure].style.bold ? "bold" : "normal",
                            paddingLeft: 10 * (breakdown[measure].style.depth || 0),
                          }
                        : {}
                    }
                  >
                    {breakdown[measure].title}
                  </span>
                </TableCell>
                <TableCell className={classnames(classes.tableCell)}>{breakdown[measure].actual.formatted}</TableCell>
                <TableCell className={classnames(classes.tableCell)}>{breakdown[measure].previous.formatted}</TableCell>
                <TableCell className={classnames(classes.tableCell, { [classes.red]: breakdown[measure].variance.value < 0 })}>
                  {breakdown[measure].variance.formatted}
                </TableCell>
              </TableRow>
            );
          })}

          {/* EMPTY SPACE */}
          <TableRow>
            <TableCell className={classes.tableGuidance} />
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader, classes.ruleBold)} />
            <TableCell className={classes.tableCell} />
            <TableCell className={classes.tableCell} />
            <TableCell className={classes.tableCell} />
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance} />
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader, classes.ruleBold)}>Rule 1_Grow GP faster than Revenue</TableCell>
            <TableCell className={classnames(classes.tableCell, rooa.gp.variance.value > rooa.revenue.variance.value ? classes.good : classes.bad)}>
              {rooa.gp.variance.value > rooa.revenue.variance.value ? "Yes" : "No"}
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{""}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{""}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance}>
              {getGuidanceStr(measures[0]) && <MetricGuidance guidanceHTML={getGuidanceStr(measures[0])} name={"Gross Profit"} />}
            </TableCell>
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)}>
              <span style={{ paddingLeft: 10 }}>Gross Profit</span>
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.gp.actual.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.gp.previous.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell, { [classes.red]: rooa.gp.variance.value < 0 })}>{rooa.gp.variance.formatted}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance}>
              {getGuidanceStr(measures[1]) && <MetricGuidance guidanceHTML={getGuidanceStr(measures[1])} name={"Revenue"} />}
            </TableCell>
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)}>
              <span style={{ paddingLeft: 10 }}>Revenue</span>
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.revenue.actual.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.revenue.previous.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell, { [classes.red]: rooa.revenue.variance.value < 0 })}>
              {rooa.revenue.variance.formatted}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance} />
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader, classes.ruleBold)}>Rule 2_Grow GP faster than Expenses</TableCell>
            <TableCell className={classnames(classes.tableCell, rooa.gp.variance.value > rooa.expenses.variance.value ? classes.good : classes.bad)}>
              {rooa.gp.variance.value > rooa.expenses.variance.value ? "Yes" : "No"}
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{""}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{""}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance}>
              {getGuidanceStr(measures[0]) && <MetricGuidance guidanceHTML={getGuidanceStr(measures[0])} name={"Gross Profit"} />}
            </TableCell>
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)}>
              <span style={{ paddingLeft: 10 }}>Gross Profit</span>
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.gp.actual.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.gp.previous.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell, { [classes.red]: rooa.gp.variance.value < 0 })}>{rooa.gp.variance.formatted}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance}>
              {getGuidanceStr(measures[2]) && <MetricGuidance guidanceHTML={getGuidanceStr(measures[2])} name={"Expenses"} />}
            </TableCell>
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)}>
              <span style={{ paddingLeft: 10 }}>Expenses</span>
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.expenses.actual.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.expenses.previous.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell, { [classes.red]: rooa.expenses.variance.value > 0 })}>
              {rooa.expenses.variance.formatted}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance} />
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader, classes.ruleBold)}>
              Rule 3_Grow Sales faster than Op.Assets
            </TableCell>
            <TableCell className={classnames(classes.tableCell, rooa.projectedSales.variance.value > rooa.assets.variance.value ? classes.good : classes.bad)}>
              {rooa.projectedSales.variance.value > rooa.assets.variance.value ? "Yes" : "No"}
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{""}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{""}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance}>
              {getGuidanceStr(measures[3]) && <MetricGuidance guidanceHTML={getGuidanceStr(measures[3])} name={"Annualised Sales"} />}
            </TableCell>
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)}>
              <span style={{ paddingLeft: 10 }}>Annualised Sales</span>
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.projectedSales.actual.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.projectedSales.previous.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell, { [classes.red]: rooa.projectedSales.variance.value < 0 })}>
              {rooa.projectedSales.variance.formatted}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance}>
              {getGuidanceStr(measures[4]) && <MetricGuidance guidanceHTML={getGuidanceStr(measures[4])} name={"Average Operational Assets"} />}
            </TableCell>
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)}>
              <span style={{ paddingLeft: 10 }}>Average Operational Assets</span>
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.assets.actual.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.assets.previous.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell, { [classes.red]: rooa.assets.variance.value < 0 })}>{rooa.assets.variance.formatted}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance} />
            <TableCell className={classnames(classes.tableCell, classes.ruleBold, classes.tableCellSubHeader)}>Rule 4_ROS must be positive</TableCell>
            <TableCell className={classnames(classes.tableCell, rooa.pbt.actual.value >= 0 ? classes.good : classes.bad)}>
              {rooa.pbt.actual.value >= 0 ? "Yes" : "No"}
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{""}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{""}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance}>
              {getGuidanceStr(measures[5]) && <MetricGuidance guidanceHTML={getGuidanceStr(measures[5])} name={"ROS"} />}
            </TableCell>
            <TableCell className={classnames(classes.tableCell, classes.tableCellSubHeader)}>
              <span style={{ paddingLeft: 10 }}>ROS</span>
            </TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.pbt.actual.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell)}>{rooa.pbt.previous.formatted}</TableCell>
            <TableCell className={classnames(classes.tableCell, { [classes.red]: rooa.pbt.variance.value < 0 })}>{rooa.pbt.variance.formatted}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.tableGuidance} />
            <TableCell className={classnames(classes.tableCell, classes.stickyRow, classes.note)} colSpan={4}>
              Note_MRA model has a multiplier effect, for the ROOA to improve you must at least have a positive ROS.
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export const SpecialCustomTable = withTheme()(withStyles(styles)(SpecialCustomTableUnstyled));
