import React, { createContext, useState, useReducer, useMemo } from "react";
import { Loading } from "./base";
import { Person, getPeople, createPerson, deletePerson, getPerson, updatePerson } from "../lib/api/people";

const defaultPerson = [];

export type PeopleContextState = {
  people: Person[];
  loading: Loading;
  personLoading: Loading;
  hydrate(): void;
  createPerson(person: Partial<Person>): void;
  deletePerson(id: number): void;
  getPeople(): Person[];
  getPerson(id: number): void;
  updatePerson(person: Partial<Person>): void;
};


export const PeopleContext = createContext<PeopleContextState>({
  people: defaultPerson,
  loading: { loading: false, loaded: false, error: null },
  personLoading: { loading: false, loaded: false, error: null },
  hydrate: () => {},
  createPerson: () => {},  
  deletePerson: () => {},
  getPeople: () => [],
  getPerson: () => {},
  updatePerson: () => {},
});


export const PeopleProvider = ({ children }) => {
  const [people, setPeople] = useState<Person[]>(defaultPerson);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const [person, setPerson] = useState<any>([]);
  const [personLoading, setPersonLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const hydrate = async () => {
    try {
      setLoading({
        loading: true,
        loaded: false,
        error: null,
      });
      const people = await getPeople();
      setPeople(people);
      setLoading({
        loading: false,
        loaded: true,
        error: null,
      });
    } catch (e) {
      setLoading({
        loading: false,
        loaded: false,
        error: e,
      });
    }
  }

  const value = useMemo(() => ({
    loading,
    hydrate,
    people,
    person,
    personLoading,
    getPeople() {
      if (loading.loaded && !loading.loading) {
        return people;
      }
      if (!loading.loading && !loading.error) {
        hydrate();
      }
      return [];
    },
    getPerson: async (id: number) => {
      try {
        setPersonLoading({
          loading: true,
          loaded: false,
          error: null,
        });
        const person = await getPerson(id);
        setPersonLoading({
          loading: false,
          loaded: true,
          error: null,
        });
        return person;
      } catch (e) {
        setPersonLoading({
          loading: false,
          loaded: false,
          error: e,
        });
      }
    },
    createPerson: async (person: Person) => {
      try {
        setLoading({
          loading: true,
          loaded: false,
          error: null,
        });
        const id = await createPerson(person);
        setLoading({
          loading: false,
          loaded: true,
          error: null,
        });
        hydrate();
        return id;
      } catch (e) {
        setLoading({
          loading: false,
          loaded: false,
          error: e,
        });
      }
    },
    updatePerson: async (person: Person) => {
      try {
        setLoading({
          loading: true,
          loaded: false,
          error: null,
        });
        await updatePerson(person);
        setLoading({
          loading: false,
          loaded: true,
          error: null,
        });
        hydrate();
      } catch (e) {
        setLoading({
          loading: false,
          loaded: false,
          error: e,
        });
      }
    },
    deletePerson: async (id: number) => {
      try {
        setLoading({
          loading: true,
          loaded: false,
          error: null,
        });
        await deletePerson(id);
        setLoading({
          loading: false,
          loaded: true,
          error: null,
        });
        hydrate();
      } catch (e) {
        setLoading({
          loading: false,
          loaded: false,
          error: e,
        });
      }
    },
    setPeople,
    setPerson,
  }), [people, person, personLoading, loading]);

  return (
    <PeopleContext.Provider value={value}>
      {children}
    </PeopleContext.Provider>
  )
}
