import React, { createContext, useReducer, useMemo } from "react";
import { GroupBreakdown, MetricReport, Query, stringifyQuery, customGroupBreakdownRowFor } from "../../lib/api/charts";
import { Loading } from "./../base";
import { CustomDashboardMeasures } from "../../charts/CustomDashReports";

type GroupBreakdownSet = { breakdown: GroupBreakdown; loading: Loading; custom: any };

export type CustomGroupBreakdownRowContextState = {
  breakdown: {
    [index: string]: GroupBreakdownSet;
  };
  getGroupBreakdownRow(report: MetricReport, query: Query, custom: any): Partial<GroupBreakdownSet>;
};

export const CustomGroupBreakdownRowContext = createContext<CustomGroupBreakdownRowContextState>({
  breakdown: {},
  getGroupBreakdownRow: () => ({}),
});

type QueryAction = {
  type: "PATCH_METRIC";
  id: string;
  data: Partial<GroupBreakdownSet>;
};

const defaultCustom: string = "Overall Dealership > REVENUE > Total Dealership Sales";

const defaultGroupBreakdown: GroupBreakdownSet = {
  breakdown: {},
  loading: { loaded: false, loading: true, error: null },
  custom: CustomDashboardMeasures[defaultCustom].getMeasure(defaultCustom, CustomDashboardMeasures),
};

export const CustomGroupBreakdownRowProvider = ({ children }) => {
  const memo = (report: MetricReport, query: Query, custom: any) => `${report}-${stringifyQuery(query)}-${JSON.stringify(custom)}`;

  const [breakdown, dispatchGroupBreakdown] = useReducer((state: { [index: string]: GroupBreakdownSet }, action: QueryAction) => {
    switch (action.type) {
      case "PATCH_METRIC":
        return {
          ...state,
          [action.id]: {
            ...state[action.id],
            ...action.data,
          },
        };
      default:
        return state;
    }
  }, {});

  const value = useMemo(
    () => ({
      breakdown,
      getGroupBreakdownRow: (report: MetricReport, query: Query, custom: any) => {
        const id = memo(report, query, custom);
        const set = breakdown[id];

        if (set) {
          return set;
        }

        dispatchGroupBreakdown({
          type: "PATCH_METRIC",
          id,
          data: defaultGroupBreakdown,
        });

        customGroupBreakdownRowFor(query, report, custom)
          .then(breakdown => {
            dispatchGroupBreakdown({
              type: "PATCH_METRIC",
              id,
              data: {
                breakdown: breakdown,
                custom: custom,
                loading: {
                  loaded: true,
                  loading: false,
                  error: null,
                },
              },
            });
          })
          .catch(e => {
            dispatchGroupBreakdown({
              type: "PATCH_METRIC",
              id,
              data: {
                loading: {
                  loaded: false,
                  loading: false,
                  error: e,
                },
              },
            });
          });

        return defaultGroupBreakdown;
      },
    }),
    [breakdown],
  );
  return <CustomGroupBreakdownRowContext.Provider value={value}>{children}</CustomGroupBreakdownRowContext.Provider>;
};
