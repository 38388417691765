import { request } from "./base";

type Period = "CYTD" | "FYTD";
type Brands = { value: string; label: string };

export type Meta = {
  has_dynamic: boolean;
  multiple_benchmarks: boolean;
  periods: Period[];
  brands: Brands[];
  exclude_consolidated: boolean;
  consolidated_dealers_only: boolean;
  include_consolidated: boolean;
  has_benchmarks: boolean;
  benchmarks: string[];
  show_period: boolean;
};

export const getAnalysisMeta = (dealer, datasetTitle: string): Promise<Meta> =>
  request(`/analysis-report-meta/${datasetTitle}`, { method: "POST", body: JSON.stringify({ dealers: dealer }) })
    .then(res => res.json())
    .then(({ data }) => data);

export const changePdfPassword = (password: string) =>
  request(`/pdf-export/update`, { method: "POST", body: JSON.stringify({ password }) })
    .then(res => res.json())
    .then(({ data }) => data);
