import React, { createContext, useState, useReducer, useMemo } from "react";
import { GroupBreakdown, MetricReport, Query, stringifyQuery, groupBreakdownFor } from "../lib/api/charts";
import { Loading } from "./base";

type GroupBreakdownSet = { breakdown: GroupBreakdown; loading: Loading };

export type GroupBreakdownContextState = {
  breakdown: {
    [index: string]: GroupBreakdownSet;
  };
  getGroupBreakdown(report: MetricReport, query: Query): Partial<GroupBreakdownSet>;
};

export const GroupBreakdownContext = createContext<GroupBreakdownContextState>({
  breakdown: {},
  getGroupBreakdown: () => ({}),
});

type QueryAction = {
  type: "PATCH_METRIC";
  id: string;
  data: Partial<GroupBreakdownSet>;
};

const defaultValue = {
  value: 0,
  formattedValue: "",
};

const defaultGroupBreakdown: GroupBreakdownSet = {
  breakdown: {},
  loading: { loaded: false, loading: true, error: null },
};

export const GroupBreakdownProvider = ({ children }) => {
  const memo = (report: MetricReport, query: Query) => `${report}-${stringifyQuery(query)}`;

  const [breakdown, dispatchGroupBreakdown] = useReducer((state: { [index: string]: GroupBreakdownSet }, action: QueryAction) => {
    switch (action.type) {
      case "PATCH_METRIC":
        return {
          ...state,
          [action.id]: {
            ...state[action.id],
            ...action.data,
          },
        };
      default:
        return state;
    }
  }, {});

  const value = useMemo(
    () => ({
      breakdown,
      getGroupBreakdown: (report: MetricReport, query: Query) => {
        const id = memo(report, query);
        const set = breakdown[id];

        if (set) {
          return set;
        }

        dispatchGroupBreakdown({
          type: "PATCH_METRIC",
          id,
          data: defaultGroupBreakdown,
        });

        groupBreakdownFor(query, report)
          .then(breakdown => {
            dispatchGroupBreakdown({
              type: "PATCH_METRIC",
              id,
              data: {
                breakdown: breakdown,
                loading: {
                  loaded: true,
                  loading: false,
                  error: null,
                },
              },
            });
          })
          .catch(e => {
            dispatchGroupBreakdown({
              type: "PATCH_METRIC",
              id,
              data: {
                loading: {
                  loaded: false,
                  loading: false,
                  error: e,
                },
              },
            });
          });

        return defaultGroupBreakdown;
      },
    }),
    [breakdown],
  );
  return <GroupBreakdownContext.Provider value={value}>{children}</GroupBreakdownContext.Provider>;
};
