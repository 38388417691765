import React, { createContext, useState, useReducer, useMemo } from "react";
import { Series, Report, Query, stringifyQuery, oemSeriesFor } from "../lib/api/charts";
import { Loading } from "./base";

type SeriesSet = { series: Series; loading: Loading };

export type ChartContextState = {
  series: {
    [index: string]: SeriesSet;
  };
  getSeries(report: Report, query: Query): Partial<SeriesSet>;
};

export const ChartContext = createContext<ChartContextState>({
  series: {},
  getSeries: () => ({}),
});

type QueryAction = {
  type: "PATCH_SERIES";
  id: string;
  data: Partial<SeriesSet>;
};

const defaultSeries: SeriesSet = {
  series: { name: "", rows: [], classification: "income", type: "currency", formats: { pos: "", neg: "" } },
  loading: { loaded: false, loading: true, error: null },
};

export const ChartProvider = ({ children }) => {
  const memo = (report: Report, query: Query) => `${report}-${stringifyQuery(query)}`;

  const [series, dispatchSeries] = useReducer((state: { [index: string]: SeriesSet }, action: QueryAction) => {
    switch (action.type) {
      case "PATCH_SERIES":
        return {
          ...state,
          [action.id]: {
            ...state[action.id],
            ...action.data,
          },
        };
      default:
        return state;
    }
  }, {});

  const value = useMemo(
    () => ({
      series,
      getSeries: (report: Report, query: Query) => {
        const id = memo(report, query);
        const set = series[id];

        if (set) {
          return set;
        }

        dispatchSeries({
          type: "PATCH_SERIES",
          id,
          data: defaultSeries,
        });

        oemSeriesFor(query, report)
          .then(series => {
            dispatchSeries({
              type: "PATCH_SERIES",
              id,
              data: {
                series: series,
                loading: {
                  loaded: true,
                  loading: false,
                  error: null,
                },
              },
            });
          })
          .catch(e => {
            dispatchSeries({
              type: "PATCH_SERIES",
              id,
              data: {
                loading: {
                  loaded: false,
                  loading: false,
                  error: e,
                },
              },
            });
          });

        return defaultSeries;
      },
    }),
    [series],
  );

  return <ChartContext.Provider value={value}>{children}</ChartContext.Provider>;
};
