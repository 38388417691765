import React, { createContext, useState, useMemo, useEffect } from "react";
import { formatFullDate, formatDisplayDate } from "../charts/GroupDashboardOuter";
import { subMonths } from "date-fns";

const defaultDate = new Date(2018, 11, 1);

const staticFinancialMonths: number[] = Array.from(Array(12).keys());

type Date = {
  value: string;
  label: string;
  maxMonth?: string;
};

export type GroupDateContextState = {
  date: any;
  financialMonth: number;
  financialMonthOptions: number[];
  updateDate(newDate: Date);
  setFinancialMonth: (month: number) => void;
};

export const GroupDateContext = createContext<GroupDateContextState>({
  date: defaultDate,
  financialMonth: 0,
  financialMonthOptions: staticFinancialMonths,
  updateDate: () => {},
  setFinancialMonth: () => {},
});

export const GroupDateContextProvider = ({ children }) => {
  const [date, setDate] = useState<Date>({ value: formatFullDate(defaultDate), label: formatDisplayDate(defaultDate), maxMonth: "" });
  const [financialMonth, setFinancialMonth] = useState<number>(0);
  const [financialMonthOptions, setFinancialMonthOptions] = useState<number[]>(staticFinancialMonths);

  useEffect(() => {
    setFinancialMonthOptions(staticFinancialMonths.map(number => subMonths(defaultDate, number).getMonth()));
  }, []);

  const value = useMemo(
    () => ({
      date,
      financialMonthOptions,
      financialMonth,
      setFinancialMonth,
      updateDate: async (newDate: Date) => {
        try {
          setDate(newDate);
          const newStaticMonths = staticFinancialMonths.map(number => subMonths(new Date(newDate.value), number).getMonth());
          setFinancialMonthOptions(newStaticMonths);
          // setFinancialMonth(newStaticMonths[0]);
          setFinancialMonth(newStaticMonths.filter((m) => m === financialMonth)[0]);
        } catch (e) {
          return e;
        }
      },
      setDate,
    }),
    [date, financialMonth, financialMonthOptions],
  );

  return <GroupDateContext.Provider value={value}>{children}</GroupDateContext.Provider>;
};
