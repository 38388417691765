import * as React from "react";
import classnames from "classnames";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MuiMenuItem from "@material-ui/core/MenuItem";
import Dashboard from "@material-ui/icons/Dashboard";
import FilterList from "@material-ui/icons/FilterList";
import Message from "@material-ui/icons/Message";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import User from "@material-ui/icons/AccountCircle";
import Settings from "@material-ui/icons/Settings";
import Checkbox from "@material-ui/icons/CheckBox";
import { FormattedMessage } from "react-intl";
import { MenuItemLabel } from "./Layout";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    menuItem: {
      width: "150px",
    },
    hideMenuItem: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "flex",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
      [theme.breakpoints.up("lg")]: {
        display: "flex",
      },
      [theme.breakpoints.up("xl")]: {
        display: "flex",
      },
    },
  });

export type MenuCompProps = {
  icon: string;
  link: string;
  name: string;
  mobile: boolean;
  visible: boolean;
} & React.HTMLAttributes<HTMLDivElement> &
  WithStyles<typeof styles>;

const icons = {
  dashboard: <Dashboard />,
  filteredList: <FilterList />,
  librarybooks: <LibraryBooks />,
  message: <Message />,
  user: <User />,
  settings: <Settings />,
  checkbox: <Checkbox />,
};

const MenuCompUnstlyed: React.SFC<MenuCompProps> = ({ classes, children, className, icon, link, name, mobile, visible }) => (
  <>
    {location.pathname.includes(link) && !/filters/.test(name.toLowerCase()) ? (
      <MuiMenuItem component="a" href={"/dashboard"} className={classes.menuItem}>
        <ListItemIcon>
          <Dashboard />
        </ListItemIcon>
        <MenuItemLabel classes={classes}>Dashboard</MenuItemLabel>
      </MuiMenuItem>
    ) : visible ? (
      <MuiMenuItem component="a" href={link} className={classnames(classes.menuItem, mobile ? "" : classes.hideMenuItem)}>
        <ListItemIcon>{icons[icon]}</ListItemIcon>
        <MenuItemLabel classes={classes}>
          <FormattedMessage id={name} />
        </MenuItemLabel>
      </MuiMenuItem>
    ) : null}
  </>
);

export const MenuComp = withStyles(styles)(MenuCompUnstlyed);
