import { NativeSelectProps } from "@material-ui/core/NativeSelect";
import React, { useEffect, useContext, useMemo } from "react";
import classnames from "classnames";
import { Select } from "./components/Select";
import { Dealer } from "./lib/api/charts";
import { BenchmarkContext } from "./contexts/BenchmarkContext";
import { Loading } from "./components/Loading";
import { DropdownDescription } from "./lib/enum/dropdown-description";

export type BenchmarkSelectionProps = {
  hidePeriods?: boolean;
  allowMultiple?: boolean;
  selectByTitlesOnly?: boolean;
  allowEmptyValue?: boolean;
  dealers: Dealer[];
  setBenchmark: any;
  benchmark: any;
  allowMany?: boolean;
};

export const BenchmarkSelection: React.FunctionComponent<NativeSelectProps & BenchmarkSelectionProps> = ({
  dealers,
  hidePeriods,
  allowMultiple = true,
  selectByTitlesOnly,
  allowEmptyValue,
  setBenchmark,
  benchmark,
  className,
  allowMany = false
}) => {
  const { loading } = useContext(BenchmarkContext);
  // const { setBenchmark, selected: benchmark, loading } = useContext(BenchmarkContext);

  const bms = dealers.reduce((bms, d) => {
    return d.benchmarks.reduce((bms, bm) => {
      bms[bm] = bm;
      return bms;
    }, bms);
  }, {});

  const limits = dealers.some(dealer => dealer.defaultBMLimits == true) || false;

  const benchmarks = Object.keys(bms).map(bm => {
    return selectByTitlesOnly
      ? {
          label: bm,
          value: bm,
          options: [{ value: bm, label: bm }],
        }
      : {
          label: bm,
          options: [
            { value: `${bm} - BM`, label: `Benchmark` },
            { value: `${bm} - MD`, label: `Median` },
            { value: `${bm} - AV`, label: `Average` },
            ...(limits ? [{ value: `${bm} - UL`, label: `Upper Limit` }, { value: `${bm} - LL`, label: `Lower Limit` }] : []),
          ],
        };
  });

  useEffect(() => {
    const availBm = benchmarks.reduce((arr, bm) => {
      return bm.options.reduce((arr, opt) => {
        arr.push(opt.value);
        return arr;
      }, arr);
    }, []);

    const benchmarksExist = benchmark.filter(bm => availBm.find(availableBm => availableBm === bm));
    if (benchmarksExist.length > 0) {
      setBenchmark(benchmarksExist as [string, string]);
    } else {
      if (benchmarks[0] && !allowMany) {
        setBenchmark(selectByTitlesOnly ? [benchmarks[0].value] : [benchmarks[0].options[0].value, benchmarks[0].options[1].value]);
      } else {
        setBenchmark(["", ""]);
      }
    }
  }, dealers.map(d => d.code));

  const availableBenchmarks = useMemo(() => {
    return benchmark.filter(bm => bm.length > 0);
  }, [benchmark, benchmarks]);

  if (!loading.loaded) return <Loading />;

  if (benchmarks.length <= 0) {
    return <></>;
  }

  return (
    <>
      <Select
        className={classnames(className, "joyride-filters-benchmark")}
        styles={{
          multiValueRemove: (base, state) => {
            return state.data.isFixed ? { ...base, display: "none" } : base;
          },
          groupHeading: base => {
            return {
              ...base,
              fontWeight: "bold",
              fontSize: 12,
              color: "#333",
            };
          },
          group: base => {
            return { ...base, paddingTop: 0, paddingBottom: "5px" };
          },
          option: base => {
            return {
              ...base,
              padding: "2px 12px",
            };
          },
        }}
        value={benchmark.length == 1 ? [{ value: benchmark[0], label: benchmark[0], isFixed: true }] : availableBenchmarks.map(b => ({ value: b, label: b }))}
        isClearable={false}
        onChange={value => {
          const newValue = allowMultiple ? value : [value];
          if (newValue.length == 1 || newValue.length == 2 || allowMany) {
            setBenchmark(newValue.map(v => v.value));
          }
        }}
        isMulti={allowMultiple}
        options={[
          { value: "disabled", label: DropdownDescription["BenchmarkSelect"], isdisabled: true },
          ...(allowEmptyValue
            ? [{ value: "No Benchmark", label: "No Benchmark", options: [{ value: "No Benchmark", label: "No Benchmark" }] }, ...benchmarks]
            : benchmarks),
        ]}
      />
    </>
  );
};
