import React, { createContext, useState, useMemo } from "react";
import { Loading } from "./base";
import {
  MediaFile,
  AWSFile,
  getAwsFiles,
  mediaFiles,
  createFiles,
  deleteFiles,
  deleteGroup,
  createMediaOems,
  MediaFileThumbnail,
  addThumbnail,
  UserGroups,
  getUserGroups,
  createUserGroups,
} from "../lib/api/media-files";

const defaultMediaFiles = [];

export type MediaFilesContextState = {
  files: MediaFile[];
  groupings: UserGroups[];
  awsFiles: AWSFile[];
  loading: Loading;
  hydrate(): void;
  createFile(file: Partial<MediaFile>, thumbnail?: any): void;
  deleteFile(id: number): void;
  deleteGrouping(groupName: string): void;
  getFiles(): MediaFile[];
  getAWSFile(): void;
  addThumbnail(file: Partial<MediaFileThumbnail>): void;
  getGrouping(): void;
  createGrouping(groupName: string, tabName: string): void;
};

export const MediaFilesContext = createContext<MediaFilesContextState>({
  files: defaultMediaFiles,
  groupings: defaultMediaFiles,
  awsFiles: defaultMediaFiles,
  loading: { loading: false, loaded: false, error: null },
  hydrate: () => {},
  createFile: () => {},
  deleteFile: () => {},
  deleteGrouping: () => {},
  getFiles: () => [],
  getAWSFile: () => [],
  addThumbnail: () => {},
  getGrouping: () => {},
  createGrouping: () => {},
});

export const MediaFilesProvider = ({ children }) => {
  const [files, setMediaFiles] = useState<MediaFile[]>(defaultMediaFiles);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const [groupings, setGroupings] = useState<UserGroups[]>(defaultMediaFiles);
  const [awsFiles, setAwsFiles] = useState<AWSFile[]>(defaultMediaFiles);

  const getAWSFile = async () =>{
    try {
      setLoading({
        loading: true,
        loaded: false,
        error: null,
      });
      const awsFile = await getAwsFiles();
      setAwsFiles(awsFile);
      setLoading({
        loading: false,
        loaded: true,
        error: null,
      });
    } catch (e) {
      setLoading({
        loading: false,
        loaded: false,
        error: e,
      });
    }
  };

  const hydrate = async () => {
    try {
      setLoading({
        loading: true,
        loaded: false,
        error: null,
      });
      const files = await mediaFiles();
      setMediaFiles(files);
      const groups = await getUserGroups();
      setGroupings(groups);
      setLoading({
        loading: false,
        loaded: true,
        error: null,
      });
    } catch (e) {
      setLoading({
        loading: false,
        loaded: false,
        error: e,
      });
    }
  };

  const value = useMemo(
    () => ({
      files,
      groupings,
      awsFiles,
      loading,
      hydrate,
      getAWSFile,
      getFiles() {
        if (loading.loaded && !loading.loading) {
          return files;
        }
        if (!loading.loading && !loading.error) {
          hydrate();
        }
        return [];
      },
      createFile: async (file: MediaFile, thumbnail: any) => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          const resp = await createFiles(file);
          if ((file.oemCodes || []).length > 0) {
            (file.oemCodes || []).map(async oemCode => {
              await createMediaOems({ id: Number(resp.id[0]), oemCode });
            });
          }
          if (thumbnail) {
            await addThumbnail({ mediaId: Number(resp.id[0]), file: thumbnail });
          }
          setLoading({
            loading: false,
            loaded: true,
            error: null,
          });
          hydrate();
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      deleteFile: async (id: number) => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          await deleteFiles(id);
          setLoading({
            loading: false,
            loaded: true,
            error: null,
          });
          hydrate();
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      deleteGrouping: async (groupName: string) => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          await deleteGroup(groupName);
          setLoading({
            loading: false,
            loaded: true,
            error: null,
          });
          hydrate();
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      setMediaFiles,
      addThumbnail: async (file: MediaFileThumbnail) => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          const resp = await addThumbnail(file);
          setLoading({
            loading: false,
            loaded: true,
            error: null,
          });
          hydrate();
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      getGrouping: async () => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          const group = await getUserGroups();
          setGroupings(group);
          setLoading({
            loading: false,
            loaded: true,
            error: null,
          });
          hydrate();
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      createGrouping: async (groupName: string, tabName: string) => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          await createUserGroups({ groupname: groupName, tabname: tabName });
          setLoading({
            loading: false,
            loaded: true,
            error: null,
          });
          hydrate();
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
    }),
    [files, loading, groupings],
  );

  return <MediaFilesContext.Provider value={value}>{children}</MediaFilesContext.Provider>;
};
