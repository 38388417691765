import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "es5-shim";
import "es6-shim";
import "react-app-polyfill/ie9";
import "formdata-polyfill";
import "./App.css";
import { appInsights } from "./lib//app-insights";

appInsights.loadAppInsights();

ReactDOM.render(<App />, document.getElementById("root") as HTMLElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

export default {};
