import React, { createContext, useState, useReducer, useMemo } from "react";
import { Loading } from "./base";
import { DBMeasure, measures as getMeasures } from "../lib/api/measures";

const defaultMeasures = [];

export type MeasuresContextState = {
  measures: DBMeasure[];
  loading: Loading;
  hydrate();
  getMeasures(): DBMeasure[];
};


export const MeasuresContext = createContext<MeasuresContextState>({
  measures: defaultMeasures,
  loading: { loading: false, loaded: false, error: null },
  hydrate: () => {},
  getMeasures: () => [],
});


export const MeasuresProvider = ({ children }) => {
  const [measures, setMeasures] = useState<DBMeasure[]>(defaultMeasures);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const hydrate = async () => {
    try {
      setLoading({
        loading: true,
        loaded: false,
        error: null,
      });
      const measures = await getMeasures();
      setMeasures(measures);
      setLoading({
        loading: false,
        loaded: true,
        error: null,
      });
    } catch (e) {
      setLoading({
        loading: false,
        loaded: false,
        error: e,
      });
    }
  }

  const value = useMemo(() => ({
    measures,
    loading,
    hydrate,
    getMeasures() {
      if (loading.loaded && !loading.loading) {
        return measures;
      }
      if (!loading.loading && !loading.error) {
        hydrate();
      }
      return [];
    },
    setMeasures,
  }), [measures, loading])

  return (
    <MeasuresContext.Provider value={value}>
      {children}
    </MeasuresContext.Provider>
  )
}
