import { request, postData, voidTransformCallback } from "./base";
import { Report } from "./charts";
// These are the defaults for the varias dashboards, change here to set new defaults.
export const defaultDashboardConfig: DashboardsConfig = {
  Home: {
    dashboard: "Home",
    items: {
      chart: { measure: "ros" },
    },
  },
  Oem: {
    dashboard: "Oem",
    items: {
      tr: { measure: "loss" },
      br: { measure: "ros" },
    },
  },
  OemFoaSnapshot: {
    dashboard: "Oem FoA Snapshot",
    items: {
      tr: { measure: "ros" },
      br: { measure: "foa" },
    },
  },
  OemNvSales: {
    dashboard: "Oem FoA Sales",
    items: {
      tr: { measure: "nvgp" },
      br: { measure: "totalunits" },
    },
  },
  Exec: {
    dashboard: "Exec",
    items: {
      tr: { measure: "revenue" },
      br: { measure: "ros" },
    },
  },
  New: {
    dashboard: "New",
    items: {
      tr: { measure: "totalunits" },
      br: { measure: "mix" },
    },
  },
  Used: {
    dashboard: "Used",
    items: {
      tr: { measure: "totalunits" },
      br: { measure: "mix" },
    },
  },
  Parts: {
    dashboard: "Parts",
    items: {
      tr: { measure: "revenue" },
      br: { measure: "mix" },
    },
  },
  Service: {
    dashboard: "Service",
    items: {
      tr: { measure: "revenue" },
      br: { measure: "mix" },
    },
  },
  "Body Shop": {
    dashboard: "Body Shop",
    items: {
      tr: { measure: "revenue" },
      br: { measure: "mix" },
    },
  },
};

export type DashboardsConfig = {
  [index: string]: DashboardConfig;
};

export type DashboardConfig = {
  dashboard: string;
  items: {
    [index: string]: VizConfig;
  };
};

export type LanguageConfig = {
  value: string;
};

export type User = {
  session: string;
  token: string;
  email: string;
  firstname: string;
  usertype: string;
  lastname: string;
  address: string;
  phone: string;
  id: string;
  file?: any;
  profilepicture?: { type: Buffer; data: [] };
  brands: string[];
  reloadAccess: boolean;
  modules: {
    test?: string;
    login?: string;
    menu?: string;
    editUsers?: string;
    editDealers?: string;
    editDistributors?: string;
    editDealerGroups?: string;
    dealerMapping?: string;
    accounts?: string;
    trendReport?: string;
    showRatioNote?: string;
    batchReporting?: string;
    uploadData?: string;
    maintainRatios?: string;
    maintainQualityGates?: string;
    editBenchmark?: string;
    runBenchmark?: string;
    editReport?: string;
    editDealerSet?: string;
    dataReload?: string;
    whatIf?: string;
    report?: string;
    customReport?: string;
    log?: string;
    tableEdit?: string;
    sqlStatement?: string;
    editProperties?: string;
    masterData?: string;
    uploadSchedule?: string;
    iframe?: string;
    viewEsos1Admin?: string;
    viewEsos1Marketsharedata?: string;
    cube_dashboards?: string;
    inputTemplates?: string;
    conversion?: string;
    cube_utils_currency_matrix?: string;
    editRoles?: string;
    marketshare?: string;
    systemadmin?: string;
    batchserverthresholds?: string;
    batchserver?: string;
    audit?: string;
    optionsProperties?: string;
    olapproperties?: string;
    cubeMeasures?: string;
    cubeBaseMeasures?: string;
    cubeBenches?: string;
    cubeReports?: string;
    olapDashboard?: string;
    cubeDatasets?: string;
    cubeSenarioplanner?: string;
    cubeForecasts?: string;
    cubeAccounts?: string;
    marketshares?: string; //very confusing, there is a marketshare and marketshares. Know the difference.?
  };
  languageAccess: LanguageAccess[];
};

type LanguageAccess = {
  locale: string;
};

export const whoami = (): Promise<User> => request("/whoami").then(res => res.json());
export const removeUserImg = (id: number) => postData(`/whoami/remove/profile-picture`, { id }, undefined, voidTransformCallback);

export const updateUser = async (user: Partial<User>) => {
  const formData = new FormData();

  Object.keys(user).forEach(key => {
    formData.append(key, user[key]);
  });

  return request("/whoami", {
    method: "PUT",
    body: formData,
    headers: new Headers({
      Accept: "application/json",
      "X-csrf-validation": "-",
    }),
  }).then(res => res.json());
};

export type VizConfig = any;

export const userConfig = () => request("/user/config").then(res => res.json());

export const updateUserConfig = (config: DashboardsConfig) =>
  request("/user/config", {
    method: "PUT",
    body: JSON.stringify(config),
  }).then(res => res.json());

export const updateLanguageConfig = (config: LanguageConfig) =>
  request("/user/config/language", {
    method: "PUT",
    body: JSON.stringify(config),
  }).then(res => res.json());

export const getDefaultLocale = (): Promise<{ language: string }> =>
  request("/user/default/locale", {
    method: "GET",
  }).then(res => res.json());
