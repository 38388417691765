import React, { useEffect, useContext } from "react";
import { DealerContext } from "./contexts/DealerContext";
import { CriteriaContext } from "./contexts/CriteriaContext";
import { DashboardConfigContext } from "./contexts/DashboardConfigContext";
import { OemTabsContext } from "./contexts/OemTabsContext";
import { BrandContext } from "./contexts/BrandContext";
import { ForecastContext } from "./contexts/ForecastContext";
import { OemContext } from "./contexts/OemContext";
import { UserContext } from "./contexts/UserContext";
import { Loading } from "./contexts/base";
import { MeasuresContext } from "./contexts/MeasuresContext";
import { CountryContext } from "./contexts/CountryContext";
import { BenchmarkContext } from "./contexts/BenchmarkContext";
import { TabAccessContext } from "./contexts/TabAccessContext";
import { FlashReportsConfigContext } from "./contexts/FlashReportsConfigContext";
import { LanguageContext } from "./contexts/LanguageContext";
import { CustomDashboardDepartmentsContext } from "./contexts/Custom/CustomDashboardDepartmentsContext";
import { ReportFolderContext } from "./contexts/ReportFolderContext";

type Hydratable = {
  loading: Loading;
  hydrate();
};

const shouldLoad = (loading: Loading) => {
  return !loading.loading && !loading.loaded && !loading.error;
};

export const hydrateAll = (items: Hydratable[], userLoading: Loading) => {
  return useEffect(() => {
    if (userLoading.loaded) {
      items.forEach(item => {
        if (shouldLoad(item.loading)) {
          item.hydrate();
        }
      });
    }
  }, [userLoading, items, ...items.map(item => item.loading)]);
};

export const HydrateApp = () => {
  const { loading: userLoading } = useContext(UserContext);
  const { hydrate: hydrateLanguageContext } = useContext(LanguageContext);

  hydrateAll(
    [
      useContext(DealerContext),
      useContext(CriteriaContext),
      useContext(DashboardConfigContext),
      useContext(OemTabsContext),
      useContext(BrandContext),
      useContext(BenchmarkContext),
      useContext(MeasuresContext),
      useContext(ForecastContext),
      useContext(OemContext),
      useContext(CountryContext),
      useContext(TabAccessContext),
      useContext(FlashReportsConfigContext),
      useContext(CustomDashboardDepartmentsContext),
      useContext(ReportFolderContext),
    ],
    userLoading,
  );

  useEffect(() => {
    hydrateLanguageContext();
  }, []);
  return null;
};

export const HydrateUser = () => {
  const { hydrate, loading } = useContext(UserContext);
  useEffect(() => {
    if (shouldLoad(loading)) {
      hydrate();
    }
  }, [loading, hydrate]);
  return null;
};
