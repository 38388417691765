import { request } from "./base";

export type OemTabs = {
  "Dashboard/Executive Summary": string[];
  "Dashboard/New": string[];
  "Dashboard/Used": string[];
  "Dashboard/Parts": string[];
  "Dashboard/Service": string[];
  "Dashboard/Body Shop": string[];
  "Dashboard/Custom": string[];
  "Reports/OEM Reports": string[];
  "Reports/Other BM Tools": string[];
  "Reports/Performance Analysis": string[];
  "Reports/Standard Reports": string[];
  "Submission/Deadlines": string[];
  "Submission/Download Templates": string[];
  "Submission/Submit Data": string[];
  "Insights/Interpretation": string[];
  "Insights/Data Preparation": string[];
  "Insights/BM Process": string[];
  "Insights/Industry Insights": string[];
};

export const oemTabs = (): Promise<OemTabs> =>
  request("/oem-tabs")
    .then(res => res.json())
    .then(({ data }) => data);
