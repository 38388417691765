import React, { createContext, useState, useMemo, FC, useContext } from "react";
import { Dealer } from "../lib/api/charts";
import { Loading } from "./base";
import { BenchmarkContext } from "./BenchmarkContext";
import { DateContext } from "./FilterDateContext";
import { formatFullDate, formatDisplayDate } from "../charts/DashboardOuter";
import { Filters, FilterSet, DealerCode, DealerName, defaultDealer } from "./DealerContext";

export type NetworkAnalysisDealerContextState = {
  loading: Loading;
  networkAnalysisSelected: Dealer;
  networkAnalysisFilters: Filters;
  networkAnalysisFilterSet: FilterSet;
  networkAnalysisDealerCode: DealerCode;
  networkAnalysisDealerName: DealerName;
  setNetworkAnalysisDealerCode(dealerCode: DealerCode): void;
  setNetworkAnalysisDealerName(dealerName: DealerName): void;
  setNetworkAnalysisDealer(dealer: Dealer): void;
  setNetworkAnalysisFilters(obj: Filters): void;
  setNetworkAnalysisFilterSet(obj: FilterSet): void;
};

export const NetworkAnalysisDealerContext = createContext<NetworkAnalysisDealerContextState>({
  loading: { loading: false, loaded: false, error: null },
  networkAnalysisSelected: defaultDealer,
  networkAnalysisFilters: { oem: [], region: [], dealerGroup: [], dealerSet: [], country: [], excludeNewDealers: false },
  networkAnalysisFilterSet: {
    dealerCode: "",
    dealerName: "",
    filters: { oem: [], region: [], dealerGroup: [], dealerSet: [], country: [], excludeNewDealers: false },
  },
  networkAnalysisDealerCode: { value: "" },
  networkAnalysisDealerName: { value: "" },
  setNetworkAnalysisDealerCode: () => {},
  setNetworkAnalysisDealerName: () => {},
  setNetworkAnalysisDealer: () => {},
  setNetworkAnalysisFilters: () => {},
  setNetworkAnalysisFilterSet: () => {},
});

export const NetworkAnalysisDealerProviderInner: FC = ({ children }) => {
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const { updateDate } = useContext(DateContext);
  const { setNetworkAnalysisBenchmark: setBenchmark, networkAnalysisSelected: selected } = useContext(BenchmarkContext);

  const [networkAnalysisDealer, xsetNetworkAnalysisDealer] = useState<Dealer>(defaultDealer);
  const setNetworkAnalysisDealer = (dealer?: Dealer) => {
    xsetNetworkAnalysisDealer(dealer || defaultDealer);
  };
  const [networkAnalysisDealerCode, setNetworkAnalysisDealerCodeValue] = React.useState({ value: "" });
  const [networkAnalysisDealerName, setNetworkAnalysisDealerNameValue] = React.useState({ value: "" });
  const [networkAnalysisFilters, setNetworkAnalysisFiltersValue] = React.useState({
    oem: [],
    region: [],
    dealerGroup: [],
    dealerSet: [],
    country: [],
    excludeNewDealers: false,
  });
  const [networkAnalysisFilterSet, setNetworkAnalysisFilterSetValue] = React.useState({
    dealerCode: "",
    dealerName: "",
    filters: {
      oem: [],
      region: [],
      dealerGroup: [],
      dealerSet: [],
      country: [],
      excludeNewDealers: false,
    },
  });

  const value = useMemo(
    () => ({
      networkAnalysisSelected: {
        ...networkAnalysisDealer,
        minMonth: new Date(networkAnalysisDealer.minMonth as any),
        maxMonth: new Date(networkAnalysisDealer.maxMonth as any),
      },
      networkAnalysisDealerCode,
      networkAnalysisDealerName,
      networkAnalysisFilters,
      networkAnalysisFilterSet,
      loading,
      setNetworkAnalysisDealer: async dealer => {
        if (!loading.loading) {
          try {
            setLoading({
              loading: true,
              loaded: false,
              error: null,
            });
            if (dealer.benchmarks.length <= 0) {
              await setBenchmark(["", ""]);
            } else {
              if (selected && selected.filter(bm => bm.length > 0).length > 0) {
                await setBenchmark(selected as [string, string]);
              } else {
                await setBenchmark([dealer.benchmarks[0], `${dealer.benchmarks[0]} - Median`]);
              }
            }
            setNetworkAnalysisDealer(dealer);
            if (!!dealer.dealer && dealer.maxMonth) {
              const d = new Date(Date.parse(dealer.maxMonth.toString()));
              updateDate({
                value: formatFullDate(d),
                label: formatDisplayDate(d),
              });
            }
            setLoading({
              loading: false,
              loaded: true,
              error: null,
            });
          } catch (e) {
            setLoading({
              loading: false,
              loaded: false,
              error: e,
            });
          }
          return;
        } else {
          if (dealer.benchmarks.length <= 0) {
            await setBenchmark(["", ""]);
          } else {
            await setBenchmark([dealer.benchmarks[0], `${dealer.benchmarks[0]} - Median`]);
          }
          setNetworkAnalysisDealer(dealer);
          if (dealer.maxMonth) {
            const d = new Date(Date.parse(dealer.maxMonth.toString()));
            updateDate({
              value: formatFullDate(d),
              label: formatDisplayDate(d),
            });
          }
        }
      },
      setNetworkAnalysisDealerCode: obj => {
        setNetworkAnalysisDealerCodeValue(obj);
      },
      setNetworkAnalysisDealerName: obj => {
        setNetworkAnalysisDealerNameValue(obj);
      },
      setNetworkAnalysisFilters: obj => {
        setNetworkAnalysisFiltersValue(obj);
      },
      setNetworkAnalysisFilterSet: obj => {
        setNetworkAnalysisFilterSetValue(obj);
      },
      setNetworkAnalysisDealerCodeValue,
      setNetworkAnalysisDealerNameValue,
      setNetworkAnalysisFiltersValue,
      setNetworkAnalysisFilterSetValue,
    }),
    [
      networkAnalysisDealer,
      networkAnalysisDealerCode,
      networkAnalysisDealerName,
      networkAnalysisFilters,
      networkAnalysisFilterSet,
      xsetNetworkAnalysisDealer,
      setNetworkAnalysisDealerCodeValue,
      setNetworkAnalysisDealerNameValue,
      setNetworkAnalysisFiltersValue,
      setNetworkAnalysisFilterSetValue,
      setNetworkAnalysisDealer,
    ],
  );

  return <NetworkAnalysisDealerContext.Provider value={value}>{children}</NetworkAnalysisDealerContext.Provider>;
};

export const NetworkAnalysisDealerProvider = NetworkAnalysisDealerProviderInner;
