import { CustomDashboardType, getMeasure } from "../CustomDashReports";

export const FordAcademyKPIs: { [index: string]: CustomDashboardType } = {
  "Ford Academy > New > Sales CX_CVP Sales": {
    name: "Ford Academy > New > Sales CX_CVP Sales",
    department: "Ford Academy",
    category: "New",
    label: "Sales CX_CVP Sales",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > FORD PFP - CVP - Sales | Admin | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales CX_Customer Experience Survey Returns Unit": {
    name: "Ford Academy > New > Sales CX_Customer Experience Survey Returns Unit",
    department: "Ford Academy",
    category: "New",
    label: "Sales CX_Customer Experience Survey Returns Unit",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Survey Returns Unit | New | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales CX_Customer Experience Dealer Recommend Score": {
    name: "Ford Academy > New > Sales CX_Customer Experience Dealer Recommend Score",
    department: "Ford Academy",
    category: "New",
    label: "Sales CX_Customer Experience Dealer Recommend Score",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Dealer Recommend Score | New | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales CX_Customer Experience Dealer Recommend Base Unit": {
    name: "Ford Academy > New > Sales CX_Customer Experience Dealer Recommend Base Unit",
    department: "Ford Academy",
    category: "New",
    label: "Sales CX_Customer Experience Dealer Recommend Base Unit",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Customer Experience > Dealer Recommend Base Unit | New | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales CX_Customer Experience Brand Recommend Score": {
    name: "Ford Academy > New > Sales CX_Customer Experience Brand Recommend Score",
    department: "Ford Academy",
    category: "New",
    label: "Sales CX_Customer Experience Brand Recommend Score",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Brand Recommend Score | New | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales CX_Customer Experience Brand Recommend Base Unit": {
    name: "Ford Academy > New > Sales CX_Customer Experience Brand Recommend Base Unit",
    department: "Ford Academy",
    category: "New",
    label: "Sales CX_Customer Experience Brand Recommend Base Unit",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Customer Experience > Brand Recommend Base Unit | New | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales CX_Customer Experience Sales experience easy for you Score": {
    name: "Ford Academy > New > Sales CX_Customer Experience Sales experience easy for you Score",
    department: "Ford Academy",
    category: "New",
    label: "Sales CX_Customer Experience Sales experience easy for you Score",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Customer Experience > Sales experience easy for you Score | New | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales CX_Customer Experience Sales experience easy for you Base Unit": {
    name: "Ford Academy > New > Sales CX_Customer Experience Sales experience easy for you Base Unit",
    department: "Ford Academy",
    category: "New",
    label: "Sales CX_Customer Experience Sales experience easy for you Base Unit",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Customer Experience > Sales experience easy for you Base Unit | New | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales CX_Customer Experience Initial impression of dealership Score": {
    name: "Ford Academy > New > Sales CX_Customer Experience Initial impression of dealership Score",
    department: "Ford Academy",
    category: "New",
    label: "Sales CX_Customer Experience Initial impression of dealership Score",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Customer Experience > Initial impression of dealership Score | New | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales CX_Customer Experience Initial impression of dealership Base Unit": {
    name: "Ford Academy > New > Sales CX_Customer Experience Initial impression of dealership Base Unit",
    department: "Ford Academy",
    category: "New",
    label: "Sales CX_Customer Experience Initial impression of dealership Base Unit",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Customer Experience > Initial impression of dealership Base Unit | New | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales L1_CVP Delivery Process": {
    name: "Ford Academy > New > Sales L1_CVP Delivery Process",
    department: "Ford Academy",
    category: "New",
    label: "Sales L1_CVP Delivery Process",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > FORD PFP - CVP - Delivery Process | Admin | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales L1_Sales Process Overall delivery process Base Unit": {
    name: "Ford Academy > New > Sales L1_Sales Process Overall delivery process Base Unit",
    department: "Ford Academy",
    category: "New",
    label: "Sales L1_Sales Process Overall delivery process Base Unit",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Process > Overall delivery process Base Unit | New | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales L1_Sales Process Given level of attention wanted Score": {
    name: "Ford Academy > New > Sales L1_Sales Process Given level of attention wanted Score",
    department: "Ford Academy",
    category: "New",
    label: "Sales L1_Sales Process Given level of attention wanted Score",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Process > Given level of attention wanted Score | New | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales L1_Sales Process Given level of attention wanted Base Unit": {
    name: "Ford Academy > New > Sales L1_Sales Process Given level of attention wanted Base Unit",
    department: "Ford Academy",
    category: "New",
    label: "Sales L1_Sales Process Given level of attention wanted Base Unit",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Process > Given level of attention wanted Base Unit | New | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales Diagnostic_Appearance and Cleanliness Rate": {
    name: "Ford Academy > New > Sales Diagnostic_Appearance and Cleanliness Rate",
    department: "Ford Academy",
    category: "New",
    label: "Sales Diagnostic_Appearance and Cleanliness Rate",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Diagnostics > Appearance and Cleanliness Rate | New | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales Diagnostic_Appearance and Cleanliness Base Unit": {
    name: "Ford Academy > New > Sales Diagnostic_Appearance and Cleanliness Base Unit",
    department: "Ford Academy",
    category: "New",
    label: "Sales Diagnostic_Appearance and Cleanliness Base Unit",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Diagnostics > Appearance and Cleanliness Base Unit | New | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales Diagnostic_Comfort of waiting area and amentities Rate": {
    name: "Ford Academy > New > Sales Diagnostic_Comfort of waiting area and amentities Rate",
    department: "Ford Academy",
    category: "New",
    label: "Sales Diagnostic_Comfort of waiting area and amentities Rate",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Diagnostics > Comfort of waiting area and amentities Rate | New | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales Diagnostic_Comfort of waiting area and amentities Base Unit": {
    name: "Ford Academy > New > Sales Diagnostic_Comfort of waiting area and amentities Base Unit",
    department: "Ford Academy",
    category: "New",
    label: "Sales Diagnostic_Comfort of waiting area and amentities Base Unit",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Diagnostics > Comfort of waiting area and amentities Base Unit | New | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales Diagnostic_Greeting and acknowledgment Rate": {
    name: "Ford Academy > New > Sales Diagnostic_Greeting and acknowledgment Rate",
    department: "Ford Academy",
    category: "New",
    label: "Sales Diagnostic_Greeting and acknowledgment Rate",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Diagnostics > Greeting and acknowledgment Rate | New | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales Diagnostic_Greeting and acknowledgment Base Unit": {
    name: "Ford Academy > New > Sales Diagnostic_Greeting and acknowledgment Base Unit",
    department: "Ford Academy",
    category: "New",
    label: "Sales Diagnostic_Greeting and acknowledgment Base Unit",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Diagnostics > Greeting and acknowledgment Base Unit | New | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales Diagnostic_Vehicle presentation Rate": {
    name: "Ford Academy > New > Sales Diagnostic_Vehicle presentation Rate",
    department: "Ford Academy",
    category: "New",
    label: "Sales Diagnostic_Vehicle presentation Rate",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Diagnostics > Vehicle presentation Rate | New | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales Diagnostic_Vehicle presentation Base Unit": {
    name: "Ford Academy > New > Sales Diagnostic_Vehicle presentation Base Unit",
    department: "Ford Academy",
    category: "New",
    label: "Sales Diagnostic_Vehicle presentation Base Unit",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Diagnostics  > Vehicle presentation Base Unit | New | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > No. of FGE implementations - Sales": {
    name: "Ford Academy > New > No. of FGE implementations - Sales",
    department: "Ford Academy",
    category: "New",
    label: "No. of FGE implementations - Sales",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > FORD PFP - No. of FGE implementations - Sales | Admin | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Closing Ratio": {
    name: "Ford Academy > New > Closing Ratio",
    department: "Ford Academy",
    category: "New",
    label: "Closing Ratio",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > FORD PFP - Closing Ratio | Admin | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Training_Sales Consultant % of Dealer Staff Attrition": {
    name: "Ford Academy > New > Training_Sales Consultant % of Dealer Staff Attrition",
    department: "Ford Academy",
    category: "New",
    label: "Training_Sales Consultant % of Dealer Staff Attrition",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > FORD Training - Sales Consultant % of Dealer Staff Attrition | Admin | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Training_Sales Manager % of Dealer Staff Attrition": {
    name: "Ford Academy > New > Training_Sales Manager % of Dealer Staff Attrition",
    department: "Ford Academy",
    category: "New",
    label: "Training_Sales Manager % of Dealer Staff Attrition",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > FORD Training - Sales Manager % of Dealer Staff Attrition | Admin | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Training_Day Sales -YTD (Actual)": {
    name: "Ford Academy > New > Training_Day Sales -YTD (Actual)",
    department: "Ford Academy",
    category: "New",
    label: "Training_Day Sales -YTD (Actual)",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > FORD Training - Training Day Sales-YTD Actual | Admin | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > INV & QC Sales_Test Drive Achievement MTD %": {
    name: "Ford Academy > New > INV & QC Sales_Test Drive Achievement MTD %",
    department: "Ford Academy",
    category: "New",
    label: "INV & QC Sales_Test Drive Achievement MTD %",
    tableNumber: 202,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > FORD INV & QC - Test Drive Achievement MTD % | New | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales Consultant Competence mix": {
    name: "Ford Academy > New > Sales Consultant Competence mix",
    department: "Ford Academy",
    category: "New",
    label: "Sales Consultant Competence mix",
    tableNumber: 205,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Consultant Competence mix | New Retail | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales Consultant Competence mix % (Uncertified)": {
    name: "Ford Academy > New > Sales Consultant Competence mix % (Uncertified)",
    department: "Ford Academy",
    category: "New",
    label: "Sales Consultant Competence mix % (Uncertified)",
    tableNumber: 205,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Consultant Competence mix % Uncertified | New Retail | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales Consultant Competence mix % (Induction)": {
    name: "Ford Academy > New > Sales Consultant Competence mix % (Induction)",
    department: "Ford Academy",
    category: "New",
    label: "Sales Consultant Competence mix % (Induction)",
    tableNumber: 205,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Consultant Competence mix % Induction | New Retail | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales Consultant Competence mix % (Silver)": {
    name: "Ford Academy > New > Sales Consultant Competence mix % (Silver)",
    department: "Ford Academy",
    category: "New",
    label: "Sales Consultant Competence mix % (Silver)",
    tableNumber: 205,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Consultant Competence mix % Silver | New Retail | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales Consultant Competence mix % (Gold)": {
    name: "Ford Academy > New > Sales Consultant Competence mix % (Gold)",
    department: "Ford Academy",
    category: "New",
    label: "Sales Consultant Competence mix % (Gold)",
    tableNumber: 205,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Consultant Competence mix % Gold | New Retail | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales Consultant Competence mix % (Platinum)": {
    name: "Ford Academy > New > Sales Consultant Competence mix % (Platinum)",
    department: "Ford Academy",
    category: "New",
    label: "Sales Consultant Competence mix % (Platinum)",
    tableNumber: 205,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Consultant Competence mix % Platinum | New Retail | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales Manager Competence mix": {
    name: "Ford Academy > New > Sales Manager Competence mix",
    department: "Ford Academy",
    category: "New",
    label: "Sales Manager Competence mix",
    tableNumber: 206,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Manager Competence mix | New Retail | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales Manager Competence mix % (Uncertified)": {
    name: "Ford Academy > New > Sales Manager Competence mix % (Uncertified)",
    department: "Ford Academy",
    category: "New",
    label: "Sales Manager Competence mix % (Uncertified)",
    tableNumber: 206,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Manager Competence mix % Uncertified | New Retail | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales Manager Competence mix % (Induction)": {
    name: "Ford Academy > New > Sales Manager Competence mix % (Induction)",
    department: "Ford Academy",
    category: "New",
    label: "Sales Manager Competence mix % (Induction)",
    tableNumber: 206,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Manager Competence mix % Induction | New Retail | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales Manager Competence mix % (Silver)": {
    name: "Ford Academy > New > Sales Manager Competence mix % (Silver)",
    department: "Ford Academy",
    category: "New",
    label: "Sales Manager Competence mix % (Silver)",
    tableNumber: 206,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Manager Competence mix % Silver | New Retail | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales Manager Competence mix % (Gold)": {
    name: "Ford Academy > New > Sales Manager Competence mix % (Gold)",
    department: "Ford Academy",
    category: "New",
    label: "Sales Manager Competence mix % (Gold)",
    tableNumber: 206,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Manager Competence mix % Gold | New Retail | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > New > Sales Manager Competence mix % (Platinum)": {
    name: "Ford Academy > New > Sales Manager Competence mix % (Platinum)",
    department: "Ford Academy",
    category: "New",
    label: "Sales Manager Competence mix % (Platinum)",
    tableNumber: 206,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Sales Manager Competence mix % Platinum | New Retail | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service CX_CVP Service": {
    name: "Ford Academy > Service > Service CX_CVP Service",
    department: "Ford Academy",
    category: "Service",
    label: "Service CX_CVP Service",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > FORD PFP - CVP - Service | Admin | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service CX_Customer Satisfaction Survey Returns Unit": {
    name: "Ford Academy > Service > Service CX_Customer Satisfaction Survey Returns Unit",
    department: "Ford Academy",
    category: "Service",
    label: "Service CX_Customer Satisfaction Survey Returns Unit",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Survey Returns Unit | Service | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service CX_Customer Satisfaction Dealer Recommend Score": {
    name: "Ford Academy > Service > Service CX_Customer Satisfaction Dealer Recommend Score",
    department: "Ford Academy",
    category: "Service",
    label: "Service CX_Customer Satisfaction Dealer Recommend Score",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Dealer Recommend Score | Service | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service CX_Customer Satisfaction Dealer Recommend Base Unit": {
    name: "Ford Academy > Service > Service CX_Customer Satisfaction Dealer Recommend Base Unit",
    department: "Ford Academy",
    category: "Service",
    label: "Service CX_Customer Satisfaction Dealer Recommend Base Unit",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Customer Experience > Dealer Recommend Base Unit | Service | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service CX_Customer Satisfaction Brand Recommend Score": {
    name: "Ford Academy > Service > Service CX_Customer Satisfaction Brand Recommend Score",
    department: "Ford Academy",
    category: "Service",
    label: "Service CX_Customer Satisfaction Brand Recommend Score",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Brand Recommend Score | Service | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service CX_Customer Satisfaction Brand Recommend Base Unit": {
    name: "Ford Academy > Service > Service CX_Customer Satisfaction Brand Recommend Base Unit",
    department: "Ford Academy",
    category: "Service",
    label: "Service CX_Customer Satisfaction Brand Recommend Base Unit",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Customer Experience > Brand Recommend Base Unit | Service | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service CX_CVP Service - FRFT%": {
    name: "Ford Academy > Service > Service CX_CVP Service - FRFT%",
    department: "Ford Academy",
    category: "Service",
    label: "Service CX_CVP Service - FRFT%",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > FORD CVP Service - FRFT% | Service | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service CX_CVP Service - FRFT Act": {
    name: "Ford Academy > Service > Service CX_CVP Service - FRFT Act",
    department: "Ford Academy",
    category: "Service",
    label: "Service CX_CVP Service - FRFT Act",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > FORD CVP Service - FRFT Act | Service | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Proces_Plan Service Advisor Overall Score": {
    name: "Ford Academy > Service > Service Proces_Plan Service Advisor Overall Score",
    department: "Ford Academy",
    category: "Service",
    label: "Service Proces_Plan Service Advisor Overall Score",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Process > Service Advisor Overall Score | Service | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Proces_Plan Quality of service performed Score": {
    name: "Ford Academy > Service > Service Proces_Plan Quality of service performed Score",
    department: "Ford Academy",
    category: "Service",
    label: "Service Proces_Plan Quality of service performed Score",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > FORD PFP - Service Advisor Competence Mix | Admin | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Proces_Plan Quality of service performed Unit": {
    name: "Ford Academy > Service > Service Proces_Plan Quality of service performed Unit",
    department: "Ford Academy",
    category: "Service",
    label: "Service Proces_Plan Quality of service performed Unit",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Process > Quality of Service Performed Score | Service | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Proces_Plan Overall vehicle pick-up process Score": {
    name: "Ford Academy > Service > Service Proces_Plan Overall vehicle pick-up process Score",
    department: "Ford Academy",
    category: "Service",
    label: "Service Proces_Plan Overall vehicle pick-up process Score",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Process > Quality of Service Performed Unit | Service | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Proces_Plan Overall vehicle pick-up process Unit": {
    name: "Ford Academy > Service > Service Proces_Plan Overall vehicle pick-up process Unit",
    department: "Ford Academy",
    category: "Service",
    label: "Service Proces_Plan Overall vehicle pick-up process Unit",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Process > Overall vehicle pick-up process Score | Service | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Proces_Plan Follow through on commitments Score": {
    name: "Ford Academy > Service > Service Proces_Plan Follow through on commitments Score",
    department: "Ford Academy",
    category: "Service",
    label: "Service Proces_Plan Follow through on commitments Score",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Process > Overall vehicle pick-up process Unit | Service | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Proces_Plan Follow through on commitments Unit": {
    name: "Ford Academy > Service > Service Proces_Plan Follow through on commitments Unit",
    department: "Ford Academy",
    category: "Service",
    label: "Service Proces_Plan Follow through on commitments Unit",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Process > Follow through on commitments Score | Service | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Diagnosis_Answer/resolve concern in timely manner Rate": {
    name: "Ford Academy > Service > Service Diagnosis_Answer/resolve concern in timely manner Rate",
    department: "Ford Academy",
    category: "Service",
    label: "Service Diagnosis_Answer/resolve concern in timely manner Rate",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Process > Follow through on commitments Unit | Service | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Diagnosis_Answer/resolve concern in timely manner Unit": {
    name: "Ford Academy > Service > Service Diagnosis_Answer/resolve concern in timely manner Unit",
    department: "Ford Academy",
    category: "Service",
    label: "Service Diagnosis_Answer/resolve concern in timely manner Unit",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Diagnosis > Answer/resolve concern in timely manner Rate | Service | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Diagnosis_Keeping informed of vehicle status Rate": {
    name: "Ford Academy > Service > Service Diagnosis_Keeping informed of vehicle status Rate",
    department: "Ford Academy",
    category: "Service",
    label: "Service Diagnosis_Keeping informed of vehicle status Rate",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Diagnosis > Answer/resolve concern in timely manner Unit | Service | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Diagnosis_Keeping informed of vehicle status Unit": {
    name: "Ford Academy > Service > Service Diagnosis_Keeping informed of vehicle status Unit",
    department: "Ford Academy",
    category: "Service",
    label: "Service Diagnosis_Keeping informed of vehicle status Unit",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Diagnosis > Keeping informed of vehicle status Rate | Service | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Diagnosis_Length of time to complete service Rate": {
    name: "Ford Academy > Service > Service Diagnosis_Length of time to complete service Rate",
    department: "Ford Academy",
    category: "Service",
    label: "Service Diagnosis_Length of time to complete service Rate",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Diagnosis > Keeping informed of vehicle status Unit | Service | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Diagnosis_Length of time to complete service Unit": {
    name: "Ford Academy > Service > Service Diagnosis_Length of time to complete service Unit",
    department: "Ford Academy",
    category: "Service",
    label: "Service Diagnosis_Length of time to complete service Unit",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Diagnosis > Length of time to complete > Length of time to complete service Rate | Service | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Diagnosis_Vehicle ready when promised Rate": {
    name: "Ford Academy > Service > Service Diagnosis_Vehicle ready when promised Rate",
    department: "Ford Academy",
    category: "Service",
    label: "Service Diagnosis_Vehicle ready when promised Rate",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Diagnosis > Length of time to complete service Unit | Service | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Diagnosis_Vehicle ready when promised Unit": {
    name: "Ford Academy > Service > Service Diagnosis_Vehicle ready when promised Unit",
    department: "Ford Academy",
    category: "Service",
    label: "Service Diagnosis_Vehicle ready when promised Unit",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Diagnosis > Vehicle ready when promised Rate | Service | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > No. of FGE implementations - Service": {
    name: "Ford Academy > Service > No. of FGE implementations - Service",
    department: "Ford Academy",
    category: "Service",
    label: "No. of FGE implementations - Service",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Diagnosis > Vehicle ready when promised Unit | Service | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Same Day Delivery %": {
    name: "Ford Academy > Service > Same Day Delivery %",
    department: "Ford Academy",
    category: "Service",
    label: "Same Day Delivery %",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > FORD PFP - No. of FGE implementations - Service | Admin | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Express Service Promise % (60mins gaurantee)": {
    name: "Ford Academy > Service > Express Service Promise % (60mins gaurantee)",
    department: "Ford Academy",
    category: "Service",
    label: "Express Service Promise % (60mins gaurantee)",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > FORD PFP - Same Day Delivery % | Admin | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Training_Day Service -YTD (Actual)": {
    name: "Ford Academy > Service > Training_Day Service -YTD (Actual)",
    department: "Ford Academy",
    category: "Service",
    label: "Training_Day Service -YTD (Actual)",
    tableNumber: 207,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > FORD PFP - Express Service Promise % (60mins gaurantee) | Admin | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Advisor Competence mix": {
    name: "Ford Academy > Service > Service Advisor Competence mix",
    department: "Ford Academy",
    category: "Service",
    label: "Service Advisor Competence mix",
    tableNumber: 209,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Advisor Competence mix | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Advisor Competence mix % (Uncertified)": {
    name: "Ford Academy > Service > Service Advisor Competence mix % (Uncertified)",
    department: "Ford Academy",
    category: "Service",
    label: "Service Advisor Competence mix % (Uncertified)",
    tableNumber: 209,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Advisor Competence mix % Uncertified | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Advisor Competence mix % (Induction)": {
    name: "Ford Academy > Service > Service Advisor Competence mix % (Induction)",
    department: "Ford Academy",
    category: "Service",
    label: "Service Advisor Competence mix % (Induction)",
    tableNumber: 209,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Advisor Competence mix % Induction | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Advisor Competence mix % (Silver)": {
    name: "Ford Academy > Service > Service Advisor Competence mix % (Silver)",
    department: "Ford Academy",
    category: "Service",
    label: "Service Advisor Competence mix % (Silver)",
    tableNumber: 209,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Advisor Competence mix % Silver | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Advisor Competence mix % (Gold)": {
    name: "Ford Academy > Service > Service Advisor Competence mix % (Gold)",
    department: "Ford Academy",
    category: "Service",
    label: "Service Advisor Competence mix % (Gold)",
    tableNumber: 209,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Advisor Competence mix % Gold | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Advisor Competence mix % (Platinum)": {
    name: "Ford Academy > Service > Service Advisor Competence mix % (Platinum)",
    department: "Ford Academy",
    category: "Service",
    label: "Service Advisor Competence mix % (Platinum)",
    tableNumber: 209,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Advisor Competence mix % Platinum | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > DCRC Competence mix": {
    name: "Ford Academy > Service > DCRC Competence mix",
    department: "Ford Academy",
    category: "Service",
    label: "DCRC Competence mix",
    tableNumber: 210,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > DCRC Competence mix | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > DCRC Competence mix % (Uncertified)": {
    name: "Ford Academy > Service > DCRC Competence mix % (Uncertified)",
    department: "Ford Academy",
    category: "Service",
    label: "DCRC Competence mix % (Uncertified)",
    tableNumber: 210,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > DCRC Competence mix % Uncertified | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > DCRC Competence mix % (Induction)": {
    name: "Ford Academy > Service > DCRC Competence mix % (Induction)",
    department: "Ford Academy",
    category: "Service",
    label: "DCRC Competence mix % (Induction)",
    tableNumber: 210,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > DCRC Competence mix % Induction | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > DCRC Competence mix % (Silver)": {
    name: "Ford Academy > Service > DCRC Competence mix % (Silver)",
    department: "Ford Academy",
    category: "Service",
    label: "DCRC Competence mix % (Silver)",
    tableNumber: 210,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > DCRC Competence mix % Silver | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > DCRC Competence mix % (Gold)": {
    name: "Ford Academy > Service > DCRC Competence mix % (Gold)",
    department: "Ford Academy",
    category: "Service",
    label: "DCRC Competence mix % (Gold)",
    tableNumber: 210,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > DCRC Competence mix % Gold | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > DCRC Competence mix % (Platinum)": {
    name: "Ford Academy > Service > DCRC Competence mix % (Platinum)",
    department: "Ford Academy",
    category: "Service",
    label: "DCRC Competence mix % (Platinum)",
    tableNumber: 210,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > DCRC Competence mix % Platinum | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Technician Competence mix": {
    name: "Ford Academy > Service > Technician Competence mix",
    department: "Ford Academy",
    category: "Service",
    label: "Technician Competence mix",
    tableNumber: 211,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Technician Competence mix | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Technician Competence mix % (Uncertified)": {
    name: "Ford Academy > Service > Technician Competence mix % (Uncertified)",
    department: "Ford Academy",
    category: "Service",
    label: "Technician Competence mix % (Uncertified)",
    tableNumber: 211,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Technician Competence mix % Uncertified | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Technician Competence mix % (Induction)": {
    name: "Ford Academy > Service > Technician Competence mix % (Induction)",
    department: "Ford Academy",
    category: "Service",
    label: "Technician Competence mix % (Induction)",
    tableNumber: 211,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Technician Competence mix % Induction | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Technician Competence mix % (Silver)": {
    name: "Ford Academy > Service > Technician Competence mix % (Silver)",
    department: "Ford Academy",
    category: "Service",
    label: "Technician Competence mix % (Silver)",
    tableNumber: 211,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Technician Competence mix % Silver | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Technician Competence mix % (Gold)": {
    name: "Ford Academy > Service > Technician Competence mix % (Gold)",
    department: "Ford Academy",
    category: "Service",
    label: "Technician Competence mix % (Gold)",
    tableNumber: 211,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Technician Competence mix % Gold | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Technician Competence mix % (Platinum)": {
    name: "Ford Academy > Service > Technician Competence mix % (Platinum)",
    department: "Ford Academy",
    category: "Service",
    label: "Technician Competence mix % (Platinum)",
    tableNumber: 211,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Technician Competence mix % Platinum | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Manager Competence mix": {
    name: "Ford Academy > Service > Service Manager Competence mix",
    department: "Ford Academy",
    category: "Service",
    label: "Service Manager Competence mix",
    tableNumber: 212,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Manager Competence mix | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Manager Competence mix % (Uncertified)": {
    name: "Ford Academy > Service > Service Manager Competence mix % (Uncertified)",
    department: "Ford Academy",
    category: "Service",
    label: "Service Manager Competence mix % (Uncertified)",
    tableNumber: 212,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Manager Competence mix % Uncertified | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Manager Competence mix % (Induction)": {
    name: "Ford Academy > Service > Service Manager Competence mix % (Induction)",
    department: "Ford Academy",
    category: "Service",
    label: "Service Manager Competence mix % (Induction)",
    tableNumber: 212,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Manager Competence mix % Induction | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Manager Competence mix % (Silver)": {
    name: "Ford Academy > Service > Service Manager Competence mix % (Silver)",
    department: "Ford Academy",
    category: "Service",
    label: "Service Manager Competence mix % (Silver)",
    tableNumber: 212,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Manager Competence mix % Silver | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Manager Competence mix % (Gold)": {
    name: "Ford Academy > Service > Service Manager Competence mix % (Gold)",
    department: "Ford Academy",
    category: "Service",
    label: "Service Manager Competence mix % (Gold)",
    tableNumber: 212,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Manager Competence mix % Gold | All Department | All Brand | All Model | | CLOSE",
  },
  "Ford Academy > Service > Service Manager Competence mix % (Platinum)": {
    name: "Ford Academy > Service > Service Manager Competence mix % (Platinum)",
    department: "Ford Academy",
    category: "Service",
    label: "Service Manager Competence mix % (Platinum)",
    tableNumber: 212,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Service Manager Competence mix % Platinum | All Department | All Brand | All Model | | CLOSE",
  },
};
