import * as React from "react";
import { CustomMetricCard } from "./CustomMetricCard";
import { MetricSelector, CenteredMetricSelector, getCarlineAnalysisMeasures, OemMetricSelector } from "../../../charts/MetricSelector";
import { CustomMetricRow } from "./CustomMetricRow";
import { DealerModelsContext } from "../../../contexts/Custom/DealerModelsContext";
import { SelectableChart } from "../../../charts/CustomChart";
import { CustomDashboardMeasures, AdditionalStatsMeasures, OEMSupportAnalysis } from "../../../charts/CustomDashReports";
import { fordSAScorecard } from "../../../charts/custom-dashboard-reports/FordSAScrorecard";
import { KeyboardArrowDown, Settings } from "@material-ui/icons";
import { history } from "../../../lib/history";
import { MetricInsightContext } from "../../../contexts/MetricInsightContext";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
export const defaultValue: string = "Overall Dealership > REVENUE > Total Dealership Sales";


export const Measures = {
  ...CustomDashboardMeasures,
  ...AdditionalStatsMeasures,
  ...OEMSupportAnalysis,
};

export const SelectableMetric = ({ value, query, update }) => {
  const { carlineAnalysisMeasures } = React.useContext(DealerModelsContext);

  const AllMeasures = {
    ...Measures,
    ...carlineAnalysisMeasures,
  };
  // Temp fix, need to resolve. The metric card splites the key on - but the label of the new KPIs has -.
  const savedMeasure = value ? (["Ford SA Scorecard >", "VCJ Process Data "].some(v => value.includes(v)) ? value : value.split(" - ")[0]) : value;
  const customItem = AllMeasures[savedMeasure] ? AllMeasures[savedMeasure] : AllMeasures[defaultValue];
  const measureKey = AllMeasures[savedMeasure] ? savedMeasure : defaultValue;

  return (
    <CustomMetricCard
      renderIcon={() => (
        <MetricSelector
          title={""}
          handleSelection={metric => {
            update(metric);
          }}
          report={measureKey || defaultValue}
          variant={"dealer"}
        />
      )}
      allowInsights={true}
      query={query}
      metric={"custom"}
      custom={customItem.getMeasure(measureKey || defaultValue, AllMeasures)}
    />
  );
};

export const SelectableRow = ({ value, query, update, variant }) => {
  const { dealerModels } = React.useContext(DealerModelsContext);
  const CarlineAnalysisMeasures = getCarlineAnalysisMeasures(dealerModels);

  const AllMeasures = {
    ...Measures,
    ...CarlineAnalysisMeasures,
  };
  const savedMeasure = value ? (["Ford SA Scorecard >", "VCJ Process Data "].some(v => value.includes(v)) ? value : value.split(" - ")[0]) : value;
  const customItem = AllMeasures[savedMeasure] ? AllMeasures[savedMeasure] : AllMeasures[defaultValue];
  const measureKey = AllMeasures[savedMeasure] ? savedMeasure : defaultValue;

  /* DEBUG: Table, 2 graphs kpi selection */
  // console.log("dealerModels->", dealerModels);
  // console.log("AllMeasures->", AllMeasures);
  // console.log("value->", value);
  // console.log("savedMeasure->", savedMeasure);
  // console.log("customItem->", customItem);
  // console.log("measureKey->", measureKey);

  return (
    <CustomMetricRow
      renderIcon={() => (
        <CenteredMetricSelector
          title={""}
          handleSelection={metric => {
            update(metric);
          }}
          report={measureKey || defaultValue}
          variant={"dealer"}
        />
      )}
      allowInsights={true}
      query={query}
      metric={"custom"}
      custom={customItem.getMeasure(measureKey || defaultValue, AllMeasures)}
      variant={variant}
    />
  );
};

export const CustomSelectableChart = ({ value, query, update, type, updateType, customHeight = "375px" }) => {
  const { dealerModels } = React.useContext(DealerModelsContext);
  const CarlineAnalysisMeasures = getCarlineAnalysisMeasures(dealerModels);

  const AllMeasures = {
    ...Measures,
    ...CarlineAnalysisMeasures,
  };
  const savedMeasure = value ? (["Ford SA Scorecard >", "VCJ Process Data "].some(v => value.includes(v)) ? value : value.split(" - ")[0]) : value;
  const customItem = AllMeasures[savedMeasure] ? AllMeasures[savedMeasure] : AllMeasures[defaultValue];
  const measureKey = AllMeasures[savedMeasure] ? savedMeasure : defaultValue;

  return (
    <SelectableChart
      report={"custom"}
      query={query}
      handleSelection={report => {
        update(report);
      }}
      handleChartType={type => {
        updateType(type);
      }}
      chartType={type}
      customHeight={customHeight}
      custom={customItem.getMeasure(measureKey || defaultValue, AllMeasures)}
    />
  );
};


export const OemSelectableMetric = ({ value, query, update, measures, insightsPath, classes, forceTitle, chartOptions={}}) => {
  const { carlineAnalysisMeasures } = React.useContext(DealerModelsContext);
  const { metricInsight, setMetricInsight } = React.useContext(MetricInsightContext);
  const [showMenu, setShowMenu] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const AllMeasures = {
    ...Measures,
    ...carlineAnalysisMeasures,
    ...fordSAScorecard
  };
  // Temp fix, need to resolve. The metric card splites the key on - but the label of the new KPIs has -.
  const savedMeasure = value ? (["Ford SA Scorecard >", "VCJ Process Data "].some(v => value.includes(v)) ? value : value.split(" - ")[0]) : value;
  const customItem = AllMeasures[savedMeasure] ? AllMeasures[savedMeasure] : AllMeasures[defaultValue];
  const measureKey = AllMeasures[savedMeasure] ? savedMeasure : defaultValue;
  return (
    <CustomMetricCard
      classes={classes}
      forceTitle={forceTitle}
      allowInsights={true}
      query={query}
      metric={"custom"}
      custom={customItem.getMeasure(measureKey || defaultValue, AllMeasures)}
      renderIcon={() => {
        if (Object.keys(chartOptions).length > 0) {
          return (
            <>
              <KeyboardArrowDown
                aria-haspopup="true"
                className={classes.downArrow}
                onClick={(e: any) => setAnchorEl(e.currentTarget)}
                aria-owns={anchorEl ? "department-menu" : undefined}
              />
              <Menu
                anchorEl={anchorEl}
                id="department-menu"
                onClose={() => setAnchorEl(null)}
                open={Boolean(anchorEl)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                {Object.keys(chartOptions).map((o) => {
                  return (
                    <MenuItem onClick={() => {
                      setAnchorEl(null)
                      update(o)}
                    }>{o}</MenuItem>
                  )
                })}
              </Menu>
            </>
          )
        }

      }}
    />
  );
}


