import React, { createContext, useState, useReducer, useMemo } from "react";
import { Loading } from "./base";
import { Oem, getOems } from "../lib/api/oems";

const defaultOem = [];

export type OemContextState = {
  oems: Oem[];
  loading: Loading;
  hydrate();
};


export const OemContext = createContext<OemContextState>({
  oems: defaultOem,
  loading: { loading: false, loaded: false, error: null },
  hydrate: () => {},
});


export const OemProvider = ({ children }) => {
  const [oems, setOem] = useState<Oem[]>(defaultOem);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });

  const value = useMemo(() => ({
    oems,
    loading,
    hydrate: async () => {
      try {
        setLoading({
          loading: true,
          loaded: false,
          error: null,
        });
        const returnedoems = await getOems();
        setOem(returnedoems);
        setLoading({
          loading: false,
          loaded: true,
          error: null,
        });
      } catch (e) {
        setLoading({
          loading: false,
          loaded: false,
          error: e,
        });
      }
    },
  }), [oems, loading]);

  return (
    <OemContext.Provider value={value}>
      {children}
    </OemContext.Provider>
  )
}
