import React, { createContext, useState, useReducer, useMemo } from "react";
import { Loading } from "./base";
import { CriteriaSet, criteria as fetchCriteria } from "../lib/api/filter-criteria";

const defaultCriteria = {
  oems: [],
  regions: [],
  dealerSets: [],
  dealerGroups: [],
  countries: [],
  excludeNewDealers: false,
};

export type CriteriaContextState = {
  criteria: CriteriaSet;
  loading: Loading;
  hydrate();
};

export const CriteriaContext = createContext<CriteriaContextState>({
  criteria: defaultCriteria,
  loading: { loading: false, loaded: false, error: null },
  hydrate: () => {},
});

export const CriteriaProvider = ({ children }) => {
  const [criteria, setCriteria] = useState<CriteriaSet>(defaultCriteria);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });

  const value = useMemo(
    () => ({
      criteria,
      loading,
      hydrate: async () => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          const criteria = await fetchCriteria();
          setCriteria(criteria);
          setLoading({
            loading: false,
            loaded: true,
            error: null,
          });
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      setCriteria,
    }),
    [criteria, loading],
  );
  return <CriteriaContext.Provider value={value}>{children}</CriteriaContext.Provider>;
};
