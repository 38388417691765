import React, { createContext, useState, useReducer, useMemo } from "react";
import { Loading } from "./base";
import { PersonCountry, createPersonCountries, getPersonCountries, updatePersonCountries } from "../lib/api/people";

const defaultPerson = [];

export type PersonCountryContextState = {
  personCountries: PersonCountry[];
  loading: Loading;
  hydrate(): void;
  createPersonCountries(personCountries: Partial<PersonCountry>): void;
  updatePersonCountries(personCountries: Partial<PersonCountry>): void;
  getPersonCountries(): PersonCountry[];
};


export const PersonCountryContext = createContext<PersonCountryContextState>({
  personCountries: defaultPerson,
  loading: { loading: false, loaded: false, error: null },
  hydrate: () => {},
  createPersonCountries: () => {},
  updatePersonCountries: () => {},
  getPersonCountries: () => [],
});


export const PersonCountryProvider = ({ children }) => {
  const [personCountries, setPersonCountries] = useState<PersonCountry[]>(defaultPerson);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const hydrate = async () => {
    try {
      setLoading({
        loading: true,
        loaded: false,
        error: null,
      });
      const person = await getPersonCountries();
      setPersonCountries(person);
      setLoading({
        loading: false,
        loaded: true,
        error: null,
      });
    } catch (e) {
      setLoading({
        loading: false,
        loaded: false,
        error: e,
      });
    }
  }

  const value = useMemo(() => ({
    personCountries,
    loading,
    hydrate,
    getPersonCountries() {
      if (loading.loaded && !loading.loading) {
        return personCountries;
      }
      if (!loading.loading && !loading.error) {
        hydrate();
      }
      return [];
    },
    createPersonCountries: async (personCountries: PersonCountry) => {
      try {
        setLoading({
          loading: true,
          loaded: false,
          error: null,
        });
        await createPersonCountries(personCountries);
        setLoading({
          loading: false,
          loaded: true,
          error: null,
        });
        hydrate();
      } catch (e) {
        setLoading({
          loading: false,
          loaded: false,
          error: e,
        });
      }
    },
    updatePersonCountries: async (personCountries: PersonCountry) => {
      try {
        setLoading({
          loading: true,
          loaded: false,
          error: null,
        });
        await updatePersonCountries(personCountries);
        setLoading({
          loading: false,
          loaded: true,
          error: null,
        });
        hydrate();
      } catch (e) {
        setLoading({
          loading: false,
          loaded: false,
          error: e,
        });
      }
    },
    setPersonCountries,
  }), [personCountries, loading]);

  return (
    <PersonCountryContext.Provider value={value}>
      {children}
    </PersonCountryContext.Provider>
  )
}
