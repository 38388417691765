import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import { Dealer, Query, Report, RiskSeries, ScatterReport, StackedSeries } from "../api/charts";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing.unit * 3,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: "fit-content",
      height: "450px",
    },
    buttonDiv: {
      marginLeft: "auto",
      marginBottom: theme.spacing.unit * 1,
      marginTop: theme.spacing.unit * 1,
    },
  });

type ChartDialogProps = {
  handleSelection?: (key: Report) => void;
  handleSelectionX?: (key: Report) => void;
  handleSelectionY?: (key: Report) => void;
  handleScatterSelection?: (key: ScatterReport) => void;
  handleShowSelection?: (attr: string) => any;
  report?: Report;
  title?: string;
  query?: Query;
  showBenchmark?: boolean;
  showMedian?: boolean;
  showTarget?: boolean;
  chartOptions?: any;
  renderItems?: any;
  renderMenu?: any;
  setMenuOpen?: (value: boolean) => void;
  setMenuAnchor?: (value: any) => void;
  menu?: any;
  selectedChart?: string;
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
  chart: (props: any) => React.ReactNode;
  chartName?: ScatterReport;
  measureAgainst?: string;
  measure?: any;
  theme?: Theme;
  selectedMeasure?: string;
  variation?: string;
  series?: StackedSeries | RiskSeries;
  brand?: string;
  setBrand?: (value: string) => void;
  isAcademy?: boolean;
  dashboard?: "fordacademy" | "integrated" | string;
  forcedHeight?: string;
  params?: any;
  custom?: any;
  chartType?: "line" | "bar";
  oemSelected?: Dealer;
  metricTitle?: string;
  selectedtypes?: any;
  riskDealerLookUp?: any;
  isDesktopOrLaptop?: boolean;
} & WithStyles<typeof styles>;

const ChartDialogUnstlyed: React.FC<ChartDialogProps> = props => {
  return (
    <Dialog open={props.openDialog} fullWidth={true} maxWidth={"md"} onClose={() => props.setOpenDialog(false)}>
      <DialogContent className={props.classes.root}>
        {/* MUI api not working as expected, below is the solution */}
        <span style={{ width: "900px" }} />
        {/*  */}
        {props.chart({
          ...props,
        })}
        <div className={props.classes.buttonDiv}>
          <Button color="primary" onClick={() => props.setOpenDialog(false)}>
            Close
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const ChartDialog = React.memo(withStyles(styles)(ChartDialogUnstlyed));
