import { CustomDashboardType, getMeasure } from "../CustomDashReports";

export const AfterSales: { [index: string]: CustomDashboardType } = {
  "AFTER SALES > SALES > After Sales Sales": {
  name: "AFTER SALES > SALES > After Sales Sales",
  department: "AFTER-SALES",
  category: "SALES",
  label: "After Sales Sales",
  tableNumber: 67,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | AfterSales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > SALES > Parts Sales": {
  name: "AFTER SALES > SALES > Parts Sales",
  department: "AFTER-SALES",
  category: "SALES",
  label: "Parts Sales",
  tableNumber: 80,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > SALES > Service Sales": {
  name: "AFTER SALES > SALES > Service Sales",
  department: "AFTER-SALES",
  category: "SALES",
  label: "Service Sales",
  tableNumber: 97,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > SALES > Body Shop Sales": {
  name: "AFTER SALES > SALES > Body Shop Sales",
  department: "AFTER-SALES",
  category: "SALES",
  label: "Body Shop Sales",
  tableNumber: 113,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | Body Shop | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > SALES > Parts and Service Sales per Service RO": {
  name: "AFTER SALES > SALES > Parts and Service Sales per Service RO",
  department: "AFTER-SALES",
  category: "SALES",
  label: "Parts and Service Sales per Service RO ",
  tableNumber: 67,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Parts Workshop & Service Sales p RO (Incl. Quick Service) | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > SALES > Parts Sales per Service RO": {
  name: "AFTER SALES > SALES > Parts Sales per Service RO",
  department: "AFTER-SALES",
  category: "SALES",
  label: "Parts Sales per Service RO ",
  tableNumber: 81,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Parts workshop sales per RO (Incl. Quick Service) | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > SALES > Service Sales per Service RO": {
  name: "AFTER SALES > SALES > Service Sales per Service RO ",
  department: "AFTER-SALES",
  category: "SALES",
  label: "Service Sales per Service RO ",
  tableNumber: 98,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales p RO | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > SALES > Parts and Body Shop Sales per Body Shop RO": {
  name: "AFTER SALES > SALES > Parts and Body Shop Sales per Body Shop RO",
  department: "AFTER-SALES",
  category: "SALES",
  label: "Parts and Body Shop Sales per Body Shop RO ",
  tableNumber: 67,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Parts Body Shop & Body Shop Sales p RO | Body Shop | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > SALES > Parts Sales per Body Shop RO": {
  name: "AFTER SALES > SALES > Parts Sales per Body Shop RO",
  department: "AFTER-SALES",
  category: "SALES",
  label: "Parts Sales per Body Shop RO ",
  tableNumber: 82,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Parts workshop sales per RO (BS) | Body Shop | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > SALES > Body Shop Sales per Body Shop RO": {
  name: "AFTER SALES > SALES > Body Shop Sales per Body Shop RO",
  department: "AFTER-SALES",
  category: "SALES",
  label: "Body Shop Sales per Body Shop RO ",
  tableNumber: 114,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales p RO | Body Shop | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > SALES > After Sales Breakeven Days": {
  name: "AFTER SALES > SALES > After Sales Breakeven Days",
  department: "AFTER-SALES",
  category: "SALES",
  label: "After Sales Breakeven Days",
  tableNumber: 180,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Dept. Breakeven Days | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > SALES > After Sales Breakeven RO": {
  name: "AFTER SALES > SALES > After Sales Breakeven RO",
  department: "AFTER-SALES",
  category: "SALES",
  label: "After Sales Breakeven RO",
  tableNumber: 180,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Breakeven ROs | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > MIX > AFTER SALES MIX": {
  name: "AFTER SALES > MIX > AFTER SALES MIX",
  department: "AFTER-SALES",
  category: "GROSS PROFIT",
  label: "AFTER SALES MIX",
  tableNumber: 68,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit % of Sales (MIX) | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > MIX > After Sales Sales": {
  name: "AFTER SALES > MIX > After Sales Sales",
  department: "AFTER-SALES",
  category: "GROSS PROFIT",
  label: "After Sales Sales",
  tableNumber: 68,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | AfterSales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > MIX > Parts Sales": {
  name: "AFTER SALES > MIX > Parts Sales",
  department: "AFTER-SALES",
  category: "GROSS PROFIT",
  label: "Parts Sales",
  tableNumber: 80,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > MIX > Service Sales": {
  name: "AFTER SALES > MIX > Service Sales",
  department: "AFTER-SALES",
  category: "GROSS PROFIT",
  label: "Service Sales",
  tableNumber: 97,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > MIX > Body Shop Sales": {
  name: "AFTER SALES > MIX > Body Shop Sales",
  department: "AFTER-SALES",
  category: "GROSS PROFIT",
  label: "Body Shop Sales",
  tableNumber: 113,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | Body Shop | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > MIX > After Sales GP": {
  name: "AFTER SALES > MIX > After Sales GP",
  department: "AFTER-SALES",
  category: "GROSS PROFIT",
  label: "After Sales GP",
  tableNumber: 68,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > MIX > Parts GP": {
  name: "AFTER SALES > MIX > Parts GP",
  department: "AFTER-SALES",
  category: "GROSS PROFIT",
  label: "Parts GP",
  tableNumber: 86,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > MIX > Service GP": {
  name: "AFTER SALES > MIX > Service GP",
  department: "AFTER-SALES",
  category: "GROSS PROFIT",
  label: "Service GP",
  tableNumber: 101,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > MIX > Body Shop GP": {
  name: "AFTER SALES > MIX > Body Shop GP",
  department: "AFTER-SALES",
  category: "GROSS PROFIT",
  label: "Body Shop GP",
  tableNumber: 117,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | Body Shop | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > MIX > Parts Sales mix": {
  name: "AFTER SALES > MIX > Parts Sales mix",
  department: "AFTER-SALES",
  category: "GROSS PROFIT",
  label: "Parts Sales mix",
  tableNumber: 68,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Parts Mix of Sales | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > MIX > Service Sales mix": {
  name: "AFTER SALES > MIX > Service Sales mix",
  department: "AFTER-SALES",
  category: "GROSS PROFIT",
  label: "Service Sales mix",
  tableNumber: 68,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Service QuickService Mix of Sales | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > MIX > Body Sales mix": {
  name: "AFTER SALES > MIX > Body Sales mix",
  department: "AFTER-SALES",
  category: "GROSS PROFIT",
  label: "Body Sales mix",
  tableNumber: 68,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Body Shop Mix of Sales | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > MIX > Parts GP mix": {
  name: "AFTER SALES > MIX > Parts GP mix",
  department: "AFTER-SALES",
  category: "GROSS PROFIT",
  label: "Parts GP mix",
  tableNumber: 68,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Parts Mix of TGP | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > MIX > Service GP mix": {
  name: "AFTER SALES > MIX > Service GP mix",
  department: "AFTER-SALES",
  category: "GROSS PROFIT",
  label: "Service GP mix",
  tableNumber: 68,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Service QuickService Mix of TGP | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > MIX > Body GP mix": {
  name: "AFTER SALES > MIX > Body GP mix",
  department: "AFTER-SALES",
  category: "GROSS PROFIT",
  label: "Body GP mix",
  tableNumber: 68,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Body Shop Mix of TGP | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > MIX > After Sales GP%": {
  name: "AFTER SALES > MIX > After Sales GP%",
  department: "AFTER-SALES",
  category: "GROSS PROFIT",
  label: "After Sales GP%",
  tableNumber: 68,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit % of Sales (MIX) | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > MIX > Parts GP%": {
  name: "AFTER SALES > MIX > Parts GP%",
  department: "AFTER-SALES",
  category: "GROSS PROFIT",
  label: "Parts GP%",
  tableNumber: 86,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit % of Sales (MIX) | Parts | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > MIX > Service GP%": {
  name: "AFTER SALES > MIX > Service GP%",
  department: "AFTER-SALES",
  category: "GROSS PROFIT",
  label: "Service GP%",
  tableNumber: 101,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit % of Sales (MIX) | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > MIX > Body Shop GP%": {
  name: "AFTER SALES > MIX > Body Shop GP%",
  department: "AFTER-SALES",
  category: "GROSS PROFIT",
  label: "Body Shop GP%",
  tableNumber: 117,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit % of Sales (MIX) | Body Shop | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > RETAINED > AFTER SALES RETAINED": {
  name: "AFTER SALES > RETAINED > AFTER SALES RETAINED",
  department: "AFTER-SALES",
  category: "EXPENSES",
  label: "AFTER SALES RETAINED",
  tableNumber: 69,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT % of TGP: RETAINED | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > RETAINED > AFTER SALES Total GP": {
  name: "AFTER SALES > RETAINED > AFTER SALES Total GP",
  department: "AFTER-SALES",
  category: "EXPENSES",
  label: "AFTER SALES Total GP",
  tableNumber: 68,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > RETAINED > AFTER SALES Total Expenses": {
  name: "AFTER SALES > RETAINED > AFTER SALES Total Expenses",
  department: "AFTER-SALES",
  category: "EXPENSES",
  label: "AFTER SALES Total Expenses",
  tableNumber: 69,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > RETAINED > AFTER SALES Total PBT": {
  name: "AFTER SALES > RETAINED > AFTER SALES Total PBT",
  department: "AFTER-SALES",
  category: "EXPENSES",
  label: "AFTER SALES Total PBT",
  tableNumber: 77,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT (Profit Before Tax) | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > RETAINED > After Sales Total Expenses % GP": {
  name: "AFTER SALES > RETAINED > After Sales Total Expenses % GP",
  department: "AFTER-SALES",
  category: "EXPENSES",
  label: "After Sales Total Expenses % GP",
  tableNumber: 69,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses % of TGP | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > RETAINED > After Sales Variable Expenses  % GP": {
  name: "AFTER SALES > RETAINED > After Sales Variable Expenses  % GP",
  department: "AFTER-SALES",
  category: "EXPENSES",
  label: "After Sales Variable Expenses  % GP",
  tableNumber: 70,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses % of TGP | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > RETAINED > After Sales Personnel  Expenses % GP": {
  name: "AFTER SALES > RETAINED > After Sales Personnel  Expenses % GP",
  department: "AFTER-SALES",
  category: "EXPENSES",
  label: "After Sales Personnel  Expenses % GP",
  tableNumber: 71,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PE > Personnel Expenses % of TGP | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > RETAINED > After Sales Other Semi Variable Expenses % GP": {
  name: "AFTER SALES > RETAINED > After Sales Other Semi Variable Expenses % GP",
  department: "AFTER-SALES",
  category: "EXPENSES",
  label: "After Sales Other Semi Variable Expenses % GP",
  tableNumber: 72,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "SVE > Other Semi Variable Expenses % of TGP | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > RETAINED > After Sales Fixed Expenses % GP": {
  name: "AFTER SALES > RETAINED > After Sales Fixed Expenses % GP",
  department: "AFTER-SALES",
  category: "EXPENSES",
  label: "After Sales Fixed Expenses % GP",
  tableNumber: 73,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "FE > Fixed Expenses % of TGP | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > RETAINED > After Sales Interest Expenses % GP": {
  name: "AFTER SALES > RETAINED > After Sales Interest Expenses % GP",
  department: "AFTER-SALES",
  category: "EXPENSES",
  label: "After Sales Interest Expenses % GP",
  tableNumber: 74,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Interest % of TGP | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > RETAINED > After Sales Allocation of Admin  Expenses % GP": {
  name: "AFTER SALES > RETAINED > After Sales Allocation of Admin  Expenses % GP",
  department: "AFTER-SALES",
  category: "EXPENSES",
  label: "After Sales Allocation of Admin  Expenses % GP",
  tableNumber: 69,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Adm > Admin Exp. Allocation % of TGP | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > RETAINED > After Sales Variable Expenses% Total Expenses": {
  name: "AFTER SALES > RETAINED > After Sales Variable Expenses% Total Expenses",
  department: "AFTER-SALES",
  category: "EXPENSES",
  label: "After Sales Variable Expenses% Total Expenses",
  tableNumber: 70,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses % of Total Expenses | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > RETAINED > After Sales Personnel Expenses% Total Expenses": {
  name: "AFTER SALES > RETAINED > After Sales Personnel Expenses% Total Expenses",
  department: "AFTER-SALES",
  category: "EXPENSES",
  label: "After Sales Personnel Expenses% Total Expenses",
  tableNumber: 71,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PE > Personnel Expenses % of Total Expenses | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > RETAINED > After Sales Other Semi Var.Exp.% Total Expenses": {
  name: "AFTER SALES > RETAINED > After Sales Other Semi Var.Exp.% Total Expenses",
  department: "AFTER-SALES",
  category: "EXPENSES",
  label: "After Sales Other Semi Var.Exp.% Total Expenses",
  tableNumber: 72,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "SVE > Other Semi Variable Expenses % of Total Expenses | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > RETAINED > After Sales Fixed Expenses% Total Expenses": {
  name: "AFTER SALES > RETAINED > After Sales Fixed Expenses% Total Expenses",
  department: "AFTER-SALES",
  category: "EXPENSES",
  label: "After Sales Fixed Expenses% Total Expenses",
  tableNumber: 73,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "FE > Fixed Expenses % of Total Expenses | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > RETAINED > After Sales Interest Expenses % Total Expenses": {
  name: "AFTER SALES > RETAINED > After Sales Interest Expenses % Total Expenses",
  department: "AFTER-SALES",
  category: "EXPENSES",
  label: "After Sales Interest Expenses % Total Expenses",
  tableNumber: 74,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Interest % of Total Expenses | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > RETAINED > After Sales Allocation of Admin Expenses % Total Expenses": {
  name: "AFTER SALES > RETAINED > After Sales Allocation of Admin Expenses % Total Expenses",
  department: "AFTER-SALES",
  category: "EXPENSES",
  label: "After Sales Allocation of Admin Expenses % Total Expenses",
  tableNumber: 69,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Adm > Admin Exp. Allocation % of Total Expenses | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > RETAINED > After Sales Cost Recovery": {
  name: "AFTER SALES > RETAINED > After Sales Cost Recovery",
  department: "AFTER-SALES",
  category: "EXPENSES",
  label: "After Sales Cost Recovery",
  tableNumber: 175,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Cost Recovery | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > ACTIVITY > AFTER SALES ACTIVITY": {
  name: "AFTER SALES > ACTIVITY > AFTER SALES ACTIVITY",
  department: "AFTER-SALES",
  category: "OPERATIONAL ASSETS",
  label: "AFTER SALES ACTIVITY",
  tableNumber: 75,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Asset Activity | Aftersales | All Brand | All Model | | AVG",
  canAverage: false
},
"AFTER SALES > ACTIVITY > AFTER SALES Annualised Sales": {
  name: "AFTER SALES > ACTIVITY > AFTER SALES Annualised Sales",
  department: "AFTER-SALES",
  category: "OPERATIONAL ASSETS",
  label: "AFTER SALES Annualised Sales",
  tableNumber: 67,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Projected Annualised Sales | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > ACTIVITY > AFTER SALES Average Operational Assets": {
  name: "AFTER SALES > ACTIVITY > AFTER SALES Average Operational Assets",
  department: "AFTER-SALES",
  category: "OPERATIONAL ASSETS",
  label: "AFTER SALES Average Operational Assets",
  tableNumber: 75,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Average Operational Assets | Aftersales | All Brand | All Model | | AVG",
  canAverage: true
},
"AFTER SALES > ROOA > AFTER SALES ROOA": {
  name: "AFTER SALES > ROOA > AFTER SALES ROOA",
  department: "AFTER-SALES",
  category: "MRA MODEL",
  label: "AFTER SALES ROOA",
  tableNumber: 76,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Return on Operational Assets (ROOA) | Aftersales | All Brand | All Model | | AVG",
  canAverage: false
},
"AFTER SALES > ROOA > AFTER SALES MIX": {
  name: "AFTER SALES > ROOA > AFTER SALES MIX",
  department: "AFTER-SALES",
  category: "MRA MODEL",
  label: "AFTER SALES MIX",
  tableNumber: 68,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit % of Sales (MIX) | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > ROOA > AFTER SALES RETAINED": {
  name: "AFTER SALES > ROOA > AFTER SALES RETAINED",
  department: "AFTER-SALES",
  category: "MRA MODEL",
  label: "AFTER SALES RETAINED",
  tableNumber: 69,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT % of TGP: RETAINED | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > ROOA > AFTER SALES ACTIVITY": {
  name: "AFTER SALES > ROOA > AFTER SALES ACTIVITY",
  department: "AFTER-SALES",
  category: "MRA MODEL",
  label: "AFTER SALES ACTIVITY",
  tableNumber: 75,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Asset Activity | Aftersales | All Brand | All Model | | AVG",
  canAverage: false
},
"AFTER SALES > ROOA > Gross profit": {
  name: "AFTER SALES > ROOA > Gross profit",
  department: "AFTER-SALES",
  category: "MRA MODEL",
  label: "Gross profit",
  tableNumber: 68,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > ROOA > Revenue": {
  name: "AFTER SALES > ROOA > Revenue",
  department: "AFTER-SALES",
  category: "MRA MODEL",
  label: "Revenue",
  tableNumber: 67,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > ROOA > Expenses": {
  name: "AFTER SALES > ROOA > Expenses",
  department: "AFTER-SALES",
  category: "MRA MODEL",
  label: "Expenses",
  tableNumber: 69,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > ROOA > Annualised Sales": {
  name: "AFTER SALES > ROOA > Annualised Sales",
  department: "AFTER-SALES",
  category: "MRA MODEL",
  label: "Annualised Sales",
  tableNumber: 75,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Projected Annualised Sales | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > ROOA > Average Operational Assets": {
  name: "AFTER SALES > ROOA > Average Operational Assets",
  department: "AFTER-SALES",
  category: "MRA MODEL",
  label: "Average Operational Assets",
  tableNumber: 75,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Average Operational Assets | Aftersales | All Brand | All Model | | AVG",
  canAverage: true
},
"AFTER SALES > ROOA > ROS": {
  name: "AFTER SALES > ROOA > ROS",
  department: "AFTER-SALES",
  category: "MRA MODEL",
  label: "ROS",
  tableNumber: 77,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT % of Sales | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > ROS (Income Statement) > After Sales ROS": {
  name: "AFTER SALES > ROS (Income Statement) > After Sales ROS",
  department: "AFTER-SALES",
  category: "ROS (Income Statement)",
  label: "After Sales ROS",
  tableNumber: 77,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT % of Sales | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > ROS (Income Statement) > After Sales Sales": {
  name: "AFTER SALES > ROS (Income Statement) > After Sales Sales",
  department: "AFTER-SALES",
  category: "ROS (Income Statement)",
  label: "After Sales Sales",
  tableNumber: 67,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > ROS (Income Statement) > After Sales PBT": {
  name: "AFTER SALES > ROS (Income Statement) > After Sales PBT",
  department: "AFTER-SALES",
  category: "ROS (Income Statement)",
  label: "After Sales PBT",
  tableNumber: 77,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT (Profit Before Tax) | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > ROS (Income Statement) > After Sales Dept. Profit % Net Sales": {
  name: "AFTER SALES > ROS (Income Statement) > After Sales Dept. Profit % Net Sales",
  department: "AFTER-SALES",
  category: "ROS (Income Statement)",
  label: "After Sales Dept. Profit % Net Sales",
  tableNumber: 77,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "E > Earnings After Interest % of TNS | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > ROS (Income Statement) > After Sales EBIT% (PBIT%)": {
  name: "AFTER SALES > ROS (Income Statement) > After Sales EBIT% (PBIT%)",
  department: "AFTER-SALES",
  category: "ROS (Income Statement)",
  label: "After Sales EBIT% (PBIT%)",
  tableNumber: 77,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBIT % of Sales | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > ROS (Income Statement) > After Sales Variable Selling Gross % Gorss Profit": {
  name: "AFTER SALES > ROS (Income Statement) > After Sales Variable Selling Gross % Gorss Profit",
  department: "AFTER-SALES",
  category: "ROS (Income Statement)",
  label: "After Sales Variable Selling Gross % Gorss Profit",
  tableNumber: 70,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Variable Selling Gross % of TGP | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > ROS (Income Statement) > After Sales Selling Gross % Gross Profit": {
  name: "AFTER SALES > ROS (Income Statement) > After Sales Selling Gross % Gross Profit",
  department: "AFTER-SALES",
  category: "ROS (Income Statement)",
  label: "After Sales Selling Gross % Gross Profit",
  tableNumber: 77,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Selling Gross % of TGP | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > ROS (Income Statement) > After Sales Selling Gross % Net Sales": {
  name: "AFTER SALES > ROS (Income Statement) > After Sales Selling Gross % Net Sales",
  department: "AFTER-SALES",
  category: "ROS (Income Statement)",
  label: "After Sales Selling Gross % Net Sales",
  tableNumber: 77,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Selling Gross % of TNS | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > ROS (Income Statement) > After Sales Gross Profit": {
  name: "AFTER SALES > ROS (Income Statement) > After Sales Gross Profit",
  department: "AFTER-SALES",
  category: "ROS (Income Statement)",
  label: "After Sales Gross Profit",
  tableNumber: 68,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > ROS (Income Statement) > After Sales Expenses": {
  name: "AFTER SALES > ROS (Income Statement) > After Sales Expenses",
  department: "AFTER-SALES",
  category: "ROS (Income Statement)",
  label: "After Sales Expenses",
  tableNumber: 69,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > ROS (Income Statement) > After Sales Variable Expenses": {
  name: "AFTER SALES > ROS (Income Statement) > After Sales Variable Expenses",
  department: "AFTER-SALES",
  category: "ROS (Income Statement)",
  label: "After Sales Variable Expenses",
  tableNumber: 70,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > ROS (Income Statement) > After Sales Personnel Expenses": {
  name: "AFTER SALES > ROS (Income Statement) > After Sales Personnel Expenses",
  department: "AFTER-SALES",
  category: "ROS (Income Statement)",
  label: "After Sales Personnel Expenses",
  tableNumber: 71,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PE > Personnel Expenses | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > ROS (Income Statement) > After Sales Other Semi Variable Expenses": {
  name: "AFTER SALES > ROS (Income Statement) > After Sales Other Semi Variable Expenses",
  department: "AFTER-SALES",
  category: "ROS (Income Statement)",
  label: "After Sales Other Semi Variable Expenses",
  tableNumber: 72,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "SVE > Other Semi Variable Expenses | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > ROS (Income Statement) > After Sales Fixed Expenses": {
  name: "AFTER SALES > ROS (Income Statement) > After Sales Fixed Expenses",
  department: "AFTER-SALES",
  category: "ROS (Income Statement)",
  label: "After Sales Fixed Expenses",
  tableNumber: 73,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "FE > Fixed Expenses | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > ROS (Income Statement) > After Sales Interest Expenses": {
  name: "AFTER SALES > ROS (Income Statement) > After Sales Interest Expenses",
  department: "AFTER-SALES",
  category: "ROS (Income Statement)",
  label: "After Sales Interest Expenses",
  tableNumber: 74,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Interest | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > ROS (Income Statement) > After Sales Departmental Profit": {
  name: "AFTER SALES > ROS (Income Statement) > After Sales Departmental Profit",
  department: "AFTER-SALES",
  category: "ROS (Income Statement)",
  label: "After Sales Departmental Profit",
  tableNumber: 77,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "E > Earnings After Interest (EAIT) | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > ROS (Income Statement) > After Sales Allocation of Admin Expenses": {
  name: "AFTER SALES > ROS (Income Statement) > After Sales Allocation of Admin Expenses",
  department: "AFTER-SALES",
  category: "ROS (Income Statement)",
  label: "After Sales Allocation of Admin Expenses",
  tableNumber: 69,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Adm > Admin Exp. Allocation | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > ROS (Income Statement) > After Sales EBIT": {
  name: "AFTER SALES > ROS (Income Statement) > After Sales EBIT",
  department: "AFTER-SALES",
  category: "ROS (Income Statement)",
  label: "After Sales EBIT",
  tableNumber: 77,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBIT (Profit Before Interest & Tax) | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > PRODUCTIVITY > Total Parts GP / Productive / Month": {
  name: "AFTER SALES > PRODUCTIVITY > Total Parts GP / Productive / Month",
  department: "AFTER-SALES",
  category: "PRODUCTIVITY",
  label: "Total Parts GP / Productive / Month",
  tableNumber: 96,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ave Monthly TGP per Productive | Parts | All Brand | All Model | | AVG",
  canAverage: false
},
"AFTER SALES > PRODUCTIVITY > Total Parts GP": {
  name: "AFTER SALES > PRODUCTIVITY > Total Parts GP",
  department: "AFTER-SALES",
  category: "PRODUCTIVITY",
  label: "Total Parts GP",
  tableNumber: 96,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > PRODUCTIVITY > Parts Productive Staff": {
  name: "AFTER SALES > PRODUCTIVITY > Parts Productive Staff",
  department: "AFTER-SALES",
  category: "PRODUCTIVITY",
  label: "Parts Productive Staff",
  tableNumber: 96,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Productives | Parts | All Brand | All Model | | AVG",
  canAverage: true
},
"AFTER SALES > PRODUCTIVITY > Total Parts GP / Employee / Month": {
  name: "AFTER SALES > PRODUCTIVITY > Total Parts GP / Employee / Month",
  department: "AFTER-SALES",
  category: "PRODUCTIVITY",
  label: "Total Parts GP / Employee / Month",
  tableNumber: 96,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ave Monthly TGP per Employee | Parts | All Brand | All Model | | AVG",
  canAverage: false
},
"AFTER SALES > PRODUCTIVITY > Total Parts Staff": {
  name: "AFTER SALES > PRODUCTIVITY > Total Parts Staff",
  department: "AFTER-SALES",
  category: "PRODUCTIVITY",
  label: "Total Parts Staff",
  tableNumber: 96,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Total Employees | Parts | All Brand | All Model | | AVG",
  canAverage: true
},
"AFTER SALES > PRODUCTIVITY > Total Service GP / Productive / Month": {
  name: "AFTER SALES > PRODUCTIVITY > Total Service GP / Productive / Month",
  department: "AFTER-SALES",
  category: "PRODUCTIVITY",
  label: "Total Service GP / Productive / Month",
  tableNumber: 112,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ave Monthly TGP per Productive | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: false
},
"AFTER SALES > PRODUCTIVITY > Total Service GP": {
  name: "AFTER SALES > PRODUCTIVITY > Total Service GP",
  department: "AFTER-SALES",
  category: "PRODUCTIVITY",
  label: "Total Service GP",
  tableNumber: 112,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > PRODUCTIVITY > Service Productive Staff": {
  name: "AFTER SALES > PRODUCTIVITY > Service Productive Staff",
  department: "AFTER-SALES",
  category: "PRODUCTIVITY",
  label: "Service Productive Staff",
  tableNumber: 112,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Workshop Productives | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: true
},
"AFTER SALES > PRODUCTIVITY > Total Service GP / Employee / Month": {
  name: "AFTER SALES > PRODUCTIVITY > Total Service GP / Employee / Month",
  department: "AFTER-SALES",
  category: "PRODUCTIVITY",
  label: "Total Service GP / Employee / Month",
  tableNumber: 112,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ave Monthly TGP per Employee | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: false
},
"AFTER SALES > PRODUCTIVITY > Total Service Staff": {
  name: "AFTER SALES > PRODUCTIVITY > Total Service Staff",
  department: "AFTER-SALES",
  category: "PRODUCTIVITY",
  label: "Total Service Staff",
  tableNumber: 112,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Total Employees | ServiceQuickService | All Brand | All Model | | AVG",
  canAverage: true
},
"AFTER SALES > PRODUCTIVITY > Total Body Shop GP / Productive / Month": {
  name: "AFTER SALES > PRODUCTIVITY > Total Body Shop GP / Productive / Month",
  department: "AFTER-SALES",
  category: "PRODUCTIVITY",
  label: "Total Body Shop GP / Productive / Month",
  tableNumber: 128,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ave Monthly TGP per Productive | Body Shop | All Brand | All Model | | AVG",
  canAverage: false
},
"AFTER SALES > PRODUCTIVITY > Total Body Shop GP": {
  name: "AFTER SALES > PRODUCTIVITY > Total Body Shop GP",
  department: "AFTER-SALES",
  category: "PRODUCTIVITY",
  label: "Total Body Shop GP",
  tableNumber: 128,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | Body Shop | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > PRODUCTIVITY > Body Shop Productive Staff": {
  name: "AFTER SALES > PRODUCTIVITY > Body Shop Productive Staff",
  department: "AFTER-SALES",
  category: "PRODUCTIVITY",
  label: "Body Shop Productive Staff",
  tableNumber: 128,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Workshop Productives | Body Shop | All Brand | All Model | | AVG",
  canAverage: true
},
"AFTER SALES > PRODUCTIVITY > Total Body Shop GP / Employee / Month": {
  name: "AFTER SALES > PRODUCTIVITY > Total Body Shop GP / Employee / Month",
  department: "AFTER-SALES",
  category: "PRODUCTIVITY",
  label: "Total Body Shop GP / Employee / Month",
  tableNumber: 128,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ave Monthly TGP per Employee | Body Shop | All Brand | All Model | | AVG",
  canAverage: false
},
"AFTER SALES > PRODUCTIVITY > Total Body Shop Staff": {
  name: "AFTER SALES > PRODUCTIVITY > Total Body Shop Staff",
  department: "AFTER-SALES",
  category: "PRODUCTIVITY",
  label: "Total Body Shop Staff",
  tableNumber: 128,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Total Employees | Body Shop | All Brand | All Model | | AVG",
  canAverage: true
},
"AFTER SALES > PRODUCTIVITY > After Sales Total Staff": {
  name: "AFTER SALES > PRODUCTIVITY > After Sales Total Staff",
  department: "AFTER-SALES",
  category: "PRODUCTIVITY",
  label: "After Sales Total Staff",
  tableNumber: 78,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Total Employees | Aftersales | All Brand | All Model | | AVG",
  canAverage: true
},
"AFTER SALES > PRODUCTIVITY > After Sales Productive Staff": {
  name: "AFTER SALES > PRODUCTIVITY > After Sales Productive Staff",
  department: "AFTER-SALES",
  category: "PRODUCTIVITY",
  label: "After Sales Productive Staff",
  tableNumber: 78,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Workshop Productives | Aftersales | All Brand | All Model | | AVG",
  canAverage: true
},
"AFTER SALES > PRODUCTIVITY > After Sales Support Staff incl. SA": {
  name: "AFTER SALES > PRODUCTIVITY > After Sales Support Staff incl. SA",
  department: "AFTER-SALES",
  category: "PRODUCTIVITY",
  label: "After Sales Support Staff incl. SA",
  tableNumber: 78,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Non-Productives | Aftersales | All Brand | All Model | | AVG",
  canAverage: true
},
"AFTER SALES > PRODUCTIVITY > After Sales Support Service Advisor": {
  name: "AFTER SALES > PRODUCTIVITY > After Sales Support Service Advisor",
  department: "AFTER-SALES",
  category: "PRODUCTIVITY",
  label: "After Sales Support Service Advisor",
  tableNumber: 78,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Service Advisor | Aftersales | All Brand | All Model | | AVG",
  canAverage: true
},
"AFTER SALES > FOA > FOA": {
  name: "AFTER SALES > FOA > FOA",
  department: "AFTER-SALES",
  category: "FOA",
  label: "FOA",
  tableNumber: 79,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Fixed Operation Absorption | Aftersales | All Brand | All Model | | SUM",
  canAverage: false
},
"AFTER SALES > FOA > After Sales GP": {
  name: "AFTER SALES > FOA > After Sales GP",
  department: "AFTER-SALES",
  category: "FOA",
  label: "After Sales GP",
  tableNumber: 68,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | Aftersales | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > FOA > Overhead Expenses": {
  name: "AFTER SALES > FOA > Overhead Expenses",
  department: "AFTER-SALES",
  category: "FOA",
  label: "Overhead Expenses",
  tableNumber: 79,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Overhead Expenses FOA | All Department | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > FOA > Parts GP": {
  name: "AFTER SALES > FOA > Parts GP",
  department: "AFTER-SALES",
  category: "FOA",
  label: "Parts GP",
  tableNumber: 86,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | Parts | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > FOA > Service GP": {
  name: "AFTER SALES > FOA > Service GP",
  department: "AFTER-SALES",
  category: "FOA",
  label: "Service GP",
  tableNumber: 101,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | ServiceQuickService | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > FOA > Body Shop GP": {
  name: "AFTER SALES > FOA > Body Shop GP",
  department: "AFTER-SALES",
  category: "FOA",
  label: "Body Shop GP",
  tableNumber: 117,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | Body Shop | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > FOA > Variable Expenses (Non Vehicle)": {
  name: "AFTER SALES > FOA > Variable Expenses (Non Vehicle)",
  department: "AFTER-SALES",
  category: "FOA",
  label: "Variable Expenses (Non Vehicle)",
  tableNumber: 79,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses (Non Vehicle) | All Department | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > FOA > Personnel Expenses": {
  name: "AFTER SALES > FOA > Personnel Expenses",
  department: "AFTER-SALES",
  category: "FOA",
  label: "Personnel Expenses",
  tableNumber: 6,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PE > Personnel Expenses | All Department | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > FOA > Other Semi Variable Expenses": {
  name: "AFTER SALES > FOA > Other Semi Variable Expenses",
  department: "AFTER-SALES",
  category: "FOA",
  label: "Other Semi Variable Expenses",
  tableNumber: 7,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "SVE > Other Semi Variable Expenses | All Department | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > FOA > Fixed Expenses": {
  name: "AFTER SALES > FOA > Fixed Expenses",
  department: "AFTER-SALES",
  category: "FOA",
  label: "Fixed Expenses",
  tableNumber: 8,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "FE > Fixed Expenses | All Department | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > FOA > Interest Expenses": {
  name: "AFTER SALES > FOA > Interest Expenses",
  department: "AFTER-SALES",
  category: "FOA",
  label: "Interest Expenses",
  tableNumber: 9,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Interest | All Department | All Brand | All Model | | SUM",
  canAverage: true
},
"AFTER SALES > FOA > Admin Fees": {
  name: "AFTER SALES > FOA > Admin Fees",
  department: "AFTER-SALES",
  category: "FOA",
  label: "Admin Fees",
  tableNumber: 10,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Adm > Admin Fee Payable | All Department | All Brand | All Model | | SUM",
  canAverage: true
},
};

export const AfterSalesOEMSupportsAnalysis: { [index: string]: CustomDashboardType } = {
  "AFTER SALES > OEM Supports Analysis > After Sales Support from OEM": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales Support from OEM",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales Support from OEM",
  measure: "G > OEM Incentives & Expenses Subsidy | AfterSales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: true
},
"AFTER SALES > OEM Supports Analysis > After Sales OEM Incentives": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales OEM Incentives",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales OEM Incentives",
  measure: "G > OEM Incentives | AfterSales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: true
},
"AFTER SALES > OEM Supports Analysis > After Sales OEM Expenses Subsidy": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales OEM Expenses Subsidy",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales OEM Expenses Subsidy",
  measure: "TE > OEM Expenses Subsidy | AfterSales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: true
},
"AFTER SALES > OEM Supports Analysis > After Sales OEM Direct Advertising Subsidy": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales OEM Direct Advertising Subsidy",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales OEM Direct Advertising Subsidy",
  measure: "VE > Advertising Subsidy | AfterSales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: true
},
"AFTER SALES > OEM Supports Analysis > After Sales OEM Indirect Advertising Subsidy": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales OEM Indirect Advertising Subsidy",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales OEM Indirect Advertising Subsidy",
  measure: "SVE > Indirect Advertising Subsidy | AfterSales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: true
},
"AFTER SALES > OEM Supports Analysis > After Sales OEM Incentives % TGP": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales OEM Incentives % TGP",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales OEM Incentives % TGP",
  measure: "G > OEM Incentives % TGP UsedF&IAftercare | AfterSales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: false
},
"AFTER SALES > OEM Supports Analysis > After Sales OEM Incentives % Sales": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales OEM Incentives % Sales",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales OEM Incentives % Sales",
  measure: "G > OEM Incentives % Net Sales | AfterSales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: false
},
"AFTER SALES > OEM Supports Analysis > After Sales OEM Expenses Subsidy % Sales": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales OEM Expenses Subsidy % Sales",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales OEM Expenses Subsidy % Sales",
  measure: "TE > OEM Expenses Subsidy % Net Sales | AfterSales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: false
},
"AFTER SALES > OEM Supports Analysis > After Sales OEM All Supports % Sales": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales OEM All Supports % Sales",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales OEM All Supports % Sales",
  measure: "G > OEM Incentives & Expenses Subsidy % Net Sales | AfterSales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: false
},
"AFTER SALES > OEM Supports Analysis > After Sales MIX": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales MIX",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales MIX",
  measure: "G > Gross Profit % of Sales (MIX) | Aftersales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: false
},
"AFTER SALES > OEM Supports Analysis > After Sales MIX (excl Incentive)": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales MIX (excl Incentive)",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales MIX (excl Incentive)",
  measure: "G > Gross Profit (excl Incentive) % of Net Sales | AfterSales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: false
},
"AFTER SALES > OEM Supports Analysis > After Sales RETAINED": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales RETAINED",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales RETAINED",
  measure: "PBT > PBT % of TGP: RETAINED | Aftersales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: false
},
"AFTER SALES > OEM Supports Analysis > After Sales RETAINED (excl All Supports from OEM)": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales RETAINED (excl All Supports from OEM)",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales RETAINED (excl All Supports from OEM)",
  measure: "PBT > PBT % of TGP: RETAINED (excl OEM Incentives & Expenses Subsidy) | Aftersales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: false
},
"AFTER SALES > OEM Supports Analysis > After Sales RETAINED (excl Incentives)": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales RETAINED (excl Incentives)",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales RETAINED (excl Incentives)",
  measure: "PBT > PBT % of TGP: RETAINED (excl Incentive) | Aftersales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: false
},
"AFTER SALES > OEM Supports Analysis > After Sales RETAINED (excl OEM Expenses Subsidy)": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales RETAINED (excl OEM Expenses Subsidy)",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales RETAINED (excl OEM Expenses Subsidy)",
  measure: "PBT > PBT % of TGP: RETAINED (excl OEM Expenses Subsidy) | Aftersales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: false
},
"AFTER SALES > OEM Supports Analysis > After Sales Expenses % TGP": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales Expenses % TGP",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales Expenses % TGP",
  measure: "TE > Total Expenses % of TGP | Aftersales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: false
},
"AFTER SALES > OEM Supports Analysis > After Sales Expenses % TGP (excl All Supports from OEM)": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales Expenses % TGP (excl All Supports from OEM)",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales Expenses % TGP (excl All Supports from OEM)",
  measure: "TE > Total Expenses % of TGP (excl OEM Incentives & Expenses Subsidy) | Aftersales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: false
},
"AFTER SALES > OEM Supports Analysis > After Sales Expenses % TGP (excl Incentives)": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales Expenses % TGP (excl Incentives)",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales Expenses % TGP (excl Incentives)",
  measure: "TE > Total Expenses % of TGP (excl Incentive) | Aftersales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: false
},
"AFTER SALES > OEM Supports Analysis > After Sales Expenses % TGP (excl OEM Expenses Subsidy)": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales Expenses % TGP (excl OEM Expenses Subsidy)",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales Expenses % TGP (excl OEM Expenses Subsidy)",
  measure: "TE > Total Expenses % of TGP (excl OEM Expenses Subsidy) | Aftersales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: false
},
"AFTER SALES > OEM Supports Analysis > After Sales ROS": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales ROS",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales ROS",
  measure: "PBT > PBT % of Sales | Aftersales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: false
},
"AFTER SALES > OEM Supports Analysis > After Sales ROS (excl All Supports from OEM)": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales ROS (excl All Supports from OEM)",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales ROS (excl All Supports from OEM)",
  measure: "PBT > PBT (excl OEM Incentives & Expenses Subsidy) % of Sales | Aftersales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: false
},
"AFTER SALES > OEM Supports Analysis > After Sales ROS (excl Incentives)": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales ROS (excl Incentives)",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales ROS (excl Incentives)",
  measure: "PBT > PBT (excl OEM Incentive) % of Sales | Aftersales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: false
},
"AFTER SALES > OEM Supports Analysis > After Sales ROS (excl OEM Expenses Subsidy)": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales ROS (excl OEM Expenses Subsidy)",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales ROS (excl OEM Expenses Subsidy)",
  measure: "PBT > PBT (excl OEM Expenses Subsidy) % of Sales | Aftersales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: false
},
"AFTER SALES > OEM Supports Analysis > After Sales Sales": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales Sales",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales Sales",
  measure: "TNS > Net Sales | Aftersales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: true
},
"AFTER SALES > OEM Supports Analysis > After Sales GP": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales GP",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales GP",
  measure: "G > Gross Profit | Aftersales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: true
},
"AFTER SALES > OEM Supports Analysis > After Sales GP (excl Incentives)": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales GP (excl Incentives)",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales GP (excl Incentives)",
  measure: "G > Gross Profit (excl Incentive) | Aftersales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: true
},
"AFTER SALES > OEM Supports Analysis > After Sales Expenses": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales Expenses",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales Expenses",
  measure: "TE > Total Expenses | Aftersales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: true
},
"AFTER SALES > OEM Supports Analysis > After Sales Expenses (excl OEM Expenses Subsidy)": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales Expenses (excl OEM Expenses Subsidy)",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales Expenses (excl OEM Expenses Subsidy)",
  measure: "TE > Total Expenses (excl Expenses Subsidy) | Aftersales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: true
},
"AFTER SALES > OEM Supports Analysis > After Sales PBT": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales PBT",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales PBT",
  measure: "PBT > PBT (Profit Before Tax) | Aftersales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: true
},
"AFTER SALES > OEM Supports Analysis > After Sales PBT (excl All Supports from OEM)": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales PBT (excl All Supports from OEM)",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales PBT (excl All Supports from OEM)",
  measure: "PBT > PBT (excl OEM Incentives & Expenses Subsidy) | Aftersales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: true
},
"AFTER SALES > OEM Supports Analysis > After Sales PBT (excl Incentives)": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales PBT (excl Incentives)",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales PBT (excl Incentives)",
  measure: "PBT > PBT (excl OEM Incentive) | Aftersales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: true
},
"AFTER SALES > OEM Supports Analysis > After Sales PBT (excl OEM Expenses Subsidy)": {
  name: "AFTER SALES > OEM Supports Analysis > After Sales PBT (excl OEM Expenses Subsidy)",
  department: "AFTER-SALES",
  category: "OEM Supports Analysis",
  label: "After Sales PBT (excl OEM Expenses Subsidy)",
  measure: "PBT > PBT (excl OEM Expenses Subsidy) | Aftersales | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 530,
  canAverage: true
},
};
