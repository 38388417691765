import React, { createContext, useState, useReducer, useMemo } from "react";
import { StackedSeries, Report, Query, stringifyQuery, stackedFor } from "../lib/api/charts";
import { Loading } from "./base";

type SeriesSet = { series: StackedSeries, loading: Loading };

export type StackedChartContextState = {
  series: {
    [index: string]: SeriesSet;
  };
  getStackedSeries(report: Report, query: Query, variation: string): Partial<SeriesSet>;
};

export const StackedChartContext = createContext<StackedChartContextState>({
  series: {},
  getStackedSeries: () => ({}),
});

type QueryAction = {
  type: "PATCH_SERIES";
  id: string;
  data: Partial<SeriesSet>;
};

const defaultSeries: SeriesSet = { series: { name: "", stacked: true, measures: [], lines: [], bars: [], linesb: [], barsb: [], rows: [], classifications: [], types: [], formats: [], type: "stacked" }, loading: { loaded: false, loading: true, error: null }  };

export const StackedChartProvider = ({ children }) => {
  const memo = (report: Report, query: Query, variation: string) => `${report}-${stringifyQuery(query)}-${variation}`;

  const [series, dispatchSeries] = useReducer((state: { [index: string]: SeriesSet }, action: QueryAction) => {
    switch (action.type) {
      case "PATCH_SERIES":
        return {
          ...state,
          [action.id]: {
            ...state[action.id],
            ...action.data,
          },
        };
      default:
        return state;
    }
  }, {});

  const value = useMemo(() => ({
    series,
    getStackedSeries: (report: Report, query: Query, variation: string) => {
      const id = memo(report, query, variation);
      const set = series[id];

      if (set) {
        return set;
      }

      dispatchSeries({
        type: "PATCH_SERIES",
        id,
        data: defaultSeries,
      });

      stackedFor(query, report, variation).then((series) => {
        dispatchSeries({
          type: "PATCH_SERIES",
          id,
          data: {
            series: series,
            loading: {
              loaded: true,
              loading: false,
              error: null
            }
          },
        });
      }).catch((e) => {
        dispatchSeries({
          type: "PATCH_SERIES",
          id,
          data: {
            loading: {
              loaded: false,
              loading: false,
              error: e
            }
          },
        });
      });

      return defaultSeries;
    }
  }), [series]);

  return (
    <StackedChartContext.Provider value={value}>
      {children}
    </StackedChartContext.Provider>
  )
}
