import * as React from 'react';
import { createPortal } from 'react-dom';


export type LoginPortalProps = {

} & React.HTMLAttributes<HTMLDivElement>;

export const LoginPortal: React.SFC<LoginPortalProps> = ({  children, className }) => createPortal(
  children,
  document.getElementById("login-portal")
);
