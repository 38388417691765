import { request } from "./base";

export type Critera = {
  value: string;
  label: string;
};

export type CriteriaSet = {
  oems: Critera[];
  regions: Critera[];
  dealerSets: Critera[];
  dealerGroups: Critera[];
  countries: Critera[];
  excludeNewDealers: boolean;
};

export const criteria = async () => {
  const res = await request("/filter-criteria");
  const data: CriteriaSet = await res.json();
  return data;
};
