import React, { useEffect, useState, useCallback } from "react";
import classnames from "classnames";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { DashboardConfigContext } from "../../contexts/DashboardConfigContext";
import { Dealer } from "../../lib/api/charts";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import { Select } from "../../components/Select";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { DealerContext } from "../../contexts/DealerContext";
import { SubmissionReloadContext, computeEndMonth } from "./SubmissionReloadContext";
import { FormattedMessage } from "react-intl";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    tableGridHead: {
      border: "1px solid #ddd",
      borderBottom: "none",
      marginTop: theme.spacing.unit * 2,
    },
    tableGrid: {
      height: "120px",
      overflowY: "scroll",
      border: "1px solid #ddd",
      borderTop: "none",
    },
    tableHead: {
      backgroundColor: "white",
      fontSize: theme.typography.fontSize * 1,
    },
    tableBody: {},
    cell: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      fontSize: "0.8rem",
    },
    cellHead: {
      backgroundColor: "#f3f3f3",
    },
    clickable: {
      cursor: "pointer",
    },
    tableBodyRow: {
      backgroundColor: "white",
    },
    code: {
      width: 100,
    },
    selectInput: {
      padding: 0,
    },
    inputText: {
      backgroundColor: "white",
      padding: 9,
      fontSize: `${theme.typography.fontSize + 2}px`,
    },
    control: {
      marginTop: theme.spacing.unit * 2,
      width: "100%",
    },
    hr: {
      width: "95%",
      marginTop: 15,
    },
    formLabelText: {
      paddingBottom: theme.spacing.unit * 1,
      fontSize: "0.8rem",
    },
    selectInputs: {
      fontSize: `${theme.typography.fontSize + 2}px`,
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    },
    gridButton: {
      marginTop: "40px",
    },
  });

export type DealerSelectionProps = {
  tempDealers: any;
  setTempDealers: any;
  filteredDealers: any;
  dealerVisibilty: boolean;
} & React.HTMLAttributes<HTMLDivElement> &
  WithStyles<typeof styles>;

const DealerSelectionUnstlyed: React.SFC<DealerSelectionProps> = ({
  classes,
  children,
  className,
  tempDealers,
  setTempDealers,
  filteredDealers,
  dealerVisibilty,
}) => {
  const { selectedDealers } = React.useContext(SubmissionReloadContext);

  const handleDealerSelection = useCallback(
    (opt: any) => {
      if (Boolean(tempDealers.find(selected => selected.code === opt.code))) {
        setTempDealers([...tempDealers.filter(d => d.code != opt.code)]);
      } else {
        setTempDealers([...tempDealers, opt]);
      }
    },
    [tempDealers, setTempDealers],
  );

  return (
    <div className={classnames(classes.root, className)}>
      <Grid item xs={12} className={classes.tableGridHead}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell className={classnames(classes.cell, classes.cellHead)} style={{ maxWidth: "80px", width: "80px" }} >
                {dealerVisibilty && <>
                  <span className={classes.clickable} onClick={() => setTempDealers(filteredDealers)}>
                    <FormattedMessage id="all.label" /> | &nbsp;
                  </span>

                  <span className={classes.clickable} onClick={() => setTempDealers([])}>
                    <FormattedMessage id="clear.button" />
                  </span>
                </>}
              </TableCell>
              <TableCell className={classnames(classes.cell, classes.cellHead, classes.code)} style={{ maxWidth: "100px", width: "100px" }}>
                Dealer Code
              </TableCell>
              <TableCell className={classnames(classes.cell, classes.cellHead)}>Dealer Name</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Grid>
      <Grid item xs={12} className={classes.tableGrid}>
        <Table>
          <TableBody className={classes.tableBody}>
            {(dealerVisibilty ? filteredDealers : []).map((opt, indx: number) => {
              if (!selectedDealers.find(dealer => opt.code == dealer.code)) {
                return (
                  <TableRow className={classes.tableBodyRow} key={indx}>
                    <TableCell className={classes.cell} style={{ maxWidth: "70px", width: "70px" }}>
                      <Checkbox
                        checked={Boolean(tempDealers.find(selected => selected.code === opt.code))}
                        className={classes.selectInput}
                        onChange={() => handleDealerSelection(opt)}
                        value={opt.dealer}
                        name="dealer"
                        color="default"
                      />
                    </TableCell>
                    <TableCell className={classes.cell} style={{ maxWidth: "100px", width: "100px" }}>
                      {opt.code}
                    </TableCell>
                    <TableCell className={classes.cell}>{opt.dealer}</TableCell>
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </Grid>
    </div>
  );
};

export const DealerSelection = withStyles(styles)(DealerSelectionUnstlyed);
