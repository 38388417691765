import { request } from "./base";

export type NotificationAttachment = {
  Id?: number;
  MessageId?: string;
  Content?: Buffer;
  FieldName: string;
  OriginalName: string;
  Encoding: string;
  MimeType: string;
  Size: number;
};

export type NotificationMessage = {
  Id: string;
  Title: string;
  Body: string;
  SenderId: number;
  SentBy: string;
  ParentId: string;
  Sent: Date;
  Type: string;
};

export type NotificationRecipient = {
  UserId: number;
  MessageId: string;
};

export type Message = NotificationMessage & {
  replies: Message[];
  attachments: NotificationAttachment[];
};

export const messages = () =>
  request("/notifications")
    .then(res => res.json())
    .then(({ data }) => data as NotificationMessage[]);

export const users = (
  filter: {
    oem: string[];
    region: string[];
    dealerSet: string[];
    dealerGroup: string[];
    country: string[];
    dealer: string[];
    designation: string[];
  },
  searched: string,
  limit = true,
) =>
  request(`/notifications/users`, {
    method: "POST",
    body: JSON.stringify({ filter, searched: searched, limit: limit }),
  })
    .then(res => res.json())
    .then(({ data }) => data as { UserId: string; Name: string; UserDesignation: string }[]);

export const message = (id: string) =>
  request(`/notifications/${id}`)
    .then(res => res.json())
    .then(({ data }) => data as Message);

export const removeMessage = (id: string) =>
  request(`/notifications/${id}`, { method: "DELETE" })
    .then(res => res.json())
    .then(({ data }) => data);

export const createMessage = async (message: Partial<NotificationMessage>, recipients: string[], attachments: File[]) => {
  const formData = new FormData();

  Object.keys(message).forEach((key: any) => {
    if ((message as any)[key]) {
      formData.append(key, (message as any)[key]);
    }
  });

  formData.append("recipients", (recipients || []).join(","));
  (attachments || []).forEach((file, i) => {
    formData.append("attatchments", file);
  });

  await request("/notifications", {
    method: "post",
    headers: {},
    body: formData,
  })
    .then(res => res.json())
    .then(({ data }) => data as NotificationMessage);
};
