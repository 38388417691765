import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { Report } from "../lib/api/charts";
import { CustomDashboardMeasures, CustomDashboardType, AdditionalStatsMeasures } from "./CustomDashReports";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { FormattedMessage, useIntl } from "react-intl";
import { translation } from "../translations/Translations";
import { useMediaQuery } from "react-responsive";
import { CustomDashboardDepartmentsContext } from "../contexts/Custom/CustomDashboardDepartmentsContext";
import { AdditionalStatsContext } from "../contexts/Custom/AdditionalStatsContext";
import { DealerModelsContext } from "../contexts/Custom/DealerModelsContext";
import { OverallDealership, OverallDealershipOEMSupportsAnalysis } from "./custom-dashboard-reports/OverallDealership";
import { BalanceSheet } from "./custom-dashboard-reports/BalanceSheet";
import { NewVehicle, NewVehicleOEMSupportsAnalysis } from "./custom-dashboard-reports/NewVehicle";
import { UsedVehicle, UsedVehicleOEMSupportsAnalysis } from "./custom-dashboard-reports/UsedVehicle";
import { FI, FI_OEMSupportsAnalysis } from "./custom-dashboard-reports/F&I";
import { AfterSales, AfterSalesOEMSupportsAnalysis } from "./custom-dashboard-reports/AfterSales";
import { Parts, PartsOEMSupportsAnalysis } from "./custom-dashboard-reports/Parts";
import { Service, ServiceOEMSupportsAnalysis } from "./custom-dashboard-reports/Service";
import { BodyShop, BodyShopOEMSupportsAnalysis } from "./custom-dashboard-reports/BodyShop";
import { Driveway, DrivewayOEMSupportsAnalysis } from "./custom-dashboard-reports/Driveway";
import { DealerContext } from "../contexts/DealerContext";
import { OemContext } from "../contexts/OemContext";
import { FordAcademyKPIs } from "./custom-dashboard-reports/FordAcademy";
import { VCJProcessData1, VCJProcessData2 } from "./custom-dashboard-reports/VCJProcessData";
import { NetworkStats } from "./custom-dashboard-reports/NetworkStats";
import { fordSAScorecard } from "./custom-dashboard-reports/FordSAScrorecard";
import { Input } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.fontSize + 2,
      fontWeight: "bold",
      color: "#2f2f2f",
      marginLeft: theme.spacing.unit * 2,
      cursor: "pointer",
    },
    reportHeader: {
      cursor: "pointer",
      fontWeight: "bold",
      fontSize: `${theme.typography.fontSize + 4}px`,
    },
    rightArr: {
      fill: "#636463",
      marginLeft: "5px",
      fontSize: "16px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    menuItemText: {
      width: "100%",
    },
  });

type Departments =
  | "Additional Stats"
  | "After Sales"
  | "Balance Sheet"
  | "Body Shop"
  | "Carline Analysis"
  | "Driveway"
  | "F&I"
  | "NV F&I"
  | "OEM Support Analysis"
  | "Overall Dealership"
  | "Parts"
  | "Service"
  | "UV F&I"
  | "Ford Academy KPIs"
  | "VCJ Process Data"
  | "VCJ Process Data 2022"
  | "Network Stats"
  | "Ford SA Scorecard";

const findDepartment = (department: Departments) => {
  const { departments } = React.useContext(CustomDashboardDepartmentsContext);

  return departments.find(d => d.name === department);
};

export type ReportSelectorProps = {
  report: Report;
  handleSelection?(report: Report): void;
  title: String;
  isPieChart?: boolean;
  setAllowPie?(allow: boolean): void;
} & WithStyles<typeof styles>;

const ReportSelectorUnstyled: React.FunctionComponent<ReportSelectorProps> = ({ classes, report: reportKey, handleSelection, title, isPieChart, setAllowPie }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [categoryEl, setCategoryEl] = React.useState(null);
  const [labelEl, setLabelEl] = React.useState(null);
  const [kpiSearch, setKpiSearch] = React.useState("");
  const [department, setDepartment] = React.useState<string>("");
  const [category, setCategory] = React.useState<string>("");
  const [label, setLabel] = React.useState<string>("");

  const { carlineAnalysisMeasures } = React.useContext(DealerModelsContext);
  const { selectedDealers } = React.useContext(DealerContext);
  const { oems } = React.useContext(OemContext);
  const { additionalStats } = React.useContext(AdditionalStatsContext);

  const determineAdditionalStats = () => {
    try {
      const oemcodes = selectedDealers.reduce((a, c) => {
        return a.concat(c.oems);
      }, []);
      
      return oems.filter(oem => oemcodes.includes(oem.code)).some(oem => oem.enableCustomAdditionalStats);
    }
    catch (error) {
      return [];
    }
  };

  const AllMeasures: { [index: string]: CustomDashboardType } = {
    ...(findDepartment("Overall Dealership") && OverallDealership),
    ...(findDepartment("Balance Sheet") && BalanceSheet),
    ...(findDepartment("NV F&I") && NewVehicle),
    ...(findDepartment("Carline Analysis") && carlineAnalysisMeasures),
    ...(findDepartment("UV F&I") && UsedVehicle),
    ...(findDepartment("F&I") && FI),
    ...(findDepartment("After Sales") && AfterSales),
    ...(findDepartment("Parts") && Parts),
    ...(findDepartment("Service") && Service),
    ...(findDepartment("Body Shop") && BodyShop),
    ...(findDepartment("Driveway") && Driveway),
    ...(findDepartment("Network Stats") && NetworkStats),
    ...(findDepartment("Ford Academy KPIs") && FordAcademyKPIs),
    ...(findDepartment("VCJ Process Data 2022") && VCJProcessData1),
    ...(findDepartment("VCJ Process Data") && VCJProcessData2),
    ...(findDepartment("Ford SA Scorecard") && fordSAScorecard),
    ...((additionalStats || determineAdditionalStats()) && findDepartment("Additional Stats") && AdditionalStatsMeasures),
    ...(findDepartment("Overall Dealership") && findDepartment("OEM Support Analysis") && OverallDealershipOEMSupportsAnalysis),
    ...(findDepartment("NV F&I") && findDepartment("OEM Support Analysis") && NewVehicleOEMSupportsAnalysis),
    ...(findDepartment("UV F&I") && findDepartment("OEM Support Analysis") && UsedVehicleOEMSupportsAnalysis),
    ...(findDepartment("F&I") && findDepartment("OEM Support Analysis") && FI_OEMSupportsAnalysis),
    ...(findDepartment("After Sales") && findDepartment("OEM Support Analysis") && AfterSalesOEMSupportsAnalysis),
    ...(findDepartment("Parts") && findDepartment("OEM Support Analysis") && PartsOEMSupportsAnalysis),
    ...(findDepartment("Service") && findDepartment("OEM Support Analysis") && ServiceOEMSupportsAnalysis),
    ...(findDepartment("Body Shop") && findDepartment("OEM Support Analysis") && BodyShopOEMSupportsAnalysis),
    ...(findDepartment("Driveway") && findDepartment("OEM Support Analysis") && DrivewayOEMSupportsAnalysis),
  };

  const customMeasures = Object.keys(AllMeasures) || [];

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });


  const departments = React.useMemo(() => {
    if (handleSelection) {
      const arr = [];
      customMeasures.map(key => {
        if (kpiSearch === "" ) {
          if (!arr.includes(AllMeasures[key].department)) {
            return arr.push(AllMeasures[key].department);
          }
          return AllMeasures[key].department;
        } else {
          if (AllMeasures[key].label.toLocaleLowerCase().includes(kpiSearch.toLocaleLowerCase())) {
          if (!arr.includes(AllMeasures[key].department)) {
            return arr.push(AllMeasures[key].department);
          }
          return AllMeasures[key].department;
          }
        }

      });
      return arr;
    }
  }, [kpiSearch]);

  const categories = React.useMemo(() => {
    if (handleSelection) {
      const arr = [];
      customMeasures.map(key => {
        if (kpiSearch === "") {
          if (AllMeasures[key].department === department) {
            if (!arr.includes(AllMeasures[key].category)) {
              return arr.push(AllMeasures[key].category);
            }
            return AllMeasures[key].category;
          }
        } else {
          if (AllMeasures[key].department === department 
            && AllMeasures[key].label.toLocaleLowerCase().includes(kpiSearch.toLocaleLowerCase())) 
            {
            if (!arr.includes(AllMeasures[key].category)) {
              return arr.push(AllMeasures[key].category);
            }
            return AllMeasures[key].category;
          }
        }
        return AllMeasures[key].category;
      });
      return arr;
    }
  }, [department, kpiSearch]);

  const labels = React.useMemo(() => {
    if (handleSelection) {
      const arr = [];
      customMeasures.map(key => {
        if (
          AllMeasures[key].department === department && 
          AllMeasures[key].category === category && 
          AllMeasures[key].label.toLocaleLowerCase().includes(kpiSearch.toLocaleLowerCase())) {
          if (!arr.includes(AllMeasures[key].label)) {
            return arr.push(key);
          }
          return AllMeasures[key].label;
        }
        return AllMeasures[key].label;
      });
      return arr;
    }
  }, [department, category, kpiSearch]);

  if (setAllowPie ) {
    Object.keys(AllMeasures).forEach((key) => {
      if (AllMeasures[key].label === title){
        setAllowPie(!AllMeasures[key].canAverage);
        return;
      }
    })
  }

  const intl = useIntl();
  if (handleSelection) {
    return (
      <div>
        <Typography
          aria-haspopup="true"
          className={classes.reportHeader}
          onClick={(e: any) => setAnchorEl(e.currentTarget)}
          aria-owns={anchorEl ? "department-menu" : undefined}
        >
          {translation[`${title}`] ? <FormattedMessage id={translation[`${title}`]} /> : title} 
        </Typography>
        {/* Department Dropdown menu */}
        {isDesktopOrLaptop && (
          <>
          <Menu
            anchorEl={anchorEl}
            id="department-menu"
            onClose={() => setAnchorEl(null)}
            open={Boolean(anchorEl)}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
          >
            <MenuItem disabled value="">
              <em>
                <FormattedMessage id={translation["Departments"]} />
              </em>
            </MenuItem>
            <MenuItem>
              <Input
                placeholder="search KPI"
                onChange={(val) => {
                  setKpiSearch(val.target.value);
                }}
              />
            </MenuItem>
            {(departments || []).map((key: string) => (
              <MenuItem
                key={key}
                selected={department == key}
                aria-owns={categoryEl ? "category-menu" : undefined}
                aria-haspopup="true"
                onClick={(e: any) => {
                  setDepartment(key);
                  setCategoryEl(e.currentTarget);
                }}
              >
                <Typography className={classes.menuItemText}>{translation[key] ? intl.formatMessage({ id: translation[key] }) : key}</Typography>
                <KeyboardArrowRight className={classes.rightArr} />
              </MenuItem>
            ))}
          </Menu>
          </>
        )}
        {/* Category Dropdown menu */}
        {isDesktopOrLaptop && (
          <Menu
            id="category-menu"
            anchorEl={categoryEl}
            open={Boolean(categoryEl)}
            onClose={() => setCategoryEl(null)}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
          >
            <MenuItem disabled value="">
              <em>
                <FormattedMessage id={translation["Categories"]} />
              </em>
            </MenuItem>
            {(categories || []).map((key: string) => (
              <MenuItem
                key={key}
                selected={category == key}
                aria-owns={categoryEl ? "label-menu" : undefined}
                onClick={(e: any) => {
                  setCategory(key);
                  setLabelEl(e.currentTarget);
                }}
              >
                <Typography className={classes.menuItemText}>{translation[key] ? intl.formatMessage({ id: translation[key] }) : key}</Typography>
                <KeyboardArrowRight className={classes.rightArr} />
              </MenuItem>
            ))}
          </Menu>
        )}
        {/* Labels Dropdown menu */}
        {isDesktopOrLaptop && (
          <Menu
            id="label-menu"
            anchorEl={labelEl}
            open={Boolean(labelEl)}
            onClose={() => setLabelEl(null)}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
          >
            <MenuItem disabled value="">
              <em>
                <FormattedMessage id={translation["Labels"]} />
              </em>
            </MenuItem>
            {(labels || []).map((key: string) => (
              <MenuItem
                disabled={isPieChart && !AllMeasures[key].canAverage}
                key={key}
                selected={label == AllMeasures[key].label}
                onClick={() => {
                  setLabel(key);
                  customMeasures.map(measure => {
                    if (AllMeasures[measure].name === key) {
                      setAnchorEl(null);
                      setCategoryEl(null);
                      setLabelEl(null);
                      return handleSelection(AllMeasures[measure].name as any);
                    }
                  });
                }}
              >
                <Typography className={classes.menuItemText}>{AllMeasures[key].label}</Typography>
              </MenuItem>
            ))}
          </Menu>
        )}
      </div>
    );
  }
  return <Typography className={classes.heading}>{title ? title : CustomDashboardMeasures[reportKey].name}</Typography>;
};

export const CustomReportSelector = withStyles(styles)(ReportSelectorUnstyled);
