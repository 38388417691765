import React, { createContext, useState, useMemo } from "react";
import { Loading } from "./base";
import { getTabs } from "../lib/api/tab-access";

const defaultTabAccess = [];

export type TabAccess = {
  name: string;
  tabId: number;
};

export type TabAccessContextState = {
  tabs: TabAccess[];
  loading: Loading;
  hydrate();
};

export const TabAccessContext = createContext<TabAccessContextState>({
  tabs: defaultTabAccess,
  loading: { loading: false, loaded: false, error: null },
  hydrate: () => {},
});

export const TabAccessProvider = ({ children }) => {
  const [tabs, setTabs] = useState<TabAccess[]>(defaultTabAccess);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const value = useMemo(
    () => ({
      tabs,
      loading,
      hydrate: async () => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          const data = await getTabs();
          setTabs(data);
          setLoading({
            loading: false,
            loaded: true,
            error: null,
          });
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      setTabs,
    }),
    [tabs, loading],
  );
  return <TabAccessContext.Provider value={value}>{children}</TabAccessContext.Provider>;
};
