import React, { createContext, useState, useReducer, useMemo, useContext } from "react";
import { Loading } from "./base";
import { userConfig, updateUserConfig, DashboardConfig, defaultDashboardConfig } from "../lib/api/user";
import { DealerContext } from "./DealerContext";

export type DashboardConfigContextState = {
  configs: { [index: string]: any };
  loading: Loading;
  dashboardTypeConfig: { label: string; value: string; };
  dashboardTypeConfigs: { [index: string]: { label: string; value: string; } };
  oemSpecificView: boolean;
  setDashboardTypeConfig: (val) => void;
  getConfig(key: string, defaultValue: any): any;
  updateConfig: (key: string, newConf: DashboardConfig) => void;
  updateUserConfig: (config: Object) => void;
  updateOemSpecificView: (value: boolean) => void;
  hydrate();
  updateResetAllDashboards();
};


const dashboardTypeConfigs = {
  mra: { label: 'MRA Dashboard', value: 'mra_dashboard' },
  mraDrivers: { label: 'MRA Drivers', value: 'mra_drivers' },
}

const defaultDashboardTypeConfigSelected = dashboardTypeConfigs.mra;

export const DashboardConfigContext = createContext<DashboardConfigContextState>({
  configs: {},
  loading: { loading: false, loaded: false, error: null },
  dashboardTypeConfig: defaultDashboardTypeConfigSelected,
  dashboardTypeConfigs: dashboardTypeConfigs,
  oemSpecificView: false,
  setDashboardTypeConfig: (val) => { },
  getConfig: () => ({ dashboard: "", items: {} }),
  updateConfig: (key: string, newConf: DashboardConfig) => { },
  updateUserConfig: (config: Object) => { },
  hydrate: () => { },
  updateOemSpecificView: () => { },
  updateResetAllDashboards: () => { },
});


export const DashboardConfigProvider = ({ children }) => {
  const [configs, setDashboardConfigs] = useState<{ [index: string]: DashboardConfig }>({});

  const [dashboardTypeConfig, setDashboardTypeConfig] = useState<{ label: string; value: string; }>(defaultDashboardTypeConfigSelected);
  const [oemSpecificView, setOemSpecificView] = useState<boolean>(false);

  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const { setDealer, available } = useContext(DealerContext);

  const updateConfig = async (config: string, newConfig: DashboardConfig) => {
    const patched = { ...configs[config], ...newConfig };
    const newConfigs = { ...configs, [config]: patched };
    await updateUserConfig(newConfigs); // fire off to backend to store
    setDashboardConfigs(newConfigs);
  };

  const updateResetAllDashboards = async () => {
    let newConfigs = configs;
    await Object.keys(defaultDashboardConfig).map(key => {
      newConfigs = { ...newConfigs, [key]: defaultDashboardConfig[key] };
    });
    await updateUserConfig(newConfigs);
    setDashboardConfigs(newConfigs);
  };

  const updateOemSpecificView = (value: boolean) => {
    setOemSpecificView(value)
    if (value) {
      setDashboardTypeConfig(defaultDashboardTypeConfigSelected)
    }
  }

  const hydrate = () => {
    setLoading({
      loading: true,
      loaded: false,
      error: null,
    });
    return userConfig()
      .then(configs => {
        setDashboardConfigs(configs);
        setLoading({
          loading: false,
          loaded: true,
          error: null,
        });
      })
      .catch(e => {
        setLoading({
          loading: false,
          loaded: false,
          error: e,
        });
      });
  };
  const value = useMemo(
    () => ({
      configs,
      loading,
      hydrate,
      getConfig: (key: string, defaultValue = { dashboard: "", items: {} }) => {
        const config = configs[key];
        if (config) {
          return config;
        }
        hydrate();
        return defaultValue;
      },
      updateConfig,
      updateUserConfig,
      updateResetAllDashboards,
      dashboardTypeConfig,
      dashboardTypeConfigs,
      setDashboardTypeConfig,
      oemSpecificView,
      updateOemSpecificView
    }),
    [configs, loading, dashboardTypeConfig, oemSpecificView],
  );

  return <DashboardConfigContext.Provider value={value}>{children}</DashboardConfigContext.Provider>;
};
