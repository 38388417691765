import Card from "@material-ui/core/Card";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Settings from "@material-ui/icons/Settings";
import classnames from "classnames";
import React, { useContext } from "react";
import { Loading } from "../components/Loading";
import { MetricReport, Query } from "../lib/api/charts";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";
import { history } from "../lib/history";
import { Route } from "react-router-dom";
import { GroupMetricContext } from "../contexts/GroupMetricContext";
import { FormattedMessage, useIntl } from "react-intl";
import { TruncatedMetricText } from "./MetricCard";
import { DashboardConfigContext } from "../contexts/DashboardConfigContext";
import Tooltip from "@material-ui/core/Tooltip";
import { translation } from "../translations/Translations";

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      border: "1px #DADADA solid",
      backgroundColor: "#FFF",
      borderRadius: "5px",
      // boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06) !important",
      padding: `${theme.spacing.unit * 2}px`,
      color: "#191919",
      minHeight: "205px",
      height: "100%",
    },
    cardDriverContent: {
      display: "grid",
      gridTemplateAreas: `
      "content"
    `,
      gridGap: `${theme.spacing.unit}px`,
      gridTemplateColumns: "auto",
      color: "#555",
    },
    cardHeader: {
      color: "#191919",
      fontWeight: 600,
      paddingBottom: theme.spacing.unit / 2,
    },
    dealerTable: {
      paddingTop: "4px",
      display: "grid",
      gridAutoFlow: "column",
      gridTemplateColumns: "1fr 1fr",
      columnGap: "8px",
    },
    benchmarkGroup: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      paddingBottom: theme.spacing.unit / 2,
      gridColumnGap: "10px",
    },
    header: {
      display: "grid",
      backgroundColor: "#FFF",
      gridGap: `${theme.spacing.unit}px`,
      padding: theme.spacing.unit,
      paddingLeft: 0,
      paddingBottom: 0,
      color: "#191919",
    },
    boldRight: {
      display: "flex",
      fill: "#636463",
      justifyContent: "flex-end",
      // fontWeight: 400,
    },
    heading: {
      height: "55px",
    },
    mainTitleText: {
      fontSize: `${theme.typography.fontSize * 2 + 2}px`,
      whiteSpace: "nowrap",
    },
    mainTitle: {
      textDecoration: "underline",
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    yellow: {
      color: `${orange[500]} !important`,
    },
    red: {
      color: `${red[500]} !important`,
    },
    black: {
      color: "#191919",
    },
    bold: {
      fontWeight: 600,
    },
    dealerInfo: {
      display: "grid",
      gridTemplateColumns: "2fr 1fr",
      gridGap: "5px",
    },
    benchmarkText: {
      display: "flex",
      justifyContent: "space-between",
    },
    cardText: {
      lineHeight: "1.8em !important",
      fontSize: "12px",
      color: "#191919",
    },
    cardValue: {
      lineHeight: "1.8em !important",
      fontSize: "12px",
      color: "#191919",
      textAlign: "right",
    },
    settings: {
      fill: "#636463",
      fontSize: "24px !important",
      "&:hover": {
        cursor: "pointer",
      },
    },
    actions: {
      display: "none",
      flexDirection: "row",
      alignItems: "center",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
      [theme.breakpoints.up("lg")]: {
        display: "block",
      },
      [theme.breakpoints.up("xl")]: {
        display: "block",
      },
    },
    metricCardHeader: {
      display: "flex",
      height: "55px",
      justifyContent: "space-between",
      fontWeight: "bold",
    },
    hideCard: {
      display: "none",
      [theme.breakpoints.up("lg")]: {
        display: "block",
      },
      [theme.breakpoints.up("xl")]: {
        display: "block",
      },
    },
    title: {
      color: "#636463",
      fontWeight: 600,
      fontSize: `${theme.typography.fontSize + 3}px`,
      textDecoration: "none",
    },
    headingChildren: {
      fontWeight: "bold",
      fontSize: `${theme.typography.fontSize + 2}px`,
    },
    subheading: {
      fontSize: `${theme.typography.fontSize + 3}px`,
    },
    dealerCountText: {
      color: "darkgray",
    },
    toolTipNoMaxWidth: {
      maxWidth: "none",
    },
  });

export type MetricProps = {
  query: Query;
  title?: string;
  metric: MetricReport;
  renderIcon?(): React.ReactNode;
  driverMetric?: string;
  allowFlip?: boolean;
} & React.HtmlHTMLAttributes<HTMLDivElement> &
  WithStyles<typeof styles>;

const metricColour = (metric: any, classes: any, metricValue: any) => {
  if (metric.classification == "income") {
    return {
      [classes.yellow]: metricValue.actual.value >= metric.median.value && metricValue.actual.value < metric.benchmark.value,
      [classes.red]: metricValue.actual.value < metric.median.value,
    };
  }
  return {
    [classes.yellow]: metricValue.actual.value < metric.median.value && metricValue.actual.value > metric.benchmark.value,
    [classes.red]: metricValue.actual.value > metric.median.value,
  };
};

const replaceNonNumbers = (value: any) => {
  if ((value || "").includes("NaN") || (value || "").includes("Infinity")) {
    return "";
  }
  return value;
};

export type MetricHeaderProps = {
  title: string;
  driverMetric?: string;
  override?: boolean;
} & React.HtmlHTMLAttributes<HTMLDivElement> &
  WithStyles<typeof styles>;

const MetricHeader: React.FunctionComponent<MetricHeaderProps> = ({ classes, title, driverMetric, children, override = true }) => {
  const { dashboardTypeConfig } = useContext(DashboardConfigContext);
  const intl = useIntl();
  const translatedTitle = override ? (translation[title] ? intl.formatMessage({ id: translation[title] }) : title) : title;
  return (
    <div className={classnames(classes.title, classes.mainTitle, classes.bold, classes.header)}>
      {translatedTitle.includes("-") ? (
        <span className={classes.heading}>
          <Typography className={classnames(classes.bold, classes.headingChildren)} variant="h6" color="inherit">
            {translatedTitle.split(" - ")[0]}
          </Typography>
          <TruncatedMetricText
            className={classnames(classes.bold, classes.headingChildren, classes.subheading)}
            value={translatedTitle.split(" - ")[1]}
            greaterThan={33}
            truncateAter={30}
            metricTitle={true}
          />
        </span>
      ) : (
        <span className={classes.heading}>
          <Typography className={classnames(classes.bold, classes.headingChildren)} variant="h6" color="inherit">
            {translatedTitle}
          </Typography>
          {dashboardTypeConfig.value == "mra_drivers" && driverMetric && (
            <Typography className={classnames(classes.bold, classes.headingChildren, classes.subheading)} variant="h6" color="inherit">
              {driverMetric}
            </Typography>
          )}
        </span>
      )}
      {children}
    </div>
  );
};

const GroupMetricUnstlyed: React.FunctionComponent<MetricProps> = ({
  classes,
  title,
  metric: report,
  query,
  renderIcon,
  driverMetric = undefined,
  allowFlip = true,
}) => {
  const intl = useIntl();
  const { getGroupMetric } = useContext(GroupMetricContext);
  const { metric, loading } = getGroupMetric(report, query);

  if (!loading.loaded || loading.loading) {
    return <Loading />;
  }

  // const override = translation[metric.titleOverride] ? translation[metric.titleOverride] : "";
  // const overrideExist = override.includes("metric.card.override.");
  // const metricTitle = overrideExist ? intl.formatMessage({ id: override }) : title || metric.title;

  const override = !!metric.titleOverride ? intl.formatMessage({ id: metric.titleOverride }) : "";
  const overrideExist = override.includes("metric.card.override.");
  const metricTitle = overrideExist ? title || metric.title : override;

  const benchmarksType = query.benchmarks
    .filter(bm => bm.length > 0)
    .map(x => {
      const [bench, part] = x.split(/-(?=[^-]+$)/);
      switch ((part || "").trim()) {
        case "MD":
          return intl.formatMessage({ id: "metric.median.label" });
        case "BM":
          return intl.formatMessage({ id: "metric.benchmark.label" });
        case "AV":
          return intl.formatMessage({ id: "metric.average.label" });
        case "UL":
          return intl.formatMessage({ id: "metric.upper.limit.label" });
        case "LL":
          return intl.formatMessage({ id: "metric.lower.limit.label" });
        case "Median":
          return intl.formatMessage({ id: "metric.median.label" });
        default:
          return intl.formatMessage({ id: "metric.benchmark.label" });
      }
    });

  return (
    <Card className={classnames(classes.root, metricTitle == "N/A" && classes.hideCard)}>
      <div className={classes.metricCardHeader}>
        <FormattedMessage id={metricTitle}>
          {text => <MetricHeader title={text.toString()} driverMetric={driverMetric} classes={classes} override={overrideExist} />}
        </FormattedMessage>
        <div className={classes.actions}>
          {metricTitle != "N/A" && allowFlip && (
            <Route
              path="/group-dashboard/:dashboard"
              render={({ match }) => (
                <Settings
                  onClick={() => history.push(`/group-dashboard/${match.params.dashboard}/insight/${report}/${query.department}`)}
                  className={classes.settings}
                />
              )}
            />
          )}
        </div>
      </div>
      <div className={classes.benchmarkGroup}>
        {benchmarksType.length > 0 && (
          <>
            <Typography className={classes.benchmarkText}>
              <span className={classnames(classes.cardText)}>{benchmarksType[0]}</span>
              <span className={classnames(classes.bold, classes.cardText)}>{metric && metric.benchmark.formatted}</span>
            </Typography>
            {benchmarksType.length > 1 && (
              <Typography className={classes.benchmarkText}>
                <span className={classnames(classes.cardText)}>{benchmarksType[1]}</span>
                <span className={classnames(classes.bold, classes.cardText)}>{metric && metric.median.formatted}</span>
              </Typography>
            )}
          </>
        )}
      </div>
      <div className={classes.dealerTable} style={{ gridTemplateRows: `repeat(${Math.ceil(metric.values.length / 2)}, auto)` }}>
        {((metric && metric.values) || []).map((value, key: number) => {
          return (
            <div key={`${value.dealer}_${key}`} className={classes.dealerInfo}>
              <Tooltip
                title={
                  <h2>
                    {" "}
                    {value.dealerNames} <br />{" "}
                    {value.dealerCount.value > 0 ? `${intl.formatMessage({ id: "metric.card.dealer.count.label" })}: ${value.dealerCount.value}` : ""}
                  </h2>
                }
              >
                <Typography className={classnames(classes.cardText)}>
                  {`${value.dealer} `}
                  {value.dealerCount.value > 0 ? <span className={classnames(classes.dealerCountText)}>({value.dealerCount.value})</span> : ""}
                </Typography>
              </Tooltip>
              {value.actual.value != 0 ? (
                <Tooltip
                  title={
                    <>
                      <h1>Y-1: &nbsp; {replaceNonNumbers(value.previous.formatted)}</h1>
                      <h1>Var: &nbsp; {replaceNonNumbers(value.variance.formatted)}</h1>
                    </>
                  }
                >
                  <Typography className={classnames(classes.cardValue, metricColour(metric, classes, value), classes.bold)}>
                    {replaceNonNumbers(value.actual.formatted)}
                  </Typography>
                </Tooltip>
              ) : (
                <Typography className={classnames(classes.cardValue, classes.bold, classes.black)}>-</Typography>
              )}
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export const GroupMetricCard = withStyles(styles)(GroupMetricUnstlyed);
