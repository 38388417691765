import { request } from "./base";

export type Oem = {
  name: string;
  country: string;
  code: string;
  hasTargets: boolean;
  enableCustomAdditionalStats: boolean;
};

export const getOems = (): Promise<Oem[]> =>
  request("/oems")
    .then(res => res.json())
    .then(({ data }) => data);
