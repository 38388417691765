import React, { createContext, useState, useReducer, useMemo } from "react";
import { Breakdown, BreakdownReport, Query, stringifyQuery, breakdownFor } from "../lib/api/charts";
import { Loading } from "./base";

type BreakdownSet = { breakdown: Breakdown; loading: Loading };

export type BreakdownContextState = {
  breakdown: {
    [index: string]: BreakdownSet;
  };
  getBreakdown(report: BreakdownReport, query: Query, custom?: any): Partial<BreakdownSet>;
};

export const BreakdownContext = createContext<BreakdownContextState>({
  breakdown: {},
  getBreakdown: () => ({}),
});

type QueryAction = {
  type: "PATCH_METRIC";
  id: string;
  data: Partial<BreakdownSet>;
};

const defaultValue = {
  value: 0,
  formattedValue: "",
};

const defaultBreakdown: BreakdownSet = {
  breakdown: {},
  loading: { loaded: false, loading: true, error: null },
};

export const BreakdownProvider = ({ children }) => {
  const memo = (report: BreakdownReport, query: Query, custom?: any) => `${report}-${stringifyQuery(query)}-${JSON.stringify(custom)}`;

  const [breakdown, dispatchBreakdown] = useReducer((state: { [index: string]: BreakdownSet }, action: QueryAction) => {
    switch (action.type) {
      case "PATCH_METRIC":
        return {
          ...state,
          [action.id]: {
            ...state[action.id],
            ...action.data,
          },
        };
      default:
        return state;
    }
  }, {});

  const value = useMemo(
    () => ({
      breakdown,
      getBreakdown: (report: BreakdownReport, query: Query, custom?: any) => {
        const id = memo(report, query, custom);
        const set = breakdown[id];

        if (set) {
          return set;
        }

        dispatchBreakdown({
          type: "PATCH_METRIC",
          id,
          data: defaultBreakdown,
        });

        breakdownFor(query, report, custom && custom)
          .then(breakdown => {
            dispatchBreakdown({
              type: "PATCH_METRIC",
              id,
              data: {
                breakdown: breakdown,
                loading: {
                  loaded: true,
                  loading: false,
                  error: null,
                },
              },
            });
          })
          .catch(e => {
            dispatchBreakdown({
              type: "PATCH_METRIC",
              id,
              data: {
                loading: {
                  loaded: false,
                  loading: false,
                  error: e,
                },
              },
            });
          });

        return defaultBreakdown;
      },
    }),
    [breakdown],
  );
  return <BreakdownContext.Provider value={value}>{children}</BreakdownContext.Provider>;
};
