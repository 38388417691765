import * as React from "react";
import classnames from "classnames";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { MeasuresContext } from "../contexts/MeasuresContext";
import Info from "@material-ui/icons/Info";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Loading } from "./Loading";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    guide: {
      width: "400px",
      height: "550px",
      overflowY: "scroll",
      padding: theme.spacing.unit * 2,
    },
    popper: {
      zIndex: 99999,
    },
    infoIcon: {
      cursor: "pointer",
      color: "#191919",
      fontSize: "15px",
      marginRight: "5px",
    },
  });

export type MetricGuidanceProps = {
  name: any;
  guidanceHTML?: string;
} & React.HTMLAttributes<HTMLDivElement> &
  WithStyles<typeof styles>;

const MetricGuidanceUnstlyed: React.SFC<MetricGuidanceProps> = ({ classes, children, className, guidanceHTML, name }) => {
  const [open, setOpen] = React.useState(false);
  const { loading: measureLoading } = React.useContext(MeasuresContext);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classnames(classes.root, className)}>
      <Info
        className={classes.infoIcon}
        onClick={e => {
          setOpen(true);
        }}
      />
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title">{name}</DialogTitle>
        <DialogContent style={{ width: "500px", maxWidth: "500px" }}>
          {measureLoading.loaded ? (
            <Typography
              dangerouslySetInnerHTML={{
                __html: guidanceHTML,
              }}
            />
          ) : (
            <Loading />
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const MetricGuidance = withStyles(styles)(MetricGuidanceUnstlyed);
