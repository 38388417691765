import React, { createContext, useState, useReducer, useMemo } from "react";
import { GroupMetric, MetricReport, Query, stringifyQuery, customGroupMetricFor } from "../../lib/api/charts";
import { Loading } from "../base";
import { CustomType, CustomDashboardMeasures } from "../../charts/CustomDashReports";

type GroupMetricSet = { metric: GroupMetric; loading: Loading; custom?: any };

export type CustomGroupMetricContextState = {
  metric: {
    [index: string]: GroupMetricSet;
  };
  getGroupMetric(report: MetricReport, query: Query, custom?: CustomType): Partial<GroupMetricSet>;
};

export const CustomGroupMetricContext = createContext<CustomGroupMetricContextState>({
  metric: {},
  getGroupMetric: () => ({}),
});

type QueryAction = {
  type: "PATCH_METRIC";
  id: string;
  data: Partial<GroupMetricSet>;
};

const defaultValue = {
  value: 0,
  formattedValue: "",
};

const defaultCustom: string = "Overall Dealership > REVENUE > Total Dealership Sales";

const defaultGroupMetric: GroupMetricSet = {
  metric: {
    title: "",
    actualTitle: "",
    classification: "income",
    values: [],
    dealers: [],
    dealerNames: [],
    benchmark: defaultValue,
    median: defaultValue,
    target: defaultValue,
  },
  loading: { loaded: false, loading: true, error: null },
  custom: CustomDashboardMeasures[defaultCustom].getMeasure(defaultCustom, CustomDashboardMeasures),
};

export const CustomGroupMetricProvider = ({ children }) => {
  const memo = (report: MetricReport, query: Query, custom?: any) => `${report}-${stringifyQuery(query)}-${JSON.stringify(custom)}`;

  const [metric, dispatchGroupMetric] = useReducer((state: { [index: string]: GroupMetricSet }, action: QueryAction) => {
    switch (action.type) {
      case "PATCH_METRIC":
        return {
          ...state,
          [action.id]: {
            ...state[action.id],
            ...action.data,
          },
        };
      default:
        return state;
    }
  }, {});

  const value = useMemo(
    () => ({
      metric,
      getGroupMetric: (report: MetricReport, query: Query, custom?: any) => {
        const id = memo(report, query, custom);
        const set = metric[id];

        if (set) {
          return set;
        }

        dispatchGroupMetric({
          type: "PATCH_METRIC",
          id,
          data: defaultGroupMetric,
        });

        customGroupMetricFor(query, report, custom)
          .then(metric => {
            dispatchGroupMetric({
              type: "PATCH_METRIC",
              id,
              data: {
                metric: metric,
                custom: custom,
                loading: {
                  loaded: true,
                  loading: false,
                  error: null,
                },
              },
            });
          })
          .catch(e => {
            dispatchGroupMetric({
              type: "PATCH_METRIC",
              id,
              data: {
                loading: {
                  loaded: false,
                  loading: false,
                  error: e,
                },
              },
            });
          });

        return defaultGroupMetric;
      },
    }),
    [metric],
  );
  return <CustomGroupMetricContext.Provider value={value}>{children}</CustomGroupMetricContext.Provider>;
};
