import React from "react";
import { Text } from "recharts";

export const CustomizedXAxisTick = ({ x, y, payload }) => {
  // YEAR
  if (/FYTD|CYTD/.test(payload.value)) {
    return <Text angle={90} fill="#636463" x={x} y={y} textAnchor="start" verticalAnchor="start">{`${payload.value}`}</Text>;
  }

  // YEAR
  if (payload.value.length == 4) {
    return <Text fill="#636463" x={x} y={y} width={20} angle={90} textAnchor="start" verticalAnchor="start">{`${payload.value}`}</Text>;
  }

  // Percentage
  if (typeof payload.value == "number") {
    const pc = (payload.value * 100).toFixed(0) + "%";
    return <Text fill="#636463" x={x} y={y} width={20} angle={90} textAnchor="start" verticalAnchor="start">{`${pc}`}</Text>;
  }

  // Quarters
  if (payload.value.includes("Q")) {
    const [year, quarter] = payload.value.split(" ");
    return <Text angle={90} fill="#636463" x={x} y={y} textAnchor="start" verticalAnchor="start">{`${year}-${quarter}`}</Text>;
  }

  // Month Year
  const date = breakdownPayloadValue(payload.value);
  if (date.date1 && date.date2 && date.date1.year) {
    return (
      <Text fill="#636463" x={x} y={y} width={20} angle={90} textAnchor="start" verticalAnchor="start">{`${date.date1.month}-${date.date1.year ? "'" : ""}${date.date1
        .year || ""}-${date.date2.month} ${date.date2.year ? "'" : ""}${date.date2.year || ""}`}</Text>
    );
  }
  if (date.date1 && date.date2) {
    return (
      <Text fill="#636463" x={x} y={y} width={20} angle={90} textAnchor="start" verticalAnchor="start">{`${date.date1.month}-${date.date2.month} ${
        date.date2.year ? "'" : ""
      }${date.date2.year || ""}`}</Text>
    );
  }
  return (
    <Text fill="#636463" x={x} y={y} angle={90} width={20} textAnchor="start" verticalAnchor="start">{`${date.date1.month}-${date.date1.year ? "'" : ""}${date.date1
      .year || ""}`}</Text>
  );
};

export const FordAcademyCustomizedXAxisTick = ({ x, y, payload }) => {
  // YEAR
  if (/FYTD|CYTD/.test(payload.value)) {
    return <Text angle={90} fill="#636463" x={x} y={y} textAnchor="start" verticalAnchor="start">{`${payload.value}`}</Text>;
  }

  // YEAR
  if (payload.value.length == 4) {
    return <Text fill="#636463" x={x} y={y} width={20} textAnchor="middle" verticalAnchor="start">{`${payload.value}`}</Text>;
  }

  // Percentage
  if (typeof payload.value == "number") {
    const pc = (payload.value * 100).toFixed(0) + "%";
    return <Text fill="#636463" x={x} y={y} width={20} textAnchor="middle" verticalAnchor="start">{`${pc}`}</Text>;
  }

  // Quarters
  if (payload.value.includes("Q")) {
    const [year, quarter] = payload.value.split(" ");
    return <Text angle={90} fill="#636463" x={x} y={y} textAnchor="start" verticalAnchor="start">{`${year} ${quarter}`}</Text>;
  }

  // Month Year
  return (
    <Text fill="#636463" x={x} y={y} angle={90} width={200} textAnchor="start" verticalAnchor="start">
      {payload.value}
    </Text>
  );
};

const breakdownPayloadValue = (value: string) => {
  if (value.includes("-")) {
    const [date1, date2] = value.split("-");
    if (/\d/.test(date1)) {
      const [month1, year1] = date1.split(" ");
      const [month2, year2] = date2.split(" ");
      return { date1: { month: month1, year: year1 }, date2: { month: month2, year: year2 } };
    }
    const [month, year] = date2.split(" ");
    return { date1: { month: date1, year: null }, date2: { month: month, year: year } };
  }
  const [month, year] = value.split(" ");
  return { date1: { month: month, year: year }, date2: null };
};
