import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { history } from "./history";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "ac5eca94-e8df-4e14-aef9-425a0f04ca17",
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: history },
    },
  },
});
