import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import React, { useState, useContext } from "react";
import { Report } from "../../lib/api/charts";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { DashboardConfigContext } from "../../contexts/DashboardConfigContext";
import { GroupDashboard } from "../../charts/GroupDashboard";
import { GroupMetricCard } from "../../charts/GroupMetricCard";
import { SelectableChart as SelectableSeriesChart } from "../../charts/GroupSeriesChart";
import { SelectableChart as SelectableBarChart } from "../../charts/GroupStackedChart";
import { DealerContext } from "../../contexts/DealerContext";
import { GroupSelectableChart } from "../../charts/GroupSelectableChart";

const styles = (theme: Theme) =>
  createStyles({
    menuItem: {
      width: "250px",
      height: "50px",
    },
    media: {
      "@media (max-width:800px)": {
        width: "100%",
      },
    },
  });

export type GroupBalanceSheetDashboardProps = {} & WithStyles<typeof styles>;

const chartOptions = {
  "currentratio": {
    canAverage: false,
    display: "Current Ratio",
  },
  "workingcapitaldays": {
    canAverage: false,
    display: "Working Capital Days"
  },
  "workingcapitalamount" : {
    canAverage: true,
    display: "Working Capital Amount '000"
  },
  "cashconversioncycle" : {
    canAverage: false, 
    display: "Cash Conversion Cycle (CCC)"
  },
  "totaldebtordays" : {
    canAverage: false, 
    display: "Total Debtor Days"
  },
  "totalinventorydays" : {
    canAverage: false,
    display: "Total Inventory Days"
  },
  "debttoequity" : {
    canAverage: false, 
    display: "Debt to Equity"
  },
  "roi": {
    canAverage: false,
    display: "ROI (with full BS)"
  },
}



const GroupBalanceSheetDashboardUnstlyed: React.FunctionComponent<GroupBalanceSheetDashboardProps> = ({ classes, children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { updateResetAllDashboards } = useContext(DashboardConfigContext);
  const { view } = useContext(DealerContext);

  return (
    <GroupDashboard
      department="All Department"
      title="Balance Sheet"
      showAvgSelector={true}
      toggle={true}
      metrics={({ query }) => (
        <>
          <GroupMetricCard metric="a" query={{ ...query, view: view, dashboard: "balance-sheet" }} />
          <GroupMetricCard metric="b" query={{ ...query, view: view, dashboard: "balance-sheet" }} />
          <GroupMetricCard metric="c" query={{ ...query, view: view, dashboard: "balance-sheet" }} />
          <GroupMetricCard metric="d" query={{ ...query, view: view, dashboard: "balance-sheet" }} />
          <GroupMetricCard metric="e" query={{ ...query, view: view, dashboard: "balance-sheet" }} />
          <GroupMetricCard metric="f" query={{ ...query, view: view, dashboard: "balance-sheet" }} />
          <GroupMetricCard metric="g" query={{ ...query, view: view, dashboard: "balance-sheet" }} />
          <GroupMetricCard metric="h" query={{ ...query, view: view, dashboard: "balance-sheet" }} />
        </>
      )}
      filters={({ query, config, updateConfig }) => (
        <>
          <Button
            onClick={e => setAnchorEl(e.currentTarget)}
            variant="contained"
            className={classes.media}
            style={{
              margin: 0,
              backgroundColor: "#fff",
              paddingTop: 6,
              paddingBottom: 6,
              paddingLeft: 10,
              paddingRight: 10,
              fontWeight: "bold",
              textTransform: "unset",
              fontSize: "12px",
            }}
          >
            Reset
          </Button>
          <Menu open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorEl={anchorEl}>
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                updateConfig("Balance Sheet", {
                  dashboard: "Balance Sheet",
                  items: {
                    tr: { measure: "workingcapitaldays" },
                    br: { measure: "debttoequity" },
                  },
                });
                setAnchorEl(null);
              }}
            >
              Reset Balance Sheet
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                updateResetAllDashboards();
                setAnchorEl(null);
              }}
            >
              Reset All
            </MenuItem>
          </Menu>
        </>
      )}
      grid={({ query }) => <></>}
      charts={({ query, config, updateConfig }) => {
        const execConfig = config["Balance Sheet"] || {
          dashboard: "Balance Sheet",
          items: {
            tr: { measure: "currentratio" },
            br: { measure: "workingcapitaldays" },
          },
        };
        const update = (key: string) => (report: Report) => {
          updateConfig("Balance Sheet", {
            ...execConfig,
            items: {
              ...execConfig.items,
              [key]: { measure: report },
            },
          });
        };
        return (
          <>
            <GroupSelectableChart
              update={update("tr")}
              report={execConfig.items.tr.measure}
              title="Working Capital Days"
              query={query}
              chartOptions={chartOptions}
              initialChartType="line"
            />
            <GroupSelectableChart
              update={update("br")}
              report={execConfig.items.br.measure}
              title="DEBT : EQUITY"
              query={query}
              chartOptions={chartOptions}
              initialChartType="bar"
            />
          </>
        );
      }}
    />
  );
};

export const GroupBalanceSheetDashboard = withStyles(styles)(GroupBalanceSheetDashboardUnstlyed);

export default GroupBalanceSheetDashboard;
