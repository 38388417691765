import React, { useEffect, useMemo } from "react";
import classnames from "classnames";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { DashboardConfigContext } from "./contexts/DashboardConfigContext";
import { Dealer } from "./lib/api/charts";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import { Select } from "./components/Select";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { DealerContext } from "./contexts/DealerContext";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { DragDropContext, Droppable, Draggable, DroppableProvided, DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import DragHandle from "@material-ui/icons/DragHandle";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    tableGridHead: {
      border: "1px solid #ddd",
      borderBottom: "none",
      marginTop: theme.spacing.unit * 2,
    },
    tableGrid: {
      height: "120px",
      overflowY: "scroll",
      border: "1px solid #ddd",
      borderTop: "none",
    },
    tableHead: {
      backgroundColor: "white",
      fontSize: theme.typography.fontSize * 1,
    },
    tableBody: {},
    cell: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing.unit,
      paddingRight: theme.spacing.unit,
      fontSize: "0.8rem",
    },
    cellHead: {
      backgroundColor: "#f3f3f3",
    },
    clickable: {
      cursor: "pointer",
    },
    tableBodyRow: {
      backgroundColor: "white",
    },
    code: {
      width: 100,
    },
    selectInput: {
      padding: 0,
    },
    inputText: {
      backgroundColor: "white",
      padding: 9,
      fontSize: `${theme.typography.fontSize + 2}px`,
    },
    control: {
      marginTop: theme.spacing.unit * 2,
      width: "100%",
    },
    hr: {
      width: "95%",
      marginTop: 15,
    },
    formLabelText: {
      paddingBottom: theme.spacing.unit * 1,
      fontSize: "0.8rem",
    },
    selectInputs: {
      fontSize: `${theme.typography.fontSize + 2}px`,
      fontFamily:
        "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",
    },
    gridButton: {
      marginTop: "40px",
    },
    draggableListItem: {
      backgroundColor: "white",
      borderBottom: "1px solid #ddd",
    },
  });

export type GroupDealerProps = {
  multi: boolean;
  max: number;
  selectAll: any;
  setTouched: any;
  filteredDealers: any;
  xselectedDealers: any;
  xsetSelectedDealers: any;
  dealerVisibilty: boolean;
  filters: any;
} & React.HTMLAttributes<HTMLDivElement> &
  WithStyles<typeof styles>;

const availableDealerCodes = (config: { [index: string]: { name: string; dealers: string[] } }, dealerCodes: Dealer[]) => {
  const filteredConfigNames = Object.values(config).filter(list => list);
  if (filteredConfigNames.length > 0) {
    const configDealers = filteredConfigNames.map((dealer: { name: string; dealers: string[] }) => dealer.dealers || []).reduce((acc, val) => [...acc, ...val]);
    const dealers = (configDealers || []).filter((d, pos) => (configDealers || []).indexOf(d) === pos);
    const filtered = (dealerCodes || []).filter(d => (dealers || []).find(c => c === d.code));
    return filtered;
  }
  return [];
};

const GroupDealerUnstlyed: React.SFC<GroupDealerProps> = ({
  multi,
  max,
  classes,
  children,
  className,
  selectAll,
  setTouched,
  filteredDealers,
  dealerVisibilty,
  xselectedDealers,
  xsetSelectedDealers,
  filters,
}) => {
  const { configs, loading: configLoading, updateConfig } = React.useContext(DashboardConfigContext);
  const [customLists, setCustomLists] = React.useState((configs && configs.dealersList) || {});
  const [customListName, setCustomListName] = React.useState<string>("");
  const [selectedList, setSelectedList] = React.useState<{ value: string; label: string }>();
  const [listUpdated, setListUpdated] = React.useState<boolean>(false);
  const [save, setSave] = React.useState(false);
  const { available } = React.useContext(DealerContext);
  const settings = configs.settings || { oems: [], country: "" };

  const handleSaveList = () => {
    const lists = configs.dealersList || {};
    const listDealers = xselectedDealers.map(d => d.code);
    const newList = { ...lists, [customListName]: { name: customListName, dealers: listDealers } };
    setCustomLists(newList);
    updateConfig("dealersList", {
      ...newList,
    });
    setSelectedList({ value: customListName, label: customListName });
    selectListState();
  };

  const handleRemoveList = () => {
    setSelectedList({ value: "", label: "" });
    const lists = configs.dealersList || {};
    const newList = { ...lists, [customListName]: undefined };
    updateConfig("dealersList", {
      ...newList,
    });
    setCustomListName("");
    setCustomLists(newList);
    xsetSelectedDealers([]);
  };

  const selectAllDealers = () => {
    if (filteredDealers.length >= max) {
      setTouched(true);
      return xsetSelectedDealers(filteredDealers.slice(0, max));
    } else if (filteredDealers.length < max) {
      setTouched(true);
      return xsetSelectedDealers(filteredDealers.slice(0, filteredDealers.length));
    }
  };

  const filterDealers = (available: Dealer[], settings) => {
    return available.filter(dealer => {
      return settings.oems.length > 0 ? dealer.oems.find(v => settings.oems.includes(v)) : true;
    });
  };

  const availableCodes = (configLoading.loaded && configs.dealersList && availableDealerCodes(configs.dealersList, filterDealers(available, settings))) || [];

  const availableList = React.useMemo(() => {
    return Object.keys(customLists)
      .map(list => customLists[list])
      .filter(list => list)
      .filter(list => list.dealers.filter(c => availableCodes.find(d => d.code == c)).length == list.dealers.length)
      .map(d => ({ value: d.name, label: d.name }));
  }, [filters, availableCodes]);

  const handleListSelect = e => {
    if (e.value.length > 0) {
      const listDealers = customLists[e.value].dealers.map(d => filterDealers(available, settings).find(f => f.code == d));
      setTouched(true);
      setCustomListName(e.value);
      xsetSelectedDealers(listDealers);
      return setSelectedList({ value: e.label, label: e.label });
    }
    setCustomListName("");
    xsetSelectedDealers([]);
    setSelectedList({ value: "", label: "" });
  };

  const saveListState = () => {
    setSave(true);
  };

  const selectListState = () => {
    setSave(false);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const items = reorder(xselectedDealers, result.source.index, result.destination.index);

    xsetSelectedDealers(items);

    setTouched(true);
  };

  const sortDealers = (a, b) => {
    if (a.index > b.index) {
      return 1;
    }
    if (a.index < b.index) {
      return -1;
    }
    return 0;
  };

  return (
    <div className={classnames(classes.root, className)}>
      <Grid item xs={12} className={classes.tableGridHead}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell className={classnames(classes.cell, classes.cellHead)} style={{ maxWidth: "70px", width: "70px" }}>
                <span style={{ display: "flex" }}>
                  <span
                    className={classes.clickable}
                    style={{ paddingRight: "2px" }}
                    onClick={() => {
                      selectAll([]);
                      xsetSelectedDealers([]);
                    }}
                  >
                    Clear
                  </span>
                  {dealerVisibilty && filteredDealers.length > 0 && (
                    <>
                      <span>|</span>
                      <span className={classes.clickable} style={{ paddingLeft: "2px" }} onClick={() => selectAllDealers()}>
                        All
                      </span>
                    </>
                  )}
                </span>
              </TableCell>
              <TableCell className={classnames(classes.cell, classes.cellHead, classes.code)} style={{ maxWidth: "100px", width: "100px" }}>
                <FormattedMessage id="dealer.search.dealer.code.label" />
              </TableCell>
              <TableCell className={classnames(classes.cell, classes.cellHead)}>
                <FormattedMessage id="dealer.search.dealer.name.label" />
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Grid>
      <Grid item xs={12} className={classes.tableGrid}>
        <Table>
          <TableBody className={classes.tableBody}>
            {(dealerVisibilty ? filteredDealers : [])
              .filter(d => d.active)
              .map((opt, indx: number) => {
                if (!xselectedDealers.find(dealer => opt.code == dealer.code)) {
                  return (
                    <TableRow className={classes.tableBodyRow}>
                      <TableCell className={classes.cell} style={{ maxWidth: "70px", width: "70px" }}>
                        <Checkbox
                          disabled={xselectedDealers.length >= max && !xselectedDealers.includes(opt)}
                          checked={xselectedDealers.includes(opt)}
                          className={classes.selectInput}
                          onChange={() => {
                            if (xselectedDealers.includes(opt)) {
                              xsetSelectedDealers(xselectedDealers.filter(d => d != opt));
                            } else {
                              xsetSelectedDealers([...xselectedDealers, opt]);
                            }
                            setTouched(true);
                          }}
                          value={opt.dealer}
                          name="dealer"
                          color="default"
                        />
                      </TableCell>
                      <TableCell className={classes.cell} style={{ maxWidth: "100px", width: "100px" }}>
                        {opt.code}
                      </TableCell>
                      <TableCell className={classes.cell}>{opt.dealer}</TableCell>
                    </TableRow>
                  );
                }
              })}
            {(dealerVisibilty ? filteredDealers : []).filter(d => !d.active).length > 0 && (
              <TableRow className={classes.tableBodyRow}>
                <TableCell className={classnames(classes.cell, classes.cellHead)} colSpan={3}>
                  Inactive Dealers
                </TableCell>
              </TableRow>
            )}
            {(dealerVisibilty ? filteredDealers : [])
              .filter(d => !d.active)
              .map((opt, indx: number) => {
                if (!xselectedDealers.find(dealer => opt.code == dealer.code)) {
                  return (
                    <TableRow className={classes.tableBodyRow}>
                      <TableCell className={classes.cell} style={{ maxWidth: "70px", width: "70px" }}>
                        <Checkbox
                          disabled={xselectedDealers.length >= max && !xselectedDealers.includes(opt)}
                          checked={xselectedDealers.includes(opt)}
                          className={classes.selectInput}
                          onChange={() => {
                            if (xselectedDealers.includes(opt)) {
                              xsetSelectedDealers(xselectedDealers.filter(d => d != opt));
                            } else {
                              xsetSelectedDealers([...xselectedDealers, opt]);
                            }
                            setTouched(true);
                          }}
                          value={opt.dealer}
                          name="dealer"
                          color="default"
                        />
                      </TableCell>
                      <TableCell className={classes.cell} style={{ maxWidth: "100px", width: "100px" }}>
                        {opt.code}
                      </TableCell>
                      <TableCell className={classes.cell}>{opt.dealer}</TableCell>
                    </TableRow>
                  );
                }
              })}
          </TableBody>
        </Table>
      </Grid>
      {save ? (
        <>
          {
            <Grid container item xs={12} style={{ paddingTop: "20px" }}>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  variant={"outlined"}
                  value={customListName}
                  placeholder={"Custom list name"}
                  inputProps={{ className: classes.inputText }}
                  onChange={e => setCustomListName(e.target.value)}
                />
              </Grid>
              <Grid item xs={2}>
                <Button fullWidth color={"primary"} onClick={() => handleSaveList()} disabled={customListName.trim().length === 0}>
                  <CheckIcon />
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button fullWidth color={"default"} onClick={selectListState}>
                  <CloseIcon />
                </Button>
              </Grid>
            </Grid>
          }
        </>
      ) : (
        <>
          {multi && (
            <Grid container spacing={8} item xs={12}>
              <Grid item xs={6}>
                <FormControl className={classes.control}>
                  <FormLabel className={classes.formLabelText}>
                    <FormattedMessage id="group.custom.list" />
                  </FormLabel>
                  <Select
                    fullWidth
                    isSearchable
                    value={selectedList}
                    onChange={handleListSelect}
                    className={classes.selectInputs}
                    options={[{ value: "", label: "" }, ...availableList]}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <Button fullWidth color={"primary"} onClick={saveListState} className={classes.gridButton} disabled={(xselectedDealers || []).length < 1}>
                  <FormattedMessage id="group.save.custom.list" />
                </Button>
              </Grid>
              {Object.keys(customLists).length > 0 && customLists[customListName] && (
                <Grid item xs={2}>
                  <Button fullWidth color={"default"} onClick={() => handleRemoveList()} className={classes.gridButton}>
                    <FormattedMessage id="group.remove.custom.list" />
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </>
      )}
      <Grid item xs={12} className={classes.tableGridHead}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell className={classnames(classes.cell, classes.cellHead)} style={{ maxWidth: "30px", width: "30px" }} />
              <TableCell className={classnames(classes.cell, classes.cellHead)} style={{ maxWidth: "70px", width: "70px" }}>
                <>
                  <span
                    className={classes.clickable}
                    onClick={() => {
                      xsetSelectedDealers([]);
                      selectAll([]);
                    }}
                  >
                    <FormattedMessage id="clear.button" />
                  </span>
                </>
              </TableCell>
              <TableCell className={classnames(classes.cell, classes.cellHead, classes.code)} style={{ maxWidth: "100px", width: "100px" }}>
                <FormattedMessage id="dealer.search.dealer.code.label" />
              </TableCell>
              <TableCell className={classnames(classes.cell, classes.cellHead)}>
                <FormattedMessage id="dealer.search.dealer.name.label" />
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Grid>
      <Grid item xs={12} className={classes.tableGrid}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(droppableProvided: DroppableProvided) => (
              <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                {(xselectedDealers || [])
                  .filter(d => d.active)
                  .map((opt, indx: number) => {
                    return (
                      <Draggable key={"item-" + indx} draggableId={"draggable-" + indx} index={indx}>
                        {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                          return (
                            <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} {...draggableProvided.dragHandleProps}>
                              <Table>
                                <TableBody className={classes.tableBody}>
                                  <TableRow className={classes.tableBodyRow}>
                                    <TableCell className={classes.cell} style={{ maxWidth: "28px", width: "25px" }}>
                                      <div {...draggableProvided.dragHandleProps}>
                                        <DragHandle />
                                      </div>
                                    </TableCell>
                                    <TableCell className={classes.cell} style={{ maxWidth: "70px", width: "70px" }}>
                                      <Checkbox
                                        disabled={xselectedDealers.length >= max && !xselectedDealers.includes(opt)}
                                        checked={xselectedDealers.includes(opt)}
                                        className={classes.selectInput}
                                        onChange={() => {
                                          if (xselectedDealers.includes(opt)) {
                                            xsetSelectedDealers(xselectedDealers.filter(d => d != opt));
                                          } else {
                                            xsetSelectedDealers([...xselectedDealers, opt]);
                                          }
                                          setTouched(true);
                                        }}
                                        value={opt.dealer}
                                        name="dealer"
                                        color="default"
                                      />
                                    </TableCell>
                                    <TableCell className={classes.cell} style={{ maxWidth: "100px", width: "100px" }}>
                                      {opt.code}
                                    </TableCell>
                                    <TableCell className={classes.cell}>{opt.dealer}</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
      {(xselectedDealers || []).filter(d => !d.active).length > 0 && (
        <>
          <Grid item xs={4}>
            <FormControl className={classes.control}>
              <FormLabel className={classes.formLabelText}>
                <FormattedMessage id="dealers.inactive.label" />
              </FormLabel>
            </FormControl>
          </Grid>
          <Grid item xs={12} className={classes.tableGridHead} style={{ marginTop: 5 }}>
            <Table>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell className={classnames(classes.cell, classes.cellHead)} style={{ maxWidth: "30px", width: "30px" }} />
                  <TableCell className={classnames(classes.cell, classes.cellHead)} style={{ maxWidth: "70px", width: "70px" }}>
                    <>
                      <span
                        className={classes.clickable}
                        onClick={() => {
                          xsetSelectedDealers([]);
                          selectAll([]);
                        }}
                      >
                        <FormattedMessage id="clear.button" />
                      </span>
                    </>
                  </TableCell>
                  <TableCell className={classnames(classes.cell, classes.cellHead, classes.code)} style={{ maxWidth: "100px", width: "100px" }}>
                    <FormattedMessage id="dealer.search.dealer.code.label" />
                  </TableCell>
                  <TableCell className={classnames(classes.cell, classes.cellHead)}>
                    <FormattedMessage id="dealer.search.dealer.name.label" />
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Grid>
          <Grid item xs={12} className={classes.tableGrid}>
            {([...xselectedDealers] || [])
              .sort(sortDealers)
              .filter(d => !d.active)
              .map((opt, indx: number) => {
                return (
                  <Table>
                    <TableBody className={classes.tableBody}>
                      <TableRow key={indx} className={classes.tableBodyRow}>
                        <TableCell className={classes.cell} style={{ maxWidth: "70px", width: "70px" }}>
                          <Checkbox
                            disabled={xselectedDealers.length >= max && !xselectedDealers.includes(opt)}
                            checked={xselectedDealers.includes(opt)}
                            className={classes.selectInput}
                            onChange={() => {
                              if (xselectedDealers.includes(opt)) {
                                xsetSelectedDealers(xselectedDealers.filter(d => d != opt));
                              } else {
                                xsetSelectedDealers([...xselectedDealers, opt]);
                              }
                              setTouched(true);
                            }}
                            value={opt.dealer}
                            name="dealer"
                            color="default"
                          />
                        </TableCell>
                        <TableCell className={classes.cell} style={{ maxWidth: "100px", width: "100px" }}>
                          {opt.code}
                        </TableCell>
                        <TableCell className={classes.cell}>{opt.dealer}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                );
              })}
          </Grid>
        </>
      )}
    </div>
  );
};

export const GroupDealer = withStyles(styles)(GroupDealerUnstlyed);
