import { request } from "./base";

export const childDealers = (dealerCode: string, date: Date, dealerCodes: string[]): Promise<{ dealers: string[] }> =>
  request(`/dealers/children/${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, "0")}`, {
    method: "POST",
    body: JSON.stringify({ dealerCode: dealerCode, dealerCodes: dealerCodes }),
  })
    .then(res => res.json())
    .then(({ data }) => data);

export const postCubeReload = async (data: { dealerCodes: string[]; startMonth: string; endMonth: string }) => {
  const response = await request("/dealers/cube-reload", {
    method: "POST",
    body: JSON.stringify(data),
  });
  if (response.status < 400) {
    return;
  }
  const { error } = await response.json();
  const err = { error: error.message, statusCode: response.status };
  throw err;
};
