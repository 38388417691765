import React, { createContext, useState, useReducer, useMemo, useCallback } from "react";
import { Loading } from "../base";
import { getAdditionalStats as apiGetAdditionalStats } from "../../lib/api/custom";

export type AdditionalStatsContextState = {
  additionalStats: boolean;
  loading: Loading;
  getAdditionalStats(dealers: string[]);
};

export const AdditionalStatsContext = createContext<AdditionalStatsContextState>({
  additionalStats: false,
  loading: { loading: false, loaded: false, error: null },
  getAdditionalStats: () => {},
});

export const AdditionalStatsProvider = ({ children }) => {
  const [additionalStats, setAdditionalStats] = useState<boolean>(false);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });

  const getAdditionalStats = useCallback(
    async (dealers: string[]) => {
      try {
        setLoading({
          loading: true,
          loaded: false,
          error: null,
        });
        const result = await apiGetAdditionalStats(dealers);
        setAdditionalStats(result.data);
        setLoading({
          loading: false,
          loaded: true,
          error: null,
        });
      } catch (e) {
        setLoading({
          loading: false,
          loaded: false,
          error: e,
        });
      }
    },
    [setLoading, setAdditionalStats],
  );

  const value = useMemo(
    () => ({
      additionalStats,
      loading,
      getAdditionalStats,
    }),
    [additionalStats, loading, getAdditionalStats],
  );

  return <AdditionalStatsContext.Provider value={value}>{children}</AdditionalStatsContext.Provider>;
};
