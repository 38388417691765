import React, { createContext, useState, useMemo } from "react";

const defaultMetricInsight = { custom: "", department: "", metric: "", dashboard: "" };

export type MetricInsight = {
  custom: string;
  department?: string;
  metric?: string;
  dashboard?: string;
  modelCustom?: any;
};

export type MetricInsightContextState = {
  metricInsight: MetricInsight;
  setMetricInsight(value: MetricInsight): void;
};

export const MetricInsightContext = createContext<MetricInsightContextState>({
  metricInsight: defaultMetricInsight,
  setMetricInsight: () => {},
});

export const MetricInsightProvider = ({ children }) => {
  const [metricInsight, setMetricInsight] = useState<MetricInsight>(defaultMetricInsight);

  const value = useMemo(
    () => ({
      metricInsight,
      setMetricInsight: (value: MetricInsight) => {
        setMetricInsight(value);
      },
    }),
    [metricInsight],
  );

  return <MetricInsightContext.Provider value={value}>{children}</MetricInsightContext.Provider>;
};
