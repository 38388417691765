import React, { createContext, useState, FC, useMemo } from "react";

type Loading = {
  loading: boolean;
  loaded: boolean;
  error?: Error;
};

type Period = "Monthly" | "TMRA" | "Quarterly" | "Calendar Year" | "Financial Year";

const defaultPeriod: Period = "Monthly";
const periods: Period[] = ["Monthly", "TMRA", "Quarterly", "Calendar Year", "Financial Year"];

export type PeriodContextState = {
  selected: Period;
  periods: Period[];
  loading: Loading;
  setPeriod: (period: Period) => void;
};

export const PeriodContext = createContext<PeriodContextState>({
  selected: defaultPeriod,
  periods,
  loading: { loading: false, loaded: false, error: undefined },
  setPeriod: () => {},
});

export const PeriodProvider: FC = ({ children }) => {
  const [selected, setPeriod] = useState<Period>(defaultPeriod);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: undefined });

  const value = useMemo(
    () => ({
      selected,
      loading,
      periods,
      setPeriod,
      setLoading,
    }),
    [selected, loading, setPeriod],
  );

  return <PeriodContext.Provider value={value}>{children}</PeriodContext.Provider>;
};
