import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { CustomDashboardMeasures as BaseMeasures, AdditionalStatsMeasures, CustomDashboardType } from "./CustomDashReports";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { FormattedMessage, useIntl } from "react-intl";
import { translation } from "../translations/Translations";
import { useMediaQuery } from "react-responsive";
import { AdditionalStatsContext } from "../contexts/Custom/AdditionalStatsContext";
import { DealerModelsContext } from "../contexts/Custom/DealerModelsContext";
import { CustomDashboardModelTemplate, CustomDashboardRetailUnitsTemplate } from "./custom-dashboard-reports/NewVehicle";
import { OverallDealershipOEMSupportsAnalysis, OverallDealership } from "./custom-dashboard-reports/OverallDealership";
import { NewVehicleOEMSupportsAnalysis, NewVehicle } from "./custom-dashboard-reports/NewVehicle";
import { UsedVehicleOEMSupportsAnalysis, UsedVehicle } from "./custom-dashboard-reports/UsedVehicle";
import { FI_OEMSupportsAnalysis, FI } from "./custom-dashboard-reports/F&I";
import { AfterSalesOEMSupportsAnalysis, AfterSales } from "./custom-dashboard-reports/AfterSales";
import { PartsOEMSupportsAnalysis, Parts } from "./custom-dashboard-reports/Parts";
import { ServiceOEMSupportsAnalysis, Service } from "./custom-dashboard-reports/Service";
import { BodyShopOEMSupportsAnalysis, BodyShop } from "./custom-dashboard-reports/BodyShop";
import { DrivewayOEMSupportsAnalysis, Driveway } from "./custom-dashboard-reports/Driveway";
import { CustomDashboardDepartmentsContext } from "../contexts/Custom/CustomDashboardDepartmentsContext";
import { BalanceSheet } from "./custom-dashboard-reports/BalanceSheet";
import { Aftercare } from "./custom-dashboard-reports/Aftercare";
import { OtherDept } from "./custom-dashboard-reports/OtherDept";
import { NetworkStats } from "./custom-dashboard-reports/NetworkStats";
import { FordAcademyKPIs } from "./custom-dashboard-reports/FordAcademy";
import { OemContext } from "../contexts/OemContext";
import { DealerContext } from "../contexts/DealerContext";
import { VCJProcessData1, VCJProcessData2 } from "./custom-dashboard-reports/VCJProcessData";
import { fordSAScorecard } from "./custom-dashboard-reports/FordSAScrorecard";
import { Input } from "@material-ui/core";

export const styles = (theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.fontSize + 2,
      fontWeight: "bold",
      color: "#2f2f2f",
    },
    downArrow: {
      fill: "#636463",
      marginTop: "2px",
      marginRight: "10px",
      marginLeft: "5px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    rightArr: {
      fill: "#636463",
      marginLeft: "5px",
      fontSize: "16px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    menuItemText: {
      width: "100%",
    },
  });

export const centeredStyles = (theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.fontSize + 2,
      fontWeight: "bold",
      color: "#2f2f2f",
    },
    downArrow: {
      fill: "#636463",
      "&:hover": {
        cursor: "pointer",
      },
    },
    rightArr: {
      fill: "#636463",
      marginLeft: "5px",
      fontSize: "16px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    menuItemText: {
      width: "100%",
    },
  });

export type MetricSelectorProps = {
  report: any;
  handleSelection?(report: any): void;
  title: string;
  variant: "dealer" | "group";
  forceMobile?: boolean;
  measures?: any;
} & WithStyles<typeof styles>;

type Departments =
  | "Additional Stats"
  | "Aftercare"
  | "After Sales"
  | "Balance Sheet"
  | "Body Shop"
  | "Carline Analysis"
  | "Driveway"
  | "F&I"
  | "NV F&I"
  | "OEM Support Analysis"
  | "Other Dept."
  | "Overall Dealership"
  | "Parts"
  | "Service"
  | "UV F&I"
  | "Ford Academy KPIs"
  | "VCJ Process Data"
  | "VCJ Process Data 2022"
  | "Network Stats"
  | "Ford SA Scorecard";

const findDepartment = (department: Departments) => {
  const { departments } = React.useContext(CustomDashboardDepartmentsContext);

  return departments.find(d => d.name === department);
};

const MetricSelectorUnstyled: React.FunctionComponent<MetricSelectorProps> = ({ classes, report: reportKey, handleSelection, title, forceMobile }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [categoryEl, setCategoryEl] = React.useState(null);
  const [labelEl, setLabelEl] = React.useState(null);
  const [kpiSearch, setKpiSearch] = React.useState("");
  const [department, setDepartment] = React.useState<string>("");
  const [category, setCategory] = React.useState<string>("");
  const [label, setLabel] = React.useState<string>("");
  const departmentRef = React.useRef();
  const categoryRef = React.useRef();
  const { carlineAnalysisMeasures } = React.useContext(DealerModelsContext);
  const { selectedDealers } = React.useContext(DealerContext);
  const { oems } = React.useContext(OemContext);
  const { additionalStats } = React.useContext(AdditionalStatsContext);

  const determineAdditionalStats = () => {
    try {
      const oemcodes = selectedDealers.reduce((a, c) => {
        return a.concat(c.oems);
      }, []);
      
      return oems.filter(oem => oemcodes.includes(oem.code)).some(oem => oem.enableCustomAdditionalStats);
    }
    catch (error) {
      return [];
    }
  };

  const AllMeasures: { [index: string]: CustomDashboardType } = {
    ...(findDepartment("Overall Dealership") && OverallDealership),
    ...(findDepartment("Balance Sheet") && BalanceSheet),
    ...(findDepartment("NV F&I") && NewVehicle),
    ...(findDepartment("Carline Analysis") && carlineAnalysisMeasures),
    ...(findDepartment("UV F&I") && UsedVehicle),
    ...(findDepartment("F&I") && FI),
    ...(findDepartment("Aftercare") && Aftercare),
    ...(findDepartment("After Sales") && AfterSales),
    ...(findDepartment("Parts") && Parts),
    ...(findDepartment("Service") && Service),
    ...(findDepartment("Body Shop") && BodyShop),
    ...(findDepartment("Driveway") && Driveway),
    ...(findDepartment("Other Dept.") && OtherDept),
    ...(findDepartment("Network Stats") && NetworkStats),
    ...(findDepartment("Ford Academy KPIs") && FordAcademyKPIs),
    ...(findDepartment("VCJ Process Data 2022") && VCJProcessData1),
    ...(findDepartment("VCJ Process Data") && VCJProcessData2),
    ...(findDepartment("Ford SA Scorecard") && fordSAScorecard),
    ...((additionalStats || determineAdditionalStats()) && findDepartment("Additional Stats") && AdditionalStatsMeasures),
    ...(findDepartment("Overall Dealership") && findDepartment("OEM Support Analysis") && OverallDealershipOEMSupportsAnalysis),
    ...(findDepartment("NV F&I") && findDepartment("OEM Support Analysis") && NewVehicleOEMSupportsAnalysis),
    ...(findDepartment("UV F&I") && findDepartment("OEM Support Analysis") && UsedVehicleOEMSupportsAnalysis),
    ...(findDepartment("F&I") && findDepartment("OEM Support Analysis") && FI_OEMSupportsAnalysis),
    ...(findDepartment("After Sales") && findDepartment("OEM Support Analysis") && AfterSalesOEMSupportsAnalysis),
    ...(findDepartment("Parts") && findDepartment("OEM Support Analysis") && PartsOEMSupportsAnalysis),
    ...(findDepartment("Service") && findDepartment("OEM Support Analysis") && ServiceOEMSupportsAnalysis),
    ...(findDepartment("Body Shop") && findDepartment("OEM Support Analysis") && BodyShopOEMSupportsAnalysis),
    ...(findDepartment("Driveway") && findDepartment("OEM Support Analysis") && DrivewayOEMSupportsAnalysis),
  };

  const customMeasures = Object.keys(AllMeasures) || [];

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });

  const departments = React.useMemo(() => {
    if (handleSelection) {
      const arr = [];
      customMeasures.map(key => {
        if (kpiSearch === "" ) {
          if (!arr.includes(AllMeasures[key].department)) {
            return arr.push(AllMeasures[key].department);
          }
          return AllMeasures[key].department;
        } else {
          if (AllMeasures[key].label.toLocaleLowerCase().includes(kpiSearch.toLocaleLowerCase())) {
          if (!arr.includes(AllMeasures[key].department)) {
            return arr.push(AllMeasures[key].department);
          }
          return AllMeasures[key].department;
          }
        }
      });
      return arr;
    }
  }, [kpiSearch]);

  const categories = React.useMemo(() => {
    if (handleSelection) {
      const arr = [];
      customMeasures.map(key => {
        if (kpiSearch === "") {
          if (AllMeasures[key].department === department) {
            if (!arr.includes(AllMeasures[key].category)) {
              return arr.push(AllMeasures[key].category);
            }
            return AllMeasures[key].category;
          }
        } else {
          if (AllMeasures[key].department === department 
            && AllMeasures[key].label.toLocaleLowerCase().includes(kpiSearch.toLocaleLowerCase())) 
            {
            if (!arr.includes(AllMeasures[key].category)) {
              return arr.push(AllMeasures[key].category);
            }
            return AllMeasures[key].category;
          }
        }
        return AllMeasures[key].category;
      });
      return arr;
    }
  }, [department, kpiSearch]);

  // List does not empty if the user clicks away and does not select a label.
  const labels = React.useMemo(() => {
    if (handleSelection) {
      const arr = [];
      customMeasures.map(key => {
        if (
          AllMeasures[key].department === department && 
          AllMeasures[key].category === category && 
          AllMeasures[key].label.toLocaleLowerCase().includes(kpiSearch.toLocaleLowerCase())) {
          if (!arr.includes(AllMeasures[key].label)) {
            return arr.push(key);
          }
          return AllMeasures[key].label;
        }
        return AllMeasures[key].label;
      });
      return arr;
    }
  }, [department, category, kpiSearch]);

  const intl = useIntl();
  if (handleSelection) {
    return (
      <div>
        {(isDesktopOrLaptop || forceMobile) && (
          <KeyboardArrowDown
            aria-haspopup="true"
            className={classes.downArrow}
            onClick={(e: any) => setAnchorEl(e.currentTarget)}
            aria-owns={anchorEl ? "department-menu" : undefined}
          />
        )}
        {/* Department Dropdown menu */}
        <Menu
          anchorEl={anchorEl}
          id="department-menu"
          onClose={() => setAnchorEl(null)}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div ref={departmentRef}>
          <MenuItem disabled value="">
            <em>
              <FormattedMessage id={translation["Departments"]} />
            </em>
          </MenuItem>
          <MenuItem>
            <Input
              placeholder="search KPI"
              onChange={(val) => {
                setKpiSearch(val.target.value);
              }}
            />
          </MenuItem>
          </div>
          {(departments || []).map((key: string) => (
            <MenuItem
              key={key}
              selected={department == key}
              aria-owns={categoryEl ? "category-menu" : undefined}
              aria-haspopup="true"
              onClick={(e: any) => {
                setDepartment(key);
                setCategoryEl(departmentRef.current);
              }}
            >
              <Typography className={classes.menuItemText}>{translation[key] ? intl.formatMessage({ id: translation[key] }) : key}</Typography>
              <KeyboardArrowRight className={classes.rightArr} />
            </MenuItem>
          ))}
        </Menu>
        {/* Category Dropdown menu */}
        <Menu
          id="category-menu"
          anchorEl={categoryEl}
          open={Boolean(categoryEl)}
          onClose={() => setCategoryEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div ref={categoryRef}>
            <MenuItem disabled value="">
              <em>
                <FormattedMessage id={translation["Categories"]} />
              </em>
            </MenuItem>
          </div>
          {(categories || []).map((key: string) => (
            <MenuItem
              key={key}
              selected={category == key}
              aria-owns={categoryEl ? "label-menu" : undefined}
              onClick={(e: any) => {
                setCategory(key);
                setLabelEl(categoryRef.current);
              }}
            >
              <Typography className={classes.menuItemText}>{translation[key] ? intl.formatMessage({ id: translation[key] }) : key}</Typography>
              <KeyboardArrowRight className={classes.rightArr} />
            </MenuItem>
          ))}
        </Menu>
        {/* Labels Dropdown menu */}
        <Menu
          id="label-menu"
          anchorEl={labelEl}
          open={Boolean(labelEl)}
          onClose={() => setLabelEl(null)}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <MenuItem disabled value="">
            <em>
              <FormattedMessage id={translation["Labels"]} />
            </em>
          </MenuItem>
          {(labels || []).map((key: string) => {
            // translates the label
            const translatedLabel = translation[AllMeasures[key].label]
              ? intl.formatMessage({ id: translation[AllMeasures[key].label] })
              : AllMeasures[key].label;
            // car line analysis has models added dynamically so it will remain in English
            const listedlabel = /\b(Retail 1st GP % - |Retail Units Sold - )\b/.test(translatedLabel)
              ? translation[translatedLabel.split(" - ")[0]]
                ? `${intl.formatMessage({ id: translation[translatedLabel.split(" - ")[0]] })} - ${translatedLabel.split(" - ")[1]}`
                : translatedLabel
              : translatedLabel;
            return (
              <MenuItem
                key={key}
                selected={label == AllMeasures[key].label}
                onClick={() => {
                  setLabel(key);
                  customMeasures.map(measure => {
                    if (AllMeasures[measure].name === key) {
                      setAnchorEl(null);
                      setCategoryEl(null);
                      setLabelEl(null);
                      return handleSelection(AllMeasures[measure].name);
                    }
                  });
                }}
              >
                <Typography className={classes.menuItemText}>{listedlabel}</Typography>
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  }
  return <Typography className={classes.heading}>{title ? title : AllMeasures[reportKey].name}</Typography>;
};


// TODO
//   - come back and property type (move this onto it's on props type
const OemMetricSelectorUnstyled: React.FunctionComponent<MetricSelectorProps> = ({ classes, report: reportKey, handleSelection, title, forceMobile, measures : AllMeasures }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [categoryEl, setCategoryEl] = React.useState(null);
  const [department, setDepartment] = React.useState<string>("");
  const departmentRef = React.useRef();


  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });


  const intl = useIntl();
  if (handleSelection) {
    return (
      <div>
        {(isDesktopOrLaptop || forceMobile) && (
          <KeyboardArrowDown
            aria-haspopup="true"
            className={classes.downArrow}
            onClick={(e: any) => setAnchorEl(e.currentTarget)}
            aria-owns={anchorEl ? "department-menu" : undefined}
          />
        )}
        {/* Department Dropdown menu */}
        <Menu
          anchorEl={anchorEl}
          id="metric-menu"
          onClose={() => setAnchorEl(null)}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {(AllMeasures || []).map((key: string) => (
            <MenuItem
              key={key}
              onClick={(e: any) => {
                handleSelection(key)
              }}
            >
              <Typography className={classes.menuItemText}>{translation[key] ? intl.formatMessage({ id: translation[key] }) : key}</Typography>
              <KeyboardArrowRight className={classes.rightArr} />
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
  return <Typography className={classes.heading}>{title ? title : AllMeasures[reportKey].name}</Typography>;
};


export const MetricSelector = withStyles(styles)(MetricSelectorUnstyled);
export const OemMetricSelector = withStyles(styles)(OemMetricSelectorUnstyled);
export const CenteredMetricSelector = withStyles(centeredStyles)(MetricSelectorUnstyled);

export const getCarlineAnalysisMeasures = (dealerModels: string[]) => {
  const customLabels = dealerModels.map((model, i) => {
    return CustomDashboardModelTemplate(model, 501 + i);
  });

  const customRetailLabels = dealerModels.map((model, i) => {
    return CustomDashboardRetailUnitsTemplate(model, 501 + i);
  });

  var a: { [index: string]: CustomDashboardType } = {};

  customLabels.map(label => (a[label.name] = label));
  customRetailLabels.map(label => (a[label.name] = label));

  return a;
};
