import { CustomDashboardType, getMeasure } from "../CustomDashReports";

export const NewVehicle: { [index: string]: CustomDashboardType } = {
"NV F&I > SALES > Retail Units Sold": {
  name: "NV F&I > SALES > Retail Units Sold",
  department: "NV F&I",
  category: "NV SALES",
  label: "NV Retail Units Sold",
  tableNumber: 27,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > In Dealer Units | New Retail | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > SALES > Fleet Units Sold": {
  name: "NV F&I > SALES > Fleet Units Sold",
  department: "NV F&I",
  category: "NV SALES",
  label: "NV Fleet Units Sold",
  tableNumber: 27,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > In Dealer Units | New Fleet | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > SALES > Total Units Sold": {
  name: "NV F&I > SALES > Total Units Sold",
  department: "NV F&I",
  category: "NV SALES",
  label: "NV Total Units Sold",
  tableNumber: 27,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > In Dealer Units | New | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > SALES > Total Units Target ": {
  name: "NV F&I > SALES > Total Units Target ",
  department: "NV F&I",
  category: "NV SALES",
  label: "NV Total Units Target ",
  tableNumber: 27,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Market Share > Sales Target > New Retail Units | New | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > SALES > Target ahcievment (Factory)": {
  name: "NV F&I > SALES > Target ahcievment (Factory)",
  department: "NV F&I",
  category: "NV SALES",
  label: "NV Target Achievement (Factory)",
  tableNumber: 27,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "MS > Sales Target % Achieved - New Total Units | New | All Brand | All Model | | AVG",
  canAverage: false
},
"NV F&I > SALES > NV Sales": {
  name: "NV F&I > SALES > NV Sales",
  department: "NV F&I",
  category: "NV SALES",
  label: "NV Sales",
  tableNumber: 27,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | New | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > SALES > NV F&I Sales": {
  name: "NV F&I > SALES > NV F&I Sales",
  department: "NV F&I",
  category: "NV SALES",
  label: "NV F&I Sales",
  tableNumber: 55,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > F&I Net Sales New | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > SALES > NV Aftercare Sales": {
  name: "NV F&I > SALES > NV Aftercare Sales",
  department: "NV F&I",
  category: "NV SALES",
  label: "NV Aftercare Sales",
  tableNumber: 142,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Aftercare Net Sales New | Aftercare | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > SALES > NV Sales incl. F&I & Aftercare": {
  name: "NV F&I > SALES > NV Sales incl. F&I & Aftercare",
  department: "NV F&I",
  category: "NV SALES",
  label: "NV Sales incl. F&I & Aftercare",
  tableNumber: 27,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > New Net Sales New Aftercare New F&I | New | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > SALES > Breakeven Days": {
  name: "NV F&I > SALES > Breakeven Days",
  department: "NV F&I",
  category: "NV SALES",
  label: "NV Breakeven Days",
  tableNumber: 28,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Dept. Breakeven Days | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > SALES > Breakeven Units": {
  name: "NV F&I > SALES > Breakeven Units",
  department: "NV F&I",
  category: "NV SALES",
  label: "NV Breakeven Units",
  tableNumber: 28,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > NV Breakeven Units | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > SALES > Total Dealership Breakeven NV Units": {
  name: "NV F&I > SALES > Total Dealership Breakeven NV Units",
  department: "NV F&I",
  category: "NV SALES",
  label: "Total Dealership Breakeven NV Units",
  tableNumber: 2,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Total Dealership Breakeven Units | All Department | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > SALES > SC Productivity": {
  name: "NV F&I > SALES > SC Productivity",
  department: "NV F&I",
  category: "NV SALES",
  label: "NV SC Productivity",
  tableNumber: 27,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Units per Productive | New | All Brand | All Model | | AVG",
  canAverage: false
},
"NV F&I > SALES > Number of SC (Average)": {
  name: "NV F&I > SALES > Number of SC (Average)",
  department: "NV F&I",
  category: "NV SALES",
  label: "NV Number of SC (Average)",
  tableNumber: 27,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Productives | New | All Brand | All Model | | AVG",
  canAverage: true
},
"NV F&I > SALES > NV Net Sales / Unit (excl. Acc)": {
  name: "NV F&I > SALES > NV Net Sales / Unit (excl. Acc)",
  department: "NV F&I",
  category: "NV SALES",
  label: "NV Net Sales / Unit (excl. Acc)",
  tableNumber: 27,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales (excl Acc) pu | New | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > SALES > NV Retail Sales / NV Retail Unit": {
  name: "NV F&I > SALES > NV Retail Sales / NV Retail Unit",
  department: "NV F&I",
  category: "NV SALES",
  label: "NV Retail Sales / NV Retail Unit",
  tableNumber: 27,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales (excl Acc) pu | New Retail | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > SALES > NV Fleet Sales / NV Fleet Unit": {
  name: "NV F&I > SALES > NV Fleet Sales / NV Fleet Unit",
  department: "NV F&I",
  category: "NV SALES",
  label: "NV Fleet Sales / NV Fleet Unit",
  tableNumber: 27,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales (excl Acc) pu | New Fleet | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > SALES > NV Accessories Net Sales / Unit": {
  name: "NV F&I > SALES > NV Accessories Net Sales / Unit",
  department: "NV F&I",
  category: "NV SALES",
  label: "NV Accessories Net Sales / Unit",
  tableNumber: 27,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Accessories Net sales pu | New | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > SALES > NV Finance Penetration Retail": {
  name: "NV F&I > SALES > NV Finance Penetration Retail",
  department: "NV F&I",
  category: "NV SALES",
  label: "NV Finance Penetration Retail",
  tableNumber: 27,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > F&I Contracts 1st Yr New Retail pu - Financed | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > SALES > NV Insurance Penetration Retail": {
  name: "NV F&I > SALES > NV Insurance Penetration Retail",
  department: "NV F&I",
  category: "NV SALES",
  label: "NV Insurance Penetration Retail",
  tableNumber: 27,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > F&I Contracts 1st Yr New Retail pu - Insurance | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > SALES > NV Extended Warranty Sales / Contract": {
  name: "NV F&I > SALES > NV Extended Warranty Sales / Contract",
  department: "NV F&I",
  category: "NV SALES",
  label: "NV Extended Warranty Sales / Contract",
  tableNumber: 27,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Aftercare Net Sales New p warranty contract | Aftercare | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > MIX > NV MIX": {
  name: "NV F&I > MIX > NV MIX",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV MIX",
  tableNumber: 29,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > New Gross Profit % of Sales (incl F&I & Aftercare) | NewF&IAftercare | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > MIX > NV Sales incl. F&I & Aftercare": {
  name: "NV F&I > MIX > NV Sales incl. F&I & Aftercare",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV Sales incl. F&I & Aftercare",
  tableNumber: 27,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > New Net Sales New Aftercare New F&I | New | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > MIX > NV Sales": {
  name: "NV F&I > MIX > NV Sales",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV Sales",
  tableNumber: 27,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | New | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > MIX > NV F&I Sales": {
  name: "NV F&I > MIX > NV F&I Sales",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV F&I Sales",
  tableNumber: 55,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > F&I Gross New | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > MIX > NV Aftercare Sales": {
  name: "NV F&I > MIX > NV Aftercare Sales",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV Aftercare Sales",
  tableNumber: 142,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Aftercare Gross New | Aftercare | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > MIX > NV GP incl. F&I & Aftercare": {
  name: "NV F&I > MIX > NV GP incl. F&I & Aftercare",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV GP incl. F&I & Aftercare",
  tableNumber: 29,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > New Gross Profit (incl F&I & Aftercare) | New | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > MIX > NV GP": {
  name: "NV F&I > MIX > NV GP",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV GP",
  tableNumber: 29,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | New | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > MIX > NV F&I GP": {
  name: "NV F&I > MIX > NV F&I GP",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV F&I GP",
  tableNumber: 56,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > F&I Gross New | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > MIX > NV Aftercare GP": {
  name: "NV F&I > MIX > NV Aftercare GP",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV Aftercare GP",
  tableNumber: 143,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Aftercare Gross New | Aftercare | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > MIX > NV Front End Gross / Unit": {
  name: "NV F&I > MIX > NV Front End Gross / Unit",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV Front End Gross / Unit",
  tableNumber: 29,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Front End Gross (excl Acc) pu | New | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > MIX > NV Direct Incentives / Unit": {
  name: "NV F&I > MIX > NV Direct Incentives / Unit",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV Direct Incentives / Unit",
  tableNumber: 29,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > 1st > Direct Incentives pu | New | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > MIX > NV First Gross / Unit": {
  name: "NV F&I > MIX > NV First Gross / Unit",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV First Gross / Unit",
  tableNumber: 29,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > 1st Gross Profit (excl Acc) pu | New | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > MIX > NV Accessory GP / Unit": {
  name: "NV F&I > MIX > NV Accessory GP / Unit",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV Accessory GP / Unit",
  tableNumber: 29,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Accessories GP pu | New | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > MIX > NV Indirect Incentives / Unit": {
  name: "NV F&I > MIX > NV Indirect Incentives / Unit",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV Indirect Incentives / Unit",
  tableNumber: 29,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "OI > Indirect Incentives pu | New | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > MIX > NV GP / Unit": {
  name: "NV F&I > MIX > NV GP / Unit",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV GP / Unit",
  tableNumber: 29,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit pu | New | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > MIX > NV F&I GP / Unit": {
  name: "NV F&I > MIX > NV F&I GP / Unit",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV F&I GP / Unit",
  tableNumber: 56,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > F&I Gross New PNU | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > MIX > NV GP incl. F&I / Unit": {
  name: "NV F&I > MIX > NV GP incl. F&I / Unit",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV GP incl. F&I / Unit",
  tableNumber: 29,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit (New GP (incl New F&I)) pu | New | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > MIX > NV Aftercare GP / Unit": {
  name: "NV F&I > MIX > NV Aftercare GP / Unit",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV Aftercare GP / Unit",
  tableNumber: 143,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Aftercare Gross New pu | Aftercare | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > MIX > NV GP incl. F&I & Aftercare / Unit": {
  name: "NV F&I > MIX > NV GP incl. F&I & Aftercare / Unit",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV GP incl. F&I & Aftercare / Unit",
  tableNumber: 29,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > New Gross Profit (incl F&I & Aftercare) pu | New | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > MIX > FEG % Gross Sales": {
  name: "NV F&I > MIX > FEG % Gross Sales",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV FEG % Gross Sales",
  tableNumber: 29,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Front End Gross % of Gross Sales | New | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > MIX > First Gross % Net Sales": {
  name: "NV F&I > MIX > First Gross % Net Sales",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV First Gross % Net Sales",
  tableNumber: 29,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > 1st Gross Profit (excl Acc) % of Net Sales | New | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > MIX > NV GP %": {
  name: "NV F&I > MIX > NV GP %",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV GP %",
  tableNumber: 29,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit % of Sales (MIX) | New | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > MIX > NV GP % incl. F&I & Aftercare": {
  name: "NV F&I > MIX > NV GP % incl. F&I & Aftercare",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV GP % incl. F&I & Aftercare",
  tableNumber: 29,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > New Gross Profit % of Sales (incl F&I & Aftercare) | NewF&IAftercare | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > NV MIX > NV GP % incl. F&I & Aftercare (excluding OEM Incentives)": {
  name: "NV F&I > NV MIX > NV GP % incl. F&I & Aftercare (excluding OEM Incentives)",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV GP % incl. F&I & Aftercare (excluding OEM Incentives)",
  tableNumber: 29,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > New Gross Profit exc OEM Incentives % of Sales (incl F&I & Aftercare) | NewF&IAftercare | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > MIX > NV OEM Incentives % GP": {
  name: "NV F&I > MIX > NV OEM Incentives % GP",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV OEM Incentives % GP",
  tableNumber: 29,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > OEM Incentives % TGP NewF&IAftercare | NewF&IAftercare | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > MIX > Finance Penetration Retail": {
  name: "NV F&I > MIX > Finance Penetration Retail",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV Finance Penetration Retail",
  tableNumber: 55,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > F&I Contracts 1st Yr New Retail pu - Financed | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > MIX > Insurance Penetration Retail": {
  name: "NV F&I > MIX > Insurance Penetration Retail",
  department: "NV F&I",
  category: "NV GROSS PROFIT",
  label: "NV Insurance Penetration Retail",
  tableNumber: 55,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > F&I Contracts 1st Yr New Retail pu - Insurance | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAINED > NV RETAINED": {
  name: "NV F&I > RETAINED > NV RETAINED",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV RETAINED",
  tableNumber: 30,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT % of TGP: RETAINED | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAINED > NV Total GP": {
  name: "NV F&I > RETAINED > NV Total GP",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV Total GP",
  tableNumber: 29,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > New Gross Profit (incl F&I & Aftercare) | New | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > RETAINED > NV Total Expenses": {
  name: "NV F&I > RETAINED > NV Total Expenses",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV Total Expenses",
  tableNumber: 30,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > RETAINED > NV Total PBT": {
  name: "NV F&I > RETAINED > NV Total PBT",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV Total PBT",
  tableNumber: 38,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT (Profit Before Tax) | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > RETAINED > Total Expenses % GP": {
  name: "NV F&I > RETAINED > Total Expenses % GP",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV Total Expenses % GP",
  tableNumber: 30,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses % of TGP | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAINED > Variable Expenses  % GP": {
  name: "NV F&I > RETAINED > Variable Expenses  % GP",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV Variable Expenses  % GP",
  tableNumber: 31,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses % of TGP | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAINED > Personnel Expenses % GP": {
  name: "NV F&I > RETAINED > Personnel Expenses % GP",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV Personnel Expenses % GP",
  tableNumber: 32,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PE > Personnel Expenses % of TGP | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAINED > Other Semi Variable Expenses % GP": {
  name: "NV F&I > RETAINED > Other Semi Variable Expenses % GP",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV Other Semi Variable Expenses % GP",
  tableNumber: 33,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "SVE > Other Semi Variable Expenses % of TGP | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAINED > Fixed Expenses % GP": {
  name: "NV F&I > RETAINED > Fixed Expenses % GP",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV Fixed Expenses % GP",
  tableNumber: 34,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "FE > Fixed Expenses % of TGP | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAINED > Interest Expenses % GP": {
  name: "NV F&I > RETAINED > Interest Expenses % GP",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV Interest Expenses % GP",
  tableNumber: 35,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Interest % of TGP | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAINED > Allocation of Admin Expenses % GP": {
  name: "NV F&I > RETAINED > Allocation of Admin Expenses % GP",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV Allocation of Admin Expenses % GP",
  tableNumber: 30,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Adm > Admin Exp. Allocation % of TGP | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAINED > Variable Expenses% Total Expenses": {
  name: "NV F&I > RETAINED > Variable Expenses% Total Expenses",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV Variable Expenses% Total Expenses",
  tableNumber: 31,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses % of Total Expenses | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAINED > Personnel Expenses% Total Expenses": {
  name: "NV F&I > RETAINED > Personnel Expenses% Total Expenses",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV Personnel Expenses% Total Expenses",
  tableNumber: 32,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PE > Personnel Expenses % of Total Expenses | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAINED > Other Semi Var.Exp.% Total Expenses": {
  name: "NV F&I > RETAINED > Other Semi Var.Exp.% Total Expenses",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV Other Semi Var.Exp.% Total Expenses",
  tableNumber: 33,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "SVE > Other Semi Variable Expenses % of Total Expenses | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAINED > Fixed Expenses% Total Expenses": {
  name: "NV F&I > RETAINED > Fixed Expenses% Total Expenses",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV Fixed Expenses% Total Expenses",
  tableNumber: 34,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "FE > Fixed Expenses % of Total Expenses | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAINED > Interest Expenses% Total Expenses": {
  name: "NV F&I > RETAINED > Interest Expenses% Total Expenses",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV Interest Expenses% Total Expenses",
  tableNumber: 35,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Interest % of Total Expenses | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAINED > Allocation of Admin Expenses% Total Expenses": {
  name: "NV F&I > RETAINED > Allocation of Admin Expenses% Total Expenses",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV Allocation of Admin Expenses% Total Expenses",
  tableNumber: 30,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Adm > Admin Exp. Allocation % of Total Expenses | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAINED > Variable Expenses / Unit": {
  name: "NV F&I > RETAINED > Variable Expenses / Unit",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV Variable Expenses / Unit",
  tableNumber: 31,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses pu | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAINED > Sales Commission / Unit": {
  name: "NV F&I > RETAINED > Sales Commission / Unit",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV Sales Commission / Unit",
  tableNumber: 31,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Sales Commission pu | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAINED > Direct Advertising / Unit": {
  name: "NV F&I > RETAINED > Direct Advertising / Unit",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV Direct Advertising / Unit",
  tableNumber: 31,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Direct Advertising pu | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAINED > PDI & Free Service / Unit": {
  name: "NV F&I > RETAINED > PDI & Free Service / Unit",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV PDI & Free Service / Unit",
  tableNumber: 31,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Pre-delivery and Free Services pu | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAINED > Other Variable Exp. / Unit": {
  name: "NV F&I > RETAINED > Other Variable Exp. / Unit",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV Other Variable Exp. / Unit",
  tableNumber: 31,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses (Excl Comm, Adv. and PDI) pu | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAINED > NV Floor Plan Interest / Unit": {
  name: "NV F&I > RETAINED > NV Floor Plan Interest / Unit",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "NV Floor Plan Interest / Unit",
  tableNumber: 35,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Floorplan Interest pu | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAINED > New F&I Aftercare Cost Recovery": {
  name: "NV F&I > RETAINED > New F&I Aftercare Cost Recovery",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "New F&I Aftercare Cost Recovery",
  tableNumber: 171,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Cost Recovery | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAINED > New Cost Recovery": {
  name: "NV F&I > RETAINED > New Cost Recovery",
  department: "NV F&I",
  category: "NV EXPENSES",
  label: "New Cost Recovery",
  tableNumber: 171,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Cost Recovery | New | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > ACTIVITY > NV ACTIVITY": {
  name: "NV F&I > ACTIVITY > NV ACTIVITY",
  department: "NV F&I",
  category: "NV OPERATIONAL ASSETS",
  label: "NV ACTIVITY",
  tableNumber: 36,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Asset Activity | NewAftercareF&ISplit | All Brand | All Model | | AVG",
  canAverage: false
},
"NV F&I > ACTIVITY > NV Annualised Sales": {
  name: "NV F&I > ACTIVITY > NV Annualised Sales",
  department: "NV F&I",
  category: "NV OPERATIONAL ASSETS",
  label: "NV Annualised Sales",
  tableNumber: 27,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Projected Annualised Sales | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > ACTIVITY > NV Average Operational Assets": {
  name: "NV F&I > ACTIVITY > NV Average Operational Assets",
  department: "NV F&I",
  category: "NV OPERATIONAL ASSETS",
  label: "NV Average Operational Assets",
  tableNumber: 36,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Average Operational Assets | NewAftercareF&ISplit | All Brand | All Model | | AVG",
  canAverage: true
},
"NV F&I > ACTIVITY > NV Inventory Days": {
  name: "NV F&I > ACTIVITY > NV Inventory Days",
  department: "NV F&I",
  category: "NV OPERATIONAL ASSETS",
  label: "NV Inventory Days",
  tableNumber: 36,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Inventory days | New | All Brand | All Model | | AVG",
  canAverage: false
},
"NV F&I > ACTIVITY > NV Inventory Units (ending)": {
  name: "NV F&I > ACTIVITY > NV Inventory Units (ending)",
  department: "NV F&I",
  category: "NV OPERATIONAL ASSETS",
  label: "NV Inventory Units (ending)",
  tableNumber: 36,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Inventory Units | New | All Brand | All Model | | CLOSE",
  canAverage: true
},
"NV F&I > ACTIVITY > NV Inventory Value (ending)": {
  name: "NV F&I > ACTIVITY > NV Inventory Value (ending)",
  department: "NV F&I",
  category: "NV OPERATIONAL ASSETS",
  label: "NV Inventory Value (ending)",
  tableNumber: 36,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Inventory Value | New | All Brand | All Model | | CLOSE",
  canAverage: true
},
"NV F&I > ACTIVITY > NV Inventory Value 0-30 days mix": {
  name: "NV F&I > ACTIVITY > NV Inventory Value 0-30 days mix",
  department: "NV F&I",
  category: "NV OPERATIONAL ASSETS",
  label: "NV Inventory Value 0-30 days mix",
  tableNumber: 36,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Inventory Value > 0 - 30 days MIX | New | All Brand | All Model | | CLOSE",
  canAverage: false
},
"NV F&I > ACTIVITY > NV Inventory Value 31-60 days mix": {
  name: "NV F&I > ACTIVITY > NV Inventory Value 31-60 days mix",
  department: "NV F&I",
  category: "NV OPERATIONAL ASSETS",
  label: "NV Inventory Value 31-60 days mix",
  tableNumber: 36,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Inventory Value > 31 - 60 days MIX | New | All Brand | All Model | | CLOSE",
  canAverage: false
},
"NV F&I > ACTIVITY > NV Inventory Value 61-90 days mix": {
  name: "NV F&I > ACTIVITY > NV Inventory Value 61-90 days mix",
  department: "NV F&I",
  category: "NV OPERATIONAL ASSETS",
  label: "NV Inventory Value 61-90 days mix",
  tableNumber: 36,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Inventory Value > 61 - 90 days MIX | New | All Brand | All Model | | CLOSE",
  canAverage: false
},
"NV F&I > ACTIVITY > NV Inventory Value >90 days mix": {
  name: "NV F&I > ACTIVITY > NV Inventory Value >90 days mix",
  department: "NV F&I",
  category: "NV OPERATIONAL ASSETS",
  label: "NV Inventory Value >90 days mix",
  tableNumber: 36,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Inventory Value > 90+ days MIX | New | All Brand | All Model | | CLOSE",
  canAverage: false
},
"NV F&I > ACTIVITY > Floor Plan Interest % NV GP": {
  name: "NV F&I > ACTIVITY > Floor Plan Interest % NV GP",
  department: "NV F&I",
  category: "NV OPERATIONAL ASSETS",
  label: "NV Floor Plan Interest % NV GP",
  tableNumber: 35,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Floorplan Interest % of Gross Profit | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > ACTIVITY > Floor Plan Interest / Unit": {
  name: "NV F&I > ACTIVITY > Floor Plan Interest / Unit",
  department: "NV F&I",
  category: "NV OPERATIONAL ASSETS",
  label: "NV Floor Plan Interest / Unit",
  tableNumber: 35,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Floorplan Interest pu | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > ROOA > ROOA NV incl. F&I & Aftercare": {
  name: "NV F&I > ROOA > ROOA NV incl. F&I & Aftercare",
  department: "NV F&I",
  category: "NV MRA MODEL",
  label: "NV ROOA incl. F&I & Aftercare",
  tableNumber: 37,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Return on Operational Assets (ROOA) | NewAftercareF&ISplit | All Brand | All Model | | AVG",
  canAverage: false
},
"NV F&I > ROOA > ROOA NV only": {
  name: "NV F&I > ROOA > ROOA NV only",
  department: "NV F&I",
  category: "NV MRA MODEL",
  label: "ROOA NV only",
  tableNumber: 37,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Return on Operational Assets (ROOA) | New | All Brand | All Model | | AVG",
  canAverage: false
},
"NV F&I > ROOA > MIX": {
  name: "NV F&I > ROOA > MIX",
  department: "NV F&I",
  category: "NV MRA MODEL",
  label: "NV MIX",
  tableNumber: 29,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit % of Sales (MIX) | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > ROOA > RETAINED": {
  name: "NV F&I > ROOA > RETAINED",
  department: "NV F&I",
  category: "NV MRA MODEL",
  label: "NV RETAINED",
  tableNumber: 30,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT % of TGP: RETAINED | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > ROOA > ACTIVITY": {
  name: "NV F&I > ROOA > ACTIVITY",
  department: "NV F&I",
  category: "NV MRA MODEL",
  label: "NV ACTIVITY",
  tableNumber: 36,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Asset Activity | NewAftercareF&ISplit | All Brand | All Model | | AVG",
  canAverage: false
},
"NV F&I > ROOA > Gross profit": {
  name: "NV F&I > ROOA > Gross profit",
  department: "NV F&I",
  category: "NV MRA MODEL",
  label: "NV Gross profit",
  tableNumber: 29,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > ROOA > Revenue": {
  name: "NV F&I > ROOA > Revenue",
  department: "NV F&I",
  category: "NV MRA MODEL",
  label: "NV Revenue",
  tableNumber: 27,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > ROOA > Expenses": {
  name: "NV F&I > ROOA > Expenses",
  department: "NV F&I",
  category: "NV MRA MODEL",
  label: "NV Expenses",
  tableNumber: 30,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > ROOA > Annualised Sales": {
  name: "NV F&I > ROOA > Annualised Sales",
  department: "NV F&I",
  category: "NV MRA MODEL",
  label: "NV Annualised Sales",
  tableNumber: 36,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Projected Annualised Sales | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > ROOA > Average Operational Assets": {
  name: "NV F&I > ROOA > Average Operational Assets",
  department: "NV F&I",
  category: "NV MRA MODEL",
  label: "NV Average Operational Assets",
  tableNumber: 36,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Average Operational Assets | NewAftercareF&ISplit | All Brand | All Model | | AVG",
  canAverage: true
},
"NV F&I > ROOA > ROS": {
  name: "NV F&I > ROOA > ROS",
  department: "NV F&I",
  category: "NV MRA MODEL",
  label: "NV ROS",
  tableNumber: 38,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT Profit Before Tax % of Sales (NewF&IAftercare) | New | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > ROS (Income Statement) > ROS": {
  name: "NV F&I > ROS (Income Statement) > ROS",
  department: "NV F&I",
  category: "NV ROS (Income Statement)",
  label: "NV ROS",
  tableNumber: 38,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT Profit Before Tax % of Sales (NewF&IAftercare) | New | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > ROS (Income Statement) > Sales": {
  name: "NV F&I > ROS (Income Statement) > Sales",
  department: "NV F&I",
  category: "NV ROS (Income Statement)",
  label: "NV Sales",
  tableNumber: 27,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > New Net Sales New Aftercare New F&I | New | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > ROS (Income Statement) > PBT": {
  name: "NV F&I > ROS (Income Statement) > PBT",
  department: "NV F&I",
  category: "NV ROS (Income Statement)",
  label: "NV PBT",
  tableNumber: 38,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT (Profit Before Tax) | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > ROS (Income Statement) > Dept. Profit % Net Sales": {
  name: "NV F&I > ROS (Income Statement) > Dept. Profit % Net Sales",
  department: "NV F&I",
  category: "NV ROS (Income Statement)",
  label: "NV Dept. Profit % Net Sales",
  tableNumber: 38,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "E > Earnings After Interest % of TNS (NewF&IAftercare) | New | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > ROS (Income Statement) > EBIT% (PBIT%)": {
  name: "NV F&I > ROS (Income Statement) > EBIT% (PBIT%)",
  department: "NV F&I",
  category: "NV ROS (Income Statement)",
  label: "NV EBIT% (PBIT%)",
  tableNumber: 38,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBIT % of Sales | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > ROS (Income Statement) > Variable Selling Gross % Gorss Profit": {
  name: "NV F&I > ROS (Income Statement) > Variable Selling Gross % Gorss Profit",
  department: "NV F&I",
  category: "NV ROS (Income Statement)",
  label: "NV Variable Selling Gross % Gorss Profit",
  tableNumber: 31,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Variable Selling Gross % of TGP | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > ROS (Income Statement) > Selling Gross % Gross Profit": {
  name: "NV F&I > ROS (Income Statement) > Selling Gross % Gross Profit",
  department: "NV F&I",
  category: "NV ROS (Income Statement)",
  label: "NV Selling Gross % Gross Profit",
  tableNumber: 38,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Selling Gross % of TGP | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > ROS (Income Statement) > Selling Gross % Net Sales": {
  name: "NV F&I > ROS (Income Statement) > Selling Gross % Net Sales",
  department: "NV F&I",
  category: "NV ROS (Income Statement)",
  label: "NV Selling Gross % Net Sales",
  tableNumber: 38,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Selling Gross % of TNS | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > ROS (Income Statement) > Gross Profit": {
  name: "NV F&I > ROS (Income Statement) > Gross Profit",
  department: "NV F&I",
  category: "NV ROS (Income Statement)",
  label: "NV Gross Profit",
  tableNumber: 29,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > New Gross Profit (incl F&I & Aftercare) | New | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > ROS (Income Statement) > Expenses": {
  name: "NV F&I > ROS (Income Statement) > Expenses",
  department: "NV F&I",
  category: "NV ROS (Income Statement)",
  label: "NV Expenses",
  tableNumber: 30,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > ROS (Income Statement) > Variable Expenses": {
  name: "NV F&I > ROS (Income Statement) > Variable Expenses",
  department: "NV F&I",
  category: "NV ROS (Income Statement)",
  label: "NV Variable Expenses",
  tableNumber: 31,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > ROS (Income Statement) > Personnel Expenses": {
  name: "NV F&I > ROS (Income Statement) > Personnel Expenses",
  department: "NV F&I",
  category: "NV ROS (Income Statement)",
  label: "NV Personnel Expenses",
  tableNumber: 32,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PE > Personnel Expenses | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > ROS (Income Statement) > Other Semi Variable Expenses": {
  name: "NV F&I > ROS (Income Statement) > Other Semi Variable Expenses",
  department: "NV F&I",
  category: "NV ROS (Income Statement)",
  label: "NV Other Semi Variable Expenses",
  tableNumber: 33,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "SVE > Other Semi Variable Expenses | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > ROS (Income Statement) > Fixed Expenses": {
  name: "NV F&I > ROS (Income Statement) > Fixed Expenses",
  department: "NV F&I",
  category: "NV ROS (Income Statement)",
  label: "NV Fixed Expenses",
  tableNumber: 34,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "FE > Fixed Expenses | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > ROS (Income Statement) > Interest Expenses": {
  name: "NV F&I > ROS (Income Statement) > Interest Expenses",
  department: "NV F&I",
  category: "NV ROS (Income Statement)",
  label: "NV Interest Expenses",
  tableNumber: 35,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Interest | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > ROS (Income Statement) > Departmental Profit": {
  name: "NV F&I > ROS (Income Statement) > Departmental Profit",
  department: "NV F&I",
  category: "NV ROS (Income Statement)",
  label: "NV Departmental Profit",
  tableNumber: 38,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "E > Earnings After Interest (EAIT) | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > ROS (Income Statement) > Allocation of Admin Expenses": {
  name: "NV F&I > ROS (Income Statement) > Allocation of Admin Expenses",
  department: "NV F&I",
  category: "NV ROS (Income Statement)",
  label: "NV Allocation of Admin Expenses",
  tableNumber: 30,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Adm > Admin Exp. Allocation | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > ROS (Income Statement) > EBIT": {
  name: "NV F&I > ROS (Income Statement) > EBIT",
  department: "NV F&I",
  category: "NV ROS (Income Statement)",
  label: "NV EBIT",
  tableNumber: 38,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBIT (Profit Before Interest & Tax) | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > PRODUCTIVITY > SC Productivity": {
  name: "NV F&I > PRODUCTIVITY > SC Productivity",
  department: "NV F&I",
  category: "NV PRODUCTIVITY",
  label: "NV SC Productivity",
  tableNumber: 39,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Units per Productive | New | All Brand | All Model | | AVG",
  canAverage: false
},
"NV F&I > PRODUCTIVITY > NV Units Sold": {
  name: "NV F&I > PRODUCTIVITY > NV Units Sold",
  department: "NV F&I",
  category: "NV PRODUCTIVITY",
  label: "NV Units Sold",
  tableNumber: 39,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > In Dealer Units | New | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > PRODUCTIVITY > NV Productive Staff": {
  name: "NV F&I > PRODUCTIVITY > NV Productive Staff",
  department: "NV F&I",
  category: "NV PRODUCTIVITY",
  label: "NV Productive Staff",
  tableNumber: 39,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Productives | New | All Brand | All Model | | AVG",
  canAverage: true
},
"NV F&I > PRODUCTIVITY > GP / Productive / Month": {
  name: "NV F&I > PRODUCTIVITY > GP / Productive / Month",
  department: "NV F&I",
  category: "NV PRODUCTIVITY",
  label: "NV GP / Productive / Month",
  tableNumber: 39,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ave Monthly TGP per Productive | NewAftercareF&ISplit | All Brand | All Model | | AVG",
  canAverage: false
},
"NV F&I > PRODUCTIVITY > GP / Employee / Month": {
  name: "NV F&I > PRODUCTIVITY > GP / Employee / Month",
  department: "NV F&I",
  category: "NV PRODUCTIVITY",
  label: "NV GP / Employee / Month",
  tableNumber: 39,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ave Monthly TGP per Employee | NewAftercareF&ISplit | All Brand | All Model | | AVG",
  canAverage: false
},
"NV F&I > PRODUCTIVITY > Total Staff": {
  name: "NV F&I > PRODUCTIVITY > Total Staff",
  department: "NV F&I",
  category: "NV PRODUCTIVITY",
  label: "NV Total Staff",
  tableNumber: 39,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Total Employees | NewAftercareF&ISplit | All Brand | All Model | | AVG",
  canAverage: true
},
"NV F&I > PRODUCTIVITY > NV Non-Productive Staff": {
  name: "NV F&I > PRODUCTIVITY > NV Non-Productive Staff",
  department: "NV F&I",
  category: "NV PRODUCTIVITY",
  label: "NV Non-Productive Staff",
  tableNumber: 39,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Non-Productives | New | All Brand | All Model | | AVG",
  canAverage: true
},
"NV F&I > PRODUCTIVITY > NV PDI Staff": {
  name: "NV F&I > PRODUCTIVITY > NV PDI Staff",
  department: "NV F&I",
  category: "NV PRODUCTIVITY",
  label: "NV PDI Staff",
  tableNumber: 39,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Dedicated PDI Technician | New | All Brand | All Model | | AVG",
  canAverage: true
},
"NV F&I > PRODUCTIVITY > F&I Staff Allocation": {
  name: "NV F&I > PRODUCTIVITY > F&I Staff Allocation",
  department: "NV F&I",
  category: "NV PRODUCTIVITY",
  label: "F&I Staff Allocation NV",
  tableNumber: 66,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > F&I Allocation New | F&I Dept | All Brand | All Model | | AVG",
  canAverage: true
},
"NV F&I > PRODUCTIVITY > Aftercare Staff Allocation": {
  name: "NV F&I > PRODUCTIVITY > Aftercare Staff Allocation",
  department: "NV F&I",
  category: "NV PRODUCTIVITY",
  label: "Aftercare Staff Allocation NV",
  tableNumber: 153,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Aftercare Allocation New | Aftercare | All Brand | All Model | | AVG",
  canAverage: true
},
"NV F&I > PRODUCTIVITY > NV Ratio Productive : Non Productive": {
  name: "NV F&I > PRODUCTIVITY > NV Ratio Productive : Non Productive",
  department: "NV F&I",
  category: "NV PRODUCTIVITY",
  label: "NV Ratio Productive : Non Productive",
  tableNumber: 39,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ratio Productive to Non-Productive staff | NewAftercareF&ISplit | All Brand | All Model | | AVG",
  canAverage: false
},
"NV F&I > PRODUCTIVITY > NV Ratio Productive : Non Productive (excl. F&I Aftercare)": {
  name: "NV F&I > PRODUCTIVITY > NV Ratio Productive : Non Productive (excl. F&I Aftercare)",
  department: "NV F&I",
  category: "NV PRODUCTIVITY",
  label: "NV Ratio Productive : Non Productive (excl. F&I Aftercare)",
  tableNumber: 39,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ratio Productive to Non-Productive staff | New | All Brand | All Model | | AVG",
  canAverage: false
},
"NV F&I > RETAIL & NV FLEET > Target ahcievment (Factory)": {
  name: "NV F&I > RETAIL & NV FLEET > Target ahcievment (Factory)",
  department: "NV F&I",
  category: "NV RETAIL  &  NV FLEET",
  label: "NV Target Achievement (Factory)",
  tableNumber: 40,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "MS > Sales Target % Achieved - New Total Units | New | All Brand | All Model | | AVG",
  canAverage: false
},
"NV F&I > RETAIL & NV FLEET > Retail Units Sold": {
  name: "NV F&I > RETAIL & NV FLEET > Retail Units Sold",
  department: "NV F&I",
  category: "NV RETAIL  &  NV FLEET",
  label: "NV Retail Units Sold",
  tableNumber: 40,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > In Dealer Units | New Retail | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > RETAIL & NV FLEET > Fleet Units Sold": {
  name: "NV F&I > RETAIL & NV FLEET > Fleet Units Sold",
  department: "NV F&I",
  category: "NV RETAIL  &  NV FLEET",
  label: "NV Fleet Units Sold",
  tableNumber: 40,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > In Dealer Units | New Fleet | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > RETAIL & NV FLEET > Total Units Sold": {
  name: "NV F&I > RETAIL & NV FLEET > Total Units Sold",
  department: "NV F&I",
  category: "NV RETAIL  &  NV FLEET",
  label: "NV Total Units Sold",
  tableNumber: 40,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > In Dealer Units | New | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > RETAIL & NV FLEET > Total Units Target ": {
  name: "NV F&I > RETAIL & NV FLEET > Total Units Target ",
  department: "NV F&I",
  category: "NV RETAIL  &  NV FLEET",
  label: "NV Total Units Target ",
  tableNumber: 40,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Market Share > Sales Target > New Retail Units | New | All Brand | All Model | | SUM",
  canAverage: true
},
"NV F&I > RETAIL & NV FLEET > Retail Net Sales / Unit (excl. Acc)": {
  name: "NV F&I > RETAIL & NV FLEET > Retail Net Sales / Unit (excl. Acc)",
  department: "NV F&I",
  category: "NV RETAIL  &  NV FLEET",
  label: "NV Retail Net Sales / Unit (excl. Acc)",
  tableNumber: 40,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales (excl Acc) pu | New Retail | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAIL & NV FLEET > Retail Give Away / Unit": {
  name: "NV F&I > RETAIL & NV FLEET > Retail Give Away / Unit",
  department: "NV F&I",
  category: "NV RETAIL  &  NV FLEET",
  label: "NV Retail Give Away / Unit",
  tableNumber: 40,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > COS > Free Accessories pu | New Retail | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAIL & NV FLEET > Retail Front End Gross / Unit": {
  name: "NV F&I > RETAIL & NV FLEET > Retail Front End Gross / Unit",
  department: "NV F&I",
  category: "NV RETAIL  &  NV FLEET",
  label: "NV Retail Front End Gross / Unit",
  tableNumber: 40,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Front End Gross (excl Acc) pu | New Retail | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAIL & NV FLEET > Retail Direct Incentives / Unit": {
  name: "NV F&I > RETAIL & NV FLEET > Retail Direct Incentives / Unit",
  department: "NV F&I",
  category: "NV RETAIL  &  NV FLEET",
  label: "NV Retail Direct Incentives / Unit",
  tableNumber: 40,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > 1st > Direct Incentives pu | New Retail | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAIL & NV FLEET > Retail First Gross / Unit": {
  name: "NV F&I > RETAIL & NV FLEET > Retail First Gross / Unit",
  department: "NV F&I",
  category: "NV RETAIL  &  NV FLEET",
  label: "NV Retail First Gross / Unit",
  tableNumber: 40,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > 1st Gross Profit (excl Acc) pu | New Retail | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAIL & NV FLEET > Retail Sales Effort % Gross Sales": {
  name: "NV F&I > RETAIL & NV FLEET > Retail Sales Effort % Gross Sales",
  department: "NV F&I",
  category: "NV RETAIL  &  NV FLEET",
  label: "NV Retail Sales Effort % Gross Sales",
  tableNumber: 40,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Sales Effort % | New Retail | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAIL & NV FLEET > Retail FEG % Gross Sales": {
  name: "NV F&I > RETAIL & NV FLEET > Retail FEG % Gross Sales",
  department: "NV F&I",
  category: "NV RETAIL  &  NV FLEET",
  label: "NV Retail FEG % Gross Sales",
  tableNumber: 40,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Front End Gross % of Gross Sales | New Retail | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAIL & NV FLEET > Retail First Gross % Net Sales": {
  name: "NV F&I > RETAIL & NV FLEET > Retail First Gross % Net Sales",
  department: "NV F&I",
  category: "NV RETAIL  &  NV FLEET",
  label: "NV Retail First Gross % Net Sales",
  tableNumber: 40,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > 1st Gross Profit (excl Acc) % of Net Sales | New Retail | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAIL & NV FLEET > Fleet Net Sales / Unit (excl. Acc)": {
  name: "NV F&I > RETAIL & NV FLEET > Fleet Net Sales / Unit (excl. Acc)",
  department: "NV F&I",
  category: "NV RETAIL  &  NV FLEET",
  label: "NV Fleet Net Sales / Unit (excl. Acc)",
  tableNumber: 40,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales (excl Acc) pu | New Fleet | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAIL & NV FLEET > Fleet Give Away / Unit": {
  name: "NV F&I > RETAIL & NV FLEET > Fleet Give Away / Unit",
  department: "NV F&I",
  category: "NV RETAIL  &  NV FLEET",
  label: "NV Fleet Give Away / Unit",
  tableNumber: 40,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > COS > Free Accessories pu | New Fleet | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAIL & NV FLEET > Fleet Front End Gross / Unit": {
  name: "NV F&I > RETAIL & NV FLEET > Fleet Front End Gross / Unit",
  department: "NV F&I",
  category: "NV RETAIL  &  NV FLEET",
  label: "NV Fleet Front End Gross / Unit",
  tableNumber: 40,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Front End Gross (excl Acc) pu | New Fleet | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAIL & NV FLEET > Fleet Direct Incentives / Unit": {
  name: "NV F&I > RETAIL & NV FLEET > Fleet Direct Incentives / Unit",
  department: "NV F&I",
  category: "NV RETAIL  &  NV FLEET",
  label: "NV Fleet Direct Incentives / Unit",
  tableNumber: 40,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > 1st > Direct Incentives pu | New Fleet | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAIL & NV FLEET > Fleet First Gross / Unit": {
  name: "NV F&I > RETAIL & NV FLEET > Fleet First Gross / Unit",
  department: "NV F&I",
  category: "NV RETAIL  &  NV FLEET",
  label: "NV Fleet First Gross / Unit",
  tableNumber: 40,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > 1st Gross Profit (excl Acc) pu | New Fleet | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAIL & NV FLEET > Fleet Sales Effort % Gross Sales": {
  name: "NV F&I > RETAIL & NV FLEET > Fleet Sales Effort % Gross Sales",
  department: "NV F&I",
  category: "NV RETAIL  &  NV FLEET",
  label: "NV Fleet Sales Effort % Gross Sales",
  tableNumber: 40,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Sales Effort % | New Fleet | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAIL & NV FLEET > Fleet FEG % Gross Sales": {
  name: "NV F&I > RETAIL & NV FLEET > Fleet FEG % Gross Sales",
  department: "NV F&I",
  category: "NV RETAIL  &  NV FLEET",
  label: "NV Fleet FEG % Gross Sales",
  tableNumber: 40,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Front End Gross % of Gross Sales | New Fleet | All Brand | All Model | | SUM",
  canAverage: false
},
"NV F&I > RETAIL & NV FLEET > Fleet First Gross % Net Sales": {
  name: "NV F&I > RETAIL & NV FLEET > Fleet First Gross % Net Sales",
  department: "NV F&I",
  category: "NV RETAIL  &  NV FLEET",
  label: "NV Fleet First Gross % Net Sales",
  tableNumber: 40,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > 1st Gross Profit (excl Acc) % of Net Sales | New Fleet | All Brand | All Model | | SUM",
  canAverage: false
},
};

export const NewVehicleOEMSupportsAnalysis: { [index: string]: CustomDashboardType } = {
  "NV F&I > OEM Supports Analysis > NV Support from OEM": {
  name: "NV F&I > OEM Supports Analysis > NV Support from OEM",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV Support from OEM",
  measure: "G > OEM Incentives & Expenses Subsidy | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: true
},
"NV F&I > OEM Supports Analysis > NV OEM Incentives": {
  name: "NV F&I > OEM Supports Analysis > NV OEM Incentives",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV OEM Incentives",
  measure: "G > OEM Incentives | New | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: true
},
"NV F&I > OEM Supports Analysis > NV OEM Direct Incentives": {
  name: "NV F&I > OEM Supports Analysis > NV OEM Direct Incentives",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV OEM Direct Incentives",
  measure: "G > 1st > Direct Incentives | New | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: true
},
"NV F&I > OEM Supports Analysis > NV OEM Indirect Incentives": {
  name: "NV F&I > OEM Supports Analysis > NV OEM Indirect Incentives",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV OEM Indirect Incentives",
  measure: "OI > Incentives | New | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: true
},
"NV F&I > OEM Supports Analysis > NV OEM Expenses Subsidy": {
  name: "NV F&I > OEM Supports Analysis > NV OEM Expenses Subsidy",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV OEM Expenses Subsidy",
  measure: "TE > OEM Expenses Subsidy | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: true
},
"NV F&I > OEM Supports Analysis > NV OEM Direct Advertising Subsidy": {
  name: "NV F&I > OEM Supports Analysis > NV OEM Direct Advertising Subsidy",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV OEM Direct Advertising Subsidy",
  measure: "VE > Advertising Subsidy | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: true
},
"NV F&I > OEM Supports Analysis > NV OEM Indirect Advertising Subsidy": {
  name: "NV F&I > OEM Supports Analysis > NV OEM Indirect Advertising Subsidy",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV OEM Indirect Advertising Subsidy",
  measure: "SVE > Indirect Advertising Subsidy | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: true
},
"NV F&I > OEM Supports Analysis > NV OEM Floor Plan Interest Subsidy": {
  name: "NV F&I > OEM Supports Analysis > NV OEM Floor Plan Interest Subsidy",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV OEM Floor Plan Interest Subsidy",
  measure: "I > Floorplan Interest Subsidy | New | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: true
},
"NV F&I > OEM Supports Analysis > NV OEM Incentives % TGP": {
  name: "NV F&I > OEM Supports Analysis > NV OEM Incentives % TGP",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV OEM Incentives % TGP",
  measure: "G > OEM Incentives % TGP NewF&IAftercare | NewF&IAftercare | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: false
},
"NV F&I > OEM Supports Analysis > NV OEM Incentives % Sales": {
  name: "NV F&I > OEM Supports Analysis > NV OEM Incentives % Sales",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV OEM Incentives % Sales",
  measure: "G > OEM Incentives % Net Sales | NewF&IAftercare | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: false
},
"NV F&I > OEM Supports Analysis > NV OEM Expenses Subsidy % Sales": {
  name: "NV F&I > OEM Supports Analysis > NV OEM Expenses Subsidy % Sales",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV OEM Expenses Subsidy % Sales",
  measure: "TE > OEM Expenses Subsidy % Net Sales | NewF&IAftercare | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: false
},
"NV F&I > OEM Supports Analysis > NV OEM All Supports % Sales": {
  name: "NV F&I > OEM Supports Analysis > NV OEM All Supports % Sales",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV OEM All Supports % Sales",
  measure: "G > OEM Incentives & Expenses Subsidy % Net Sales | NewF&IAftercare | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: false
},
"NV F&I > OEM Supports Analysis > NV MIX": {
  name: "NV F&I > OEM Supports Analysis > NV MIX",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV MIX",
  measure: "G > New Gross Profit % of Sales (incl F&I & Aftercare) | NewF&IAftercare | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: false
},
"NV F&I > OEM Supports Analysis > NV MIX (excl Incentive)": {
  name: "NV F&I > OEM Supports Analysis > NV MIX (excl Incentive)",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV MIX (excl Incentive)",
  measure: "G > New Gross Profit exc OEM Incentives % of Sales (incl F&I & Aftercare) | NewF&IAftercare | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: false
},
"NV F&I > OEM Supports Analysis > NV RETAINED": {
  name: "NV F&I > OEM Supports Analysis > NV RETAINED",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV RETAINED",
  measure: "PBT > PBT % of TGP: RETAINED | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: false
},
"NV F&I > OEM Supports Analysis > NV RETAINED (excl All Supports from OEM)": {
  name: "NV F&I > OEM Supports Analysis > NV RETAINED (excl All Supports from OEM)",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV RETAINED (excl All Supports from OEM)",
  measure: "PBT > PBT % of TGP: RETAINED (excl OEM Incentives & Expenses Subsidy) | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: false
},
"NV F&I > OEM Supports Analysis > NV RETAINED (excl Incentives)": {
  name: "NV F&I > OEM Supports Analysis > NV RETAINED (excl Incentives)",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV RETAINED (excl Incentives)",
  measure: "PBT > PBT % of TGP: RETAINED (excl Incentive) | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: false
},
"NV F&I > OEM Supports Analysis > NV RETAINED (excl OEM Expenses Subsidy)": {
  name: "NV F&I > OEM Supports Analysis > NV RETAINED (excl OEM Expenses Subsidy)",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV RETAINED (excl OEM Expenses Subsidy)",
  measure: "PBT > PBT % of TGP: RETAINED (excl OEM Expenses Subsidy) | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: false
},
"NV F&I > OEM Supports Analysis > NV Expenses % TGP": {
  name: "NV F&I > OEM Supports Analysis > NV Expenses % TGP",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV Expenses % TGP",
  measure: "TE > Total Expenses % of TGP | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: false
},
"NV F&I > OEM Supports Analysis > NV Expenses % TGP (excl All Supports from OEM)": {
  name: "NV F&I > OEM Supports Analysis > NV Expenses % TGP (excl All Supports from OEM)",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV Expenses % TGP (excl All Supports from OEM)",
  measure: "TE > Total Expenses % of TGP (excl OEM Incentives & Expenses Subsidy) | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: false
},
"NV F&I > OEM Supports Analysis > NV Expenses % TGP (excl Incentives)": {
  name: "NV F&I > OEM Supports Analysis > NV Expenses % TGP (excl Incentives)",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV Expenses % TGP (excl Incentives)",
  measure: "TE > Total Expenses % of TGP (excl Incentive) | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: false
},
"NV F&I > OEM Supports Analysis > NV Expenses % TGP (excl OEM Expenses Subsidy)": {
  name: "NV F&I > OEM Supports Analysis > NV Expenses % TGP (excl OEM Expenses Subsidy)",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV Expenses % TGP (excl OEM Expenses Subsidy)",
  measure: "TE > Total Expenses % of TGP (excl OEM Expenses Subsidy) | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: false
},
"NV F&I > OEM Supports Analysis > NV ROS": {
  name: "NV F&I > OEM Supports Analysis > NV ROS",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV ROS",
  measure: "PBT > PBT % of Sales | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: false
},
"NV F&I > OEM Supports Analysis > NV ROS (excl All Supports from OEM)": {
  name: "NV F&I > OEM Supports Analysis > NV ROS (excl All Supports from OEM)",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV ROS (excl All Supports from OEM)",
  measure: "PBT > PBT (excl OEM Incentives & Expenses Subsidy) % of Sales | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: false
},
"NV F&I > OEM Supports Analysis > NV ROS (excl Incentives)": {
  name: "NV F&I > OEM Supports Analysis > NV ROS (excl Incentives)",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV ROS (excl Incentives)",
  measure: "PBT > PBT (excl OEM Incentive) % of Sales | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: false
},
"NV F&I > OEM Supports Analysis > NV ROS (excl OEM Expenses Subsidy)": {
  name: "NV F&I > OEM Supports Analysis > NV ROS (excl OEM Expenses Subsidy)",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV ROS (excl OEM Expenses Subsidy)",
  measure: "PBT > PBT (excl OEM Expenses Subsidy) % of Sales | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: false
},
"NV F&I > OEM Supports Analysis > NV Sales": {
  name: "NV F&I > OEM Supports Analysis > NV Sales",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV Sales",
  measure: "TNS > New Net Sales New Aftercare New F&I | New | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: true
},
"NV F&I > OEM Supports Analysis > NV GP": {
  name: "NV F&I > OEM Supports Analysis > NV GP",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV GP",
  measure: "G > New Gross Profit (incl F&I & Aftercare) | New | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: true
},
"NV F&I > OEM Supports Analysis > NV GP (excl Incentives)": {
  name: "NV F&I > OEM Supports Analysis > NV GP (excl Incentives)",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV GP (excl Incentives)",
  measure: "G > New Gross Profit exc OEM incentive (incl F&I & Aftercare) | New | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: true
},
"NV F&I > OEM Supports Analysis > NV Expenses": {
  name: "NV F&I > OEM Supports Analysis > NV Expenses",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV Expenses",
  measure: "TE > Total Expenses | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: true
},
"NV F&I > OEM Supports Analysis > NV Expenses (excl OEM Expenses Subsidy)": {
  name: "NV F&I > OEM Supports Analysis > NV Expenses (excl OEM Expenses Subsidy)",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV Expenses (excl OEM Expenses Subsidy)",
  measure: "TE > Total Expenses (excl Expenses Subsidy) | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: true
},
"NV F&I > OEM Supports Analysis > NV PBT": {
  name: "NV F&I > OEM Supports Analysis > NV PBT",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV PBT",
  measure: "PBT > PBT (Profit Before Tax) | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: true
},
"NV F&I > OEM Supports Analysis > NV PBT (excl All Supports from OEM)": {
  name: "NV F&I > OEM Supports Analysis > NV PBT (excl All Supports from OEM)",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV PBT (excl All Supports from OEM)",
  measure: "PBT > PBT (excl OEM Incentives & Expenses Subsidy) | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: true
},
"NV F&I > OEM Supports Analysis > NV PBT (excl Incentives)": {
  name: "NV F&I > OEM Supports Analysis > NV PBT (excl Incentives)",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV PBT (excl Incentives)",
  measure: "PBT > PBT (excl OEM Incentive) | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: true
},
"NV F&I > OEM Supports Analysis > NV PBT (excl OEM Expenses Subsidy)": {
  name: "NV F&I > OEM Supports Analysis > NV PBT (excl OEM Expenses Subsidy)",
  department: "NV F&I",
  category: "OEM Supports Analysis",
  label: "NV PBT (excl OEM Expenses Subsidy)",
  measure: "PBT > PBT (excl OEM Expenses Subsidy) | NewAftercareF&ISplit | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 527,
  canAverage: true
},
};

export const CustomDashboardModelTemplate = (model: string, tableId: number) => {
  return {
    name: `NV F&I > Car Line Analysis > Retail 1st GP % > ${model}`,
    department: "NV F&I",
    category: "CAR LINE ANALYSIS",
    label: `Retail 1st GP % - ${model}`,
    measure: `G > 1st Gross Profit (excl Acc) % of Net Sales | New Retail | All Brand | ${model} | | SUM`,
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: tableId,
    model: model,
  };
};

export const CustomDashboardRetailUnitsTemplate = (model: string, tableId: number) => {
  return {
    name: `NV F&I > Car Line Analysis > Retail Units Sold > ${model}`,
    department: "NV F&I",
    category: "CAR LINE ANALYSIS",
    label: `Retail Units Sold - ${model}`,
    measure: `STAT > In Dealer Units | New Retail | All Brand | ${model} | | SUM`,
    getMeasure: (label, measures) => getMeasure(label, measures),
    tableNumber: tableId,
    model: model,
  };
};
