import { request } from "./base";

export type DBMeasure = {
  title: string;
  dashboardNotes: string;
  description: string;
};

export const measures = (): Promise<DBMeasure[]> =>
  request("/measures")
    .then(res => res.json())
    .then(({ data }) => data);
