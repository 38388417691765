import React, { createContext, useState, useReducer, useMemo } from "react";
import { Loading } from "./base";
import { Forecast, forecast as getForecasts } from "../lib/api/forecast";

const defaultForecast = [];
const defaultSelectedForecast = { value: "", label: "All Forecast" }

export type ForecastContextState = {
  forecasts: Forecast[];
  loading: Loading;
  selectedForecast: { value: string; label: string };
  hydrate();
  getForecasts(): Forecast[];
  setSelectedForecast: (value: { value: string; label: string }) => void;
};

export const ForecastContext = createContext<ForecastContextState>({
  forecasts: defaultForecast,
  loading: { loading: false, loaded: false, error: null },
  selectedForecast: defaultSelectedForecast,
  hydrate: () => {},
  getForecasts: () => [],
  setSelectedForecast: () => {},
});

export const ForecastProvider = ({ children }) => {
  const [forecasts, setForecast] = useState<Forecast[]>(defaultForecast);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });
  const [selectedForecast, setSelectedForecast] = useState<{ value: string; label: string }>(defaultSelectedForecast)
  const hydrate = async () => {
    try {
      setLoading({
        loading: true,
        loaded: false,
        error: null,
      });
      const forecasts = await getForecasts();
      setForecast(forecasts);
      setLoading({
        loading: false,
        loaded: true,
        error: null,
      });
    } catch (e) {
      setLoading({
        loading: false,
        loaded: false,
        error: e,
      });
    }
  };
  const value = useMemo(
    () => ({
      forecasts,
      loading,
      selectedForecast,
      hydrate,
      getForecasts() {
        if (loading.loaded && !loading.loading) {
          return forecasts;
        }
        if (!loading.loading && !loading.error) {
          hydrate();
        }
        return [];
      },
      setForecast,
      setSelectedForecast,
    }),
    [forecasts, selectedForecast, setSelectedForecast, loading],
  );
  return <ForecastContext.Provider value={value}>{children}</ForecastContext.Provider>;
};
