import React, { createContext, useCallback, useContext, useMemo, useState } from "react";

import format from "date-fns/format";

import { Dealer } from "../../../lib/api/charts";
import { postCubeReload } from "../../../lib/api/dealers";
import { Dealers } from "../SubmissionReloadContext";
import { UserContext } from "../../../contexts/UserContext";

type CubeReloadContextType = {
  selectedDealers: Dealer[] & Dealers[];
  setSelectedDealers(dealers: Dealer[]): void;
  handleCubeReload(startMonth: string, endMonth: string): Promise<void>;
  canReloadData: boolean;
};

export const CubeReloadContext = createContext<CubeReloadContextType>({
  selectedDealers: [],
  setSelectedDealers: () => {},
  handleCubeReload: () => Promise.resolve(),
  canReloadData: false,
});

export const CubeReloadProvider = ({ children }) => {
  const { user } = useContext(UserContext);

  const [selectedDealers, setSelectedDealers] = useState([]);

  const handleCubeReload = useCallback(
    async (startMonth, endMonth) =>
      await postCubeReload({
        dealerCodes: selectedDealers.map(dealer => dealer.code),
        startMonth: format(new Date(startMonth), "yyyyMM"),
        endMonth: format(new Date(endMonth), "yyyyMM"),
      }),
    [postCubeReload, selectedDealers],
  );

  const canReloadData = useMemo((): boolean => {
    return user.reloadAccess;
  }, [user]);

  const value = useMemo(
    () => ({
      selectedDealers,
      setSelectedDealers,
      handleCubeReload,
      canReloadData,
    }),
    [selectedDealers],
  );

  return <CubeReloadContext.Provider value={value}>{children}</CubeReloadContext.Provider>;
};
