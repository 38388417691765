import { CustomDashboardType, getMeasure } from "../CustomDashReports";

export const FI: { [index: string]: CustomDashboardType } = {
"F&I > SALES > Finance Contract": {
  name: "F&I > SALES > Finance Contract",
  department: "F&I",
  category: "SALES",
  label: "NV Finance Contract",
  tableNumber: 55,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > F&I Contracts 1st yr New Retail - Financed | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > SALES > Finance Penetration": {
  name: "F&I > SALES > Finance Penetration",
  department: "F&I",
  category: "SALES",
  label: "NV Finance Penetration",
  tableNumber: 55,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > F&I Contracts 1st Yr New Retail pu - Financed | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > SALES > Insurance Contract": {
  name: "F&I > SALES > Insurance Contract",
  department: "F&I",
  category: "SALES",
  label: "NV Insurance Contract",
  tableNumber: 55,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > F&I Contracts 1st yr New Retail - Insurance | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > SALES > Insurance Penetration": {
  name: "F&I > SALES > Insurance Penetration",
  department: "F&I",
  category: "SALES",
  label: "NV Insurance Penetration",
  tableNumber: 55,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > F&I Contracts 1st Yr New Retail pu - Insurance | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > SALES > UV Finance Contract": {
  name: "F&I > SALES > UV Finance Contract",
  department: "F&I",
  category: "SALES",
  label: "UV Finance Contract",
  tableNumber: 55,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > F&I Contracts 1st yr Used - Financed | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > SALES > UV Finance Penetration": {
  name: "F&I > SALES > UV Finance Penetration",
  department: "F&I",
  category: "SALES",
  label: "UV Finance Penetration",
  tableNumber: 55,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > F&I Contracts 1st yr Used pu - Financed | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > SALES > UV Insurance Contract": {
  name: "F&I > SALES > UV Insurance Contract",
  department: "F&I",
  category: "SALES",
  label: "UV Insurance Contract",
  tableNumber: 55,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > F&I Contracts 1st yr Used - Insurance | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > SALES > UV Insurance Penetration": {
  name: "F&I > SALES > UV Insurance Penetration",
  department: "F&I",
  category: "SALES",
  label: "UV Insurance Penetration",
  tableNumber: 55,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > F&I Contracts 1st yr Used pu - Insurance | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > SALES > F&I Total Sales": {
  name: "F&I > SALES > F&I Total Sales",
  department: "F&I",
  category: "SALES",
  label: "F&I Total Sales",
  tableNumber: 55,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > SALES > Finance Sales": {
  name: "F&I > SALES > Finance Sales",
  department: "F&I",
  category: "SALES",
  label: "F&I Finance Sales",
  tableNumber: 55,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > F&I Total Finance Income | All Department | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > SALES > Insurance Sales": {
  name: "F&I > SALES > Insurance Sales",
  department: "F&I",
  category: "SALES",
  label: "F&I Insurance Sales",
  tableNumber: 55,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > F&I Total Insurance Income | All Department | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > SALES > Other Sales": {
  name: "F&I > SALES > Other Sales",
  department: "F&I",
  category: "SALES",
  label: "F&I Other Sales",
  tableNumber: 55,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > F&I Total Other Sales | All Department | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > SALES > UV F&I Sales": {
  name: "F&I > SALES > UV F&I Sales",
  department: "F&I",
  category: "SALES",
  label: "UV F&I Sales",
  tableNumber: 55,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > F&I Net Sales Used | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > SALES > NV F&I Sales": {
  name: "F&I > SALES > NV F&I Sales",
  department: "F&I",
  category: "SALES",
  label: "NV F&I Sales",
  tableNumber: 55,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > F&I Net Sales New | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > MIX > F&I MIX": {
  name: "F&I > MIX > F&I MIX",
  department: "F&I",
  category: "GROSS PROFIT",
  label: "F&I MIX",
  tableNumber: 56,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit % of Sales (MIX) | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > MIX > F&I Total Sales": {
  name: "F&I > MIX > F&I Total Sales",
  department: "F&I",
  category: "GROSS PROFIT",
  label: "F&I Total Sales",
  tableNumber: 55,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > MIX > F&I Total GP": {
  name: "F&I > MIX > F&I Total GP",
  department: "F&I",
  category: "GROSS PROFIT",
  label: "F&I Total GP",
  tableNumber: 56,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > MIX > NV F&I GP / NV Unit": {
  name: "F&I > MIX > NV F&I GP / NV Unit",
  department: "F&I",
  category: "GROSS PROFIT",
  label: "NV F&I GP / NV Unit",
  tableNumber: 56,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > F&I Gross New PNU | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > MIX > Finance GP / NV Retail Unit": {
  name: "F&I > MIX > Finance GP / NV Retail Unit",
  department: "F&I",
  category: "GROSS PROFIT",
  label: "Finance GP / NV Retail Unit",
  tableNumber: 56,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > F&I New Retail Financed Gross 1st yr PNRU | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > MIX > Insurance GP / NV Retail Unit": {
  name: "F&I > MIX > Insurance GP / NV Retail Unit",
  department: "F&I",
  category: "GROSS PROFIT",
  label: "Insurance GP / NV Retail Unit",
  tableNumber: 56,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > F&I New Retail Insurance Gross 1st yr PNRU | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > MIX > F&I UV GP / Unit": {
  name: "F&I > MIX > F&I UV GP / Unit",
  department: "F&I",
  category: "GROSS PROFIT",
  label: "F&I UV GP / Unit",
  tableNumber: 56,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > F&I Gross Used PUU | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > MIX > Finance GP / Unit": {
  name: "F&I > MIX > Finance GP / Unit",
  department: "F&I",
  category: "GROSS PROFIT",
  label: "Finance GP / Unit",
  tableNumber: 56,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > F&I Used Financed Gross 1st yr PUU | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > MIX > Insurance GP / Unit": {
  name: "F&I > MIX > Insurance GP / Unit",
  department: "F&I",
  category: "GROSS PROFIT",
  label: "Insurance GP / Unit",
  tableNumber: 56,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > F&I Used Insurance Gross 1st yr PUU | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > RETAINED > F&I RETAINED": {
  name: "F&I > RETAINED > F&I RETAINED",
  department: "F&I",
  category: "EXPENSES",
  label: "F&I RETAINED",
  tableNumber: 57,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT % of TGP: RETAINED |  F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > RETAINED > F&I Total GP": {
  name: "F&I > RETAINED > F&I Total GP",
  department: "F&I",
  category: "EXPENSES",
  label: "F&I Total GP",
  tableNumber: 56,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > RETAINED > F&I Total Expenses": {
  name: "F&I > RETAINED > F&I Total Expenses",
  department: "F&I",
  category: "EXPENSES",
  label: "F&I Total Expenses",
  tableNumber: 57,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > RETAINED > F&I Total PBT": {
  name: "F&I > RETAINED > F&I Total PBT",
  department: "F&I",
  category: "EXPENSES",
  label: "F&I Total PBT",
  tableNumber: 52,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT (Profit Before Tax) | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > RETAINED > Total Expenses % GP": {
  name: "F&I > RETAINED > Total Expenses % GP",
  department: "F&I",
  category: "EXPENSES",
  label: "F&I Total Expenses % GP",
  tableNumber: 57,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses % of TGP | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > RETAINED > Variable Expenses  % GP": {
  name: "F&I > RETAINED > Variable Expenses  % GP",
  department: "F&I",
  category: "EXPENSES",
  label: "F&I Variable Expenses  % GP",
  tableNumber: 58,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses % of TGP | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > RETAINED > Personnel Expenses % GP": {
  name: "F&I > RETAINED > Personnel Expenses % GP",
  department: "F&I",
  category: "EXPENSES",
  label: "F&I Personnel Expenses % GP",
  tableNumber: 59,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PE > Personnel Expenses % of TGP | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > RETAINED > Other Semi Variable Expenses % GP": {
  name: "F&I > RETAINED > Other Semi Variable Expenses % GP",
  department: "F&I",
  category: "EXPENSES",
  label: "F&I Other Semi Variable Expenses % GP",
  tableNumber: 60,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "SVE > Other Semi Variable Expenses % of TGP | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > RETAINED > Fixed Expenses % GP": {
  name: "F&I > RETAINED > Fixed Expenses % GP",
  department: "F&I",
  category: "EXPENSES",
  label: "F&I Fixed Expenses % GP",
  tableNumber: 61,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "FE > Fixed Expenses % of TGP | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > RETAINED > Interest Expenses% GP": {
  name: "F&I > RETAINED > Interest Expenses% GP",
  department: "F&I",
  category: "EXPENSES",
  label: "F&I Interest Expenses% GP",
  tableNumber: 62,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Interest % of TGP | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > RETAINED > Allocation of Admin Expenses % GP": {
  name: "F&I > RETAINED > Allocation of Admin Expenses % GP",
  department: "F&I",
  category: "EXPENSES",
  label: "F&I Allocation of Admin Expenses % GP",
  tableNumber: 57,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Adm > Admin Exp. Allocation % of TGP | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > RETAINED > Variable Expenses% Total Expenses": {
  name: "F&I > RETAINED > Variable Expenses% Total Expenses",
  department: "F&I",
  category: "EXPENSES",
  label: "F&I Variable Expenses% Total Expenses",
  tableNumber: 58,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses % of Total Expenses | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > RETAINED > Personnel Expenses% Total Expenses": {
  name: "F&I > RETAINED > Personnel Expenses% Total Expenses",
  department: "F&I",
  category: "EXPENSES",
  label: "F&I Personnel Expenses% Total Expenses",
  tableNumber: 59,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PE > Personnel Expenses % of Total Expenses | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > RETAINED > Other Semi Var.Exp.% Total Expenses": {
  name: "F&I > RETAINED > Other Semi Var.Exp.% Total Expenses",
  department: "F&I",
  category: "EXPENSES",
  label: "F&I Other Semi Var.Exp.% Total Expenses",
  tableNumber: 60,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "SVE > Other Semi Variable Expenses % of Total Expenses | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > RETAINED > Fixed Expenses% Total Expenses": {
  name: "F&I > RETAINED > Fixed Expenses% Total Expenses",
  department: "F&I",
  category: "EXPENSES",
  label: "F&I Fixed Expenses% Total Expenses",
  tableNumber: 61,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "FE > Fixed Expenses % of Total Expenses | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > RETAINED > Interest Expenses% Total Expenses": {
  name: "F&I > RETAINED > Interest Expenses% Total Expenses",
  department: "F&I",
  category: "EXPENSES",
  label: "F&I Interest Expenses% Total Expenses",
  tableNumber: 62,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Interest % of Total Expenses | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > RETAINED > Allocation of Admin Expenses % Total Expenses": {
  name: "F&I > RETAINED > Allocation of Admin Expenses % Total Expenses",
  department: "F&I",
  category: "EXPENSES",
  label: "F&I Allocation of Admin Expenses % Total Expenses",
  tableNumber: 57,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Adm > Admin Exp. Allocation % of Total Expenses | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > RETAINED > F&I Cost Recovery": {
  name: "F&I > RETAINED > F&I Cost Recovery",
  department: "F&I",
  category: "EXPENSES",
  label: "F&I Cost Recovery",
  tableNumber: 173,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Cost Recovery | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > ACTIVITY > F&I ACTIVITY": {
  name: "F&I > ACTIVITY > F&I ACTIVITY",
  department: "F&I",
  category: "OPERATIONAL ASSETS",
  label: "F&I ACTIVITY",
  tableNumber: 63,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Asset Activity | F&I Dept | All Brand | All Model | | AVG",
  canAverage: false
},
"F&I > ACTIVITY > F&I Annualised Sales": {
  name: "F&I > ACTIVITY > F&I Annualised Sales",
  department: "F&I",
  category: "OPERATIONAL ASSETS",
  label: "F&I Annualised Sales",
  tableNumber: 55,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Projected Annualised Sales | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > ACTIVITY > F&I Average Operational Assets": {
  name: "F&I > ACTIVITY > F&I Average Operational Assets",
  department: "F&I",
  category: "OPERATIONAL ASSETS",
  label: "F&I Average Operational Assets",
  tableNumber: 63,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Average Operational Assets | F&I Dept | All Brand | All Model | | AVG",
  canAverage: true
},
"F&I > ROOA > ROOA": {
  name: "F&I > ROOA > ROOA",
  department: "F&I",
  category: "MRA MODEL",
  label: "F&I ROOA",
  tableNumber: 64,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Return on Operational Assets (ROOA) | F&I Dept | All Brand | All Model | | AVG",
  canAverage: false
},
"F&I > ROOA > MIX": {
  name: "F&I > ROOA > MIX",
  department: "F&I",
  category: "MRA MODEL",
  label: "F&I MIX",
  tableNumber: 56,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit % of Sales (MIX) | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > ROOA > RETAINED": {
  name: "F&I > ROOA > RETAINED",
  department: "F&I",
  category: "MRA MODEL",
  label: "F&I RETAINED",
  tableNumber: 57,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT % of TGP: RETAINED | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > ROOA > ACTIVITY": {
  name: "F&I > ROOA > ACTIVITY",
  department: "F&I",
  category: "MRA MODEL",
  label: "F&I ACTIVITY",
  tableNumber: 63,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Asset Activity | F&I Dept | All Brand | All Model | | AVG",
  canAverage: false
},
"F&I > ROOA > Gross profit": {
  name: "F&I > ROOA > Gross profit",
  department: "F&I",
  category: "MRA MODEL",
  label: "Gross profit",
  tableNumber: 56,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > ROOA > Revenue": {
  name: "F&I > ROOA > Revenue",
  department: "F&I",
  category: "MRA MODEL",
  label: "Revenue",
  tableNumber: 55,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > ROOA > Expenses": {
  name: "F&I > ROOA > Expenses",
  department: "F&I",
  category: "MRA MODEL",
  label: "Expenses",
  tableNumber: 57,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > ROOA > Annualised Sales": {
  name: "F&I > ROOA > Annualised Sales",
  department: "F&I",
  category: "MRA MODEL",
  label: "Annualised Sales",
  tableNumber: 63,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Projected Annualised Sales | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > ROOA > Average Operational Assets": {
  name: "F&I > ROOA > Average Operational Assets",
  department: "F&I",
  category: "MRA MODEL",
  label: "Average Operational Assets",
  tableNumber: 63,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Average Operational Assets | F&I Dept | All Brand | All Model | | AVG",
  canAverage: true
},
"F&I > ROOA > ROS": {
  name: "F&I > ROOA > ROS",
  department: "F&I",
  category: "MRA MODEL",
  label: "ROS",
  tableNumber: 65,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT % of Sales | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > ROS (Income Statement) > ROS": {
  name: "F&I > ROS (Income Statement) > ROS",
  department: "F&I",
  category: "ROS (Income Statement)",
  label: "F&I ROS",
  tableNumber: 65,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT % of Sales | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > ROS (Income Statement) > Sales": {
  name: "F&I > ROS (Income Statement) > Sales",
  department: "F&I",
  category: "ROS (Income Statement)",
  label: "F&I Sales",
  tableNumber: 55,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > ROS (Income Statement) > PBT": {
  name: "F&I > ROS (Income Statement) > PBT",
  department: "F&I",
  category: "ROS (Income Statement)",
  label: "F&I PBT",
  tableNumber: 65,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT (Profit Before Tax) | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > ROS (Income Statement) > Dept. Profit % Net Sales": {
  name: "F&I > ROS (Income Statement) > Dept. Profit % Net Sales",
  department: "F&I",
  category: "ROS (Income Statement)",
  label: "F&I Dept. Profit % Net Sales",
  tableNumber: 65,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "E > Earnings After Interest % of TNS | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > ROS (Income Statement) > EBIT% (PBIT%)": {
  name: "F&I > ROS (Income Statement) > EBIT% (PBIT%)",
  department: "F&I",
  category: "ROS (Income Statement)",
  label: "F&I EBIT% (PBIT%)",
  tableNumber: 65,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBIT % of Sales | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > ROS (Income Statement) > Variable Selling Gross % Gorss Profit": {
  name: "F&I > ROS (Income Statement) > Variable Selling Gross % Gorss Profit",
  department: "F&I",
  category: "ROS (Income Statement)",
  label: "F&I Variable Selling Gross % Gorss Profit",
  tableNumber: 58,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Variable Selling Gross % of TGP | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > ROS (Income Statement) > Selling Gross % Gross Profit": {
  name: "F&I > ROS (Income Statement) > Selling Gross % Gross Profit",
  department: "F&I",
  category: "ROS (Income Statement)",
  label: "F&I Selling Gross % Gross Profit",
  tableNumber: 65,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Selling Gross % of TGP | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > ROS (Income Statement) > Selling Gross % Net Sales": {
  name: "F&I > ROS (Income Statement) > Selling Gross % Net Sales",
  department: "F&I",
  category: "ROS (Income Statement)",
  label: "F&I Selling Gross % Net Sales",
  tableNumber: 65,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Selling Gross % of TNS | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"F&I > ROS (Income Statement) > Gross Profit": {
  name: "F&I > ROS (Income Statement) > Gross Profit",
  department: "F&I",
  category: "ROS (Income Statement)",
  label: "F&I Gross Profit",
  tableNumber: 56,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > ROS (Income Statement) > Expenses": {
  name: "F&I > ROS (Income Statement) > Expenses",
  department: "F&I",
  category: "ROS (Income Statement)",
  label: "F&I Expenses",
  tableNumber: 57,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > ROS (Income Statement) > Variable Expenses": {
  name: "F&I > ROS (Income Statement) > Variable Expenses",
  department: "F&I",
  category: "ROS (Income Statement)",
  label: "F&I Variable Expenses",
  tableNumber: 58,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > ROS (Income Statement) > Personnel Expenses": {
  name: "F&I > ROS (Income Statement) > Personnel Expenses",
  department: "F&I",
  category: "ROS (Income Statement)",
  label: "F&I Personnel Expenses",
  tableNumber: 59,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PE > Personnel Expenses | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > ROS (Income Statement) > Other Semi Variable Expenses": {
  name: "F&I > ROS (Income Statement) > Other Semi Variable Expenses",
  department: "F&I",
  category: "ROS (Income Statement)",
  label: "F&I Other Semi Variable Expenses",
  tableNumber: 60,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "SVE > Other Semi Variable Expenses | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > ROS (Income Statement) > Fixed Expenses": {
  name: "F&I > ROS (Income Statement) > Fixed Expenses",
  department: "F&I",
  category: "ROS (Income Statement)",
  label: "F&I Fixed Expenses",
  tableNumber: 61,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "FE > Fixed Expenses | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > ROS (Income Statement) > Interest Expenses": {
  name: "F&I > ROS (Income Statement) > Interest Expenses",
  department: "F&I",
  category: "ROS (Income Statement)",
  label: "F&I Interest Expenses",
  tableNumber: 62,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Interest | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > ROS (Income Statement) > Departmental Profit": {
  name: "F&I > ROS (Income Statement) > Departmental Profit",
  department: "F&I",
  category: "ROS (Income Statement)",
  label: "F&I Departmental Profit",
  tableNumber: 65,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "E > Earnings After Interest (EAIT) | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > ROS (Income Statement) > Allocation of Admin Expenses": {
  name: "F&I > ROS (Income Statement) > Allocation of Admin Expenses",
  department: "F&I",
  category: "ROS (Income Statement)",
  label: "F&I Allocation of Admin Expenses",
  tableNumber: 57,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Adm > Admin Exp. Allocation | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > ROS (Income Statement) > EBIT": {
  name: "F&I > ROS (Income Statement) > EBIT",
  department: "F&I",
  category: "ROS (Income Statement)",
  label: "F&I EBIT",
  tableNumber: 65,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBIT (Profit Before Interest & Tax) | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > PRODUCTIVITY > Total F&I GP / Productive / Month": {
  name: "F&I > PRODUCTIVITY > Total F&I GP / Productive / Month",
  department: "F&I",
  category: "PRODUCTIVITY",
  label: "F&I GP / Productive / Month",
  tableNumber: 66,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ave Monthly TGP per Productive | F&I Dept | All Brand | All Model | | AVG",
  canAverage: false
},
"F&I > PRODUCTIVITY > Total F&I GP": {
  name: "F&I > PRODUCTIVITY > Total F&I GP",
  department: "F&I",
  category: "PRODUCTIVITY",
  label: "F&I GP",
  tableNumber: 66,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"F&I > PRODUCTIVITY > Parts Productive Staff": {
  name: "F&I > PRODUCTIVITY > Parts Productive Staff",
  department: "F&I",
  category: "PRODUCTIVITY",
  label: "F&I Productive Staff",
  tableNumber: 66,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Productives | F&I Dept | All Brand | All Model | | AVG",
  canAverage: true
},
"F&I > PRODUCTIVITY > Total Parts GP / Employee / Month": {
  name: "F&I > PRODUCTIVITY > Total Parts GP / Employee / Month",
  department: "F&I",
  category: "PRODUCTIVITY",
  label: "F&I GP / Employee / Month",
  tableNumber: 66,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ave Monthly TGP per Employee | F&I Dept | All Brand | All Model | | AVG",
  canAverage: false
},
"F&I > PRODUCTIVITY > Total Staff": {
  name: "F&I > PRODUCTIVITY > Total Staff",
  department: "F&I",
  category: "PRODUCTIVITY",
  label: "Total Staff",
  tableNumber: 66,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Total Employees | F&I Dept | All Brand | All Model | | AVG",
  canAverage: true
},
"F&I > PRODUCTIVITY > Productive Staff": {
  name: "F&I > PRODUCTIVITY > Productive Staff",
  department: "F&I",
  category: "PRODUCTIVITY",
  label: "Productive Staff",
  tableNumber: 66,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Productives | F&I Dept | All Brand | All Model | | AVG",
  canAverage: true
},
"F&I > PRODUCTIVITY > Non-Productive Staff": {
  name: "F&I > PRODUCTIVITY > Non-Productive Staff",
  department: "F&I",
  category: "PRODUCTIVITY",
  label: "Non-Productive Staff",
  tableNumber: 66,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Non-Productives | F&I Dept | All Brand | All Model | | AVG",
  canAverage: true
},
"F&I > PRODUCTIVITY > Ratio Productive : Non Productive": {
  name: "F&I > PRODUCTIVITY > Ratio Productive : Non Productive",
  department: "F&I",
  category: "PRODUCTIVITY",
  label: "Ratio Productive : Non Productive",
  tableNumber: 66,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ratio Productive to Non-Productive staff | F&I Dept | All Brand | All Model | | AVG",
  canAverage: false
},
"F&I > PRODUCTIVITY > F&I Staff Allocation NV": {
  name: "F&I > PRODUCTIVITY > F&I Staff Allocation NV",
  department: "F&I",
  category: "PRODUCTIVITY",
  label: "F&I Staff Allocation NV",
  tableNumber: 66,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > F&I Dept Allocation New | F&I Dept | All Brand | All Model | | AVG",
  canAverage: false
},
"F&I > PRODUCTIVITY > F&I Staff Allocation UV": {
  name: "F&I > PRODUCTIVITY > F&I Staff Allocation UV",
  department: "F&I",
  category: "PRODUCTIVITY",
  label: "F&I Staff Allocation UV",
  tableNumber: 66,
  getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > F&I Dept Allocation Used | F&I Dept | All Brand | All Model | | AVG",
  canAverage: false
},
};

export const FI_OEMSupportsAnalysis: { [index: string]: CustomDashboardType } = {
"F&I > OEM Supports Analysis > F&I OEM Expenses Subsidy": {
  name: "F&I > OEM Supports Analysis > F&I OEM Expenses Subsidy",
  department: "F&I",
  category: "OEM Supports Analysis",
  label: "F&I OEM Expenses Subsidy",
  measure: "TE > OEM Expenses Subsidy | F&I Dept | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 529,
  canAverage: true
},
"F&I > OEM Supports Analysis > F&I OEM Direct Advertising Subsidy": {
  name: "F&I > OEM Supports Analysis > F&I OEM Direct Advertising Subsidy",
  department: "F&I",
  category: "OEM Supports Analysis",
  label: "F&I OEM Direct Advertising Subsidy",
  measure: "VE > Advertising Subsidy | F&I Dept | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 529,
  canAverage: true
},
"F&I > OEM Supports Analysis > F&I OEM Indirect Advertising Subsidy": {
  name: "F&I > OEM Supports Analysis > F&I OEM Indirect Advertising Subsidy",
  department: "F&I",
  category: "OEM Supports Analysis",
  label: "F&I OEM Indirect Advertising Subsidy",
  measure: "SVE > Indirect Advertising Subsidy | F&I Dept | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 529,
  canAverage: true
},
"F&I > OEM Supports Analysis > F&I OEM Expenses Subsidy % Sales": {
  name: "F&I > OEM Supports Analysis > F&I OEM Expenses Subsidy % Sales",
  department: "F&I",
  category: "OEM Supports Analysis",
  label: "F&I OEM Expenses Subsidy % Sales",
  measure: "TE > OEM Expenses Subsidy % Net Sales | F&I Dept | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 529,
  canAverage: false
},
"F&I > OEM Supports Analysis > F&I MIX": {
  name: "F&I > OEM Supports Analysis > F&I MIX",
  department: "F&I",
  category: "OEM Supports Analysis",
  label: "F&I MIX",
  measure: "G > Gross Profit % of Sales (MIX) | F&I Dept | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 529,
  canAverage: false
},
"F&I > OEM Supports Analysis > F&I RETAINED": {
  name: "F&I > OEM Supports Analysis > F&I RETAINED",
  department: "F&I",
  category: "OEM Supports Analysis",
  label: "F&I RETAINED",
  measure: "PBT > PBT % of TGP: RETAINED |  F&I Dept | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 529,
  canAverage: false
},
"F&I > OEM Supports Analysis > F&I RETAINED (excl OEM Expenses Subsidy)": {
  name: "F&I > OEM Supports Analysis > F&I RETAINED (excl OEM Expenses Subsidy)",
  department: "F&I",
  category: "OEM Supports Analysis",
  label: "F&I RETAINED (excl OEM Expenses Subsidy)",
  measure: "PBT > PBT % of TGP: RETAINED (excl OEM Expenses Subsidy) | F&I Dept | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 529,
  canAverage: false
},
"F&I > OEM Supports Analysis > F&I Expenses % TGP": {
  name: "F&I > OEM Supports Analysis > F&I Expenses % TGP",
  department: "F&I",
  category: "OEM Supports Analysis",
  label: "F&I Expenses % TGP",
  measure: "TE > Total Expenses % of TGP | F&I Dept | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 529,
  canAverage: false
},
"F&I > OEM Supports Analysis > F&I Expenses % TGP (excl OEM Expenses Subsidy)": {
  name: "F&I > OEM Supports Analysis > F&I Expenses % TGP (excl OEM Expenses Subsidy)",
  department: "F&I",
  category: "OEM Supports Analysis",
  label: "F&I Expenses % TGP (excl OEM Expenses Subsidy)",
  measure: "TE > Total Expenses % of TGP (excl OEM Expenses Subsidy) | F&I Dept | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 529,
  canAverage: false
},
"F&I > OEM Supports Analysis > F&I ROS": {
  name: "F&I > OEM Supports Analysis > F&I ROS",
  department: "F&I",
  category: "OEM Supports Analysis",
  label: "F&I ROS",
  measure: "PBT > PBT % of Sales | F&I Dept | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 529,
  canAverage: false
},
"F&I > OEM Supports Analysis > F&I ROS (excl OEM Expenses Subsidy)": {
  name: "F&I > OEM Supports Analysis > F&I ROS (excl OEM Expenses Subsidy)",
  department: "F&I",
  category: "OEM Supports Analysis",
  label: "F&I ROS (excl OEM Expenses Subsidy)",
  measure: "PBT > PBT (excl OEM Expenses Subsidy) % of Sales | F&I Dept | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 529,
  canAverage: false
},
"F&I > OEM Supports Analysis > F&I Sales": {
  name: "F&I > OEM Supports Analysis > F&I Sales",
  department: "F&I",
  category: "OEM Supports Analysis",
  label: "F&I Sales",
  measure: "TNS > Net Sales | F&I Dept | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 529,
  canAverage: true
},
"F&I > OEM Supports Analysis > F&I GP": {
  name: "F&I > OEM Supports Analysis > F&I GP",
  department: "F&I",
  category: "OEM Supports Analysis",
  label: "F&I GP",
  measure: "G > Gross Profit | F&I Dept | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 529,
  canAverage: true
},
"F&I > OEM Supports Analysis > F&I Expenses": {
  name: "F&I > OEM Supports Analysis > F&I Expenses",
  department: "F&I",
  category: "OEM Supports Analysis",
  label: "F&I Expenses",
  measure: "TE > Total Expenses | F&I Dept | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 529,
  canAverage: true
},
"F&I > OEM Supports Analysis > F&I Expenses (excl OEM Expenses Subsidy)": {
  name: "F&I > OEM Supports Analysis > F&I Expenses (excl OEM Expenses Subsidy)",
  department: "F&I",
  category: "OEM Supports Analysis",
  label: "F&I Expenses (excl OEM Expenses Subsidy)",
  measure: "TE > Total Expenses (excl Expenses Subsidy) | F&I Dept | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 529,
  canAverage: true
},
"F&I > OEM Supports Analysis > F&I PBT": {
  name: "F&I > OEM Supports Analysis > F&I PBT",
  department: "F&I",
  category: "OEM Supports Analysis",
  label: "F&I PBT",
  measure: "PBT > PBT (Profit Before Tax) | F&I Dept | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 529,
  canAverage: true
},
"F&I > OEM Supports Analysis > F&I PBT (excl All Supports from OEM)": {
  name: "F&I > OEM Supports Analysis > F&I PBT (excl All Supports from OEM)",
  department: "F&I",
  category: "OEM Supports Analysis",
  label: "F&I PBT (excl All Supports from OEM)",
  measure: "PBT > PBT (excl OEM Incentives & Expenses Subsidy) | F&I Dept | All Brand | All Model | | SUM",
  getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 529,
  canAverage: true
},
};
