import React, { useContext } from "react";
import classnames from "classnames";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFileExcel, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { FormattedMessage, useIntl } from "react-intl";
import html2canvas from "html2canvas";
import { MetricReport } from "../lib/api/charts";
import { UserContext } from "../contexts/UserContext";
import { translation } from "../translations/Translations";
import { red, green, blue } from "@material-ui/core/colors";
import { JPFont } from "../style/JPFont";
import { TWFont } from "../style/TWFont";
import { VNFont } from "../style/VNFont";
import { THFont } from "../style/THFont";
import { CNFont } from "../style/CNFont";
import { LanguageContext } from "../contexts/LanguageContext";

const styles = (theme: Theme) =>
  createStyles({
    root: { textTransform: "lowercase" },
    exportButton: {
      border: "none",
      outline: "none",
      color: "rgba(0, 0, 0, 0.87)",
      padding: "6px 7px",
      fontSize: "0.6875rem",
      minWidth: "64px",
      boxSizing: "border-box",
      lineHeight: 1.75,
      fontWeight: 500,
      fontFamily: "Montserrat,-apple-systrem,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,Helvetica,Arial,sans-serif",
      borderRadius: "4px",
      cursor: "pointer",
      backgroundColor: "transparent",
      transition:
        "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    icon: {
      marginLeft: "6px",
      fontSize: "12px",
      color: green[500],
    },
    green: {
      color: green[500],
    },
    red: {
      color: red[500],
    },
    blue: {
      color: blue[500],
    },
    excelIcon: {
      paddingLeft: "5px",
    },
    exportPDFText: {
      paddingLeft: "5px",
    },
  });

type DashboardFilters = {
  dealer?: string;
  brand: string;
  date: string;
  forecast?: string;
  period?: string;
  benchmarks?: string[];
  financialMonth?: string;
  currency?: string;
  view?: string;
};

export type ExportButtonsProps = {
  id:
    | "metric-table"
    | "group-table"
    | "custom-table"
    | "dashboard-metrics"
    | "group-metrics"
    | "oem-metrics"
    | "custom-metrics"
    | "graph-table-dashboard"
    | "distribution-dashbord"
    | string;
  title: string;
  filters?: DashboardFilters;
  dashboard?: string;
  report?: MetricReport;
  measures?: string[];
  name?: string;
} & React.HTMLAttributes<HTMLDivElement> &
  WithStyles<typeof styles>;

const ExportButtonsUnstlyed: React.SFC<ExportButtonsProps> = ({ classes, children, className, title, id, filters, dashboard, report, measures, name = "" }) => {
  const [PDFLoading, setPDFLoading] = React.useState(false);
  const intl = useIntl();
  const language = useContext(LanguageContext);

  const { user } = useContext(UserContext);
  const exportMeasuresAccess = [
    "rowan.smith@vulcanlabs.com",
    "kurt.adendorff@vulcanlabs.com",
    "omar.salie@vulcanlabs.com",
    "sphaphaeng@msxi.com",
    "bfusberti@msxi.com",
    "vilasinee.kawinchiraphat@msxi.com",
    "wongmanee.chavanwan@msxi.com",
    "msioco@sewells-msxi.com",
    "msioco@msxi.com",
    "asakuma@msxi.com",
    "qdo@msxi.com",
    "csangjunsuk@msxi.com",
    "kkhamkaew@msxi.com",
    "lconybeare@msxi.com",
    "prinruts.takviriyanan@msxi.com",
    "panipuck.arunsodsai@msxi.com",
  ].includes(user.email.toLowerCase());

  const exportMeasures = (dashboard: string, metricType: string, measures: string[]) => {
    const headers = [[`Dashboard - ${name}`]];
    const measureArr = (measures || []).reduce((result, measure) => {
      const [title, measureFormula, department, brand, model, agg] = measure.split(" | ");
      result.push(
        Array(intl.formatMessage({ id: `${translation[title]}` }) + " | " + measureFormula + " | " + department + " | " + brand + " | " + model + " | " + agg),
      );
      return result;
    }, []);

    const Results = [...headers, ...measureArr];
    var CsvString = "";
    Results.forEach(function(RowItem, RowIndex) {
      RowItem.forEach(function(ColItem, ColIndex) {
        CsvString += ColItem;
      });
      CsvString += "\r\n";
    });
    CsvString = "data:application/csv," + encodeURIComponent(CsvString);
    console.log(CsvString);
    var x = document.createElement("A");
    x.setAttribute("href", CsvString);
    x.setAttribute("download", `${name} Table Measures.txt`);
    document.body.appendChild(x);
    x.click();
  };

  const ConvertPDF = async () => {
    setPDFLoading(true);
    try {
      if (id === "metric-table" || id === "group-table" || id === "custom-table" || id.includes("group-table-")) {
        const tableRows = document.getElementById(id).querySelectorAll("tr");
        const tableRowsArray = Array.from(tableRows);
        const tableArray = (tableRowsArray || []).map((r, i) => {
          if (id == "metric-table" && i > 1) {
            return Array.from(r.querySelectorAll("td"))
              .slice(1)
              .map(r => ({ content: r.textContent }));
          }
          if (id == "metric-table" && i > 0) {
            return Array.from(r.querySelectorAll("th"))
              .slice(1)
              .map(r => ({ content: r.textContent }));
          }
          if (id != "metric-table" && i > 0) {
            return Array.from(r.querySelectorAll("td"))
              .map(r => ({ content: r.textContent }))
              .filter(r => r.content.length > 0);
          }
          return Array.from(r.querySelectorAll("th"))
            .map(r => ({ content: r.textContent }))
            .filter(r => r.content.length > 0);
        });

        const tableContent = (tableArray || []).map(row => (row || []).map((r, ri) => ({ ...r, styles: { halign: ri >= 1 ? "right" : "left" } })));
        const doc: any = new jsPDF();
        let font = undefined;
        switch (language.language) {
          case "jp":
            doc.addFileToVFS("jp.ttf", JPFont);
            doc.addFont("jp.ttf", "jp", "normal");
            font = "jp";
            break;
          case "tw":
            doc.addFileToVFS("tw.ttf", TWFont);
            doc.addFont("tw.ttf", "tw", "normal");
            font = "tw";
            break;
          case "cn":
            doc.addFileToVFS("cn.ttf", CNFont);
            doc.addFont("cn.ttf", "cn", "normal");
            font = "cn";
            break;
          case "th":
            doc.addFileToVFS("th.ttf", THFont);
            doc.addFont("th.ttf", "th", "normal");
            doc.setFont("th");
            font = "th";
            break;
          case "vn":
            doc.addFileToVFS("vn.ttf", VNFont);
            doc.addFont("vn.ttf", "vn", "normal");
            font = "vn";
            break;
          default:
            console.log("Language is ", language.language);
        }
        doc.setFont(font);
        console.log("Language is ", language.language);
        doc.autoTable({
          head: [tableContent[0]],
          body: (tableContent || []).splice(1, (tableContent || []).length),
          font: font,
          theme: "striped",
          tableWidth: "auto",
          headStyles: { fillColor: "#ededed", textColor: [85, 85, 85], fontStyle: font },
          styles: { cellPadding: 1, fontSize: 5, cellWidth: "auto", font: font },
          margin: { top: 1, left: 1, right: 1 },
        });
        doc.save(`${title}.pdf`);
      }
      if (
        id === "dashboard-metrics" ||
        id === "group-metrics" ||
        id === "oem-metrics" ||
        id === "custom-metrics" ||
        id === "graph-table-dashboard" ||
        id === "distribution-dashbord"
      ) {
        const headers = [
          {
            dealer: "Dealer",
            brand: "Brand",
            date: "Date",
            forecast: "Forecast",
            period: "Period",
            financialMonth: "Financial Month",
            benchmarks: "Benchmarks",
            view: "View",
            currency: "Currency",
          },
        ];
        const dashboardFilters = [
          {
            dealer: id == "group-metrics" ? "" : filters.dealer,
            brand: filters.brand,
            date: filters.date,
            forecast: filters.forecast,
            period: filters.period,
            financialMonth: filters.period === "Financial Year" ? filters.financialMonth : "",
            benchmarks: filters.benchmarks.length === 1 ? filters.benchmarks[0] : `${filters.benchmarks[0]}, ${filters.benchmarks[1]}`,
            view: filters.view,
            currency: filters.currency,
          },
        ];

        const input = document.getElementById(id);
        html2canvas(input).then(canvas => {
          const imgData = canvas.toDataURL("image/png");
          const pdf = new jsPDF("landscape");
          pdf.addImage(imgData, "JPEG", 0, 10, pdf.internal.pageSize.width - 2, pdf.internal.pageSize.height - 12);
          (pdf as any).autoTable({
            head: headers,
            body: dashboardFilters || [],
            theme: "striped",
            tableWidth: "auto",
            headStyles: { fillColor: "#ededed", textColor: [85, 85, 85] },
            styles: { cellPadding: 1, fontSize: 5, cellWidth: "auto" },
            margin: { top: 1, left: 1, right: 1 },
          });
          pdf.save(`${title}.pdf`);
        });
      }
    } catch (e) {
      setPDFLoading(false);
      return e;
    }
    setPDFLoading(false);
  };

  return (
    <div className={classnames(classes.root, className)}>
      {(id === "metric-table" || id === "group-table" || id === "custom-table" || id.includes("group-table-")) && (
        <Button color={"default"} style={{ padding: 0 }}>
          <span className={classes.excelIcon}>
            <FontAwesomeIcon className={classnames(classes.green)} size={"lg"} icon={faFileExcel} />
          </span>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className={classes.exportButton}
            table={id}
            filename={title}
            sheet="tablexls"
            buttonText={`${intl.formatMessage({ id: "export.to.excel.button" })}`}
          />
        </Button>
      )}
      <Button
        style={{ fontSize: "11px" }}
        color={"default"}
        onClick={() => {
          ConvertPDF();
        }}
      >
        {PDFLoading ? (
          <CircularProgress color={"secondary"} size={20} />
        ) : (
          <span>
            <FontAwesomeIcon icon={faFilePdf} className={classnames(classes.red)} size={"lg"} />
            <span className={classes.exportPDFText}>
              <FormattedMessage id="export.to.pdf.button" />
            </span>
          </span>
        )}
      </Button>
      {exportMeasuresAccess &&
        (measures || []).length > 0 &&
        (id === "metric-table" || id === "group-table" || id === "custom-table" || id.includes("group-table-")) && (
          <Button
            style={{ fontSize: "11px" }}
            color={"default"}
            onClick={() => {
              exportMeasures(dashboard, report, measures);
            }}
          >
            <span>
              <FontAwesomeIcon className={classnames(classes.blue)} size={"lg"} icon={faFile} />
              <span className={classes.exportPDFText}>Export Measures</span>
            </span>
          </Button>
        )}
    </div>
  );
};

export const ExportButtons = withStyles(styles)(ExportButtonsUnstlyed);
