import React, { createContext, useReducer, useMemo } from "react";
import { RiskReport as Report, Query, stringifyQuery, RiskSeries, riskScatterSeriesFor, ScatterReport } from "../lib/api/charts";
import { Loading } from "./base";

type GroupSeriesSet = { series: RiskSeries; loading: Loading; measure?: any };

export type RiskScatterChartContextState = {
  series: {
    [index: string]: GroupSeriesSet;
  };
  getScatterSeries(report: Report, query: Query, chart?: ScatterReport, measure?: any, brand?: string): Partial<GroupSeriesSet>;
};

export const RiskScatterChartContext = createContext<RiskScatterChartContextState>({
  series: {},
  getScatterSeries: () => ({}),
});

type QueryAction = {
  type: "PATCH_SERIES";
  id: string;
  data: Partial<GroupSeriesSet>;
};

const defaultGroupSeries: GroupSeriesSet = {
  series: {
    name: "",
    rows: [],
    scatterdata: { "No Risk": [], "Early Warning": [], Warning: [], Risk: [] },
    classification: "income",
    type: "currency",
    dealers: [],
    xformats: { pos: "", neg: "" },
    yformats: { pos: "", neg: "" },
  },
  loading: { loaded: false, loading: true, error: null },
  measure: {},
};

export const RiskScatterChartProvider = ({ children }) => {
  const memo = (report: Report, query: Query, chart?: ScatterReport, measure?: any, brand?: string) =>
    `${report}-${chart}-${stringifyQuery(query)}-${JSON.stringify(measure)}-${brand}`;

  const [series, dispatchGroupSeries] = useReducer((state: { [index: string]: GroupSeriesSet }, action: QueryAction) => {
    switch (action.type) {
      case "PATCH_SERIES":
        return {
          ...state,
          [action.id]: {
            ...state[action.id],
            ...action.data,
          },
        };
      default:
        return state;
    }
  }, {});

  const value = useMemo(
    () => ({
      series,
      getScatterSeries: (report: Report, query: Query, chart?: ScatterReport, measure?: any, brand?: string) => {
        const id = memo(report, query, chart, measure, brand);
        const set = series[id];

        if (set) {
          return set;
        }

        dispatchGroupSeries({
          type: "PATCH_SERIES",
          id,
          data: defaultGroupSeries,
        });

        riskScatterSeriesFor(query, report, chart, measure, brand)
          .then(series => {
            dispatchGroupSeries({
              type: "PATCH_SERIES",
              id,
              data: {
                series: series,
                measure,
                loading: {
                  loaded: true,
                  loading: false,
                  error: null,
                },
              },
            });
          })
          .catch(e => {
            dispatchGroupSeries({
              type: "PATCH_SERIES",
              id,
              data: {
                loading: {
                  loaded: false,
                  loading: false,
                  error: e,
                },
              },
            });
          });

        return defaultGroupSeries;
      },
    }),
    [series],
  );

  return <RiskScatterChartContext.Provider value={value}>{children}</RiskScatterChartContext.Provider>;
};
