import { useState, useCallback } from "react";

export type Loading = {
  loading: boolean;
  loaded: boolean;
  error?: Error;
};

export const useLoading = <T,>(
  inital: Loading = {
    loading: false,
    loaded: false,
    error: undefined,
  },
): [Loading, (loading: Partial<Loading>) => void, (work: () => Promise<T>) => Promise<T | void>] => {
  const [loading, setLoading] = useState(inital);
  const load = useCallback(
    (newLoading: Partial<Loading>) => {
      setLoading({ ...loading, ...newLoading });
    },
    [loading],
  );
  const withLoading = useCallback(
    async (work: () => Promise<T>) => {
      try {
        load({ loading: true });
        const result = await work();
        load({ loading: false, loaded: true });
        return result;
      } catch (e) {
        load({ loading: false, error: e });
      }
    },
    [load],
  );
  return [loading, load, withLoading];
};