import { request, apiHeaders } from "./base";

export type MediaFile = {
  id: number;
  title: string;
  file?: any;
  tab: string;
  type: string;
  video?: string;
  awsVideo?: string;
  oemCodes?: string[];
  link?: string;
  mediaId: string;
  group: string;
};

export type MediaFileThumbnail = {
  id: number;
  mediaId: number;
  file: any;
};

export const mediaFiles = (): Promise<MediaFile[]> =>
  request("/media-files")
    .then(res => res.json())
    .then(({ data }) => data);

export const createFiles = async (file: Partial<MediaFile>) => {
  const formData = new FormData();

  Object.keys(file).forEach(key => {
    formData.append(key, file[key]);
  });

  return request("/media-files", {
    method: "POST",
    body: formData,
    headers: new Headers({
      Accept: "application/json",
      "X-csrf-validation": "-",
    }),
  }).then(res => res.json());
};

export const createMediaOems = async (data: { id: Number; oemCode: string }) =>
  request("/media-files/oems", {
    method: "POST",
    body: JSON.stringify(data),
  })
    .then(res => res.json())
    .then(({ data }) => data);

export const deleteFiles = (id: number) =>
  request(`/media-files/${id}`, {
    method: "DELETE",
  }).then(res => res.json());

export const deleteGroup = (groupName: string) =>
  request(`/user-groups/${groupName}`, {
    method: "DELETE",
  }).then(res => res.json());

export const addThumbnail = async (file: Partial<MediaFileThumbnail>) => {
  const formData = new FormData();

  Object.keys(file).forEach(key => {
    formData.append(key, file[key]);
  });

  return request("/media-file/thumbnail", {
    method: "POST",
    body: formData,
    headers: new Headers({
      Accept: "application/json",
      "X-csrf-validation": "-",
    }),
  }).then(res => res.json());
};

export type UserGroups = {
  userId: number;
  groupName: string;
  tabName: string;
};

export const getUserGroups = (): Promise<UserGroups[]> =>
  request("/user-groups")
    .then(res => res.json())
    .then(({ data }) => data);

export const createUserGroups = async (data: { groupname: string; tabname: string }) => {
  const content = JSON.stringify(data);
  return request("/user-groups", {
    method: "POST",
    body: content,
  }).then(res => res.json());
};

export type AWSFile = {
  Key: string;
  LastModified: Date;
  ETag: string;
  Size: number;
  StorageClass: string;
  DisplayName: string;
};

export const getAwsFiles = (): Promise<AWSFile[]> =>
  request("/getAWSS3Files")
    .then(res => res.json())
    .then(({ data }) => data);
