import React, { useContext } from "react";
import classnames from "classnames";
import { withStyles, createStyles, WithStyles, Theme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { Loading } from "./Loading";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { MeasuresContext } from "../contexts/MeasuresContext";
import { Metric } from "../lib/api/charts";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    dialogContent: {
      width: "500px",
      maxWidth: "500px",
    },
  });

export type GuidanceModalProps = {
  open: boolean;
  header: string;
  setOpen(value: boolean);
  guidanceHTML?: string;
} & React.HTMLAttributes<HTMLDivElement> &
  WithStyles<typeof styles>;

const GuidanceModalUnstlyed: React.SFC<GuidanceModalProps> = ({ classes, children, className, header, open, setOpen, guidanceHTML }) => {
  const { loading: measureLoading } = useContext(MeasuresContext);

  return (
    <div className={classnames(classes.root, className)}>
      <Dialog onClose={() => setOpen(false)} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title">{header}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {measureLoading.loaded ? (
            <Typography
              dangerouslySetInnerHTML={{
                __html: guidanceHTML,
              }}
            />
          ) : (
            <Loading />
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const GuidanceModal = withStyles(styles)(GuidanceModalUnstlyed);
