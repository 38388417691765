import { request } from "./base";

export type Permission = {
  id: number;
  mainMenuItem: string;
  subMenuItem: string;
  canRead: boolean;
  canEdit: boolean;
  canCreate: boolean;
  moduleName: string;
};

export type NewsPermission = {
  userId: number;
  firstName: string;
  lastName: string;
  username: string;
};

export type CommandPermissions = {
  id: number;
  moduleName: string;
  commandName: string;
  canExecute: boolean;
};

export type NewsMessage = { title: string; message: string; classification: string; dealers: string[] };

export const permissions = (): Promise<Permission[]> =>
  request("/permissions")
    .then(res => res.json())
    .then(({ data }) => data);

export const newsPermissionsData = (): Promise<NewsPermission[]> =>
  request("/news/permissions")
    .then(res => res.json())
    .then(({ data }) => data);

export const getAnalysisExcelDownloadPermission = (): Promise<boolean> =>
  request("/analysis-repot/excel")
    .then(res => res.json())
    .then(({ data }) => data);

export const getAllowDashboardAvgPermission = (): Promise<boolean> =>
  request("/permissions/allowDashboardAvg")
    .then(res => res.json())
    .then(({ data }) => data);

export const getCommandPermissions = (): Promise<CommandPermissions[]> =>
  request("/command-permissions")
    .then(res => res.json())
    .then(({ data }) => data);
