import { request, apiHeaders } from "./base";

export type OemComparativeDealer = {
  name: string;
  oemcode: string;
  dealercode: string;
  language: string;
  inventorydays: number;
};

export type FlashReportLanguages = {
  IsoCode: string;
  EnglishName: string;
};

export const flashReportsConfig = (): Promise<OemComparativeDealer[]> =>
  request("/flash-reports")
    .then(res => res.json())
    .then(({ data }) => data);

export const flashReportDealerAnalysis = (): Promise<Partial<OemComparativeDealer>[]> =>
  request("/dealer-analysis")
    .then(res => res.json())
    .then(({ data }) => data);

export const flashReportNetworkAnalysis = (): Promise<Partial<OemComparativeDealer>[]> =>
  request("/network-analysis")
    .then(res => res.json())
    .then(({ data }) => data);

export const riskDashboardComparativeDealers = (): Promise<Partial<OemComparativeDealer>[]> =>
  request("/risk-dashboard-comparative-dealer")
    .then(res => res.json())
    .then(({ data }) => data);

export const getFlashReportLanguages = (dealercode: string, datasetTitle: string): Promise<FlashReportLanguages[]> =>
  request(`/flash-report-languages/${dealercode}/${datasetTitle}`)
    .then(res => res.json())
    .then(({ data }) => data);
