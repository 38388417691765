import React, { createContext, useState, useMemo, FC, useContext, useEffect } from "react";
import { Loading } from "./base";
import {
  riskDashboardComparativeDealers,
  OemComparativeDealer,
} from "../lib/api/flash-reports-config";
import { DealerContext } from "./DealerContext";

const defaultRiskDashboardConfig = [];

export type RiskDashboardConfigContextState = {
  comparativeDealers: Partial<OemComparativeDealer>[];
  loading: Loading;
  hydrate(): void;
};

export const RiskDashboardConfigContext = createContext<RiskDashboardConfigContextState>({
  comparativeDealers: defaultRiskDashboardConfig,
  loading: { loading: false, loaded: false, error: null },
  hydrate: () => {},
});

export const RiskDashboardConfigProvider: FC = ({ children }) => {
  const [comparativeDealers, setComparativeDealers] = useState<Partial<OemComparativeDealer>[]>(defaultRiskDashboardConfig);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });

  const hydrate = async () => {
    try {
      setLoading({
        loading: true,
        loaded: false,
        error: null,
      });
      const networkAnalysis = await riskDashboardComparativeDealers();
      setComparativeDealers(networkAnalysis);
      setLoading({
        loading: false,
        loaded: true,
        error: null,
      });
    } catch (e) {
      setLoading({
        loading: false,
        loaded: false,
        error: e,
      });
    }
  }
  useEffect(()=> {
    hydrate()
  },[])

  const value = useMemo(
    () => ({
      comparativeDealers,
      loading,
      hydrate
    }),
    [comparativeDealers, loading, setComparativeDealers],
  );
  return <RiskDashboardConfigContext.Provider value={value}>{children}</RiskDashboardConfigContext.Provider>;
};
