import React, { createContext, useState, FC, useMemo } from "react";
import { benchmarks as apiBenchmarks, apiBenchmarkDates } from "../lib/api/charts";

type Loading = {
  loading: boolean;
  loaded: boolean;
  error?: Error;
};

export type Benchmark = string;

export type BenchmarkDates = {
  [index: string]: { maxdate: Date };
};

const defaultBenchmarks: string[] = [];

export type BenchmarkContextState = {
  benchmarks: string[];
  benchmarkDates: BenchmarkDates;
  selected: Benchmark[];
  oemSelected: Benchmark[];
  customSelected: Benchmark[];
  dealerAnalysisSelected: Benchmark[];
  networkAnalysisSelected: Benchmark[];
  loading: Loading;
  hydrate();
  setBenchmark: (benchmark: Benchmark[]) => void;
  setOemBenchmark: (benchmark: Benchmark[]) => void;
  setCustomBenchmark: (benchmark: Benchmark[]) => void;
  setDealerAnalysisBenchmark: (benchmark: Benchmark[]) => void;
  setNetworkAnalysisBenchmark: (benchmark: Benchmark[]) => void;
};

export const BenchmarkContext = createContext<BenchmarkContextState>({
  benchmarks: defaultBenchmarks,
  benchmarkDates: {},
  selected: [],
  oemSelected: [],
  customSelected: [],
  dealerAnalysisSelected: [],
  networkAnalysisSelected: [],
  loading: { loading: false, loaded: false, error: undefined },
  hydrate: () => {},
  setBenchmark: () => {},
  setOemBenchmark: () => {},
  setCustomBenchmark: () => {},
  setDealerAnalysisBenchmark: () => {},
  setNetworkAnalysisBenchmark: () => {},
});

export const BenchmarkProvider: FC = ({ children }) => {
  const [benchmarks, setBenchmarks] = useState<string[]>(defaultBenchmarks);
  const [benchmarkDates, setBenchmarkDates] = useState<BenchmarkDates>({});
  const [selected, setSelected] = useState<Benchmark[]>();
  const [oemSelected, setOemSelected] = useState<Benchmark[]>();
  const [customSelected, setCustomSelected] = useState<Benchmark[]>();
  const [dealerAnalysisSelected, setDealerAnalysisSelected] = useState<Benchmark[]>();
  const [networkAnalysisSelected, setNetworkAnalysisSelected] = useState<Benchmark[]>();
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: undefined });

  const value = useMemo(
    () => ({
      benchmarks,
      benchmarkDates,
      selected,
      oemSelected,
      customSelected,
      dealerAnalysisSelected,
      networkAnalysisSelected,
      loading,
      setLoading,
      setBenchmark: setSelected,
      setBenchmarkDates,
      setOemBenchmark: setOemSelected,
      setCustomBenchmark: setCustomSelected,
      setDealerAnalysisBenchmark: setDealerAnalysisSelected,
      setNetworkAnalysisBenchmark: setNetworkAnalysisSelected,
      hydrate: async () => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          const { benchmarks } = await apiBenchmarks();
          const loadedDates = await apiBenchmarkDates();

          setBenchmarks(benchmarks);
          setBenchmarkDates(
            loadedDates.reduce((memo, value) => {
              memo[value.benchmark_name] = { maxdate: value.maxdate };
              return memo;
            }, {}),
          );
          //setSelected();
          setLoading({
            loading: false,
            loaded: true,
            error: null,
          });
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
    }),
    [
      benchmarks,
      selected,
      oemSelected,
      customSelected,
      dealerAnalysisSelected,
      networkAnalysisSelected,
      loading,
      setBenchmarks,
      setOemSelected,
      setCustomSelected,
      setDealerAnalysisSelected,
      setNetworkAnalysisSelected,
      setSelected,
    ],
  );

  return <BenchmarkContext.Provider value={value}>{children}</BenchmarkContext.Provider>;
};
