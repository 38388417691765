import { request, apiHeaders } from "./base";

export type Attachment =  {
  id: number
  file: any;
  messageId: number;
}

export const getAttachments = (): Promise<Attachment[]> => request("/attachments").then((res) => res.json()).then(({ data }) => data );

export const createAttachments = async (file: Partial<Attachment>) => {
  const formData = new FormData();

  Object.keys(file).forEach((key) => {
    formData.append(key, file[key]);
  });

  return request("/attachments", {
    method: "POST",
    body: formData,
    headers: new Headers({
      Accept: "application/json",
      "X-csrf-validation": "-",
    })
  }).then((res) => res.json());
}

export const deleteAttachment = (id: number) => request(`/attachment/${id}`, {
  method: "DELETE",
}).then((res) => res.json());
