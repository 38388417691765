import { request } from './base';


export type Forecast = {
  name: string;
  type: string;
  dealers: string[];
  measures: string[];
};

export const forecast = (): Promise<Forecast[]> =>
  request('/forecast')
    .then(res => res.json())
    .then(({ data }) => data);
