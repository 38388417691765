import React, { createContext, useState, useReducer, useMemo } from "react";
import { Loading } from "./base";
import { OemTabs, oemTabs } from "../lib/api/oem-tabs";

const defaultOemTabs = {
  "Dashboard/Executive Summary": [],
  "Dashboard/New": [],
  "Dashboard/Used": [],
  "Dashboard/Parts": [],
  "Dashboard/Service": [],
  "Dashboard/Body Shop": [],
  "Dashboard/Custom": [],
  "Reports/OEM Reports": [],
  "Reports/Other BM Tools": [],
  "Reports/Performance Analysis": [],
  "Reports/Standard Reports": [],
  "Submission/Deadlines": [],
  "Submission/Download Templates": [],
  "Submission/Submit Data": [],
  "Insights/Interpretation": [],
  "Insights/Data Preparation": [],
  "Insights/BM Process": [],
  "Insights/Industry Insights": [],
};

export type OemTabsContextState = {
  tabs: OemTabs;
  loading: Loading;
  hydrate();
};

export const OemTabsContext = createContext<OemTabsContextState>({
  tabs: defaultOemTabs,
  loading: { loading: false, loaded: false, error: null },
  hydrate: () => {},
});

export const OemTabsProvider = ({ children }) => {
  const [tabs, setOemTabs] = useState<OemTabs>(defaultOemTabs);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: null });

  const value = useMemo(
    () => ({
      tabs,
      loading,
      hydrate: async () => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: null,
          });
          const tabs = await oemTabs();
          setOemTabs(tabs);
          setLoading({
            loading: false,
            loaded: true,
            error: null,
          });
        } catch (e) {
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      setOemTabs,
    }),
    [tabs, loading],
  );

  return <OemTabsContext.Provider value={value}>{children}</OemTabsContext.Provider>;
};
