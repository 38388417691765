import { CustomDashboardType, getMeasure } from "../CustomDashReports";

export const NetworkStats: { [index: string]: CustomDashboardType } = {
  "Network Stats > Additional Stats > Profitable Dealers Count CYTD": {
    name: "Network Stats > Additional Stats > Profitable Dealers Count CYTD",
    department: "Network Stats",
    category: "Additional Stats",
    label: "Profitable Dealers Count CYTD",
    tableNumber: 169,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Profitable Dealers YTD | All Department | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Network Stats > Additional Stats > Profitable Dealers Count FYTD": {
    name: "Network Stats > Additional Stats > Profitable Dealers Count FYTD",
    department: "Network Stats",
    category: "Additional Stats",
    label: "Profitable Dealers Count FYTD",
    tableNumber: 169,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Profitable Dealers FYTD | All Department | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Network Stats > Additional Stats > Profitable Dealers Count MTD": {
    name: "Network Stats > Additional Stats > Profitable Dealers Count MTD",
    department: "Network Stats",
    category: "Additional Stats",
    label: "Profitable Dealers Count MTD",
    tableNumber: 169,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Profitable Dealers MTD | All Department | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Network Stats > Additional Stats > Profitable Dealers Count 3RM": {
    name: "Network Stats > Additional Stats > Profitable Dealers Count 3RM",
    department: "Network Stats",
    category: "Additional Stats",
    label: "Profitable Dealers Count 3RM",
    tableNumber: 169,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Profitable Dealers 3RM | All Department | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Network Stats > Additional Stats > Loss Dealers Count CYTD": {
    name: "Network Stats > Additional Stats > Loss Dealers Count CYTD",
    department: "Network Stats",
    category: "Additional Stats",
    label: "Loss Dealers Count CYTD",
    tableNumber: 169,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Loss Dealers YTD | All Department | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Network Stats > Additional Stats > Loss Dealers Count FYTD": {
    name: "Network Stats > Additional Stats > Loss Dealers Count FYTD",
    department: "Network Stats",
    category: "Additional Stats",
    label: "Loss Dealers Count FYTD",
    tableNumber: 169,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Loss Dealers FYTD | All Department | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Network Stats > Additional Stats > Loss Dealers Count MTD": {
    name: "Network Stats > Additional Stats > Loss Dealers Count MTD",
    department: "Network Stats",
    category: "Additional Stats",
    label: "Loss Dealers Count MTD",
    tableNumber: 169,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Loss Dealers MTD | All Department | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Network Stats > Additional Stats > Loss Dealers Count 3RM": {
    name: "Network Stats > Additional Stats > Loss Dealers Count 3RM",
    department: "Network Stats",
    category: "Additional Stats",
    label: "Loss Dealers Count 3RM",
    tableNumber: 169,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Loss Dealers 3RM | All Department | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Network Stats > Additional Stats > Profitable Dealers % CYTD": {
    name: "Network Stats > Additional Stats > Profitable Dealers % CYTD",
    department: "Network Stats",
    category: "Additional Stats",
    label: "Profitable Dealers % CYTD",
    tableNumber: 169,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Profitable Dealers YTD % of Reporting Dealer count | All Department | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Network Stats > Additional Stats > Profitable Dealers % FYTD": {
    name: "Network Stats > Additional Stats > Profitable Dealers % FYTD",
    department: "Network Stats",
    category: "Additional Stats",
    label: "Profitable Dealers % FYTD",
    tableNumber: 169,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Profitable Dealers FYTD % of Reporting Dealer count | All Department | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Network Stats > Additional Stats > Profitable Dealers % MTD": {
    name: "Network Stats > Additional Stats > Profitable Dealers % MTD",
    department: "Network Stats",
    category: "Additional Stats",
    label: "Profitable Dealers % MTD",
    tableNumber: 169,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Profitable Dealers MTD % of Reporting Dealer count | All Department | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Network Stats > Additional Stats > Profitable Dealers % 3RM": {
    name: "Network Stats > Additional Stats > Profitable Dealers % 3RM",
    department: "Network Stats",
    category: "Additional Stats",
    label: "Profitable Dealers % 3RM",
    tableNumber: 169,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Profitable Dealers 3RM % of Reporting Dealer count | All Department | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Network Stats > Additional Stats > Loss Dealers % CYTD": {
    name: "Network Stats > Additional Stats > Loss Dealers % CYTD",
    department: "Network Stats",
    category: "Additional Stats",
    label: "Loss Dealers % CYTD",
    tableNumber: 169,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Loss Dealers YTD % of Reporting Dealer count | All Department | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Network Stats > Additional Stats > Loss Dealers % FYTD": {
    name: "Network Stats > Additional Stats > Loss Dealers % FYTD",
    department: "Network Stats",
    category: "Additional Stats",
    label: "Loss Dealers % FYTD",
    tableNumber: 169,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Loss Dealers FYTD % of Reporting Dealer count | All Department | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Network Stats > Additional Stats > Loss Dealers % MTD": {
    name: "Network Stats > Additional Stats > Loss Dealers % MTD",
    department: "Network Stats",
    category: "Additional Stats",
    label: "Loss Dealers % MTD",
    tableNumber: 169,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Loss Dealers MTD % of Reporting Dealer count | All Department | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Network Stats > Additional Stats > Loss Dealers % 3RM": {
    name: "Network Stats > Additional Stats > Loss Dealers % 3RM",
    department: "Network Stats",
    category: "Additional Stats",
    label: "Loss Dealers % 3RM",
    tableNumber: 169,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "MS > Loss Dealers 3RM % of Reporting Dealer count | All Department | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Network Stats > Additional Stats > Reporting Dealers Count": {
    name: "Network Stats > Additional Stats > Reporting Dealers Count",
    department: "Network Stats",
    category: "Additional Stats",
    label: "Reporting Dealer Count (End of period)",
    tableNumber: 169,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STATS > Dealer Count | All Department | All Brand | All Model | | CLOSE",
    canAverage: false,
  },
  "Network Stats > Additional Stats > Reporting Dealer Count": {
    name: "Network Stats > Additional Stats > Reporting Dealer Count",
    department: "Network Stats",
    category: "Additional Stats",
    label: "Reporting Dealer Count (Average)",
    tableNumber: 169,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "STATS > Dealer Count | All Department | All Brand | All Model | | AVG",
    canAverage: false,
  },
  "Network Stats > Additional Stats > Reporting Dealers %": {
    name: "Network Stats > Additional Stats > Reporting Dealers %",
    department: "Network Stats",
    category: "Additional Stats",
    label: "Reporting Dealers %",
    tableNumber: 169,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Placeholder Measure",
    canAverage: false,
  },
  "Network Stats > Additional Stats > Data Quality score": {
    name: "Network Stats > Additional Stats > Data Quality score",
    department: "Network Stats",
    category: "Additional Stats",
    label: "Data Quality score",
    tableNumber: 169,
    getMeasure: (label, measures) => getMeasure(label, measures),
    measure: "Placeholder Measure",
    canAverage: false,
  },
};
