import React, { createContext, useState, FC, useMemo } from "react";
import { NotificationMessage, Message, messages as getMessages, message as getMessage } from "../lib/api/notifications";

type Loading = {
  loading: boolean;
  loaded: boolean;
  error?: Error;
};

const defaultMessages: NotificationMessage[] = [];

export type NotificationsContextState = {
  notifications: NotificationMessage[];
  messages: { [index: string]: { message: Partial<Message>; loading: Partial<Loading> } };
  loading: Loading;
  hydrate: () => Promise<void>;
  getMessage: (id: string, force?: boolean) => [Partial<Loading>, Partial<Message>];
};

export const NotificationsContext = createContext<NotificationsContextState>({
  notifications: defaultMessages,
  messages: {},
  hydrate: () => Promise.resolve(),
  loading: { loading: false, loaded: false, error: undefined },
  getMessage: () => [{}, {}],
});

export const NotificationsProvider: FC = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationMessage[]>(defaultMessages);
  const [messages, setMessages] = useState<{ [index: string]: { message: Partial<Message>; loading: Partial<Loading> } }>({});
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: undefined });
  const hydrate = async () => {
    try {
      setLoading({ loading: true, loaded: false, error: null });
      const messages = await getMessages();
      setNotifications(messages);
      setLoading({ loading: false, loaded: true, error: null });
    } catch (e) {
      setLoading({ loading: false, loaded: false, error: e });
    }
  };
  const value = useMemo(
    () => ({
      notifications,
      messages,
      loading,
      hydrate,
      getMessage: (id: string, force = false): [Partial<Loading>, Partial<Message>] => {
        if (!messages[id] || force) {
          const loading = { loading: true, loaded: false, error: null };
          setMessages({ ...messages, [id]: { message: {}, loading } });
          getMessage(id)
            .then(message => {
              const loading = { loading: false, loaded: true, error: null };
              setMessages({ ...messages, [id]: { message, loading } });
            })
            .catch(e => {
              const loading = { loading: false, loaded: false, error: e };
              setMessages({ ...messages, [id]: { message: {}, loading } });
            });
          return [loading, {}];
        }
        return [messages[id].loading, messages[id].message];
      },
    }),
    [notifications, hydrate, messages, loading],
  );

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};
