
import { SelectablePieChart } from "./GroupPieChart";
import { SelectableChart as SelectableSeriesChart } from "./GroupSeriesChart";
import { SelectableChart as SelectableBarChart } from "./GroupStackedChart";
import { Query } from "../lib/api/charts";
import React from "react";


// handleSelection={update("tr")}
// report={execConfig.items.tr.measure}
// title="SALES `000"
// query={query}
// showBenchmark
// showTarget
// chartOptions={chartOptions}
type GroupSelectableChartProps = {
    update: any,
    report: any,
    title: string,
    query: Query,
    chartOptions: any,
    initialChartType: string,
    measure?: any,
    lockScorecardOptions?: boolean,
}
export const GroupSelectableChart: React.FC<GroupSelectableChartProps> = ({update, report, title, query, chartOptions, initialChartType, measure, lockScorecardOptions}) => {
    const [chartType, updateType] = React.useState(initialChartType || "bar");
  
    if (chartType === "pie") {
      return (
        <SelectablePieChart
          report={report}
          query={query}
          title={title}
          handleSelection={
            update
          }
          handleChartType={chartType => {
            updateType(chartType);
          }}
          chartType={chartType}
          chartOptions={chartOptions}
          measure={measure}
        />
      );
    }
    if (chartType === "line") {
      return (
        <>
        <SelectableSeriesChart
          report={report}
          allowPieSelection={(chartOptions[report] && chartOptions[report].canAverage) || false}
          query={query} 
          title={title}
          lockScorecardOptions={lockScorecardOptions}
          handleSelection={
            update
          }
          handleChartType={chartType => {
            updateType(chartType);
          }}
          chartType={chartType}
          chartOptions={chartOptions}
          measure={measure}
        />
        </>
      );
    }
    return (
      <>
      <SelectableBarChart
        report={report}
        allowPieSelection={(chartOptions[report] && chartOptions[report].canAverage) || false}
        query={query}
        lockScorecardOptions={lockScorecardOptions}
        handleSelection={
          update
        }
        chartOptions={chartOptions}
        handleChartType={chartType => {
          updateType(chartType);
        }}
        chartType={chartType}
        measure={measure}
      />
      </>
    );
  };