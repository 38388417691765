import { CustomDashboardType, getMeasure } from "../CustomDashReports";

export const UsedVehicle: { [index: string]: CustomDashboardType } = {
  "UV F&I > SALES > UV Retail & Demo Units": {
  name: "UV F&I > SALES > UV Retail & Demo Units",
  department: "UV F&I",
  category: "UV SALES",
  label: "UV Retail & Demo Units",
  tableNumber: 41,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Units Retail & Demo | Used | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > SALES > Retail Units Sold": {
  name: "UV F&I > SALES > Retail Units Sold",
  department: "UV F&I",
  category: "UV SALES",
  label: "UV Retail Units Sold",
  tableNumber: 41,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Units Retail | Used | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > SALES > Demo Vehicle Units Sold": {
  name: "UV F&I > SALES > Demo Vehicle Units Sold",
  department: "UV F&I",
  category: "UV SALES",
  label: "UV Demo Vehicle Units Sold",
  tableNumber: 41,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Units Demo | Used | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > SALES > Trade Units Sold": {
  name: "UV F&I > SALES > Trade Units Sold",
  department: "UV F&I",
  category: "UV SALES",
  label: "UV Trade Units Sold",
  tableNumber: 41,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Units Trade | Used | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > SALES > Total Units Sold": {
  name: "UV F&I > SALES > Total Units Sold",
  department: "UV F&I",
  category: "UV SALES",
  label: "UV Total Units Sold",
  tableNumber: 41,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > In Dealer Units | Used | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > SALES > UV (Retail & Demo) : NV Ratio": {
  name: "UV F&I > SALES > UV (Retail & Demo) : NV Ratio",
  department: "UV F&I",
  category: "UV SALES",
  label: "UV (Retail & Demo) : NV Ratio",
  tableNumber: 41,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ratio Used : New rd | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > SALES > UV Sales": {
  name: "UV F&I > SALES > UV Sales",
  department: "UV F&I",
  category: "UV SALES",
  label: "UV Sales",
  tableNumber: 41,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | Used | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > SALES > UV F&I Sales": {
  name: "UV F&I > SALES > UV F&I Sales",
  department: "UV F&I",
  category: "UV SALES",
  label: "UV F&I Sales",
  tableNumber: 55,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > F&I Net Sales Used | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > SALES > UV Aftercare Sales": {
  name: "UV F&I > SALES > UV Aftercare Sales",
  department: "UV F&I",
  category: "UV SALES",
  label: "UV Aftercare Sales",
  tableNumber: 142,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Aftercare Net Sales Used | Aftercare | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > SALES > UV Sales incl. F&I & Aftercare": {
  name: "UV F&I > SALES > UV Sales incl. F&I & Aftercare",
  department: "UV F&I",
  category: "UV SALES",
  label: "UV Sales incl. F&I & Aftercare",
  tableNumber: 41,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Used Net Sales Used Aftercare Used F&I | Used | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > SALES > Breakeven Days": {
  name: "UV F&I > SALES > Breakeven Days",
  department: "UV F&I",
  category: "UV SALES",
  label: "UV Breakeven Days",
  tableNumber: 42,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Dept. Breakeven Days | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > SALES > Breakeven Units": {
  name: "UV F&I > SALES > Breakeven Units",
  department: "UV F&I",
  category: "UV SALES",
  label: "UV Breakeven Units",
  tableNumber: 42,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > NV Breakeven Units | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > SALES > SC Productivity": {
  name: "UV F&I > SALES > SC Productivity",
  department: "UV F&I",
  category: "UV SALES",
  label: "UV UV SC Productivity",
  tableNumber: 41,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Units per Productive | Used | All Brand | All Model | | AVG",
  canAverage: false
},
"UV F&I > SALES > Number of SC (Average)": {
  name: "UV F&I > SALES > Number of SC (Average)",
  department: "UV F&I",
  category: "UV SALES",
  label: "UV UV Number of SC (Average)",
  tableNumber: 41,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Productives | Used | All Brand | All Model | | AVG",
  canAverage: true
},
"UV F&I > SALES > UV Net Sales / Unit (excl. Acc)": {
  name: "UV F&I > SALES > UV Net Sales / Unit (excl. Acc)",
  department: "UV F&I",
  category: "UV SALES",
  label: "UV Net Sales / Unit (excl. Acc)",
  tableNumber: 41,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales (excl Acc) pu | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > SALES > Retail & Demo Sales / Retail & Demo Unit": {
  name: "UV F&I > SALES > Retail & Demo Sales / Retail & Demo Unit",
  department: "UV F&I",
  category: "UV SALES",
  label: "UV Retail & Demo Sales / Retail & Demo Unit",
  tableNumber: 41,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales (excl Trade) prdu | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > SALES > Retail Sales / Retail Unit": {
  name: "UV F&I > SALES > Retail Sales / Retail Unit",
  department: "UV F&I",
  category: "UV SALES",
  label: "UV Retail Sales / Retail Unit",
  tableNumber: 41,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Retail Net Sales pru | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > SALES > Demo Sales / Demo Unit": {
  name: "UV F&I > SALES > Demo Sales / Demo Unit",
  department: "UV F&I",
  category: "UV SALES",
  label: "UV Demo Sales / Demo Unit",
  tableNumber: 41,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Demo Net Sales pdu | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > SALES > Trade Sales / Trade Unit": {
  name: "UV F&I > SALES > Trade Sales / Trade Unit",
  department: "UV F&I",
  category: "UV SALES",
  label: "UV Trade Sales / Trade Unit",
  tableNumber: 41,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Trade Net Sales ptu | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > SALES > UV Finance Penetration": {
  name: "UV F&I > SALES > UV Finance Penetration",
  department: "UV F&I",
  category: "UV SALES",
  label: "UV Finance Penetration",
  tableNumber: 55,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > F&I Contracts 1st yr Used pu - Financed | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > SALES > UV Insurance Penetration": {
  name: "UV F&I > SALES > UV Insurance Penetration",
  department: "UV F&I",
  category: "UV SALES",
  label: "UV Insurance Penetration",
  tableNumber: 55,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > F&I Contracts 1st yr Used pu - Insurance | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > SALES > UV Extended Warranty Sales / Contract": {
  name: "UV F&I > SALES > UV Extended Warranty Sales / Contract",
  department: "UV F&I",
  category: "UV SALES",
  label: "UV Extended Warranty Sales / Contract",
  tableNumber: 142,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Aftercare Net Sales Used p warranty contract | Aftercare | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > UV MIX": {
  name: "UV F&I > MIX > UV MIX",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV MIX",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Used Gross Profit % of Sales (incl F&I & Aftercare) | UsedF&IAftercare | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > UV Sales incl. F&I & Aftercare": {
  name: "UV F&I > MIX > UV Sales incl. F&I & Aftercare",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV Sales incl. F&I & Aftercare",
  tableNumber: 41,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Used Net Sales Used Aftercare Used F&I | Used | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > MIX > UV Sales": {
  name: "UV F&I > MIX > UV Sales",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV Sales",
  tableNumber: 41,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | Used | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > MIX > UV F&I Sales": {
  name: "UV F&I > MIX > UV F&I Sales",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV F&I Sales",
  tableNumber: 55,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > F&I Gross Used | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > MIX > UV Aftercare Sales": {
  name: "UV F&I > MIX > UV Aftercare Sales",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV Aftercare Sales",
  tableNumber: 142,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Aftercare Gross Used | Aftercare | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > MIX > UV GP incl. F&I & Aftercare": {
  name: "UV F&I > MIX > UV GP incl. F&I & Aftercare",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV GP incl. F&I & Aftercare",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Used Gross Profit (incl F&I & Aftercare) | Used | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > MIX > UV GP": {
  name: "UV F&I > MIX > UV GP",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV GP",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | Used | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > MIX > UV F&I GP": {
  name: "UV F&I > MIX > UV F&I GP",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV F&I GP",
  tableNumber: 56,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > F&I Gross Used | F&I Dept | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > MIX > UV Aftercare GP": {
  name: "UV F&I > MIX > UV Aftercare GP",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV Aftercare GP",
  tableNumber: 143,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Aftercare Gross Used | Aftercare | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > MIX > Reconditioning Retail / Retail Unit": {
  name: "UV F&I > MIX > Reconditioning Retail / Retail Unit",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV Reconditioning Retail / Retail Unit",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > 1st > Reconditioning Retail pu | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > Direct Incentives / Retail & Demo Unit": {
  name: "UV F&I > MIX > Direct Incentives / Retail & Demo Unit",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV Direct Incentives / Retail & Demo Unit",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > 1st > Direct Incentives prdu | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > Retail & Demo 1st GP/ Retail & Demo Unit": {
  name: "UV F&I > MIX > Retail & Demo 1st GP/ Retail & Demo Unit",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV Retail & Demo 1st GP/ Retail & Demo Unit",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > 1st Gross Profit (excl Acc, Trade & Inc.) prdu | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > Retail 1st GP / Retail Unit": {
  name: "UV F&I > MIX > Retail 1st GP / Retail Unit",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV Retail 1st GP / Retail Unit",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Front End Gross Retail (excl Acc) pru | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > Demo 1st GP / Demo Unit": {
  name: "UV F&I > MIX > Demo 1st GP / Demo Unit",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV Demo 1st GP / Demo Unit",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Demo GP pdu | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > Trade 1st GP / Trade Unit": {
  name: "UV F&I > MIX > Trade 1st GP / Trade Unit",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV Trade 1st GP / Trade Unit",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > 1st Gross Trade (excl Acc) ptu | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > UV 1st GP / Unit": {
  name: "UV F&I > MIX > UV 1st GP / Unit",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV 1st GP / Unit",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > 1st Gross Profit (excl Acc) pu | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > UV Accessory GP / Unit": {
  name: "UV F&I > MIX > UV Accessory GP / Unit",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV Accessory GP / Unit",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Accessories GP pu | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > UV Indirect Incentives / Unit": {
  name: "UV F&I > MIX > UV Indirect Incentives / Unit",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV Indirect Incentives / Unit",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "OI > Indirect Incentives pu | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > UV GP / Unit": {
  name: "UV F&I > MIX > UV GP / Unit",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV GP / Unit",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit pu | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > UV F&I GP / Unit": {
  name: "UV F&I > MIX > UV F&I GP / Unit",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV F&I GP / Unit",
  tableNumber: 56,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > F&I Gross Used PUU | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > UV GP incl. F&I / Unit": {
  name: "UV F&I > MIX > UV GP incl. F&I / Unit",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV GP incl. F&I / Unit",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit (Used GP (incl Used F&I)) pu | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > UV Aftercare GP / Unit": {
  name: "UV F&I > MIX > UV Aftercare GP / Unit",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV Aftercare GP / Unit",
  tableNumber: 143,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Aftercare Gross Used pu | Aftercare | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > UV GP incl. F&I & Aftercare / Unit": {
  name: "UV F&I > MIX > UV GP incl. F&I & Aftercare / Unit",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV GP incl. F&I & Aftercare / Unit",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit Used Aftercare F&I pu | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > FEG % Gross Sales": {
  name: "UV F&I > MIX > FEG % Gross Sales",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV FEG % Gross Sales",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Front End Gross % of Gross Sales | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > Retail & Demo 1st GP % Retail & Demo Sales": {
  name: "UV F&I > MIX > Retail & Demo 1st GP % Retail & Demo Sales",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV Retail & Demo 1st GP % Retail & Demo Sales",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > 1st Gross Profit (excl Acc, Trade & Inc.) % of Net Sales | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > Retail 1st GP % Retail Sales": {
  name: "UV F&I > MIX > Retail 1st GP % Retail Sales",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV Retail 1st GP % Retail Sales",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Front End Gross Retail (excl Acc) % Net Retail sales | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > Demo 1st GP % Demo Sales": {
  name: "UV F&I > MIX > Demo 1st GP % Demo Sales",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV Demo 1st GP % Demo Sales",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Demo GP % Demo Sales | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > Trade 1st GP % Trade Sales": {
  name: "UV F&I > MIX > Trade 1st GP % Trade Sales",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV Trade 1st GP % Trade Sales",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Trade GP % Trade Sales | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > UV 1st GP % Net Sales": {
  name: "UV F&I > MIX > UV 1st GP % Net Sales",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV 1st GP % Net Sales",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > 1st Gross Profit (excl Acc) % of Net Sales | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > UV GP %": {
  name: "UV F&I > MIX > UV GP %",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV GP %",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit % of Sales (MIX) | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > UV GP % incl. F&I Aftercare": {
  name: "UV F&I > MIX > UV GP % incl. F&I Aftercare",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV GP % incl. F&I Aftercare",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Used Gross Profit % of Sales (incl F&I & Aftercare) | UsedF&IAftercare | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > UV GP % incl. F&I & Aftercare (excluding OEM Incentives)": {
  name: "UV F&I > MIX > UV GP % incl. F&I & Aftercare (excluding OEM Incentives)",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV GP % incl. F&I & Aftercare (excluding OEM Incentives)",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Used Gross Profit exc OEM Incentives % of Sales (incl F&I & Aftercare) | UsedF&IAftercare | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > UV OEM Incentives % GP": {
  name: "UV F&I > MIX > UV OEM Incentives % GP",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV OEM Incentives % GP",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > OEM Incentives % TGP UsedF&IAftercare | UsedF&IAftercare | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > UV Finance Penetration": {
  name: "UV F&I > MIX > UV Finance Penetration",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV Finance Penetration",
  tableNumber: 55,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > F&I Contracts 1st yr Used pu - Financed | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > MIX > UV Insurance Penetration": {
  name: "UV F&I > MIX > UV Insurance Penetration",
  department: "UV F&I",
  category: "UV GROSS PROFIT",
  label: "UV Insurance Penetration",
  tableNumber: 55,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > F&I Contracts 1st yr Used pu - Insurance | F&I Dept | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAINED > UV RETAINED": {
  name: "UV F&I > RETAINED > UV RETAINED",
  department: "UV F&I",
  category: "UV EXPENSES",
  label: "UV RETAINED",
  tableNumber: 44,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT % of TGP: RETAINED | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAINED > UV Total GP": {
  name: "UV F&I > RETAINED > UV Total GP",
  department: "UV F&I",
  category: "UV EXPENSES",
  label: "UV Total GP",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Used Gross Profit (incl F&I & Aftercare) | Used | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > RETAINED > UV Total Expenses": {
  name: "UV F&I > RETAINED > UV Total Expenses",
  department: "UV F&I",
  category: "UV EXPENSES",
  label: "UV Total Expenses",
  tableNumber: 44,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > RETAINED > UV Total PBT": {
  name: "UV F&I > RETAINED > UV Total PBT",
  department: "UV F&I",
  category: "UV EXPENSES",
  label: "UV Total PBT",
  tableNumber: 52,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT (Profit Before Tax) | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > RETAINED > Total Expenses % GP": {
  name: "UV F&I > RETAINED > Total Expenses % GP",
  department: "UV F&I",
  category: "UV EXPENSES",
  label: "UV Total Expenses % GP",
  tableNumber: 44,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses % of TGP | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAINED > Variable Expenses  % GP": {
  name: "UV F&I > RETAINED > Variable Expenses  % GP",
  department: "UV F&I",
  category: "UV EXPENSES",
  label: "UV Variable Expenses  % GP",
  tableNumber: 45,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses % of TGP | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAINED > Personnel  Expenses % GP": {
  name: "UV F&I > RETAINED > Personnel  Expenses % GP",
  department: "UV F&I",
  category: "UV EXPENSES",
  label: "UV Personnel  Expenses % GP",
  tableNumber: 46,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PE > Personnel Expenses % of TGP | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAINED > Other Semi Variable Expenses % GP": {
  name: "UV F&I > RETAINED > Other Semi Variable Expenses % GP",
  department: "UV F&I",
  category: "UV EXPENSES",
  label: "UV Other Semi Variable Expenses % GP",
  tableNumber: 47,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "SVE > Other Semi Variable Expenses % of TGP | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAINED > Fixed Expenses % GP": {
  name: "UV F&I > RETAINED > Fixed Expenses % GP",
  department: "UV F&I",
  category: "UV EXPENSES",
  label: "UV Fixed Expenses % GP",
  tableNumber: 48,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "FE > Fixed Expenses % of TGP | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAINED > Interest Expenses% Total Expenses": {
  name: "UV F&I > RETAINED > Interest Expenses% Total Expenses",
  department: "UV F&I",
  category: "UV EXPENSES",
  label: "UV Interest Expenses% Total Expenses",
  tableNumber: 49,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Interest % of TGP | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAINED > Allocation of Admin  Expenses % GP": {
  name: "UV F&I > RETAINED > Allocation of Admin  Expenses % GP",
  department: "UV F&I",
  category: "UV EXPENSES",
  label: "UV Allocation of Admin  Expenses % GP",
  tableNumber: 44,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Adm > Admin Exp. Allocation % of TGP | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAINED > Variable Expenses% Total Expenses": {
  name: "UV F&I > RETAINED > Variable Expenses% Total Expenses",
  department: "UV F&I",
  category: "UV EXPENSES",
  label: "UV Variable Expenses% Total Expenses",
  tableNumber: 45,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses % of Total Expenses | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAINED > Personnel Expenses% Total Expenses": {
  name: "UV F&I > RETAINED > Personnel Expenses% Total Expenses",
  department: "UV F&I",
  category: "UV EXPENSES",
  label: "UV Personnel Expenses% Total Expenses",
  tableNumber: 46,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PE > Personnel Expenses % of Total Expenses | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAINED > Other Semi Var.Exp.% Total Expenses": {
  name: "UV F&I > RETAINED > Other Semi Var.Exp.% Total Expenses",
  department: "UV F&I",
  category: "UV EXPENSES",
  label: "UV Other Semi Var.Exp.% Total Expenses",
  tableNumber: 47,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "SVE > Other Semi Variable Expenses % of Total Expenses | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAINED > Fixed Expenses% Total Expenses": {
  name: "UV F&I > RETAINED > Fixed Expenses% Total Expenses",
  department: "UV F&I",
  category: "UV EXPENSES",
  label: "UV Fixed Expenses% Total Expenses",
  tableNumber: 48,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "FE > Fixed Expenses % of Total Expenses | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAINED > Allocation of Admin Expenses % Total Expenses": {
  name: "UV F&I > RETAINED > Allocation of Admin Expenses % Total Expenses",
  department: "UV F&I",
  category: "UV EXPENSES",
  label: "UV Allocation of Admin Expenses % Total Expenses",
  tableNumber: 44,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Adm > Admin Exp. Allocation % of Total Expenses | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAINED > Variable Expenses / Unit": {
  name: "UV F&I > RETAINED > Variable Expenses / Unit",
  department: "UV F&I",
  category: "UV EXPENSES",
  label: "UV Variable Expenses / Unit",
  tableNumber: 45,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses pu | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAINED > Sales Commission / Unit": {
  name: "UV F&I > RETAINED > Sales Commission / Unit",
  department: "UV F&I",
  category: "UV EXPENSES",
  label: "UV Sales Commission / Unit",
  tableNumber: 45,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Sales Commission pu | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAINED > Other Variable / Unit": {
  name: "UV F&I > RETAINED > Other Variable / Unit",
  department: "UV F&I",
  category: "UV EXPENSES",
  label: "UV Other Variable / Unit",
  tableNumber: 45,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses (Excl Commission) pu | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAINED > Floor Plan Interest / Unit": {
  name: "UV F&I > RETAINED > Floor Plan Interest / Unit",
  department: "UV F&I",
  category: "UV EXPENSES",
  label: "UV Floor Plan Interest / Unit",
  tableNumber: 49,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Floorplan Interest pu | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAINED > Used F&I Aftercare Cost Recovery": {
  name: "UV F&I > RETAINED > Used F&I Aftercare Cost Recovery",
  department: "UV F&I",
  category: "UV EXPENSES",
  label: "Used F&I Aftercare Cost Recovery",
  tableNumber: 172,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Cost Recovery | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAINED > Used Cost Recovery": {
  name: "UV F&I > RETAINED > Used Cost Recovery",
  department: "UV F&I",
  category: "UV EXPENSES",
  label: "Used Cost Recovery",
  tableNumber: 172,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Cost Recovery | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > ACTIVITY > UV ACTIVITY": {
  name: "UV F&I > ACTIVITY > UV ACTIVITY",
  department: "UV F&I",
  category: "UV OPERATIONAL ASSETS",
  label: "UV ACTIVITY",
  tableNumber: 50,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Asset Activity | UsedAftercareF&ISplit | All Brand | All Model | | AVG",
  canAverage: false
},
"UV F&I > ACTIVITY > UV Annualised Sales": {
  name: "UV F&I > ACTIVITY > UV Annualised Sales",
  department: "UV F&I",
  category: "UV OPERATIONAL ASSETS",
  label: "UV Annualised Sales",
  tableNumber: 41,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Projected Annualised Sales | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > ACTIVITY > UV Average Operational Assets": {
  name: "UV F&I > ACTIVITY > UV Average Operational Assets",
  department: "UV F&I",
  category: "UV OPERATIONAL ASSETS",
  label: "UV Average Operational Assets",
  tableNumber: 50,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Average Operational Assets | UsedAftercareF&ISplit | All Brand | All Model | | AVG",
  canAverage: true
},
"UV F&I > ACTIVITY > UV ROOA": {
  name: "UV F&I > ACTIVITY > UV ROOA",
  department: "UV F&I",
  category: "UV OPERATIONAL ASSETS",
  label: "UV ROOA",
  tableNumber: 50,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Return on Operational Assets (ROOA) | UsedAftercareF&ISplit | All Brand | All Model | | AVG",
  canAverage: false
},
"UV F&I > ACTIVITY > UV ROSI": {
  name: "UV F&I > ACTIVITY > UV ROSI",
  department: "UV F&I",
  category: "UV OPERATIONAL ASSETS",
  label: "UV ROSI",
  tableNumber: 50,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Return on Stock Investment (ROSI) | UsedAftercareF&ISplit | All Brand | All Model | | AVG",
  canAverage: false
},
"UV F&I > ACTIVITY > UV Inventory Days": {
  name: "UV F&I > ACTIVITY > UV Inventory Days",
  department: "UV F&I",
  category: "UV OPERATIONAL ASSETS",
  label: "UV Inventory Days",
  tableNumber: 50,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Inventory days | Used | All Brand | All Model | | AVG",
  canAverage: false
},
"UV F&I > ACTIVITY > Retail Inventory Days": {
  name: "UV F&I > ACTIVITY > Retail Inventory Days",
  department: "UV F&I",
  category: "UV OPERATIONAL ASSETS",
  label: "Retail Inventory Days",
  tableNumber: 50,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Inventory days (Excl Demo) | Used | All Brand | All Model | | AVG",
  canAverage: false
},
"UV F&I > ACTIVITY > Demo Inventory Days": {
  name: "UV F&I > ACTIVITY > Demo Inventory Days",
  department: "UV F&I",
  category: "UV OPERATIONAL ASSETS",
  label: "Demo Inventory Days",
  tableNumber: 50,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Inventory days Demo Inventory | Used | All Brand | All Model | | AVG",
  canAverage: false
},
"UV F&I > ACTIVITY > UV Inventory Units (ending)": {
  name: "UV F&I > ACTIVITY > UV Inventory Units (ending)",
  department: "UV F&I",
  category: "UV OPERATIONAL ASSETS",
  label: "UV Inventory Units (ending)",
  tableNumber: 50,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Inventory Units | Used | All Brand | All Model | | CLOSE",
  canAverage: true
},
"UV F&I > ACTIVITY > UV Inventory Value (ending)": {
  name: "UV F&I > ACTIVITY > UV Inventory Value (ending)",
  department: "UV F&I",
  category: "UV OPERATIONAL ASSETS",
  label: "UV Inventory Value (ending)",
  tableNumber: 50,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Inventory Value | Used | All Brand | All Model | | CLOSE",
  canAverage: true
},
"UV F&I > ACTIVITY > UV Inventory Value 0-30 days mix": {
  name: "UV F&I > ACTIVITY > UV Inventory Value 0-30 days mix",
  department: "UV F&I",
  category: "UV OPERATIONAL ASSETS",
  label: "UV Inventory Value 0-30 days mix",
  tableNumber: 50,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Inventory Value > 0 - 30 days MIX | Used | All Brand | All Model | | CLOSE",
  canAverage: false
},
"UV F&I > ACTIVITY > UV Inventory Value 31-60 days mix": {
  name: "UV F&I > ACTIVITY > UV Inventory Value 31-60 days mix",
  department: "UV F&I",
  category: "UV OPERATIONAL ASSETS",
  label: "UV Inventory Value 31-60 days mix",
  tableNumber: 50,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Inventory Value > 31 - 60 days MIX | Used | All Brand | All Model | | CLOSE",
  canAverage: false
},
"UV F&I > ACTIVITY > UV Inventory Value 61-90 days mix": {
  name: "UV F&I > ACTIVITY > UV Inventory Value 61-90 days mix",
  department: "UV F&I",
  category: "UV OPERATIONAL ASSETS",
  label: "UV Inventory Value 61-90 days mix",
  tableNumber: 50,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Inventory Value > 61 - 90 days MIX | Used | All Brand | All Model | | CLOSE",
  canAverage: false
},
"UV F&I > ACTIVITY > UV Inventory Value >90 days mix": {
  name: "UV F&I > ACTIVITY > UV Inventory Value >90 days mix",
  department: "UV F&I",
  category: "UV OPERATIONAL ASSETS",
  label: "UV Inventory Value >90 days mix",
  tableNumber: 50,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Inventory Value > 90+ days MIX | Used | All Brand | All Model | | CLOSE",
  canAverage: false
},
"UV F&I > ACTIVITY > Floor Plan Interest % UV GP": {
  name: "UV F&I > ACTIVITY > Floor Plan Interest % UV GP",
  department: "UV F&I",
  category: "UV OPERATIONAL ASSETS",
  label: "UV Floor Plan Interest % UV GP",
  tableNumber: 49,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Floorplan Interest % of Gross Profit | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > ACTIVITY > Floor Plan Interest / Unit": {
  name: "UV F&I > ACTIVITY > Floor Plan Interest / Unit",
  department: "UV F&I",
  category: "UV OPERATIONAL ASSETS",
  label: "UV Floor Plan Interest / Unit",
  tableNumber: 49,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Floorplan Interest pu | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > ROOA > ROOA incl. F&I & Aftercare": {
  name: "UV F&I > ROOA > ROOA incl. F&I & Aftercare",
  department: "UV F&I",
  category: "UV MRA MODEL",
  label: "UV ROOA incl. F&I & Aftercare",
  tableNumber: 51,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Return on Operational Assets (ROOA) | UsedAftercareF&ISplit | All Brand | All Model | | AVG",
  canAverage: false
},
"UV F&I > ROOA > ROOA UV only": {
  name: "UV F&I > ROOA > ROOA UV only",
  department: "UV F&I",
  category: "UV MRA MODEL",
  label: "ROOA UV only",
  tableNumber: 51,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Return on Operational Assets (ROOA) | Used | All Brand | All Model | | AVG",
  canAverage: false
},
"UV F&I > ROOA > MIX": {
  name: "UV F&I > ROOA > MIX",
  department: "UV F&I",
  category: "UV MRA MODEL",
  label: "UV MIX",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit % of Sales (MIX) | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > ROOA > RETAINED": {
  name: "UV F&I > ROOA > RETAINED",
  department: "UV F&I",
  category: "UV MRA MODEL",
  label: "UV RETAINED",
  tableNumber: 44,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT % of TGP: RETAINED | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > ROOA > ACTIVITY": {
  name: "UV F&I > ROOA > ACTIVITY",
  department: "UV F&I",
  category: "UV MRA MODEL",
  label: "UV ACTIVITY",
  tableNumber: 50,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Asset Activity | UsedAftercareF&ISplit | All Brand | All Model | | AVG",
  canAverage: false
},
"UV F&I > ROOA > Gross profit": {
  name: "UV F&I > ROOA > Gross profit",
  department: "UV F&I",
  category: "UV MRA MODEL",
  label: "UV Gross profit",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Gross Profit | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > ROOA > Revenue": {
  name: "UV F&I > ROOA > Revenue",
  department: "UV F&I",
  category: "UV MRA MODEL",
  label: "UV Revenue",
  tableNumber: 41,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Net Sales | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > ROOA > Expenses": {
  name: "UV F&I > ROOA > Expenses",
  department: "UV F&I",
  category: "UV MRA MODEL",
  label: "UV Expenses",
  tableNumber: 44,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > ROOA > Annualised Sales": {
  name: "UV F&I > ROOA > Annualised Sales",
  department: "UV F&I",
  category: "UV MRA MODEL",
  label: "UV Annualised Sales",
  tableNumber: 50,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Projected Annualised Sales | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > ROOA > Average Operational Assets": {
  name: "UV F&I > ROOA > Average Operational Assets",
  department: "UV F&I",
  category: "UV MRA MODEL",
  label: "UV Average Operational Assets",
  tableNumber: 50,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Average Operational Assets | UsedAftercareF&ISplit | All Brand | All Model | | AVG",
  canAverage: true
},
"UV F&I > ROOA > ROS": {
  name: "UV F&I > ROOA > ROS",
  department: "UV F&I",
  category: "UV MRA MODEL",
  label: "UV ROS",
  tableNumber: 52,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT Profit Before Tax % of Sales (UsedF&IAftercare) | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > ROS (Income Statement) > ROS": {
  name: "UV F&I > ROS (Income Statement) > ROS",
  department: "UV F&I",
  category: "UV ROS (Income Statement)",
  label: "UV ROS",
  tableNumber: 52,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT Profit Before Tax % of Sales (UsedF&IAftercare) | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > ROS (Income Statement) > Sales": {
  name: "UV F&I > ROS (Income Statement) > Sales",
  department: "UV F&I",
  category: "UV ROS (Income Statement)",
  label: "UV Sales",
  tableNumber: 41,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Used Net Sales Used Aftercare Used F&I | Used | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > ROS (Income Statement) > PBT": {
  name: "UV F&I > ROS (Income Statement) > PBT",
  department: "UV F&I",
  category: "UV ROS (Income Statement)",
  label: "UV PBT",
  tableNumber: 52,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBT (Profit Before Tax) | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > ROS (Income Statement) > Dept. Profit % Net Sales": {
  name: "UV F&I > ROS (Income Statement) > Dept. Profit % Net Sales",
  department: "UV F&I",
  category: "UV ROS (Income Statement)",
  label: "UV Dept. Profit % Net Sales",
  tableNumber: 52,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "E > Earnings After Interest % of TNS (UsedF&IAftercare) | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > ROS (Income Statement) > EBIT% (PBIT%)": {
  name: "UV F&I > ROS (Income Statement) > EBIT% (PBIT%)",
  department: "UV F&I",
  category: "UV ROS (Income Statement)",
  label: "UV EBIT% (PBIT%)",
  tableNumber: 52,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBIT % of Sales | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > ROS (Income Statement) > Variable Selling Gross % Gorss Profit": {
  name: "UV F&I > ROS (Income Statement) > Variable Selling Gross % Gorss Profit",
  department: "UV F&I",
  category: "UV ROS (Income Statement)",
  label: "UV Variable Selling Gross % Gorss Profit",
  tableNumber: 45,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Variable Selling Gross % of TGP | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > ROS (Income Statement) > Selling Gross % Gross Profit": {
  name: "UV F&I > ROS (Income Statement) > Selling Gross % Gross Profit",
  department: "UV F&I",
  category: "UV ROS (Income Statement)",
  label: "UV Selling Gross % Gross Profit",
  tableNumber: 52,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Selling Gross % of TGP | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > ROS (Income Statement) > Selling Gross % Net Sales": {
  name: "UV F&I > ROS (Income Statement) > Selling Gross % Net Sales",
  department: "UV F&I",
  category: "UV ROS (Income Statement)",
  label: "UV Selling Gross % Net Sales",
  tableNumber: 52,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Selling Gross % of TNS | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > ROS (Income Statement) > Gross Profit": {
  name: "UV F&I > ROS (Income Statement) > Gross Profit",
  department: "UV F&I",
  category: "UV ROS (Income Statement)",
  label: "UV Gross Profit",
  tableNumber: 43,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Used Gross Profit (incl F&I & Aftercare) | Used | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > ROS (Income Statement) > Expenses": {
  name: "UV F&I > ROS (Income Statement) > Expenses",
  department: "UV F&I",
  category: "UV ROS (Income Statement)",
  label: "UV Expenses",
  tableNumber: 44,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TE > Total Expenses | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > ROS (Income Statement) > Variable Expenses": {
  name: "UV F&I > ROS (Income Statement) > Variable Expenses",
  department: "UV F&I",
  category: "UV ROS (Income Statement)",
  label: "UV Variable Expenses",
  tableNumber: 45,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "VE > Variable Expenses | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > ROS (Income Statement) > Personnel Expenses": {
  name: "UV F&I > ROS (Income Statement) > Personnel Expenses",
  department: "UV F&I",
  category: "UV ROS (Income Statement)",
  label: "UV Personnel Expenses",
  tableNumber: 46,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PE > Personnel Expenses | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > ROS (Income Statement) > Other Semi Variable Expenses": {
  name: "UV F&I > ROS (Income Statement) > Other Semi Variable Expenses",
  department: "UV F&I",
  category: "UV ROS (Income Statement)",
  label: "UV Other Semi Variable Expenses",
  tableNumber: 47,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "SVE > Other Semi Variable Expenses | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > ROS (Income Statement) > Fixed Expenses": {
  name: "UV F&I > ROS (Income Statement) > Fixed Expenses",
  department: "UV F&I",
  category: "UV ROS (Income Statement)",
  label: "UV Fixed Expenses",
  tableNumber: 48,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "FE > Fixed Expenses | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > ROS (Income Statement) > Interest Expenses": {
  name: "UV F&I > ROS (Income Statement) > Interest Expenses",
  department: "UV F&I",
  category: "UV ROS (Income Statement)",
  label: "UV Interest Expenses",
  tableNumber: 49,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "I > Interest | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > ROS (Income Statement) > Departmental Profit": {
  name: "UV F&I > ROS (Income Statement) > Departmental Profit",
  department: "UV F&I",
  category: "UV ROS (Income Statement)",
  label: "UV Departmental Profit",
  tableNumber: 52,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "E > Earnings After Interest (EAIT) | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > ROS (Income Statement) > Allocation of Admin Expenses": {
  name: "UV F&I > ROS (Income Statement) > Allocation of Admin Expenses",
  department: "UV F&I",
  category: "UV ROS (Income Statement)",
  label: "UV Allocation of Admin Expenses",
  tableNumber: 44,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Adm > Admin Exp. Allocation | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > ROS (Income Statement) > EBIT": {
  name: "UV F&I > ROS (Income Statement) > EBIT",
  department: "UV F&I",
  category: "UV ROS (Income Statement)",
  label: "UV EBIT",
  tableNumber: 52,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "PBT > PBIT (Profit Before Interest & Tax) | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > PRODUCTIVITY > SC Productivity": {
  name: "UV F&I > PRODUCTIVITY > SC Productivity",
  department: "UV F&I",
  category: "UV PRODUCTIVITY",
  label: "UV SC Productivity",
  tableNumber: 53,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Units per Productive | Used | All Brand | All Model | | AVG",
  canAverage: false
},
"UV F&I > PRODUCTIVITY > UV Units Sold": {
  name: "UV F&I > PRODUCTIVITY > UV Units Sold",
  department: "UV F&I",
  category: "UV PRODUCTIVITY",
  label: "UV Units Sold",
  tableNumber: 53,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > In Dealer Units | Used | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > PRODUCTIVITY > UV Productive Staff": {
  name: "UV F&I > PRODUCTIVITY > UV Productive Staff",
  department: "UV F&I",
  category: "UV PRODUCTIVITY",
  label: "UV Productive Staff",
  tableNumber: 53,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Productives | Used | All Brand | All Model | | AVG",
  canAverage: true
},
"UV F&I > PRODUCTIVITY > GP / Productive / Month": {
  name: "UV F&I > PRODUCTIVITY > GP / Productive / Month",
  department: "UV F&I",
  category: "UV PRODUCTIVITY",
  label: "UV GP / Productive / Month",
  tableNumber: 53,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ave Monthly TGP per Productive | UsedAftercareF&ISplit | All Brand | All Model | | AVG",
  canAverage: false
},
"UV F&I > PRODUCTIVITY > GP / Employee / Month": {
  name: "UV F&I > PRODUCTIVITY > GP / Employee / Month",
  department: "UV F&I",
  category: "UV PRODUCTIVITY",
  label: "UV GP / Employee / Month",
  tableNumber: 53,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ave Monthly TGP per Employee | UsedAftercareF&ISplit | All Brand | All Model | | AVG",
  canAverage: false
},
"UV F&I > PRODUCTIVITY > Total Staff": {
  name: "UV F&I > PRODUCTIVITY > Total Staff",
  department: "UV F&I",
  category: "UV PRODUCTIVITY",
  label: "UV Total Staff",
  tableNumber: 53,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Total Employees | UsedAftercareF&ISplit | All Brand | All Model | | AVG",
  canAverage: true
},
"UV F&I > PRODUCTIVITY > UV Non-Productive Staff": {
  name: "UV F&I > PRODUCTIVITY > UV Non-Productive Staff",
  department: "UV F&I",
  category: "UV PRODUCTIVITY",
  label: "UV Non-Productive Staff",
  tableNumber: 53,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Non-Productives | Used | All Brand | All Model | | AVG",
  canAverage: true
},
"UV F&I > PRODUCTIVITY > F&I Staff Allocation": {
  name: "UV F&I > PRODUCTIVITY > F&I Staff Allocation",
  department: "UV F&I",
  category: "UV PRODUCTIVITY",
  label: "F&I Staff Allocation UV",
  tableNumber: 66,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > F&I Allocation Used | F&I Dept | All Brand | All Model | | AVG",
  canAverage: true
},
"UV F&I > PRODUCTIVITY > Afterstaff Staff Allocation": {
  name: "UV F&I > PRODUCTIVITY > Afterstaff Staff Allocation",
  department: "UV F&I",
  category: "UV PRODUCTIVITY",
  label: "Afterstaff Staff Allocation UV",
  tableNumber: 153,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "Staff > Aftercare Allocation Used | Aftercare | All Brand | All Model | | AVG",
  canAverage: true
},
"UV F&I > PRODUCTIVITY > UV Ratio Productive : Non Productive": {
  name: "UV F&I > PRODUCTIVITY > UV Ratio Productive : Non Productive",
  department: "UV F&I",
  category: "UV PRODUCTIVITY",
  label: "UV Ratio Productive : Non Productive",
  tableNumber: 53,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ratio Productive to Non-Productive staff | UsedAftercareF&ISplit | All Brand | All Model | | AVG",
  canAverage: false
},
"UV F&I > PRODUCTIVITY > UV Ratio Productive : Non Productive (excl. F&I Aftercare)": {
  name: "UV F&I > PRODUCTIVITY > UV Ratio Productive : Non Productive (excl. F&I Aftercare)",
  department: "UV F&I",
  category: "UV PRODUCTIVITY",
  label: "UV Ratio Productive : Non Productive (excl. F&I Aftercare)",
  tableNumber: 53,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ratio Productive to Non-Productive staff | Used | All Brand | All Model | | AVG",
  canAverage: false
},
"UV F&I > RETAIL > UV (Retail & Demo) : NV Ratio": {
  name: "UV F&I > RETAIL > UV (Retail & Demo) : NV Ratio",
  department: "UV F&I",
  category: "UV RETAIL",
  label: "UV (Retail & Demo) : NV Ratio",
  tableNumber: 54,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Ratio Used : New rd | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAIL > UV Retail & Demo Units Sold": {
  name: "UV F&I > RETAIL > UV Retail & Demo Units Sold",
  department: "UV F&I",
  category: "UV RETAIL",
  label: "UV Retail & Demo Units Sold",
  tableNumber: 54,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Units Retail & Demo | Used | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > RETAIL > NV Units Sold": {
  name: "UV F&I > RETAIL > NV Units Sold",
  department: "UV F&I",
  category: "UV RETAIL",
  label: "NV Units Sold",
  tableNumber: 54,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > In Dealer Units | New | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > RETAIL > Retail Units Sold": {
  name: "UV F&I > RETAIL > Retail Units Sold",
  department: "UV F&I",
  category: "UV RETAIL",
  label: "UV Retail Units Sold",
  tableNumber: 54,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "STAT > Units Retail | Used | All Brand | All Model | | SUM",
  canAverage: true
},
"UV F&I > RETAIL > UV Retail Sales / UV Retail Unit": {
  name: "UV F&I > RETAIL > UV Retail Sales / UV Retail Unit",
  department: "UV F&I",
  category: "UV RETAIL",
  label: "UV Retail Sales / UV Retail Unit",
  tableNumber: 54,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "TNS > Retail Net Sales pru | Used | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAIL > UV Retail Reconditioning / UV Retail Unit": {
  name: "UV F&I > RETAIL > UV Retail Reconditioning / UV Retail Unit",
  department: "UV F&I",
  category: "UV RETAIL",
  label: "UV Retail Reconditioning /  UV Retail Unit",
  tableNumber: 54,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > 1st > Reconditioning Retail pu | Used Retail | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAIL > UV Retail First Gross / UV Retail Unit": {
  name: "UV F&I > RETAIL > UV Retail First Gross / UV Retail Unit",
  department: "UV F&I",
  category: "UV RETAIL",
  label: "UV Retail First Gross /   UV Retail Unit",
  tableNumber: 54,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > 1st Gross Profit (excl Acc) pru | Used Retail | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAIL > FEG % Gross Sales": {
  name: "UV F&I > RETAIL > FEG % Gross Sales",
  department: "UV F&I",
  category: "UV RETAIL",
  label: "UV FEG % Gross Sales",
  tableNumber: 54,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > Front End Gross % of Gross Sales | Used Retail | All Brand | All Model | | SUM",
  canAverage: false
},
"UV F&I > RETAIL > First Gross % Net Sales": {
  name: "UV F&I > RETAIL > First Gross % Net Sales",
  department: "UV F&I",
  category: "UV RETAIL",
  label: "UV First Gross % Net Sales",
  tableNumber: 54,
 getMeasure: (label, measures) => getMeasure(label, measures),
  measure: "G > 1st Gross Profit (excl Acc) % of Net Sales | Used Retail | All Brand | All Model | | SUM",
  canAverage: false
},
};

export const UsedVehicleOEMSupportsAnalysis: { [index: string]: CustomDashboardType } = {
  "UV F&I > OEM Supports Analysis > UV Support from OEM": {
  name: "UV F&I > OEM Supports Analysis > UV Support from OEM",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV Support from OEM",
  measure: "G > OEM Incentives & Expenses Subsidy | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: true
},
"UV F&I > OEM Supports Analysis > UV OEM Incentives": {
  name: "UV F&I > OEM Supports Analysis > UV OEM Incentives",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV OEM Incentives",
  measure: "G > OEM Incentives | Used | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: true
},
"UV F&I > OEM Supports Analysis > UV OEM Direct Incentives": {
  name: "UV F&I > OEM Supports Analysis > UV OEM Direct Incentives",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV OEM Direct Incentives",
  measure: "G > 1st > Direct Incentives | Used | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: true
},
"UV F&I > OEM Supports Analysis > UV OEM Indirect Incentives": {
  name: "UV F&I > OEM Supports Analysis > UV OEM Indirect Incentives",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV OEM Indirect Incentives",
  measure: "OI > Incentives | Used | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: true
},
"UV F&I > OEM Supports Analysis > UV OEM Expenses Subsidy": {
  name: "UV F&I > OEM Supports Analysis > UV OEM Expenses Subsidy",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV OEM Expenses Subsidy",
  measure: "TE > OEM Expenses Subsidy | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: true
},
"UV F&I > OEM Supports Analysis > UV OEM Direct Advertising Subsidy": {
  name: "UV F&I > OEM Supports Analysis > UV OEM Direct Advertising Subsidy",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV OEM Direct Advertising Subsidy",
  measure: "VE > Advertising Subsidy | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: true
},
"UV F&I > OEM Supports Analysis > UV OEM Indirect Advertising Subsidy": {
  name: "UV F&I > OEM Supports Analysis > UV OEM Indirect Advertising Subsidy",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV OEM Indirect Advertising Subsidy",
  measure: "SVE > Indirect Advertising Subsidy | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: true
},
"UV F&I > OEM Supports Analysis > UV OEM Floor Plan Interest Subsidy": {
  name: "UV F&I > OEM Supports Analysis > UV OEM Floor Plan Interest Subsidy",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV OEM Floor Plan Interest Subsidy",
  measure: "I > Floorplan Interest Subsidy | Used | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: true
},
"UV F&I > OEM Supports Analysis > UV OEM Incentives % TGP": {
  name: "UV F&I > OEM Supports Analysis > UV OEM Incentives % TGP",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV OEM Incentives % TGP",
  measure: "G > OEM Incentives % TGP UsedF&IAftercare | UsedF&IAftercare | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: false
},
"UV F&I > OEM Supports Analysis > UV OEM Incentives % Sales": {
  name: "UV F&I > OEM Supports Analysis > UV OEM Incentives % Sales",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV OEM Incentives % Sales",
  measure: "G > OEM Incentives % Net Sales | UsedF&IAftercare | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: false
},
"UV F&I > OEM Supports Analysis > UV OEM Expenses Subsidy % Sales": {
  name: "UV F&I > OEM Supports Analysis > UV OEM Expenses Subsidy % Sales",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV OEM Expenses Subsidy % Sales",
  measure: "TE > OEM Expenses Subsidy % Net Sales | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: false
},
"UV F&I > OEM Supports Analysis > UV OEM All Supports % Sales": {
  name: "UV F&I > OEM Supports Analysis > UV OEM All Supports % Sales",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV OEM All Supports % Sales",
  measure: "G > OEM Incentives & Expenses Subsidy % Net Sales | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: false
},
"UV F&I > OEM Supports Analysis > UV MIX": {
  name: "UV F&I > OEM Supports Analysis > UV MIX",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV MIX",
  measure: "G > Used Gross Profit % of Sales (incl F&I & Aftercare) | UsedF&IAftercare | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: false
},
"UV F&I > OEM Supports Analysis > UV MIX (excl Incentive)": {
  name: "UV F&I > OEM Supports Analysis > UV MIX (excl Incentive)",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV MIX (excl Incentive)",
  measure: "G > Used Gross Profit exc OEM Incentives % of Sales (incl F&I & Aftercare) | UsedF&IAftercare | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: false
},
"UV F&I > OEM Supports Analysis > UV RETAINED": {
  name: "UV F&I > OEM Supports Analysis > UV RETAINED",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV RETAINED",
  measure: "PBT > PBT % of TGP: RETAINED | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: false
},
"UV F&I > OEM Supports Analysis > UV RETAINED (excl All Supports from OEM)": {
  name: "UV F&I > OEM Supports Analysis > UV RETAINED (excl All Supports from OEM)",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV RETAINED (excl All Supports from OEM)",
  measure: "PBT > PBT % of TGP: RETAINED (excl OEM Incentives & Expenses Subsidy) | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: false
},
"UV F&I > OEM Supports Analysis > UV RETAINED (excl Incentives)": {
  name: "UV F&I > OEM Supports Analysis > UV RETAINED (excl Incentives)",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV RETAINED (excl Incentives)",
  measure: "PBT > PBT % of TGP: RETAINED (excl Incentive) | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: false
},
"UV F&I > OEM Supports Analysis > UV RETAINED (excl OEM Expenses Subsidy)": {
  name: "UV F&I > OEM Supports Analysis > UV RETAINED (excl OEM Expenses Subsidy)",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV RETAINED (excl OEM Expenses Subsidy)",
  measure: "PBT > PBT % of TGP: RETAINED (excl OEM Expenses Subsidy) | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: false
},
"UV F&I > OEM Supports Analysis > UV Expenses % TGP": {
  name: "UV F&I > OEM Supports Analysis > UV Expenses % TGP",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV Expenses % TGP",
  measure: "TE > Total Expenses % of TGP | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: false
},
"UV F&I > OEM Supports Analysis > UV Expenses % TGP (excl All Supports from OEM)": {
  name: "UV F&I > OEM Supports Analysis > UV Expenses % TGP (excl All Supports from OEM)",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV Expenses % TGP (excl All Supports from OEM)",
  measure: "TE > Total Expenses % of TGP (excl OEM Incentives & Expenses Subsidy) | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: false
},
"UV F&I > OEM Supports Analysis > UV Expenses % TGP (excl Incentives)": {
  name: "UV F&I > OEM Supports Analysis > UV Expenses % TGP (excl Incentives)",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV Expenses % TGP (excl Incentives)",
  measure: "TE > Total Expenses % of TGP (excl Incentive) | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: false
},
"UV F&I > OEM Supports Analysis > UV Expenses % TGP (excl OEM Expenses Subsidy)": {
  name: "UV F&I > OEM Supports Analysis > UV Expenses % TGP (excl OEM Expenses Subsidy)",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV Expenses % TGP (excl OEM Expenses Subsidy)",
  measure: "TE > Total Expenses % of TGP (excl OEM Expenses Subsidy) | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: false
},
"UV F&I > OEM Supports Analysis > UV ROS": {
  name: "UV F&I > OEM Supports Analysis > UV ROS",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV ROS",
  measure: "PBT > PBT % of Sales | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: false
},
"UV F&I > OEM Supports Analysis > UV ROS (excl All Supports from OEM)": {
  name: "UV F&I > OEM Supports Analysis > UV ROS (excl All Supports from OEM)",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV ROS (excl All Supports from OEM)",
  measure: "PBT > PBT (excl OEM Incentives & Expenses Subsidy) % of Sales | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: false
},
"UV F&I > OEM Supports Analysis > UV ROS (excl Incentives)": {
  name: "UV F&I > OEM Supports Analysis > UV ROS (excl Incentives)",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV ROS (excl Incentives)",
  measure: "PBT > PBT (excl OEM Incentive) % of Sales | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: false
},
"UV F&I > OEM Supports Analysis > UV ROS (excl OEM Expenses Subsidy)": {
  name: "UV F&I > OEM Supports Analysis > UV ROS (excl OEM Expenses Subsidy)",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV ROS (excl OEM Expenses Subsidy)",
  measure: "PBT > PBT (excl OEM Expenses Subsidy) % of Sales | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: false
},
"UV F&I > OEM Supports Analysis > UV Sales": {
  name: "UV F&I > OEM Supports Analysis > UV Sales",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV Sales",
  measure: "TNS > Used Net Sales Used Aftercare Used F&I | Used | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: true
},
"UV F&I > OEM Supports Analysis > UV GP": {
  name: "UV F&I > OEM Supports Analysis > UV GP",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV GP",
  measure: "G > Used Gross Profit (incl F&I & Aftercare) | Used | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: true
},
"UV F&I > OEM Supports Analysis > UV GP (excl Incentives)": {
  name: "UV F&I > OEM Supports Analysis > UV GP (excl Incentives)",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV GP (excl Incentives)",
  measure: "G > Used Gross Profit exc OEM incentive (incl F&I & Aftercare) | Used | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: true
},
"UV F&I > OEM Supports Analysis > UV Expenses": {
  name: "UV F&I > OEM Supports Analysis > UV Expenses",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV Expenses",
  measure: "TE > Total Expenses | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: true
},
"UV F&I > OEM Supports Analysis > UV Expenses (excl OEM Expenses Subsidy)": {
  name: "UV F&I > OEM Supports Analysis > UV Expenses (excl OEM Expenses Subsidy)",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV Expenses (excl OEM Expenses Subsidy)",
  measure: "TE > Total Expenses (excl Expenses Subsidy) | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: true
},
"UV F&I > OEM Supports Analysis > UV PBT": {
  name: "UV F&I > OEM Supports Analysis > UV PBT",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV PBT",
  measure: "PBT > PBT (Profit Before Tax) | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: true
},
"UV F&I > OEM Supports Analysis > UV PBT (excl All Supports from OEM)": {
  name: "UV F&I > OEM Supports Analysis > UV PBT (excl All Supports from OEM)",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV PBT (excl All Supports from OEM)",
  measure: "PBT > PBT (excl OEM Incentives & Expenses Subsidy) | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: true
},
"UV F&I > OEM Supports Analysis > UV PBT (excl Incentives)": {
  name: "UV F&I > OEM Supports Analysis > UV PBT (excl Incentives)",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV PBT (excl Incentives)",
  measure: "PBT > PBT (excl OEM Incentive) | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: true
},
"UV F&I > OEM Supports Analysis > UV PBT (excl OEM Expenses Subsidy)": {
  name: "UV F&I > OEM Supports Analysis > UV PBT (excl OEM Expenses Subsidy)",
  department: "UV F&I",
  category: "OEM Supports Analysis",
  label: "UV PBT (excl OEM Expenses Subsidy)",
  measure: "PBT > PBT (excl OEM Expenses Subsidy) | UsedAftercareF&ISplit | All Brand | All Model | | SUM",
 getMeasure: (label, measures) => getMeasure(label, measures),
  tableNumber: 528,
  canAverage: true
},
};
