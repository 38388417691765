import React, { createContext, useState, useReducer, useMemo } from "react";
import { Series, Report, Query, stringifyQuery, customSeriesFor, customStackedFor } from "../../lib/api/charts";
import { Loading } from "../base";
import { CustomDashboardMeasures } from "../../charts/CustomDashReports";

type SeriesSet = { series: Series; loading: Loading; custom: any };

export type CustomChartContextState = {
  series: {
    [index: string]: SeriesSet;
  };
  seriesStacked: {
    [index: string]: any;
  };
  getCustomSeries(report: Report, query: Query, custom: any): Partial<SeriesSet>;
  getCustomSeriesStacked(report: Report, query: Query, custom: any): Partial<any>;
};

export const CustomChartContext = createContext<CustomChartContextState>({
  series: {},
  seriesStacked: {},
  getCustomSeries: () => ({}),
  getCustomSeriesStacked: () => ({}),
});

type QueryAction = {
  type: "PATCH_SERIES";
  id: string;
  data: Partial<SeriesSet>;
};

const defaultCustom: string = "Overall Dealership > REVENUE > Total Dealership Sales";

const defaultSeries: SeriesSet = {
  series: { name: "", rows: [], classification: "income", type: "currency", formats: { pos: "", neg: "" } },
  loading: { loaded: false, loading: true, error: null },
  custom: CustomDashboardMeasures[defaultCustom].getMeasure(defaultCustom, CustomDashboardMeasures),
};

const defaultSeriesStacked: any = {
  data: { name: "", rows: [], measures: [], classifications: [], types: [], formats: { pos: "", neg: "" } },
  loading: { loaded: false, loading: true, error: null },
};

export const CustomChartProvider = ({ children }) => {
  const memo = (report: Report, query: Query, custom: any) => `${report}-${stringifyQuery(query)}-${JSON.stringify(custom)}`;

  const [series, dispatchSeries] = useReducer((state: { [index: string]: SeriesSet }, action: QueryAction) => {
    switch (action.type) {
      case "PATCH_SERIES":
        return {
          ...state,
          [action.id]: {
            ...state[action.id],
            ...action.data,
          },
        };
      default:
        return state;
    }
  }, {});

  const [seriesStacked, dispatchSeriesStacked] = useReducer((state: { [index: string]: any }, action: any) => {
    switch (action.type) {
      case "PATCH_SERIES":
        return {
          ...state,
          [action.id]: {
            ...state[action.id],
            ...action.data,
          },
        };
      default:
        return state;
    }
  }, {});
  // scorecard
  const value = useMemo(
    () => ({
      series,
      seriesStacked,
      getCustomSeries: (report: Report, query: Query, custom: any) => {
        const id = memo(report, query, custom);
        const set = series[id];

        if (set) {
          return set;
        }

        dispatchSeries({
          type: "PATCH_SERIES",
          id,
          data: defaultSeries,
        });

        customSeriesFor(query, report, custom)
          .then(series => {
            dispatchSeries({
              type: "PATCH_SERIES",
              id,
              data: {
                series: series,
                custom: custom,
                loading: {
                  loaded: true,
                  loading: false,
                  error: null,
                },
              },
            });
          })
          .catch(e => {
            dispatchSeries({
              type: "PATCH_SERIES",
              id,
              data: {
                loading: {
                  loaded: false,
                  loading: false,
                  error: e,
                },
              },
            });
          });

        return defaultSeries;
      },
      getCustomSeriesStacked: (report: Report, query: Query, custom: any) => {
        const id = memo(report, query, custom);
        const set = seriesStacked[id];

        if (set) {
          return set;
        }

        dispatchSeriesStacked({
          type: "PATCH_SERIES",
          id,
          data: defaultSeriesStacked,
        });

        customStackedFor(query, report)
          .then(series => {
            dispatchSeriesStacked({
              type: "PATCH_SERIES",
              id,
              data: {
                data: series,
                custom: custom,
                loading: {
                  loaded: true,
                  loading: false,
                  error: null,
                },
              },
            });
          })
          .catch(e => {
            dispatchSeriesStacked({
              type: "PATCH_SERIES",
              id,
              data: {
                loading: {
                  loaded: false,
                  loading: false,
                  error: e,
                },
              },
            });
          });

        return defaultSeriesStacked;
      },
    }),
    [series, seriesStacked],
  );

  return <CustomChartContext.Provider value={value}>{children}</CustomChartContext.Provider>;
};
