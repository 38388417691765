import React, { createContext, useState, useMemo } from "react";

const defaultBrand = {};

export type AnalysisFilterBrandContextState = {
  brand: any;
  updateBrand(newBrand: any);
};

export const AnalysisFilterBrandContext = createContext<AnalysisFilterBrandContextState>({
  brand: defaultBrand,
  updateBrand: () => {},
});

export const AnalysisFilterBrandProvider = ({ children }) => {
  const [brand, setBrand] = useState({ value: "consolidated", label: "Total Dealer" });
  const value = useMemo(
    () => ({
      brand,
      updateBrand: async (newBrand: any) => {
        try {
          setBrand(newBrand);
        } catch (e) {
          return e;
        }
      },
      setBrand,
    }),
    [brand],
  );
  return <AnalysisFilterBrandContext.Provider value={value}>{children}</AnalysisFilterBrandContext.Provider>;
};
