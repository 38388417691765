import { request, apiHeaders } from "./base";

export type Task = {
  id: number;
  user: any;
  date: string;
  task: string;
  completed: boolean;
}

export type TaskAttachment =  {
  id: number
  file: any;
  taskId: number;
}

export const getTasks = (): Promise<Task[]> => request("/todolist").then((res) => res.json()).then(({ data }) => data );

export const createTask = (todo: Task) => request("/todolist", {
  method: "POST",
  body: JSON.stringify(todo)
}).then((res) => res.json());

export const updateTask = (todo: Task) => request("/todolist", {
  method: "PUT",
  body: JSON.stringify(todo)
}).then((res) => res.json());

export const deleteTask = (id: number) => request(`/todolist/${id}`, {
  method: "DELETE"
}).then((res) => res.json());

/* TASKS ATTACHMENTS */

export const getTaskAttachments = (): Promise<TaskAttachment[]> => request("/todo-attachments").then((res) => res.json()).then(({ data }) => data );

export const createTaskAttachments = async (file: Partial<TaskAttachment>) => {
  const formData = new FormData();

  Object.keys(file).forEach((key) => {
    formData.append(key, file[key]);
  });

  return request("/todo-attachments", {
    method: "POST",
    body: formData,
    headers: new Headers({
      Accept: "application/json",
      "X-csrf-validation": "-",
    })
  }).then((res) => res.json());
}

export const deleteTaskAttachment = (id: number) => request(`/todo-attachment/${id}`, {
  method: "DELETE",
}).then((res) => res.json());
