import React, { createContext, useReducer, useMemo } from "react";
import { MetricTable, MetricReport, Query, stringifyQuery, metricTableFor } from "../lib/api/charts";
import { Loading } from "./base";

type MetricTableSet = { metric: MetricTable; loading: Loading };

export type MetricTableContextState = {
  metric: {
    [index: string]: MetricTableSet;
  };
  getMetricTable(report: MetricReport, query: Query): Partial<MetricTableSet>;
};

export const MetricTableContext = createContext<MetricTableContextState>({
  metric: {},
  getMetricTable: () => ({}),
});

type QueryAction = {
  type: "PATCH_METRIC";
  id: string;
  data: Partial<MetricTableSet>;
};

const defaultMetricTable: MetricTableSet = {
  metric: {
    name: "",
    classification: [],
    columns: [],
    rows: [],
    data: [],
    style: [],
    aggregationOverride: [],
    measures: [],
    translationKeys: [],
    divideMeasure: [],
  },
  loading: { loaded: false, loading: true, error: null },
};

export const MetricTableProvider = ({ children }) => {
  const memo = (report: MetricReport, query: Query) => `${report}-${stringifyQuery(query)}`;

  const [metric, dispatchMetricTable] = useReducer((state: { [index: string]: MetricTableSet }, action: QueryAction) => {
    switch (action.type) {
      case "PATCH_METRIC":
        return {
          ...state,
          [action.id]: {
            ...state[action.id],
            ...action.data,
          },
        };
      default:
        return state;
    }
  }, {});

  const value = useMemo(
    () => ({
      metric,
      getMetricTable: (report: MetricReport, query: Query) => {
        const id = memo(report, query);
        const set = metric[id];

        if (set) {
          return set;
        }

        dispatchMetricTable({
          type: "PATCH_METRIC",
          id,
          data: defaultMetricTable,
        });

        metricTableFor(query, report)
          .then(metric => {
            dispatchMetricTable({
              type: "PATCH_METRIC",
              id,
              data: {
                metric: metric,
                loading: {
                  loaded: true,
                  loading: false,
                  error: null,
                },
              },
            });
          })
          .catch(e => {
            dispatchMetricTable({
              type: "PATCH_METRIC",
              id,
              data: {
                loading: {
                  loaded: false,
                  loading: false,
                  error: e,
                },
              },
            });
          });

        return defaultMetricTable;
      },
    }),
    [metric],
  );

  return <MetricTableContext.Provider value={value}>{children}</MetricTableContext.Provider>;
};
