import {
  default as blue,
  default as blue700
} from "@material-ui/core/colors/blue";
import grey from "@material-ui/core/colors/grey";
import { createMuiTheme } from "@material-ui/core/styles";

export const red = {
  main: "#b71234",
  light: "#ef515d",
  dark: "#80000f"
};

export const anthracite = {
  main: "#6c6f70",
  light: "#9a9d9e",
  dark: "#414445"
};

export const analytics = {
  main: "#00b092",
  light: "#58e3c2",
  dark: "#008064"
};

const pallates = {
  default: {
    primary: blue,
    secondary: blue700
  },
  service: {
    primary: blue,
    secondary: blue700
  },
  new: {
    primary: blue,
    secondary: blue700
  },
  used: {
    primary: blue,
    secondary: blue700
  },
  parts: {
    primary: blue,
    secondary: blue700
  }
};

export const margin = {
  width: "1500px",
}

const theme = createMuiTheme();

export const themeFor = (department?: string) => {
  const pal = pallates[department] || pallates.default;
  return createMuiTheme({
    palette: pal,
    typography: {
      fontFamily: [
        "Montserrat",
        "-apple-systrem",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Roboto",
        "Oxygen-Sans",
        "Ubuntu",
        "Cantarell",
        "Helvetica Neue",
        "Helvetica",
        "Arial",
        "sans-serif"
      ].join(","),
      fontSize: 11
    },
    overrides: {
      MuiModal: {
        root: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }
      },
      MuiPaper: {
        rounded: {
          borderRadius: "none"
        },
        root: {
          boxShadow: `${theme.shadows[0]} !important`,
          // boxShadow: `3px 3px 5px 6px ${grey["300"]}`,
          border: `solid 1px ${grey["300"]}`,
          fontFamily:
            "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif"
        }
      },
      MuiTableRow: {
        root: {
          height: "30px"
        },
        head: {
          height: "30px"
        }
      }
    }
  });
};
