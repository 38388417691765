import React, { createContext, useState, useReducer, useMemo } from "react";
import { User, userConfig, whoami, updateUser, removeUserImg } from "../lib/api/user";
import { useLoading, Loading } from "../hooks/useLoading";

const defaultUser = {
  id: "",
  email: "",
  session: "",
  token: "",
  modules: {
    upload: "",
    login: "",
  },
  firstname: "",
  lastname: "",
  phone: "",
  address: "",
  usertype: "A",
  brands: [],
  languageAccess: [],
  reloadAccess: false,
};

export type UserContextState = {
  user: User;
  update(user: Partial<User>);
  removeUserProfilePicture(user: Partial<User>);
  loading: Loading;
  clearError();
  hydrate();
};

export const UserContext = createContext<UserContextState>({
  user: defaultUser,
  loading: { loading: false, loaded: false, error: null },
  hydrate: () => {},
  update: () => {},
  clearError: () => {},
  removeUserProfilePicture: () => {},
});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState<User>(defaultUser);
  const [loading, setLoading, withLoading] = useLoading<void>();
  const value = useMemo(
    () => ({
      user,
      loading,
      clearError: () => {
        setLoading({
          error: null,
        });
      },
      hydrate: async () => {
        return withLoading(async () => {
          const user = await whoami();
          setUser(user);
        });
      },
      update: async user => {
        return withLoading(async () => {
          await updateUser(user);
          setUser(user);
        });
      },
      removeUserProfilePicture: async user => {
        return withLoading(async () => {
          await removeUserImg(user.id);
          setUser(user);
        });
      },
      setUser,
    }),
    [user, loading],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
